

























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { Prop, Watch } from 'vue-property-decorator';
import ProductImage from '@/models/graphql/ProductImage';
import Category from '@/models/graphql/Category';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { Getter } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    Splide,
    SplideSlide,
    RecommendationBadgeComponent,
    FontAwesomeComponent,
  },
})
export default class ProductPageHeaderComponent extends BreakpointWrapper {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: false, default: () => [] })
  readonly categoriesInContext!: Category[];

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: false })
  readonly featured!: boolean;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBadge!: boolean;

  @Prop({ required: false, default: 'Product' })
  protected readonly __typename!: string | null;

  @Prop({ required: false, default: null })
  protected readonly name!: string | null;

  @Prop({ required: false, default: [] })
  private readonly images!: ProductImage[];

  @Prop({ default: false })
  readonly _isExhibitorFeatured!: boolean;

  private EntityType = EntityType;

  private reloadSlide = true;

  private reloadPrimarySlide = 1;

  private reloadSecondarySlide = 0;

  @Watch('breakpoint', { deep: true })
  private get slidePrimaryOptions(): object {
    return {
      type: 'slide',
      rewind: true,
      autoWidth: true,
      pagination: false,
      arrows: !this.isMobile,
    };
  }

  @Watch('breakpoint', { deep: true })
  private get slideSecondaryOptions(): object {
    if (this.isMobile) {
      return {
        type: 'slide',
        rewind: true,
        arrows: false,
        autoWidth: true,
        pagination: true,
      };
    }

    return {
      type: 'slide',
      rewind: true,
      gap: '1rem',
      pagination: false,
      fixedWidth: 60,
      fixedHeight: 60,
      cover: true,
      focus: 'center',
      isNavigation: true,
      arrows: false,
    };
  }

  private get slides(): { src: string; alt: string }[] {
    if (this.images && typeof this.images !== 'string') {
      return this.images
        .filter((item) => item.fullFileResource && item.fullFileResource.path)
        .map((item) => ({
          src: FileResourceHelper.getFullPath(item.fullFileResource as FileResource, 'w1110'),
          alt: '',
        }));
    }

    return [];
  }

  private get categories(): string {
    const categories = '';
    if (this
      && this.categoriesInContext) {
      if (Array.isArray(this.categoriesInContext)
        && (this.categoriesInContext as Category[]).length > 0) {
        const entity = this.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 3).map((c) => c.name).join(', ')}
          ${entity.length > 3 ? ` + ${entity.length - 3}` : ''}`;
        }
      }
    }
    return categories;
  }

  @Watch('breakpoint', { deep: true })
  private updateBreakpoints(): void {
    this.reloadSlide = true;
    this.reloadPrimarySlide += 1;
    this.reloadSecondarySlide += 10;
    this.syncSlides();
  }

  private syncSlides(): void {
    if (this.reloadSlide && this.$refs.primary && this.$refs.secondary) {
      const primary = this.$refs.primary as Vue & { sync: (splide: object) => void };
      const secondary = this.$refs.secondary as Vue & { splide: object };
      primary.sync(secondary.splide);
      this.reloadSlide = false;
    }
  }

  private updated(): void {
    this.syncSlides();
  }

  private mounted(): void {
    this.syncSlides();
  }

  private get isExhibitorFeatured(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this._isExhibitorFeatured
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE).enabled;
  }
}
