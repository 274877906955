





















import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserFollowFilter } from '@/graphql/_Filters/CommunityUserFollowFilter';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const communityUserFollowStore = namespace('CommunityUserFollowStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class FollowButton extends BreakpointWrapper {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Prop({ required: true })
  private entityUid!: string;

  @Prop({ required: true })
  private entityType!: string;

  @Prop({ required: false, default: false })
  private mobile!: boolean;

  @Prop({ required: false, default: 'md' })
  private size!: string;

  @Prop({ required: false, default: '' })
  private classList!: string;

  @Prop({ required: false, default: 'secondary' })
  private variant!: string;

  @Prop({ required: false, default: 'alt-2' })
  private variantSecond!: string;

  @Prop({ required: false, default: true })
  private rounded!: boolean;

  @Prop({ required: false, default: false })
  private autoFollow!: boolean;

  @Prop({ required: false, default: null })
  private _isFollowed!: string | null;

  @communityUserFollowStore.Action
  private follow!: (payload: CommunityUserFollowFilter) => Promise<CommunityUserFollow | undefined>;

  @communityUserFollowStore.Action
  private unFollowEntity!: (payload: {
    userId: string;
    entityId: string;
  }) => Promise<CommunityUserFollow | undefined>;

  private isFollowing = false;

  @Watch('_isFollowed', { immediate: true })
  private setIsFollowing(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.isFollowing = !!this._isFollowed;
  }

  private toggleFollow(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isFollowing) {
      this.isFollowing = false;
      if (this.entityUid) {
        this.unFollowEntity({
          userId: this.authUser.uid,
          entityId: this.entityUid,
        }).catch(() => {
          this.isFollowing = true;
        });
      }
    } else {
      this.isFollowing = true;
      this.follow({
        userId: this.authUser.uid,
        linkedUserId: this.entityUid,
        entityType: this.entityType,
      }).then(() => {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          this.entityType,
          StatLoggerActions.ADD,
          '',
          -1,
          this.entityUid,
          StatLoggerCategories.FOLLOW,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.isFollowing = false;
      });
    }
  }
}
