
































































import Component from 'vue-class-component';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import { Prop } from 'vue-property-decorator';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { format } from 'date-fns';
import { State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    RecommendationBadgeComponent,
  },
})
export default class ArticlePageHeaderComponent extends BreakpointWrapper {
  @Prop({ default: false })
  readonly featured!: boolean;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBadge!: boolean;

  @Prop({ required: false, default: null })
  protected readonly type!: string | null;

  @Prop({ required: false, default: null })
  protected readonly name!: string | null;

  @Prop({ required: false, default: null })
  protected readonly subtitle!: string | null;

  @Prop({ required: false, default: null })
  protected readonly authorName!: string | null;

  @Prop({ required: false, default: null })
  protected readonly updateTime!: string | null;

  @Prop({ required: false, default: null })
  protected readonly publicationTime!: string | null;

  @Prop({ required: false, default: () => [] })
  private readonly images!: FileResource[];

  @State
  private dateLocale!: Locale;

  private get bannerSrc(): string {
    return FileResourceHelper.getFullPath(this.images[0], 'w1110');
  }

  private get jobTitleEmployer(): string {
    let articleDates = this.$t('actions.create-update') as string;
    if (this.updateTime) {
      // eslint-disable-next-line max-len
      articleDates += ` ${format(new Date(this.updateTime as unknown as string), this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}`;
    } else {
      // eslint-disable-next-line max-len
      articleDates += ` ${format(new Date(this.publicationTime as unknown as string), this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}`;
    }
    if (this.authorName) {
      articleDates += ` · ${this.$t('actions.written-by')} ${this.authorName}`;
    }
    return articleDates;
  }
}
