


























































import { Component, Prop } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
  },
})
export default class PillComponent extends VueContextLoading {
  @Prop({
    required: false,
    default: '',
  })
  private readonly text!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly small!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isAddTag!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly clearable!: boolean;
}
