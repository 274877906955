

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import EntityType from '@/utils/enums/EntityType';
import FileResource from '@/models/graphql/FileResource';
import { isSameMonth, isSameYear } from 'date-fns/esm';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';
import FollowButton from '@/components/buttons/FollowButton.vue';

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
    FollowButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedExhibitingAtCard extends Vue {
  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private code!: string;

  @Prop({ required: false })
  private name!: string;

  @Prop({ required: false })
  private logoFileResource!: FileResource;

  @Prop({ required: false })
  private startTimestamp!: number;

  @Prop({ required: false })
  private endTimestamp!: number;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  @Prop({ required: false, default: null })
  private _isFollowed!: string | null;

  @Prop({ required: false, default: null })
  private _followerCount!: number;

  private entityType = EntityType.SUB_EDITION;

  @State
  private dateLocale!: Locale;

  private get imageSrc(): string {
    return FileResourceHelper.getFullPath(this.logoFileResource, 'w250');
  }

  private get time(): string {
    let startTime = '';
    let endTime = '';
    if (isSameMonth(this.startTimestamp, this.endTimestamp)
      && isSameYear(this.startTimestamp, this.endTimestamp)) {
      startTime = DateTimeHelper.formatFromUnixTime(
        this.startTimestamp as number,
        `${this.$t('app.date.monthDayFull')}`,
        this.dateLocale,
      );
      endTime = DateTimeHelper.formatFromUnixTime(
        this.endTimestamp as number,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
    } else {
      startTime = DateTimeHelper.formatFromUnixTime(
        this.startTimestamp as number,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
      endTime = DateTimeHelper.formatFromUnixTime(
        this.endTimestamp as number,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
    }
    return `${startTime}${this.$t('app.date.dateTimeSeparator')}${endTime}`;
  }

  private get eventDetailUrl(): object {
    return { name: 'event-detail', params: { editionCode: this.code || '' } };
  }
}
