























































import {
  Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import VueContextLoading from '@/components/VueContextLoading';

@Component({
  components: { PickerCalendarComponent, InputSearchComponent, FilterItemComponent },
})
export default class FilterPickerCalendarComponent extends VueContextLoading {
  @Inject({ from: 'windowWidth' })
  private readonly windowWidth!: { value: number };

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: false, default: () => [] })
  private readonly selectedDates!: number[];

  @Prop({ required: false, default: () => [] })
  private readonly events!: number[];

  @Prop({ required: false, default: PickerCalendarType.MULTI })
  private readonly type!: PickerCalendarType;

  @Prop({ required: false, default: true })
  private readonly isDouble!: boolean;

  private showCalendarPicker = false;

  private selectedTimestamps: number[] = this.selectedDates;

  private styles: { top: string; left: string; width: string } = { top: '0px', left: '0px', width: '296px' };

  mounted(): void {
    this.updateStyles();
    this.updateSelectedDates();
    document.addEventListener('scroll', this.updateStyles);
    this.$watch('showCalendarPicker', () => (this.showCalendarPicker ? this.updateStyles() : ''));
  }

  private setNewSelections(dates: number[]): void {
    this.selectedTimestamps = dates;
    this.$emit('selected-timestamps-dates', dates);
  }

  @Watch('selectedDates')
  private updateSelectedDates(): void {
    this.selectedTimestamps = this.selectedDates;
  }

  @Watch('windowWidth.value')
  private updateStyles(): void {
    const element = this.$el.querySelector('div.filter-picker-pill');
    const filter = this.$el.querySelector('div.filter-calendar');
    if (element && filter) {
      const pillLeft = element.getBoundingClientRect().left;
      const top = element.getBoundingClientRect().top + element.getBoundingClientRect().height + 8;
      const { width } = filter.getBoundingClientRect();
      const safeZone = 16;
      if (this.windowWidth.value > width + pillLeft + safeZone) {
        this.styles = {
          top: `${top}px`,
          left: `${pillLeft}px`,
          width: 'auto',
        };
      } else if (this.windowWidth.value > width + safeZone) {
        this.styles = {
          top: `${top}px`,
          left: `${safeZone}px`,
          width: 'auto',
        };
      } else {
        this.styles = {
          top: `${top}px`,
          left: '0px',
          width: 'auto',
        };
      }
    }
  }
}
