




















































































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import Session from '@/models/graphql/Session';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import InputText from '@/components/InputText.vue';

const toastStore = namespace('ToastStore');

@Component({
  components: { InputText, ButtonComponent },
})
export default class SessionLiveStreamConfigComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private videoLiveRTMPEndpoint = '';

  private videoLiveRTMPKey = '';

  private get session(): Session | null {
    if (this.data && this.data.length > 0) {
      return Session.hydrate(this.data[0]);
    }
    return null;
  }

  mounted(): void {
    if (this.session && this.session.videoLiveRTMPEndpoint && this.session.videoLiveRTMPKey) {
      if (this.session.videoLiveRTMPEndpoint) {
        this.videoLiveRTMPEndpoint = this.session.videoLiveRTMPEndpoint;
      }
      if (this.session.videoLiveRTMPKey) {
        this.videoLiveRTMPKey = this.session.videoLiveRTMPKey;
      }
    }
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: { uid: this.payload.entityCode },
          },
        }),
        operation: 'session',
        fragmentName: 'sessionLiveStreamConfigFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  private copy(str: string): void {
    navigator.clipboard.writeText(str);
    this.addNewAction({ message: this.$tc('live-stream-config.copied'), delay: 2500 });
  }
}
