var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dateTabs.length > 0)?_c('div',{staticClass:"sessions-calendar-date-tabs d-flex justify-content-center pt-32 pb-2 overflow-hidden"},[_c('div',{class:['wrapper d-flex align-items-center overflow-x-auto hide-scrollbar',
             _vm.isMobile ? 'column-gap-6': 'column-gap-12']},_vm._l((_vm.dateTabs),function(date){return _c('div',{key:date.key,staticClass:"d-flex align-items-center justify-content-center flex-column cursor-pointer",on:{"click":function($event){return _vm.onClick(date.key)}}},[_c('span',{class:['caps caps-3 bold',
                 date.selected
                   ? 'text-blue-b-3-primary-blue'
                   : 'text-neutral-n-5-placeholder'
        ]},[_vm._v(" "+_vm._s(date.dayOfWeek)+" ")]),_c('span',{class:['date caps caps-3 bold d-flex align-items-center justify-content-center',
                 date.selected
                   ? 'text-white-white-100 bg-blue-b-3-primary-blue rounded-circle'
                   : 'text-neutral-n-5-placeholder']},[_vm._v(" "+_vm._s(date.dayOfMonth)+" ")])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }