import { Component, Vue } from 'vue-property-decorator';
import { BasicTypes } from '@/utils/types/BasicTypes';
import ActionButtonVisibilityBaseStore from '@/store/ActionButtonVisibilityBaseStore';

@Component({
  inheritAttrs: false,
})
export default class ActionButtonGroupRegisterStore extends Vue {
  protected visibilityStoreName!: string;

  created(): void {
    if (this.visibilityStoreName && !this.$store.hasModule(this.visibilityStoreName)) {
      this.$store.registerModule(this.visibilityStoreName, ActionButtonVisibilityBaseStore);
    }
  }

  destroyed(): void {
    if (this.visibilityStoreName && !this.$store.hasModule(this.visibilityStoreName)) {
      this.$store.unregisterModule(this.visibilityStoreName);
    }
  }

  protected dispatch<T>(name: string, payload?: BasicTypes): Promise<T> {
    return this.$store.dispatch(`${this.visibilityStoreName}/${name}`, payload);
  }

  protected commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`${this.visibilityStoreName}/${name}`, payload);
  }

  protected getter<T>(name: string): T {
    return this.$store.getters[`${this.visibilityStoreName}/${name}`];
  }

  protected state<T>(name: string): T {
    return this.$store.state[this.visibilityStoreName][name];
  }
}
