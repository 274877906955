



















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import CommunityUser from '@/models/graphql/CommunityUser';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class BookmarkButton extends Vue {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Prop({ required: true })
  private entityUid!: string;

  @Prop({ required: true })
  private entityType!: string;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  @communityUserBookmarkStore.Action
  private bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  private unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  private userBookmark!: CommunityUserBookmark | undefined;

  private isBookmarked = false;

  @Watch('_isBookmarked', { immediate: true })
  private setIsBookmarked(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.isBookmarked = !!this._isBookmarked;
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isBookmarked) {
      this.isBookmarked = false;
      this.unBookmark({
        uid: this.userBookmark?.uid,
      }).catch(() => {
        this.isBookmarked = true;
      });
    } else {
      this.isBookmarked = true;
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.entityUid,
        entityType: this.entityType,
      }).then(() => {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          this.entityType,
          StatLoggerActions.ADD,
          '',
          -1,
          this.entityUid,
          StatLoggerCategories.BOOKMARK,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.isBookmarked = false;
      });
    }
  }
}
