import { render, staticRenderFns } from "./ArticlePageHeaderComponent.vue?vue&type=template&id=37d07110&scoped=true&"
import script from "./ArticlePageHeaderComponent.vue?vue&type=script&lang=ts&"
export * from "./ArticlePageHeaderComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/DetailPageHeaderComponent.scss?vue&type=style&index=0&id=37d07110&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d07110",
  null
  
)

export default component.exports