var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['live-poll-component  container-fluid position-relative h-100 d-flex flex-column',(_vm.isFeedItem) ? '' : 'px-7']},[(!_vm.isFeedItem)?_c('div',{staticClass:"header-live-poll mb-5"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('live-session.poll.title'))+" ")])]):_vm._e(),_c('div',{staticClass:"body d-flex flex-column shadows-line-up"},[_c('h3',{staticClass:"text-neutral-n-8-dark-ink my-4 text-left"},[_vm._v(" "+_vm._s(_vm.poll.title)+" ")]),_c('transition',{attrs:{"name":"fade"}},[((_vm.poll._myAnswer && _vm.poll._myAnswer.length)
          || (_vm.poll.endTimestamp <= _vm.DateTimeHelper.currentTimestamp) || _vm.isOwner)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_vm._l((_vm.poll.pollAnswers),function(answer){return _c('div',{key:answer.uid},[_c('b-progress',{class:[
              {'my-answer': _vm.poll._myAnswer
                && _vm.poll._myAnswer.length
                && _vm.poll._myAnswer[0].uid === answer.uid} ]},[_c('b-progress-bar',{attrs:{"max":_vm.getPollAnswersTotalCount(_vm.poll),"value":_vm.getPollAnswerCount(answer)}}),_c('div',{staticClass:"d-flex w-100 justify-content-between mb-2 position-absolute m-0 progress-text"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"d-flex text-neutral-n-8-dark-ink ml-3 flex-row"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center mr-3"},[(_vm.poll._myAnswer
                        && _vm.poll._myAnswer.length
                        && _vm.poll._myAnswer[0].pollAnswer
                        && _vm.poll._myAnswer[0].pollAnswer.uid === answer.uid)?_c('font-awesome-component',{attrs:{"icon":"fa-solid fa-check-circle","icon-classes":"icon-24 justify-content-center my-answer-progress-check label-2 medium m-0"}}):_vm._e()],1),_c('p',{staticClass:"d-flex align-items-center justify-content-center mb-0"},[_vm._v(" "+_vm._s(answer.title)+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center mr-3"},[_c('p',{staticClass:"m-0 mr-1 label-2 medium text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.getAnswerPercentage(answer))+" ")]),_c('p',{staticClass:"m-0 label label-3 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(("(" + (_vm.$tc('video-player.theater.polls.vote-count', 0, {count: answer.answerCount})) + ")"))+" ")])])])],1)],1)}),_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('p',{staticClass:"mb-0 mt-2 label label-3 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(((_vm.$tc('video-player.theater.polls.vote-count', 0, {count: _vm.getPollAnswersTotalCount(_vm.poll)})) + " " + _vm.timeLeft))+" ")])])],2):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[((_vm.poll._myAnswer && _vm.poll._myAnswer.length === 0
          && _vm.poll.endTimestamp > _vm.DateTimeHelper.currentTimestamp) && !_vm.isOwner)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_vm._l((_vm.poll.pollAnswers),function(answer){return _c('button-component',{key:answer.uid,staticClass:"text-left",attrs:{"rounded":false,"text":answer.title,"size":"lg","variant":"secondary"},on:{"on-click":function($event){return _vm.onAnswerClick(answer)}}})}),_c('div',{staticClass:"d-flex align-items-center text-left justify-content-start"},[_c('p',{staticClass:"mb-0 mt-2 label label-3 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(((_vm.$tc('video-player.theater.polls.vote-count', 0, {count: _vm.getPollAnswersTotalCount(_vm.poll)})) + " " + _vm.timeLeft))+" ")])])],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }