























































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import { namespace } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { SFacebook, STwitter } from 'vue-socials';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

const toastStore = namespace('ToastStore');

@Component({
  components: {
    StandardModal,
    ButtonComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    SFacebook,
    STwitter,
  },
})
export default class ShareModal extends VueBaseWidget {
  @Prop({ default: '' })
  url!: string;

  @Prop({ default: '' })
  type!: string;

  @Prop({ default: '' })
  modalId!: number;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private windowFeatures = {};

  private facebookShareOptions = {
    url: this.url,
    quote: '',
    hashtag: '',
  };

  private twitterShareOptions = {
    url: this.url,
    text: '',
    hashtags: [],
    via: '',
  };

  private useNativeBehavior = false;

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private async copyToClipBoard(): Promise<void> {
    try {
      await navigator.clipboard.writeText(this.url);
      this.showToast(ToastActionType.COPY_TO_CLIPBOARD);
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.ADD,
        'link',
        -1,
        '',
        StatLoggerCategories.SHARE,
        this.$i18n.locale,
      );
    } catch (err) {
      this.showToast(ToastActionType.FAILED_COPY_TO_CLIPBOARD);
    }
  }

  private facebookPost(): void {
    const facebookElement = this.$el.querySelector(`.facebook-${this.modalId}`);
    (facebookElement as HTMLElement).click();
    this.$logger.logMatomoStats(
      this.authUser,
      this.community.code || '',
      '',
      StatLoggerActions.ADD,
      'facebook',
      -1,
      '',
      StatLoggerCategories.SHARE,
      this.$i18n.locale,
    );
  }

  private twitterPost(): void {
    const twitterElement = this.$el.querySelector(`.twitter-${this.modalId}`);
    (twitterElement as HTMLElement).click();
    this.$logger.logMatomoStats(
      this.authUser,
      this.community.code || '',
      '',
      StatLoggerActions.ADD,
      'twitter',
      -1,
      '',
      StatLoggerCategories.SHARE,
      this.$i18n.locale,
    );
  }
}

