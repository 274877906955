









































































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import DropdownComponent from '@/components/form/DropdownComponent.vue';
import ChoiceParams from '@/utils/types/ChoiceParams';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    DropdownComponent,
    InputText,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class PollDurationModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  authUser!: CommunityUser;

  @Getter
  private community!: Community;

  private selectedDay = this.days[1];

  private selectedHour = this.hours[0];

  private selectedMinute = this.minutes[5];

  private selectedSecond = this.seconds[0];

  private chooseTime = true;

  private realTimeAnswers = true;

  private get days(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 7) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.days',
          0,
          { days: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  private get hours(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 23) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.hours',
          0,
          { hours: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  private get minutes(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 59) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.minutes',
          0,
          { minutes: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 1;
    }
    return result;
  }

  private get seconds(): ChoiceParams[] {
    const result = [];
    let count = 0;
    while (count <= 59) {
      result.push({
        text: this.$tc('session-polls.modal.poll-duration.dropdown.seconds',
          0,
          { seconds: count.toString() }),
        value: count.toString(),
      } as ChoiceParams);
      count += 5;
    }
    return result;
  }

  private onPublish(): void {
    this.$emit('on-publish', {
      days: this.selectedDay,
      hours: this.selectedHour,
      minutes: this.selectedMinute,
      seconds: this.selectedSecond,
      chooseTime: this.chooseTime,
      showLiveAnswer: this.realTimeAnswers,
    });
    this.onCancel();
  }

  private onDayChange(day: ChoiceParams): void {
    this.selectedDay = day;
    if (day && day.value === '7') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedHour = this.hours[0];
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[0];
    }
    if (day && day.value === '0' && this.selectedHour.value === '0') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[5];
    }
  }

  private onHourChange(hour: ChoiceParams): void{
    this.selectedHour = hour;
    if (hour && hour.value === '0' && this.selectedDay.value === '0') {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = this.minutes[5];
    }
  }

  private onShow(): void {
    /* eslint-disable prefer-destructuring */
    this.selectedDay = this.days[0];
    this.selectedHour = this.hours[0];
    this.selectedMinute = this.minutes[5];
    this.selectedSecond = this.seconds[0];
    this.realTimeAnswers = true;
    this.chooseTime = true;
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
  }
}
