









































































import { Component, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MobileMenuModal from '@/components/modals/MobileMenuModal.vue';
import { namespace } from 'vuex-class';
import MobileMenuItemParams from '@/utils/types/MobileMenuItemParams';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import { Portal } from 'portal-vue';

const companyUserRoleStore = namespace('CompanyUserRoleStore');

@Component({
  components: {
    MobileMenuModal,
    FontAwesomeComponent,
    ButtonComponent,
    Portal,
  },
})
/* eslint-disable no-underscore-dangle */
export default class ActionButtonCompanyInvite extends VueBaseActionButtonWidget {
  @companyUserRoleStore.Getter
  private fetchCompanyUserRoles!: CompanyUserRole[];

  @companyUserRoleStore.Getter
  private saveLoading!: boolean;

  private companyInvitationRequest: CompanyUserRole | null = null;

  private respondMenuOpen = false;

  private respondMenuButtons: MobileMenuItemParams[] = [
    {
      icon: 'far fa-user-plus',
      label: 'actions.accept-company-invite-request-menu',
      action: 'on-accept-company-invite',
    },
    {
      icon: 'far fa-user-xmark',
      label: 'actions.decline-company-invite-request-menu',
      action: 'on-accept-company-invite',
    },
  ];

  private get isInvited(): boolean {
    return !!(this.companyInvitationRequest
        && this.companyInvitationRequest.user
        && this.companyInvitationRequest.user.uid === this.authUser.uid
        && this.companyInvitationRequest.state === CompanyUserRoleStatusType.INVITED);
  }

  private get isDisplayed(): boolean {
    return (this.authUser
        && this.actionType === this.ActionButtonType.COMPANY_INVITE
        && this.entityType === this.EntityTypeEnum.EXHIBITOR
        && this.isInvited);
  }

  created(): void {
    this.setCompanyInvitationRequest();
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('fetchCompanyUserRoles')
  private setCompanyInvitationRequest(): void {
    const companyUserRole = this.fetchCompanyUserRoles
      .find((item) => item.company && item.company.uid === this.entityUid);
    if (companyUserRole) {
      this.companyInvitationRequest = companyUserRole;
    } else {
      this.companyInvitationRequest = null;
    }
  }

  private toggleRespondMenu(): void {
    if (this.isMobile && this.respondMenuOpen) {
      document.body.classList.remove('modal-open');
    } else if (this.isMobile && !this.respondMenuOpen) {
      document.body.classList.add('modal-open');
    }
    this.respondMenuOpen = !this.respondMenuOpen;
  }

  private onAccept(): void {
    this.respondMenuOpen = false;
    if (this.actionType === this.ActionButtonType.COMPANY_INVITE) {
      this.$root.$emit('accept-company-invitation',
        { companyUserRole: this.companyInvitationRequest });
    }
  }

  private onDecline(): void {
    this.respondMenuOpen = false;
    if (this.actionType === this.ActionButtonType.COMPANY_INVITE) {
      this.$root.$emit('decline-company-invitation',
        { companyUserRole: this.companyInvitationRequest });
    }
  }
}
