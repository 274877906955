import { ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/Role/Full';
import { EXHIBITOR_COMPANY_ROLE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/CompanyRole';

export const COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT = `
  fragment companyUserRoleForCommunityUserFragment on CompanyUserRole {
    uid
    schemaCode
    moderator
    canPost
    handleMeetings
    manageSales
    manageContent
    manageCompanyUserRoles
    defaultCompany
    state
    company {
      ...exhibitorCompanyRoleFragment
    }
    role {
      ...roleFullFragment
    }
  }
  ${EXHIBITOR_COMPANY_ROLE_FRAGMENT}
  ${ROLE_FULL_FRAGMENT}
`;
