


































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  minLength, required, requiredIf, sameAs,
} from 'vuelidate/lib/validators';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

const authenticationStore = namespace('AuthenticationStore');
const accountStore = namespace('AccountStore');
const communityUserStore = namespace('CommunityUserStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    AvatarSoloWidget,
    ButtonComponent,
    InputText,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {
        require: requiredIf((form: { haveCompanyRole: boolean }) => !form?.haveCompanyRole),
        minLength: minLength(1),
      },
      lastName: {
        require: requiredIf((form: { haveCompanyRole: boolean }) => !form?.haveCompanyRole),
        minLength: minLength(1),
      },
      haveCompanyRole: {},
      password: {
        required,
        minLength: minLength(8),
        valid(value: string): boolean {
          const containsUppercase = value ? value.match(/[A-Z]/) : false;
          const containsLowercase = value ? value.match(/[a-z]/) : false;
          const containsNumber = value ? value.match(/[0-9]/) : false;
          return !!containsUppercase && !!containsLowercase && !!containsNumber;
        },
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
      termsOfService: {
        required,
        sameAs: sameAs(() => true),
      },
      privacyPolicy: {
        required,
        sameAs: sameAs(() => true),
      },

    },
  },
})
export default class FindAccountCompleteForm extends VueBaseWidget {
  @Prop({ required: true, default: '' })
  token!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @authenticationStore.Action
  private login!: (credentials: { username: string; password: string }) => void;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @accountStore.Action
  private triggerWelcome!: (payload: {
    communityCode: string;
    userId: string;
  }) => void;

  @accountStore.Action
  private activateAccount!: (token: string) => Promise<Response>;

  @accountStore.Action
  private checkActivatability!: (
    payload: {
      token: string;
      email: string | null;
      password: string | null;
    }
  ) => Promise<Response>;

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => void;

  @communityUserStore.Action('getWithAllCompanyRole')
  private getUserById!: (uid: string) => void;

  @permissionManagerStore.Getter
  private haveACompanyRole!: (companyUid?: string) => boolean;

  @communityUserStore.Getter
  private communityUser!: CommunityUser;

  private form = {
    firstName: '',
    lastName: '',
    haveCompanyRole: false,
    password: '',
    repeatPassword: '',
    termsOfService: false,
    privacyPolicy: false,
  };

  private passwordFieldFocused = false;

  private passwordFieldIsDirty = false;

  private termsOfService = false;

  private FeatureKeys = FeatureKeys;

  private privacyPolicy = false;

  private repeatPasswordFieldFocused = false;

  private repeatPasswordFieldIsDirty = false;

  private firstNameFieldIsDirty = false;

  private lastNameFieldIsDirty = false;

  private tokenUserEmail = '';

  private tokenUserUid = '';

  private tokenCommunityName = '';

  private haveCompanyRole = false;

  private hasLastNameOwner = false;

  private FileResourceHelper = FileResourceHelper;

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.community?.logoFileResource), 'w96');
    }
    return '';
  }

  private get isUppercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[A-Z]/) : false;
  }

  private get isLowercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[a-z]/) : false;
  }

  private get hasNumber(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[0-9]/) : false;
  }

  private get CompanyOwnerFeature(): boolean {
    return !!(this.featureByKey(FeatureKeys.COMMUNITY_FORCE_UPDATE_USER_FEATURE)
      && (this.featureByKey(FeatureKeys.COMMUNITY_FORCE_UPDATE_USER_FEATURE) as CommunityFeature).enabled
      && this.haveCompanyRole);
  }

  mounted(): void {
    if (this.token) {
      const parsedToken = this.parseJwt(this.token);
      let userEmail = '';
      let userUid = '';
      let communityName = '';
      if (parsedToken) {
        userEmail = parsedToken.e ? parsedToken.e : '';
        userUid = parsedToken.u ? parsedToken.u : '';
        communityName = parsedToken.c ? parsedToken.c : '';
      }
      this.tokenUserEmail = userEmail;
      this.tokenUserUid = userUid;
      this.tokenCommunityName = communityName;
    }
    if (this.featureByKey(FeatureKeys.COMMUNITY_FORCE_UPDATE_USER_FEATURE)
      && (this.featureByKey(FeatureKeys.COMMUNITY_FORCE_UPDATE_USER_FEATURE) as CommunityFeature).enabled) {
      this.getUserById(this.tokenUserUid);
    }
  }

  onSubmit(event: Event): void {
    if (this.$v.$invalid) {
      if (this.CompanyOwnerFeature) {
        this.checkEmptyForm('firstName');
        this.checkEmptyForm('lastName');
      }
      this.checkEmptyForm('password');
      this.checkEmptyForm('repeatPassword');
      event.preventDefault();
    } else {
      this.checkActivatability({
        token: this.token,
        email: this.tokenUserEmail,
        password: this.form.password,
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.success) {
            if (this.CompanyOwnerFeature) {
              this.updateUserProfile({
                firstName: this.form.firstName.trim(),
                lastName: this.form.lastName.trim(),
              });
            }
            this.activateAccount(this.token).then((response) => response.json())
              .then((response) => {
                if (response.success) {
                  this.$emit('create-password-success');
                } else {
                  this.$emit('create-password-error');
                }
              })
              .catch();
          }
        })
        .catch();
    }
  }

  @Watch('isAuthenticated')
  loginUser(): void {
    if (this.isAuthenticated) {
      this.$router.push({ path: '/' });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseJwt(token: string): { c: string; e: string; exp: number; iat: number; u: string } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  }

  @Watch('communityUser')
  protected hasCompanyRole(): void {
    if (this.communityUser.lastName && this.communityUser.firstName) {
      this.form.lastName = this.communityUser?.lastName;
      this.form.firstName = this.communityUser?.firstName;
      this.haveCompanyRole = this.haveACompanyRole();
      this.form.haveCompanyRole = this.haveCompanyRole;
      if (!this.hasLastNameOwner) {
        this.hasLastNameOwner = this.lastNameIsOwner(this.communityUser?.lastName);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private lastNameIsOwner(lastName: string): boolean {
    return lastName === 'Owner';
  }

  private validateState(name: string): boolean {
    const $dirty = this.$v.form[name]?.$dirty;
    const $error = this.$v.form[name]?.$error;
    return $dirty ? !$error : true;
  }

  private onBlur(fieldType: string): void {
    const $error = this.$v.form[fieldType]?.$error;
    const $dirty = this.$v.form[fieldType]?.$dirty;
    if ($dirty) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldFocused = false;
          this.passwordFieldIsDirty = !!$error;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldFocused = false;
          this.repeatPasswordFieldIsDirty = !!$error;
          break;
        case 'firstName':
          this.$data.firstNameError = this.$v.form.firstName ? this.$v.form.firstName.$invalid : false;
          this.firstNameFieldIsDirty = !!$error;
          break;
        case 'lastName':
          this.$data.lastNameError = this.$v.form.lastName ? this.$v.form.lastName.$invalid : false;
          this.lastNameFieldIsDirty = !!$error;
          break;
        default:
          break;
      }
    }
  }

  private checkEmptyForm(fieldType: string): void {
    const $invalid = this.$v.form[fieldType]?.$invalid;
    const $required = this.$v.form[fieldType]?.required;
    if ($invalid) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldIsDirty = !$required;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldIsDirty = !$required;
          break;
        case 'firstName':
          this.firstNameFieldIsDirty = !$required;
          break;
        case 'lastName':
          this.lastNameFieldIsDirty = !$required;
          break;
        default:
          break;
      }
    }
  }

  private disabledSubmit(): boolean {
    if (!this.CompanyOwnerFeature) {
      this.form.firstName = 'firstName';
      this.form.lastName = 'lastName';
    }
    return this.$v.$invalid;
  }
}
