import { render, staticRenderFns } from "./FeedItemCreatorModal.vue?vue&type=template&id=136b13b6&scoped=true&"
import script from "./FeedItemCreatorModal.vue?vue&type=script&lang=ts&"
export * from "./FeedItemCreatorModal.vue?vue&type=script&lang=ts&"
import style0 from "../../../assets/styles/modal/FeedItemCreatorModal.scss?vue&type=style&index=0&id=136b13b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136b13b6",
  null
  
)

export default component.exports