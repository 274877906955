






































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

@Component({
  components: { FontAwesomeComponent, ButtonIconComponent, StandardModal },
})
export default class ImagePreviewModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ required: true, default: null })
  image!: FileResource;

  private FileResourceHelper = FileResourceHelper;

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.$emit('on-cancel');
  }
}
