



















































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import {
  Getter, Mutation, namespace, State,
} from 'vuex-class';
import VideoPlayerComponent from '@/components/video-player/VideoPlayerComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import MessageGroup from '@/models/graphql/MessageGroup';
import ActionType from '@/utils/enums/ActionType';
import { PollFilter } from '@/graphql/_Filters/PollFilter';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import Poll from '@/models/graphql/Poll';
import SessionCmsQaComponent from '@/components/session-cms/SessionCmsQaComponent.vue';
import ClientStorage from '@/utils/ClientStore';
import { getUnixTime, isBefore, isWithinInterval } from 'date-fns';
import Message from '@/models/graphql/Message';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import MessageQnaRepository from '@/repositories/MessageQnaRepository';
import PollRepository from '@/repositories/PollRepository';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';
import { VIEW_LIVE_SESSION_STAT_DELAY } from '@/utils/constants/Timer';
import PollComponent from '@/components/poll/PollComponent.vue';
import SessionSettingType from '@/utils/enums/SessionSettingType';
import { Location } from 'vue-router';
import { TabType } from '@/utils/types/TabType';

const pollStore = namespace('PollStore');
const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    PollComponent,
    SessionCmsQaComponent,
    ButtonComponent,
    VideoPlayerComponent,
    MessageBoxMessages,
    FontAwesomeComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
  },
})
export default class VideoPlayerTheaterComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  @notificationStore.Mutation
  private unsubscribeGenericEventPointer!: (channel: string) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private community!: Community;

  @Getter
  private authUser!: CommunityUser;

  @State
  private sessionSelectedTab!: TabType;

  @State
  private selectedTzName!: string;

  @Mutation
  private setSessionSelectedTab!: (tab: TabType) => void;

  @Prop({ default: 'ChatStore' })
  private readonly storeContext!: string;

  @Prop({ default: false })
  private readonly darkMode!: boolean;

  @Prop({ default: false })
  private readonly embedded!: boolean;

  @Prop({
    required: true,
    default: null,
  })
  private readonly sessionData!: Session;

  @Prop({
    required: false,
    default: true,
  })
  private readonly canViewVideo!: boolean;

  @pollStore.Action(ActionType.GET_ONE)
  private getPoll!: (payload: { filter: PollFilter }) => Promise<Poll>;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  private FileResourceHelper = FileResourceHelper;

  private toggleSidePanel = true;

  private newPoll: Poll | null = null;

  private newQaAnswer = false;

  private now = DateTimeHelper.getCurrentDateTime();

  private lastViewedPollConstant = 'last-viewed-poll';

  private lastViewedAnswerConstant = 'last-viewed-answer';

  private setIntervalKey: NodeJS.Timer | null = null;

  private settingType = SessionSettingType;

  private session = this.sessionData;

  private get selectedGroup(): MessageGroup {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedGroup`];
  }

  private get canShowSidePanel(): boolean {
    return this.session.videoType !== 'recorded'
        && this.canViewVideo
        && (this.isChatFeatureEnabled || this.isPollFeatureEnabled || this.isQaFeatureEnabled);
  }

  private get isChatFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
        && !!this.session.showLiveChat;
  }

  private get isQaFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE).enabled
        && !!this.session.showQNA;
  }

  private get isPollFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE).enabled
        && !!this.session.showPolls;
  }

  private get categories(): string {
    if (this.session && this.session.categories && this.session.categories.length) {
      const cat = this.session.categories.map((c) => c.name);
      if (cat && cat.length > 3) {
        return `${cat.join(' · ')} + ${cat.length - 3}`;
      }
      return cat.join(' · ') as string;
    }
    return '';
  }

  private get canLogViewLiveSession(): boolean {
    if (this.authUser
        && this.session
        && this.canViewVideo
        && (this.session.startTime || this.session.videoEnabledStartTime)
        && (this.session.endTime || this.session.videoEnabledEndTime)) {
      const today = DateTimeHelper.getCurrentDateTime();
      let startTime = today;
      let endTime = today;
      if (this.session.startTime) {
        startTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.startTime}Z`,
          this.selectedTzName,
        );
      }
      if (this.session.videoEnabledStartTime) {
        startTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.videoEnabledStartTime}Z`,
          this.selectedTzName,
        );
      }
      if (this.session.endTime) {
        endTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.endTime}Z`,
          this.selectedTzName,
        );
      }
      if (this.session.videoEnabledEndTime) {
        endTime = DateTimeHelper.utcToZonedTimeDate(
          `${this.session.videoEnabledEndTime}Z`,
          this.selectedTzName,
        );
      }
      return Session.hydrate(this.session).isLiveSession
          && isBefore(startTime, endTime)
          && isWithinInterval(
            today,
            {
              start: startTime,
              end: endTime,
            },
          );
    }
    return false;
  }

  mounted(): void {
    this.genericEvent({ channel: `poll-channel-${this.session.uid}` });
    this.genericEvent({ channel: `qa-channel-${this.session.uid}` });
    this.genericEvent({ channel: `session-settings-${this.session.uid}` });
    const availableTabs: TabType[] = [];
    if (this.isChatFeatureEnabled) {
      availableTabs.push('chat');
      if (this.isPollFeatureEnabled) {
        this.getPollNotification();
      }
      if (this.isQaFeatureEnabled) {
        this.getQaNotification();
      }
    }
    if (this.isQaFeatureEnabled) {
      availableTabs.push('qna');
      if (!this.isChatFeatureEnabled && this.isPollFeatureEnabled) {
        this.getPollNotification();
      }
    }
    if (this.isPollFeatureEnabled) {
      availableTabs.push('polls');
      if (!this.isChatFeatureEnabled && this.isQaFeatureEnabled) {
        this.getQaNotification();
      }
    }

    // This is to manage tab navigation by URL on page load
    const selectedTab = this.$route.query.sessionSelectedTab as TabType;
    if (selectedTab && availableTabs.includes(selectedTab)) {
      this.setNewSelectedTab(selectedTab, true);
    } else if (availableTabs.length > 0) {
      this.setNewSelectedTab(availableTabs[0], true);
    } else {
      this.setNewSelectedTab(null, true);
    }
  }

  beforeDestroy(): void {
    if (this.setIntervalKey) {
      clearInterval(this.setIntervalKey);
    }
    this.unsubscribeGenericEventPointer(`poll-channel-${this.session.uid}`);
    this.unsubscribeGenericEventPointer(`qa-channel-${this.session.uid}`);
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.POLL_PUBLISH,
      NotificationEventType.QA_NEW_ANSWER,
      NotificationEventType.QA_EDIT_ANSWER,
      NotificationEventType.SESSION_SETTINGS_CHANGE,
    ];
    window.addEventListener('popstate', () => {
      this.closeTheaterMode();
    });
    this.setIntervalKey = setInterval(this.logStat, VIEW_LIVE_SESSION_STAT_DELAY);
  }

  protected notificationCallback(event: GenericEvent): void {
    if (this.sessionSelectedTab !== 'polls'
        && this.isPollFeatureEnabled
        && event
        && event.type === NotificationEventType.POLL_PUBLISH) {
      this.getPoll({
        filter: {
          uid: event.entityId,
        },
      })
        .then((poll: Poll) => {
          if (poll) {
            const now = DateTimeHelper.currentTimestamp;
            if (!poll.endTimestamp || poll.endTimestamp > now) {
              this.newPoll = poll;
            }
          }
        });
    } else if (this.sessionSelectedTab !== 'qna'
        && this.isQaFeatureEnabled
        && event
        && (event.type === NotificationEventType.QA_NEW_ANSWER
            || event.type === NotificationEventType.QA_EDIT_ANSWER)) {
      this.newQaAnswer = true;
    }

    const extraData = JSON.parse(event.extra);
    if (event
        && event.type === NotificationEventType.SESSION_SETTINGS_CHANGE
        && this.session && event.entityId === this.session.uid
        && extraData.settingType) {
      switch (extraData.settingType) {
        case this.settingType.CHAT:
          this.session.showLiveChat = extraData.isActivated;
          if ((this.sessionSelectedTab === 'chat' && !extraData.isActivated)
              || (this.sessionSelectedTab !== 'chat' && !this.isQaFeatureEnabled && !this.isPollFeatureEnabled)) {
            this.setSelectedTab();
          }
          break;
        case this.settingType.QNA:
          this.session.showQNA = extraData.isActivated;
          if ((this.sessionSelectedTab === 'qna' && !extraData.isActivated)
              || (this.sessionSelectedTab !== 'qna' && !this.isChatFeatureEnabled && !this.isPollFeatureEnabled)) {
            this.setSelectedTab();
          }
          break;
        case this.settingType.POLLS:
          this.session.showPolls = extraData.isActivated;
          if ((this.sessionSelectedTab === 'polls' && !extraData.isActivated)
              || (this.sessionSelectedTab !== 'polls' && !this.isQaFeatureEnabled && !this.isChatFeatureEnabled)) {
            this.setSelectedTab();
          }
          break;
        default:
          break;
      }
    }
  }

  // This is to manage tabs after changing in session configurations
  private setSelectedTab(): void {
    if (this.isChatFeatureEnabled) {
      this.setNewSelectedTab('chat', true);
    } else if (this.isQaFeatureEnabled) {
      this.setNewSelectedTab('qna', true);
    } else if (this.isPollFeatureEnabled) {
      this.setNewSelectedTab('polls', true);
    } else {
      this.setNewSelectedTab(null, true);
    }
  }

  private logStat(): void {
    if (this.canLogViewLiveSession) {
      this.$logger.logMatomoStats(
        this.authUser,
          this.community.code as string,
          EntityType.SESSION,
          StatLoggerActions.VIEW,
          'viewLiveSession',
          this.session.id ? this.session.id : -1,
          this.session.uid,
          StatLoggerCategories.LIVE_SESSION,
          this.$i18n.locale,
      );
    }
  }

  private getPollNotification(): void {
    if (this.session) {
      const pollRepo = new PollRepository();
      pollRepo.filter({
        operationName: 'LoadTheLatestActivePoll',
        fragmentName: 'pollBaseFragment',
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: {
              session: { uid: this.session.uid },
              // eslint-disable-next-line @typescript-eslint/camelcase
              pollAnswers: { uid_not: null },
              // eslint-disable-next-line @typescript-eslint/camelcase
              startTimestamp_lte: getUnixTime(this.now),
              // eslint-disable-next-line @typescript-eslint/camelcase
              endTimestamp_gte: getUnixTime(this.now),
            },
            type: GqlEntityFilterType.POLL_FILTER,
          },
          orderBy: {
            value: ['id_desc'],
            type: GqlEntityOrderingType.POLL_ORDERING,
          },
          first: 1,
          offset: 0,
        }),
      })
        .then((result: Poll[]) => {
          if (result && result.length > 0) {
            const poll = result[0];
            const jsonData = ClientStorage.getItem(this.lastViewedPollConstant);
            const lastViewedPolls: { sessionUid: string; pollUid: string }[] | null = jsonData
              ? JSON.parse(jsonData)
              : null;
            if (lastViewedPolls && Object.entries(lastViewedPolls).length > 0) {
              const lastViewedPollIndex = lastViewedPolls
                .findIndex((data: { sessionUid: string; pollUid: string }) => data.sessionUid === this.session.uid);
              if (lastViewedPollIndex >= 0) {
                if (poll.uid === lastViewedPolls[lastViewedPollIndex].pollUid) {
                  this.newPoll = null;
                } else {
                  this.newPoll = poll;
                }
              } else {
                this.newPoll = poll;
              }
            } else {
              this.newPoll = poll;
            }
          }
        });
    }
  }

  private getQaNotification(): void {
    if (this.session) {
      const messageQnaRepo = new MessageQnaRepository();
      messageQnaRepo.filter({
        operationName: 'LoadTheLatestQnA',
        fragmentName: 'messageUidFragment',
        definition: buildQueryDefinition({
          cacheable: true,
          filter: {
            value: {
              group: {
                target: {
                  uid: this.session.uid,
                },
                type: 'QNA',
              },
              // eslint-disable-next-line @typescript-eslint/camelcase
              userDeleted_not: true,
              // eslint-disable-next-line @typescript-eslint/camelcase
              parentMessage_not: null,
              type: 'QNA',
            },
            type: GqlEntityFilterType.MESSAGE_FILTER,
          },
          orderBy: {
            value: ['id_desc'],
            type: GqlEntityOrderingType.MESSAGE_ORDERING,
          },
          first: 1,
          offset: 0,
        }),
      })
        .then((result: Message[]) => {
          if (result && result.length > 0) {
            const answer = result[0];
            const jsonData = ClientStorage.getItem(this.lastViewedAnswerConstant);
            const lastViewedAnswers: { sessionUid: string; messageUid: string }[] | null = jsonData
              ? JSON.parse(jsonData)
              : null;
            if (lastViewedAnswers && Object.entries(lastViewedAnswers).length > 0) {
              const lastViewedAnswerIndex = lastViewedAnswers
                .findIndex((data: {
                      sessionUid: string;
                      messageUid: string;
                    }) => data.sessionUid === this.session.uid);
              if (lastViewedAnswerIndex >= 0) {
                this.newQaAnswer = answer.uid !== lastViewedAnswers[lastViewedAnswerIndex].messageUid;
              } else {
                this.newQaAnswer = true;
              }
            } else {
              this.newQaAnswer = true;
            }
          }
        });
    }
  }

  private closeTheaterMode(): void {
    this.$emit('close-theater');
  }

  private toggleChatPanel(): void {
    this.setNewSelectedTab('chat');
  }

  private toggleQaPanel(): void {
    const messagesQnaRepo = new MessageQnaRepository();
    messagesQnaRepo.filter({
      operationName: 'LoadTheLatestViewedQnA',
      fragmentName: 'messageUidFragment',
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: {
            group: {
              target: {
                uid: this.session.uid,
              },
              type: 'QNA',
            },
            // eslint-disable-next-line @typescript-eslint/camelcase
            userDeleted_not: true,
            // eslint-disable-next-line @typescript-eslint/camelcase
            parentMessage_not: null,
            type: 'QNA',
          },
          type: GqlEntityFilterType.MESSAGE_FILTER,
        },
        orderBy: {
          value: ['id_desc'],
          type: GqlEntityOrderingType.MESSAGE_ORDERING,
        },
        first: 1,
        offset: 0,
      }),
    })
      .then((result: Message[]) => {
        if (result && result.length > 0) {
          const answer = result[0];
          this.updateLastViewedAnswer(answer.uid);
        }
      });
    this.newQaAnswer = false;
    this.setNewSelectedTab('qna');
  }

  private togglePollsPanel(): void {
    const pollRepo = new PollRepository();
    pollRepo.filter({
      operationName: 'LoadTheLatestViewedPoll',
      fragmentName: 'pollBaseFragment',
      definition: buildQueryDefinition({
        cacheable: true,
        filter: {
          value: {
            session: { uid: this.session.uid },
            // eslint-disable-next-line @typescript-eslint/camelcase
            pollAnswers: { uid_not: null },
            // eslint-disable-next-line @typescript-eslint/camelcase
            startTimestamp_lte: getUnixTime(this.now),
            // eslint-disable-next-line @typescript-eslint/camelcase
            endTimestamp_gte: getUnixTime(this.now),
          },
          type: GqlEntityFilterType.POLL_FILTER,
        },
        orderBy: {
          value: ['id_desc'],
          type: GqlEntityOrderingType.POLL_ORDERING,
        },
        first: 1,
        offset: 0,
      }),
    })
      .then((result: Poll[]) => {
        if (result && result.length > 0) {
          const poll = result[0];
          this.updateLastViewedPoll(poll.uid);
        }
      });
    this.newPoll = null;
    this.setNewSelectedTab('polls');
  }

  private setNewSelectedTab(tab: TabType, replace = false): void {
    if (tab === null) {
      delete this.$route.query.sessionSelectedTab;
    }
    if (this.sessionSelectedTab !== tab) {
      this.setSessionSelectedTab(tab);
      const url = this.$router.resolve({
        ...this.$route,
        query: {
          ...(this.$route.query || {}),
          ...(this.sessionSelectedTab ? { sessionSelectedTab: this.sessionSelectedTab } : {}),
        },
      } as Location).href;
      if (replace) {
        window.history.replaceState(
          null,
          '',
          url,
        );
      } else {
        window.history.pushState(
          null,
          '',
          url,
        );
      }
    }
  }

  private updateLastViewedPoll(pollUid: string): void {
    const jsonData = ClientStorage.getItem(this.lastViewedPollConstant);
    const lastViewedPolls: { sessionUid: string; pollUid: string }[] | null = jsonData
      ? JSON.parse(jsonData)
      : null;
    if (lastViewedPolls && lastViewedPolls.length) {
      const lastViewedPollIndex = lastViewedPolls
        .findIndex((data: {
            sessionUid: string;
            pollUid: string;
          }) => data.sessionUid === this.session.uid);
      const dataToSave = {
        sessionUid: this.session.uid,
        pollUid,
      };
      if (lastViewedPollIndex >= 0) {
        lastViewedPolls[lastViewedPollIndex] = dataToSave;
      } else {
        lastViewedPolls.push(dataToSave);
      }
      ClientStorage.setItem(this.lastViewedPollConstant, JSON.stringify(lastViewedPolls));
    } else {
      const dataToSave = [{
        sessionUid: this.session.uid,
        pollUid,
      }];
      ClientStorage.setItem(this.lastViewedPollConstant, JSON.stringify(dataToSave));
    }
  }

  private updateLastViewedAnswer(messageUid: string): void {
    const jsonData = ClientStorage.getItem(this.lastViewedAnswerConstant);
    const lastViewedAnswers: { sessionUid: string; messageUid: string }[] | null = jsonData
      ? JSON.parse(jsonData)
      : null;
    if (lastViewedAnswers && lastViewedAnswers.length) {
      const lastViewedAnswerIndex = lastViewedAnswers
        .findIndex((data: {
            sessionUid: string;
            messageUid: string;
          }) => data.sessionUid === this.session.uid);
      const dataToSave = {
        sessionUid: this.session.uid,
        messageUid,
      };
      if (lastViewedAnswerIndex >= 0) {
        lastViewedAnswers[lastViewedAnswerIndex] = dataToSave;
      } else {
        lastViewedAnswers.push(dataToSave);
      }
      ClientStorage.setItem(this.lastViewedAnswerConstant, JSON.stringify(lastViewedAnswers));
    } else {
      const dataToSave = [{
        sessionUid: this.session.uid,
        messageUid,
      }];
      ClientStorage.setItem(this.lastViewedAnswerConstant, JSON.stringify(dataToSave));
    }
  }

  private toggleSidePanelClick(): void {
    this.toggleSidePanel = !this.toggleSidePanel;
  }

  @Watch('sessionData')
  private onSessionChange(): void {
    this.session = this.sessionData;
  }
}
