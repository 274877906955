

































































import { Component, Prop } from 'vue-property-decorator';
import { BContainer } from 'bootstrap-vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';

@Component({
  components: {
    CheckboxSwitchComponent,
    ButtonComponent,
    BContainer,
  },
})
export default class SettingsBoxItem extends BreakpointWrapper {
  @Prop({ required: true, default: '' })
  title!: string;

  @Prop({ required: false, default: '' })
  subtitle!: string;

  @Prop({ required: false, default: false })
  hideEditBtn!: boolean;

  @Prop({ required: false, default: false })
  isSwitcher!: boolean;

  @Prop({ required: false, default: false })
  isSelected!: boolean;

  @Prop({ required: false, default: false })
  isLoading!: boolean;

  @Prop({ required: false, default: false })
  isFullWidth!: boolean;

  @Prop({ required: false, default: false })
  titleSmall!: boolean;
}
