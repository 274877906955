import { render, staticRenderFns } from "./FeedItemWrapperComponent.vue?vue&type=template&id=52ab6a01&scoped=true&"
import script from "./FeedItemWrapperComponent.vue?vue&type=script&lang=ts&"
export * from "./FeedItemWrapperComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/FeedItemWrapperComponent.scss?vue&type=style&index=0&id=52ab6a01&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ab6a01",
  null
  
)

export default component.exports