





















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import EntityType from '@/utils/enums/EntityType';
import Product from '@/models/graphql/Product';
import { Data } from '@/utils/types/WidgetData';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { getUnixTime } from 'date-fns';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import LargeProduct from '@/models/graphql/LargeProduct';
import StatusOptions from '@/components/company-cms/StatusOptions';
import CmsTableHeaders from '@/components/company-cms/CmsTableHeaders';
import PillComponent from '@/components/pill/PillComponent.vue';
import Deal from '@/models/graphql/Deal';
import AlertType from '@/utils/enums/AlertType';
import AlertComponent from '@/components/AlertComponent.vue';
import { State } from 'vuex-class';

@Component({
  components: {
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
    PillComponent,
    AlertComponent,
  },
})
export default class ItemSelectorModal extends VueBaseWidget {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ required: false, default: '' })
  private entityType!: EntityType;

  @Prop({ required: false })
  private readonly selectedItem!: Product | LargeProduct | Deal;

  @Prop({ required: false, default: false })
  private readonly saveLoading!: boolean;

  @Prop({ required: true })
  private listItems!: Data[];

  @Prop({ required: false })
  private publicationTime!: string;

  @State
  private dateLocale!: Locale;

  private localList: Data[] = [];

  private localSelectedItem: Data | null = null;

  private searchQuery = '';

  private alertType = AlertType;

  private isSaveBtnDisable = true;

  private orderBy = 'name_desc';

  private statusOptions = StatusOptions;

  private sortOrders: { [key: string]: number } = {};

  created(): void {
    this.setData();
  }

  @Watch('listItems')
  @Watch('selectedItem')
  private setData(): void{
    this.localList = this.listItems;
    this.localSelectedItem = (this.selectedItem) ? this.selectedItem as unknown as Data : null;
  }

  private onConfirm(): void {
    if (!this.localSelectedItem) return;
    if (!this.validatePublishingDate()) { this.$emit('on-confirm', this.localSelectedItem); }
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onSearch(payload: { query: string }): void {
    if (!this.listItems) return;
    this.localList = this.listItems;
    const search = payload.query;
    if (search.length > 0) {
      this.localList = this.listItems
        .filter((filter: Data) => filter.name
          && (filter.name as string).toLowerCase().includes(search.toLowerCase()));
    }
  }

  private validatePublishingDate(): boolean {
    let dealOrProduct;
    if (!this.publicationTime) return false;
    if (!this.localSelectedItem) return false;
    // eslint-disable-next-line default-case
    switch (this.entityType) {
      case EntityType.LARGE_PRODUCT:
      case EntityType.PRODUCT:
        dealOrProduct = this.localSelectedItem as unknown as Deal;
        if (dealOrProduct.startTime) {
          return !(new Date(this.publicationTime) < new Date(dealOrProduct.startTime));
        }
        return false;
      case EntityType.DEAL:
        dealOrProduct = this.localSelectedItem as unknown as Product;
        if (dealOrProduct.publicationTime) {
          return !(new Date(this.publicationTime) > new Date(dealOrProduct.publicationTime));
        }
        return false;
    }
    return false;
  }

  @Watch('breakpoint.value')
  private headers(): object {
    return CmsTableHeaders.getHeaders(this.breakpoint);
  }

  private isSelected(item: Data): boolean {
    return this.localSelectedItem?.uid === item.uid;
  }

  private sortTable(column: string): void {
    if (this.sortOrders[column] === 1) {
      this.sortOrders[column] = -1;
    } else {
      this.sortOrders[column] = 1;
    }

    this.localList.sort((a, b) => {
      const sign = this.sortOrders[column];
      const aVal = (a[column] as string).toLowerCase();
      const bVal = (b[column] as string).toLowerCase();
      // eslint-disable-next-line no-nested-ternary
      return aVal > bVal ? sign : aVal < bVal ? -sign : 0;
    });
  }

  private onCheckBoxClick(item: Data): void {
    if (this.isSelected(item)) {
      this.isSaveBtnDisable = true;
      this.localSelectedItem = null;
    } else {
      this.isSaveBtnDisable = false;
      this.localSelectedItem = item;
    }
  }

  private getItemImage(item: Data): string | null {
    let result = null;
    let product = null;
    let largeProduct = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
        product = item as unknown as Product;
        if (product.images && product.images.length > 0) {
          result = FileResourceHelper.getFullPath(product.images[0].fullFileResource, 'w32');
        }
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        largeProduct = item as unknown as LargeProduct;
        if (largeProduct.images && largeProduct.images.length > 0) {
          result = FileResourceHelper.getFullPath(largeProduct.images[0].fullFileResource, 'w32');
        }
        break;
      default:
        product = item as unknown as Product;
        if (product.images && product.images.length > 0) {
          result = FileResourceHelper.getFullPath(product.images[0].fullFileResource, 'w32');
        }
        break;
    }

    return result;
  }

  private getItemStatus(item: Data): object {
    let result = {};
    let publicationTime: string | number | object | null = null;
    let endPublicationTime: string | number | true | object | null = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        publicationTime = item.startTime ? getUnixTime(new Date(item.startTime as unknown as string)) : null;
        endPublicationTime = item.endTime ? getUnixTime(new Date(item.endTime as unknown as string)) : null;
        break;

      case EntityType.DEAL:
        publicationTime = item.publicationTime ? getUnixTime(new Date(item.publicationTime as unknown as string)) : null;
        endPublicationTime = item.endPublicationTime ? getUnixTime(new Date(item.endPublicationTime as unknown as string)) : null;
        break;

      default:
        break;
    }

    const today = DateTimeHelper.currentTimestamp;
    if ((!publicationTime && !endPublicationTime) || !publicationTime) {
      result = this.statusOptions.unpublished;
    } else if (publicationTime) {
      if (publicationTime <= today) {
        if (endPublicationTime && endPublicationTime <= today) {
          result = this.statusOptions.unpublished;
        } else if (endPublicationTime && endPublicationTime > today) {
          result = this.statusOptions.published;
        } else if (!endPublicationTime) {
          result = this.statusOptions.published;
        }
      } else if (publicationTime >= today) {
        result = this.statusOptions.scheduled;
      }
    }
    return result;
  }

  private getModifiedTime(item: Product | Deal | LargeProduct): string {
    let modifiedTime = 0;
    let dealOrProduct;
    // eslint-disable-next-line default-case
    switch (this.entityType) {
      case EntityType.LARGE_PRODUCT:
      case EntityType.PRODUCT:
        dealOrProduct = item as Deal;
        // eslint-disable-next-line max-len
        modifiedTime = dealOrProduct.startTime ? getUnixTime(new Date(dealOrProduct.startTime as unknown as string)) : 0;
        break;
      case EntityType.DEAL:
        dealOrProduct = item as Product;
        // eslint-disable-next-line max-len
        modifiedTime = dealOrProduct.publicationTime ? getUnixTime(new Date(dealOrProduct.publicationTime as unknown as string)) : 0;
        break;
    }
    return DateTimeHelper.formatFromUnixTime(
      modifiedTime,
      `${this.$t('app.date.defaultDateFormat')}`,
      this.dateLocale,
    );
  }
}
