var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'dropdown-container',
    { 'full-width': _vm.isFullWidth } ]},[_c('b-dropdown',{attrs:{"toggle-class":[
      'text-decoration-none dropdown-button',
      {'error': _vm.hasError},
      {'disabled': _vm.disabled},
      _vm.size
    ],"dropup":_vm.dropup,"boundary":"document","menu-class":"choices-list","no-caret":"","no-flip":"","size":"md","variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-gap-2"},[(_vm.useAvatar)?_c('avatar-solo-widget',{attrs:{"default-icon":"far fa-calendar-star'","firstname":"firstname","lastname":"lastname","src":_vm.img,"icon-classes":"text-neutral-n-6-label","size":"24","with-borders":"true"}}):_vm._e(),_c('span',{staticClass:"paragraph normal text-neutral-n-8-dark-ink m-0"},[_vm._v(" "+_vm._s(_vm.localSelectedChoice ? _vm.localSelectedChoice.text : _vm.placeholder)+" ")])],1),_c('div',{staticClass:"d-flex column-gap-2"},[(_vm.clearable)?_c('font-awesome-component',{attrs:{"container-classes":"icon-16 ml-auto","icon":"fa-solid fa-circle-xmark","icon-classes":"icon-12 text-neutral-n-5-placeholder"},on:{"click":_vm.onClear}}):_vm._e(),_c('font-awesome-component',{attrs:{"container-classes":"icon-16 ml-auto","icon":"fa-solid fa-caret-down","icon-classes":"icon-12 text-neutral-n-8-dark-ink"}})],1)])]},proxy:true}])},_vm._l((_vm.choices),function(choice){return _c('b-dropdown-item',{key:choice.id,attrs:{"link-class":[
        'choice',
        { 'selected': _vm.localSelectedChoice ? choice.value === _vm.localSelectedChoice.value : false } ]},on:{"click":function($event){return _vm.onItemClick(choice)}}},[_c('span',{staticClass:"paragraph normal text-neutral-n-8-dark-ink m-0"},[_vm._v(" "+_vm._s(choice.text)+" ")])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }