




























































































































































































































import FeedItem from '@/models/graphql/FeedItem';
import { Component, Inject, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import FeedPostCommentComponent from '@/components/feed/FeedPostCommentComponent.vue';
import FeedItemRepostModal from '@/components/feed/FeedItemRepostModal.vue';
import FeedPost from '@/models/graphql/FeedPost';
import Comment from '@/models/graphql/Comment';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueSecureHTML from 'vue-html-secure';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import SubEdition from '@/models/graphql/SubEdition';
import Exhibitor from '@/models/graphql/Exhibitor';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { CommentFilter } from '@/graphql/_Filters/CommentFilter';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import Channel from '@/models/graphql/Channel';
import Topic from '@/models/graphql/Topic';
import FeedAction from '@/utils/enums/feed/FeedAction';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import GenericEvent from '@/utils/types/GenericEvent';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import { BSpinner } from 'bootstrap-vue';

const commentStore = namespace('CommentStore');
const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const toastStore = namespace('ToastStore');
const notificationStore = namespace('NotificationStore');

@Component({
  data(): object {
    return {
      comment: '',
      newComment: null,
    };
  },
  components: {
    BSpinner,
    LoadingSpinnerComponent,
    DropdownMenuItem,
    DropdownMenu,
    FeedItemRepostModal,
    FeedPostCommentComponent,
    TextAreaComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable no-underscore-dangle,@typescript-eslint/camelcase */
export default class FeedPostReactionsComponent extends VueBaseNotify {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: true })
  private feedItems!: FeedItem[];

  @Prop({ required: true })
  private initiator!: CommunityUser | SubEdition | Exhibitor | Channel | Topic;

  @Prop({
    required: true,
    default: null,
  })
  private aggregateKey!: string;

  @Prop({ required: true })
  private type!: string;

  @Prop({ required: true })
  private feedItemElement!: FeedItemWrapper;

  @Prop({ default: null })
  private showCommentWithId!: string | null;

  @Inject({
    from: 'context',
    default: '',
  })
  private context!: string;

  @feedItemWrapperStore.Action
  private likePost!: (payload: object) => Promise<FeedPost | undefined>;

  @feedItemWrapperStore.Action
  private dislikePost!: (payload: object) => Promise<FeedPost | undefined>;

  @feedItemWrapperStore.Action
  private repostPostToFeed!: (payload: object) => Promise<FeedPost | undefined>;

  @feedItemWrapperStore.Action
  private repostPostToTopic!: (payload: object) => Promise<FeedPost | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @commentStore.Action('createFeedPostComment')
  private createComment!: (payload: {
    feedPost: FeedPost;
    authUser: CommunityUser;
    parentComment: Comment | null;
    entity: Partial<Comment>;
  }) => Promise<Comment | undefined>;

  @commentStore.Action
  private getPaginatedComments!: (payload: {
    filter: CommentFilter;
    authUser: string;
    first: number;
    offset: number;
  }) => Promise<Comment[] | null>;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @notificationStore.Action
  private genericEvent!: (payload: { channel: string; customCallback?: (event: GenericEvent) => void }) => void;

  @notificationStore.Getter
  private genericEvents!: Record<string, { unsubscribe: () => void }>;

  private comment = '';

  private numberOfLikes = 0;

  private numberOfRepost = 0;

  private numberOfComments = 0;

  private isLiked = false;

  private comments: Comment[] = [];

  private myRecentlyCreatedComments: Comment[] = [];

  private showCommentSection = false;

  private numberOfCommentPerSection = 5;

  private offset = 0;

  private onFocus = false;

  private topCommentCount = 0;

  private isLoading = false;

  private isMainCommentLoading = false;

  private get authUserPicturePath(): string {
    if (this.authUser?.pictureFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.authUser.pictureFileResource), 'w40');
    }
    return '';
  }

  private get isCommentEnable(): boolean {
    return (this.featureByKey(FeatureKeys.COMMUNITY_POST_ENABLE_COMMENT)
        && this.featureByKey(FeatureKeys.COMMUNITY_POST_ENABLE_COMMENT).enabled);
  }

  private get isReactionEnable(): boolean {
    return (this.featureByKey(FeatureKeys.COMMUNITY_ACTIVITY_FEED_REACTIONS)
        && this.featureByKey(FeatureKeys.COMMUNITY_ACTIVITY_FEED_REACTIONS).enabled);
  }

  private get isRepostEnable(): boolean {
    return (this.featureByKey(FeatureKeys.COMMUNITY_ACTIVITY_FEED_REPOSTS)
        && this.featureByKey(FeatureKeys.COMMUNITY_ACTIVITY_FEED_REPOSTS).enabled);
  }

  private get isRepost(): boolean {
    if (this.feedItems[0]) {
      const feedPost = this.feedItems[0].triggered as FeedPost;
      return feedPost.type === FeedPostType.REPOST;
    }
    return false;
  }

  private get hasMoreComments(): boolean {
    return this.comments.length + this.myRecentlyCreatedComments.length + (this.showCommentWithId ? 1 : 0) < this.topCommentCount;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.POST_COMMENT_CREATE,
      NotificationEventType.POST_COMMENT_CREATE_REPLY,
      NotificationEventType.POST_LIKE,
    ];

    if (this.context !== 'Feed' && !this.showCommentWithId) {
      this.showCommentSection = true;
      this.loadComments();
    }

    if (this.feedItems
        && this.feedItems[0].action === FeedAction.FEEDPOST_CREATED
        && this.feedItems[0].triggered) {
      const post = this.feedItems[0].triggered as FeedPost;
      if (post) {
        if (post._likesCount !== undefined) {
          this.numberOfLikes = post._likesCount;
        }
        if (post._isLiked !== undefined) {
          this.isLiked = post._isLiked as boolean;
        }
        if (post._repostsCount !== undefined) {
          const initialPost = post.repostPost?._feedItem?.triggered as FeedPost;
          this.numberOfRepost = initialPost?._repostsCount ? initialPost._repostsCount : post._repostsCount;
        }
        if (post._commentCount !== undefined) {
          const initialPost = post.repostPost?._feedItem?.triggered as FeedPost;
          this.numberOfComments = initialPost?._commentCount ? initialPost._commentCount : post._commentCount;
        }
        if (post._topCommentCount !== undefined) {
          const initialPost = post.repostPost?._feedItem?.triggered as FeedPost;
          this.topCommentCount = initialPost?._topCommentCount
            ? initialPost._topCommentCount
            : post._topCommentCount;
        }
        if (this.showCommentWithId && !this.showCommentSection) {
          this.showCommentSection = true;
          this.loadQueriedComment();
        }
      }
    }
  }

  mounted(): void {
    this.$nextTick(() => {
      if (this.authUser) {
        this.addAutoResize();
        if (this.$refs.sendComment) {
          (this.$refs.sendComment as HTMLTextAreaElement).focus();
        }
      }
    });
    this.genericEvent({ channel: 'feed-post-comment' });
  }

  onBlur(): void {
    this.onFocus = false;
  }

  focusIn(): void {
    this.onFocus = true;
  }

  onChange(e: Event): void {
    if (e && e.target && this.$data.comment === '') {
      (e.target as HTMLElement).style.height = 'auto';
    }
  }

  onRepostToMyFeedClick(): void {
    this.$bvModal.show(`repost-feed-item-modal-${(this.feedItems[0].triggered as FeedPost).uid}`);
  }

  repostPost(payload: { initiatorUid: string; topicUid: string }): void {
    const post = this.feedItems[0].triggered as FeedPost;
    this.numberOfRepost += 1;
    if (payload.topicUid) {
      this.repostPostToTopic({
        initiatorNodeUid: payload.initiatorUid,
        repostPostFeedPostUid: post.uid,
        authorUserCommunityUserUid: this.authUser.uid,
        topicTopicUid: payload.topicUid,
        entity: {
          deleted: false,
          type: FeedPostType.REPOST,
        },
      })
        .then(() => {
          this.showToast(ToastActionType.REPOST_TOPIC);
        })
        .catch((feedPost) => {
          if (!feedPost) {
            this.numberOfRepost -= 1;
          }
        });
    } else {
      this.repostPostToFeed({
        initiatorNodeUid: payload.initiatorUid,
        repostPostFeedPostUid: post.uid,
        authorUserCommunityUserUid: this.authUser.uid,
        entity: {
          deleted: false,
          type: FeedPostType.REPOST,
        },
      })
        .then(() => {
          this.showToast(ToastActionType.REPOST);
        })
        .catch((feedPost) => {
          if (!feedPost) {
            this.numberOfRepost -= 1;
          }
        });
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    const feedPost = this.getFeedPost();
    const extraData = JSON.parse(event.extra);
    if (feedPost?.uid === event.entityId
        && extraData.authUser !== this.authUser.uid
        && event.type === NotificationEventType.POST_COMMENT_CREATE) {
      this.onPostCommentCreatedFromEvent(Comment.hydrate(extraData.comment));
    } else if (feedPost?.uid === event.entityId
        && event.type === NotificationEventType.POST_COMMENT_CREATE_REPLY) {
      this.onCommentCreate(feedPost.uid);
    } else if (feedPost?.uid === event.entityId
        && extraData.authUser !== this.authUser.uid
        && event.type === NotificationEventType.POST_LIKE) {
      this.onPostLikedFromEvent(extraData.isLiked);
    }
  }

  private loadComments(): void {
    if (this.feedItems
        && this.feedItems[0].action === FeedAction.FEEDPOST_CREATED
        && this.feedItems[0].triggered) {
      this.loadMoreComments();
    }
  }

  private loadMoreComments(): void {
    if (this.feedItems
        && this.feedItems[0].action === FeedAction.FEEDPOST_CREATED
        && this.feedItems[0].triggered) {
      this.isLoading = true;
      const post = this.feedItems[0].triggered as FeedPost;
      const excludeCommentsUid = this.showCommentWithId
        ? [...this.myRecentlyCreatedComments.map((comment) => comment.uid), this.showCommentWithId]
        : this.myRecentlyCreatedComments.map((comment) => comment.uid);
      this.getPaginatedComments({
        filter: {
          post: { uid: post.uid },
          uid_not_in: excludeCommentsUid,
          parentComment: null,
        },
        authUser: this.authUser.uid,
        first: this.numberOfCommentPerSection,
        offset: this.offset,
      })
        .then((comments) => {
          if (comments?.length && this.comments) {
            this.comments.push(...comments);
            this.offset += this.numberOfCommentPerSection;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private loadQueriedComment(): void {
    if (this.feedItems
        && this.feedItems[0].action === FeedAction.FEEDPOST_CREATED
        && this.feedItems[0].triggered
        && this.showCommentWithId) {
      this.isLoading = true;
      this.getPaginatedComments({
        filter: {
          uid: this.showCommentWithId,
        },
        authUser: this.authUser.uid,
        first: 1,
        offset: 0,
      })
        .then((comments) => {
          if (comments?.length && this.comments) {
            this.comments.push(...comments);
            this.offset += this.comments.length;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private onComment(): void {
    this.onFocus = true;
    if (this.context === 'Feed') {
      this.$router.push({
        name: 'post-comment-detail',
        params: { postId: this.feedItemElement.uid },
      });
      return;
    }
    this.showCommentSection = !this.showCommentSection;
    if (this.showCommentSection) {
      this.$nextTick(() => {
        this.addAutoResize();
        (this.$refs.sendComment as HTMLTextAreaElement).focus();
      });
      if (this.comments && this.comments?.length < this.topCommentCount) {
        this.loadComments();
      }
    }
  }

  private onLike(): void {
    const post = this.feedItems[0].triggered as FeedPost;
    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.numberOfLikes += 1;
      this.likePost({
        uid: post.uid,
        likeUser_CommunityUserUid: this.authUser.uid,
      })
        .then((feedPost) => {
          if (feedPost) {
            this.triggerLikePostEvent(post.uid, true);
          } else {
            this.numberOfLikes -= 1;
            this.isLiked = false;
          }
        })
        .catch(() => {
          this.numberOfLikes -= 1;
          this.isLiked = false;
        });
    } else {
      this.numberOfLikes -= 1;
      this.dislikePost({
        uid: post.uid,
        likeUser_CommunityUserUid: this.authUser.uid,
      })
        .then((feedPost) => {
          if (feedPost) {
            this.triggerLikePostEvent(post.uid, false);
          } else {
            this.numberOfLikes += 1;
            this.isLiked = true;
          }
        })
        .catch(() => {
          this.numberOfLikes += 1;
          this.isLiked = true;
        });
    }
  }

  private triggerLikePostEvent(postId: string, isLiked: boolean): void {
    this.triggerGenericEvent({
      channels: ['feed-post-comment'],
      type: NotificationEventType.POST_LIKE,
      entityId: postId,
      extra: JSON.stringify({
        isLiked,
        authUser: this.authUser.uid,
      }),
    });
  }

  private sendComment(e: Event): void {
    const feedPost = this.getFeedPost();
    if (feedPost && this.comment.length > 0) {
      this.isMainCommentLoading = true;
      this.createComment({
        feedPost,
        authUser: this.authUser,
        parentComment: null,
        entity: {
          textContent: VueSecureHTML.escapeHTML(this.comment.trim()),
        },
      })
        .then((comment) => {
          if (comment) {
            if (this.comments) {
              this.comments.unshift(comment);
              this.myRecentlyCreatedComments.unshift(comment);
            } else {
              this.comments = [comment];
            }
            this.topCommentCount += 1;
            this.numberOfComments += 1;
            this.offset += 1;
            this.isMainCommentLoading = false;
            this.triggerGenericEvent({
              channels: ['feed-post-comment'],
              type: NotificationEventType.POST_COMMENT_CREATE,
              entityId: feedPost.uid,
              extra: JSON.stringify({
                comment,
                authUser: this.authUser.uid,
              }),
            });
          }
        });
      this.$data.comment = '';
      if (e && e.target) {
        (e.target as HTMLElement).style.height = 'auto';
      }
    }
  }

  private addAutoResize = (): void => {
    document.querySelectorAll<HTMLElement>('[data-autoresize]')
      .forEach((element) => {
        const offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', (event) => {
          if (event && event.target) {
            (event.target as HTMLElement).style.height = `${(event.target as HTMLElement).scrollHeight + offset}px`;
          }
        });
        element.removeAttribute('data-autoresize');
      });
  };

  private getFeedPost(): FeedPost | null {
    if (this.feedItems && this.feedItems.length > 0) {
      if (
        this.feedItems[0].action === FeedAction.FEEDPOST_CREATED
          && this.feedItems[0].triggered
      ) {
        return this.feedItems[0].triggered as FeedPost;
      }
    }
    return null;
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private onPostCommentCreatedFromEvent(comment: Comment): void {
    const commentIndex = this.comments ? this.comments
      .findIndex((c: Comment) => c.uid === comment.uid) : -1;
    if (commentIndex === -1) {
      if (this.comments) {
        this.comments.unshift(comment);
      } else {
        this.comments = [comment];
      }
      this.numberOfComments += 1;
      this.offset += 1;
    }
  }

  private onPostLikedFromEvent(isLiked: boolean): void {
    if (isLiked) {
      this.numberOfLikes += 1;
    } else {
      this.numberOfLikes -= 1;
    }
  }

  private onCommentCreate(feedPostUid: string | undefined): void {
    if (feedPostUid === this.getFeedPost()?.uid) {
      this.numberOfComments += 1;
    }
  }
}
