import { SURVEY_QUESTION_CHOICE_BASE_FRAGMENT } from '@/graphql/_Fragments/SurveyQuestionChoice/Base';

export const SURVEY_QUESTION_FULL_WITHOUT_SURVEY_FRAGMENT = `
  fragment surveyQuestionFullWithoutSurveyFragment on SurveyQuestion {
    uid
    label
    description
    type
    optional
    displayOrder
    choices(orderBy: displayOrder_asc) {
      ...surveyQuestionChoiceBaseFragment
    }
  }
  ${SURVEY_QUESTION_CHOICE_BASE_FRAGMENT}
`;
