











































































































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import DropdownComponent from '@/components/form/DropdownComponent.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ClipboardHelper from '@/utils/helpers/ClipboardHelper';

const toastStore = namespace('ToastStore');

@Component({
  components: {
    FontAwesomeComponent,
    DropdownComponent,
    InputText,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class PollPresentLiveModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ default: '' })
  url!: string;

  @Prop({ default: '' })
  sessionId!: string;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private get completeUrl(): string {
    return `${window.location.origin}${this.url}`;
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private async copyToClipBoard(text: string): Promise<void> {
    try {
      await ClipboardHelper.copyTextToClipboard(text);
      this.showToast(text === this.sessionId ? ToastActionType.COPY_SESSION_ID_TO_CLIPBOARD : ToastActionType.COPY_TO_CLIPBOARD);
    } catch (err) {
      this.showToast(ToastActionType.FAILED_COPY_TO_CLIPBOARD);
    }
  }

  private onPresent(): void {
    window.open(this.url, '_blank');
    this.$bvModal.hide(this.modalId);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }
}
