











































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import ShareModal from '@/components/modals/ShareModal.vue';
import { Portal } from 'portal-vue';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
    ShareModal,
    Portal,
  },
})
export default class ActionButtonShare extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: string;

  @Prop({ required: true })
  private entity!: EntityType;

  private shareModalId = +new Date();

  private get text(): string {
    return `${this.$t('actions.share')}`;
  }

  private get isDisplayed(): boolean {
    return this.actionType === this.ActionButtonType.SHARE;
  }

  // eslint-disable-next-line class-methods-use-this
  private get url(): string {
    return window.location.href as string;
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private toggleShareModal(): void {
    this.$bvModal.show(`share-modal-${this.shareModalId}`);
  }
}
