


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ProductImage from '@/models/graphql/ProductImage';
import EntityType from '@/utils/enums/EntityType';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedProductCard extends Vue {
  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private productName!: string;

  @Prop({ required: false })
  private description!: string;

  @Prop({ required: false })
  private images!: Array<ProductImage>;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  private entityType = EntityType.PRODUCT;

  private get imageSrc(): string {
    if (this.images && this.images.length > 0) {
      return FileResourceHelper.getFullPath(this.images[0].fullFileResource, 'w250');
    }
    return '';
  }
}
