export const MEETING_REQUEST_BASE_FRAGMENT = `
fragment meetingRequestBaseFragment on MeetingRequest {
    uid
    schemaCode
    readState
    timezoneName
    availability
    trash
    exhibitor {
        uid
        name
        logoFileResource {
            schemaCode
            path
        }
    }
    user {
        uid
        firstName
        lastName
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    meeting {
        uid
    }
}`;
