var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"detail-page-header exhibitor-detail-page-header\n            d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"banner rounded-lg position-relative",class:{
              'desktop': _vm.isDesktop,
              'tablet': _vm.isTablet,
              'mobile': _vm.isMobile
            },style:({backgroundImage: _vm.bannerSrc})},[(_vm.videoPresentationS3Url)?_c('div',{class:[
                'bg-transparent d-flex align-items-start justify-content-center play-button',
                {'play-button-sm': _vm.isMobile},
                {'play-button-md': _vm.isTablet},
                {'play-button-lg': _vm.isDesktop} ]},[_c('button-icon-component',{staticStyle:{"opacity":"0.8"},attrs:{"text":!_vm.isMobile ? ("" + (_vm.$t('generic-page-header.button.play'))) : '',"rounded":true,"size":_vm.isMobile ? 'md' : 'lg',"variant":"admin"},on:{"on-click":function($event){_vm.showPopup = true}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-play","icon-classes":"icon-16"}})]},proxy:true}],null,false,706225417)})],1):_vm._e(),_c('div',{staticClass:"banner-pills d-flex align-items-center position-absolute",class:_vm.isMobile ? 'justify-content-between' : 'justify-content-start'},[_c('div',{staticClass:"d-flex flex-column"},[(!_vm.autoFollow
                    && _vm.displayFollowerCountBadge
                    && _vm._followerCount
                    && _vm._followerCount > 0)?_c('recommendation-badge-component',{staticClass:"mr-2",attrs:{"icon":_vm.isMobile ? {
                    'iconDef': 'fa-regular fa-user-group',
                    'iconClass': 'icon-12'
                  } : null,"label":_vm.isMobile ?
                    _vm._followerCount :
                    _vm.$tc('actions.followers', _vm._followerCount, { followersCount: _vm._followerCount}),"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"black"}}):_vm._e()],1)])]),_c('div',{class:{
              'mobile': _vm.isMobile,
              'logo': true
            }},[_c('avatar-solo-widget',{attrs:{"recommended-for-you":_vm._isRecommendedForMe && _vm.displayRecommendedBadge,"show-edit-button":false,"size":_vm.isMobile ? 96 : 136,"src":_vm.logoSrc,"with-borders":true,"default-icon":"far fa-buildings"}}),(_vm.featured && _vm.displayFeaturedBadge)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('recommendation-badge-component',{staticClass:"mt-2",attrs:{"label":("" + (_vm.$t('actions.featured'))),"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"blue"}})],1):_vm._e()],1),(_vm.showPopup)?_c('video-component',{staticClass:"popup-video",attrs:{"reference":"playerExhibitor","url":_vm.videoPresentationS3Url}}):_vm._e(),(_vm.showPopup)?_c('div',{staticClass:"backdrop",on:{"click":function($event){_vm.showPopup = false}}}):_vm._e()],1)])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-column align-items-center mt-4 mt-md-5"},[(_vm.getBooths && _vm.getBooths.length > 0
          && (_vm.displayBooth || _vm.displayHall) && _vm.schemaCode && _vm.schemaCode !== '')?_c('div',{staticClass:"d-flex column-gap-2 mb-4"},[_c('button-component',{attrs:{"rounded":true,"text":_vm.boothDetails(_vm.getBooths[0]),"size":"md","variant":"alternative-2-black"},on:{"on-click":function($event){return _vm.onBoothClick(_vm.getBooths[0])}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-location-dot"}})]},proxy:true}],null,false,735472413)}),(_vm.getBooths.length > 1)?_c('button-component',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.showDropDown = false; }),expression:"() => showDropDown = false"}],attrs:{"id":("more-booths-" + _vm.uid),"rounded":true,"text":("+" + (_vm.getBooths.length - 1) + " " + (_vm.$t('generic-page-header.button.more'))),"variant":_vm.showDropDown ? 'alternative-2-black-dark' : 'alternative-2-black',"size":"md"},on:{"on-click":function($event){_vm.showDropDown = !_vm.showDropDown}}}):_vm._e(),_c('b-popover',{attrs:{"show":_vm.showDropDown,"target":("more-booths-" + _vm.uid),"boundary":"window","container":"app","custom-class":"dropdown-options","placement":"bottom","triggers":"click"},on:{"update:show":function($event){_vm.showDropDown=$event}}},[_vm._l((_vm.getBooths),function(booth,index){return [(index > 0)?_c('div',{key:booth.uid,staticClass:"d-flex align-items-start py-2 px-3",class:[{'cursor-pointer': !!booth.geozone}],on:{"click":function($event){return _vm.onBoothClick(booth)}}},[_c('font-awesome-component',{staticClass:"mr-3",attrs:{"container-classes":"icon-24","icon":"far fa-location-dot"}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.boothDetails(booth))+" ")])],1):_vm._e()]})],2)],1):_vm._e(),(_vm.name)?_c('div',{class:[
          'w-fit',
          'd-inline-flex align-items-center'
        ]},[_c('h1',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.name),expression:"name"}],staticClass:"m-0 name"}),(_vm.country && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE).enabled)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.country.name ? _vm.country.name.toUpperCase() : ''),expression:"country.name ? country.name.toUpperCase() : ''",modifiers:{"hover":true,"top":true}}],staticClass:"country-container d-inline-flex align-items-center ml-2"},[_c('img',{attrs:{"alt":((_vm.country.name) + " flag"),"src":("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.country.iso.toLowerCase()) + ".svg"),"height":"24"}})]):_vm._e()]):_vm._e(),(_vm.categories)?_c('div',{staticClass:"w-fit mt-2"},[_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.categories)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }