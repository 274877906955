import { render, staticRenderFns } from "./ActionButtonConnect.vue?vue&type=template&id=bec7e028&scoped=true&"
import script from "./ActionButtonConnect.vue?vue&type=script&lang=ts&"
export * from "./ActionButtonConnect.vue?vue&type=script&lang=ts&"
import style0 from "./ActionButtonConnect.vue?vue&type=style&index=0&id=bec7e028&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec7e028",
  null
  
)

export default component.exports