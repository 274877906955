




































import { Component, Vue } from 'vue-property-decorator';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import OgService from '@/services/OgService';
import OgMetadata from '@/models/feeds/OgMetaData';
import OgImage from '@/models/feeds/OgImage';

@Component({
  data(): object {
    return {
      link: {
        url: '',
        title: '',
        description: '',
        imageUrl: '',
      },
      linkForm: {
        linkUrl: '',
        error: null,
        isValidUrl: null,
        isValid: null,
      },
      isLoading: false,
      showValidation: false,
    };
  },
})
export default class FeedItemLinkForm extends Vue {
  private ogService = new OgService();

  private debounceTimer: ReturnType<typeof setTimeout> | null = null;

  private isTyping = false;

  onLinkValid(ogMetaData?: OgMetadata): void {
    this.$data.linkForm.isValid = true;
    this.$data.link = this.createLink(ogMetaData);
    this.$emit('on-new-url', this.$data.link as FeedPostLink);
    this.$data.isLoading = false;
    this.isTyping = false;
    this.$emit('disable-post', !this.isTyping);
    const textareaElement = document.getElementById('input-1') as HTMLTextAreaElement;
    if (textareaElement) textareaElement.focus();
  }

  throwError(message?: string): void {
    if (!message) {
      // eslint-disable-next-line no-param-reassign
      message = this.$t('app.feed-page.creator.url-validation-fail') as string;
    }
    this.$data.link = null;
    this.$data.linkForm.isValid = false;
    this.$data.linkForm.error = message;
    this.isTyping = false;
    this.$emit('disable-post', !this.isTyping);
    this.$emit('on-new-url', null);
    this.$data.isLoading = false;
  }

  clear(): void {
    this.$data.link = {
      url: '',
      title: '',
      description: '',
      imageUrl: '',
    };
    this.$data.linkForm = {
      linkUrl: '',
      error: null,
      isValid: null,
    };
    this.$data.showValidation = false;
  }

  private validationState(): boolean {
    const pattern = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)');
    const isUrl = !!pattern.test(this.$data.linkForm.linkUrl);
    const isValid = isUrl && this.$data.linkForm.linkUrl.length > 4;
    this.$data.linkForm.isValidUrl = isValid;
    return isValid;
  }

  private createDefaultLink(): FeedPostLink {
    return {
      url: this.$data.linkForm.linkUrl || '',
      title: this.$data.linkForm.linkUrl || '',
      imageUrl: '',
      description: '',
    } as FeedPostLink;
  }

  // eslint-disable-next-line class-methods-use-this
  private createLink(ogMetaData?: OgMetadata): FeedPostLink {
    const newLink = this.createDefaultLink();
    if (!ogMetaData) return newLink;

    if (ogMetaData.data.ogUrl) {
      newLink.url = ogMetaData.data.ogUrl;
    } else {
      newLink.url = ogMetaData.data.requestUrl;
    }
    if (ogMetaData.data.ogTitle) {
      newLink.title = ogMetaData.data.ogTitle;
    }
    if (ogMetaData.data.twitterImage) {
      if (Array.isArray(ogMetaData.data.twitterImage) && ogMetaData.data.twitterImage.length > 0) {
        newLink.imageUrl = ogMetaData.data.twitterImage[0].url;
      } else {
        const twitterImage = ogMetaData.data.twitterImage as OgImage;
        newLink.imageUrl = twitterImage.url;
      }
    } else if (ogMetaData.data.ogImage) {
      if (Array.isArray(ogMetaData.data.ogImage) && ogMetaData.data.ogImage.length > 0) {
        newLink.imageUrl = ogMetaData.data.ogImage[0].url;
      } else {
        const ogImage = ogMetaData.data.ogImage as OgImage;
        newLink.imageUrl = ogImage.url;
      }
    }
    if (ogMetaData.data.ogDescription) {
      newLink.description = ogMetaData.data.ogDescription;
    }
    return newLink;
  }

  private onTypeWait(): void{
    this.isTyping = true;
    if (this.isTyping) this.$emit('disable-post', !this.isTyping);
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.onLinkFormSubmit();
    }, 1500);
  }

  private onLinkFormSubmit(): void {
    this.$data.isLoading = true;
    this.$data.linkForm.isValid = null;
    this.$data.linkForm.isValidUrl = null;
    this.validationState();
    if (this.$data.linkForm.isValidUrl) {
      this.ogService.getUrlOgData(this.$data.linkForm.linkUrl).then((ogMetaData: OgMetadata) => {
        this.onLinkValid(ogMetaData);
      }).catch(() => {
        this.onLinkValid();
      });
    } else {
      this.throwError();
    }
  }
}
