
































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { BContainer, BLink, BRow } from 'bootstrap-vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import { Getter, namespace } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const localStorageCartStore = namespace('LocalStorageCartStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  computed: {
    FeatureKeys() {
      return FeatureKeys;
    },
  },
  components: {
    ButtonIconComponent,
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonComponent,
    BRow,
    BContainer,
    BLink,
  },
})
export default class HeaderPanel extends VueBaseWidget {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: true })
  protected readonly company!: Exhibitor;

  @Prop({ required: false })
  private route!: string;

  @localStorageCartStore.Getter
  private numberOfItemInCart!: number;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  @permissionManagerStore.Getter
  private canManageSales!: (companyUid: string) => boolean;

  private FileResourceHelper = FileResourceHelper;

  private isHambugerMenuOpen = false;

  private get customizedUrl(): string | null {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      return r;
    }
    return null;
  }

  @Watch('company')
  private setCartCount(): void {
    this.setCart(this.company.uid);
  }

  private onPreviewClick(): void {
    this.isHambugerMenuOpen = false;
    if (this.route && this.customizedUrl) {
      window.open(this.customizedUrl);
    } else {
      const route = this.$router.resolve({
        name: 'company-detail',
        params: { companyId: this.company.uid },
      });
      window.open(route.href);
    }
  }

  private onCartButtonClick(): void {
    this.isHambugerMenuOpen = false;
    this.$router.push({
      name: 'company-cms-cart',
      params: { companyId: this.company.uid },
    });
  }

  private onToggleHambugerMenu(): void {
    this.isHambugerMenuOpen = !this.isHambugerMenuOpen;
  }
}
