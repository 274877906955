





















































import { Component } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CompanyCalendarListComponent from '@/components/company-meeting/CompanyCalendarListComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import { namespace, State } from 'vuex-class';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import { MeetingRequestsInboxView } from '@/utils/enums/MeetingRequestsInboxView';
import CompanyCalendarDetailComponent from '@/components/company-meeting/CompanyCalendarDetailComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format, isToday, isYesterday } from 'date-fns';

const companyCalendarStore = namespace('CompanyCalendarStore');

@Component({
  components: {
    CompanyCalendarDetailComponent,
    PillWidget,
    CompanyCalendarListComponent,
    FontAwesomeComponent,
    InputSearchComponent,
  },
})
export default class CompanyCalendarInboxComponent extends BreakpointWrapper {
  @companyCalendarStore.State
  private meetingRequests!: MeetingRequest[];

  @companyCalendarStore.State
  private selectedMeetingRequest!: MeetingRequest;

  @companyCalendarStore.State
  private isDetailView!: boolean;

  @companyCalendarStore.State('selectedFilter')
  private defaultSelectedFilter!: MeetingRequestsInboxView;

  @companyCalendarStore.Mutation
  private setSelectedMeetingRequest!: (meetingRequest: MeetingRequest | null) => void;

  @companyCalendarStore.Mutation
  private setIsDetailView!: (isDetail: boolean) => void;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private showFiltersMenu = false;

  private filtersMenuOptions = [
    MeetingRequestsInboxView.INCOMING,
    MeetingRequestsInboxView.PENDING,
    MeetingRequestsInboxView.SCHEDULED,
    MeetingRequestsInboxView.ARCHIVES,
    MeetingRequestsInboxView.DONE,
  ];

  private selectedFilter = MeetingRequestsInboxView.INCOMING;

  private meetingRequestsInboxView = MeetingRequestsInboxView;

  private variantEnum = Variant;

  private get groupedMeetingRequests(): Map<string, MeetingRequest[]> {
    const localMap = new Map<string, MeetingRequest[]>();
    this.meetingRequests.forEach((mr) => {
      const localDate = DateTimeHelper.utcToZonedTimeDate(
        mr.createdTimestamp * 1000,
        this.selectedTzName,
      );
      let dateKey = format(
        localDate,
        `${this.$t('meeting-request.group-date-format')}`,
        { locale: this.dateLocale },
      );
      if (isToday(localDate)) {
        dateKey = `${this.$t('meeting-request.today')}`;
      }
      if (isYesterday(localDate)) {
        dateKey = `${this.$t('meeting-request.yesterday')}`;
      }
      if (!mr.readState) {
        dateKey = `${this.$t('meeting-request.unread')}`;
      }
      if (!localMap.has(dateKey)) {
        localMap.set(dateKey, []);
      }

      // eslint-disable-next-line no-unused-expressions
      localMap.get(dateKey)
          ?.push(mr);
    });
    return localMap;
  }

  private get unreadCount(): number {
    return this.meetingRequests.filter((meetingRequest) => !meetingRequest.readState).length;
  }

  mounted(): void {
    this.selectedFilter = this.defaultSelectedFilter;
  }

  private onSearch(payload: { query: string }): void {
    this.$emit('on-view-search', payload.query);
  }

  private onFilter(option: MeetingRequestsInboxView): void {
    this.showFiltersMenu = false;
    this.selectedFilter = option;
    this.$emit('on-view-filter', this.selectedFilter);
  }

  private onMeetingRequestSelected(meetingRequest: MeetingRequest): void {
    this.setSelectedMeetingRequest(meetingRequest);
    this.setIsDetailView(true);
    const url = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query: Object.assign(this.$route.query, { detail: meetingRequest.uid }),
    }).href;
    window.history.pushState(null, document.title, url);
    this.$emit('on-request-meeting-selected', meetingRequest);
  }

  private onMeetingRequestDetailClose(): void {
    this.setSelectedMeetingRequest(null);
    this.setIsDetailView(false);
    delete this.$route.query.detail;
    const url = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query: this.$route.query,
    }).href;
    window.history.pushState(null, document.title, url);
    this.$emit('on-request-meeting-selected', null);
  }

  private onMeetingScheduled(): void {
    this.setIsDetailView(false);
    this.$emit('on-meeting-scheduled');
  }
}
