































































import {
  Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { isWithinInterval, parse } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Player from '@vimeo/player';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
  },
})
export default class VideoPlayerComponent extends BreakpointWrapper {
  @Inject({ from: 'windowWidth' })
  protected readonly windowWidth!: { value: number };

  @Inject({ from: 'windowHeight' })
  protected readonly windowHeight!: { value: number };

  @Prop({
    required: true,
    default: null,
  })
  private readonly session!: Session;

  @Prop({ default: false })
  private readonly embedded!: boolean;

  @Prop({ default: false })
  private readonly toggleChat!: boolean;

  @Prop({ default: false })
  private readonly onlyPlayer!: boolean;

  @Prop({ default: null })
  private readonly cardRoute!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private sizeOptions = {
    maxHeight: '100%',
    maxWidth: '100%',
  };

  private isError = false;

  private isPlaying = false;

  private player!: Player;

  private showTitle = false;

  private videoKey = 1;

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, (this.session as unknown as Record<string, string>)[prop]);
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.session.uid}`;
      }
      return `${r}/${this.session.uid}`;
    }
    return null;
  }

  private get videoEmbedUrl(): string {
    if (this.session) {
      if (this.session.videoType === 'recorded'
          && this.session.videoArchiveEnabled
          && this.session.videoArchiveEmbedEndpoint) {
        return this.session.videoArchiveEmbedEndpoint;
      }
      if (this.session.videoType
          && this.session.videoType === 'live'
          && this.session.videoLiveEnabled
          && this.session.videoLiveEmbedEndpoint) {
        return this.session.videoLiveEmbedEndpoint;
      }
    }
    return '';
  }

  private get autoPlay(): boolean {
    return (!!(this.session
        && this.session.videoType === 'live'
        && this.session.videoEnabledStartTime
        && this.session.videoEnabledEndTime
        && this.featureByKey(FeatureKeys.COMMUNITY_AUTOPLAY_LIVE_STREAM)
        && this.featureByKey(FeatureKeys.COMMUNITY_AUTOPLAY_LIVE_STREAM).enabled
        && isWithinInterval(DateTimeHelper.toUTC(new Date()), {
          start: DateTimeHelper.toUTC(parse(this.session.videoEnabledStartTime, DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
          end: DateTimeHelper.toUTC(parse(this.session.videoEnabledEndTime, DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
        })))
      || (this.session
        && this.session.videoType === 'recorded'
        && this.featureByKey(FeatureKeys.COMMUNITY_AUTOPLAY_RECORDED_STREAM)
        && this.featureByKey(FeatureKeys.COMMUNITY_AUTOPLAY_RECORDED_STREAM).enabled);
  }

  private get playerOptions(): { title: boolean; muted: boolean | undefined } {
    if (this.autoPlay) {
      return {
        title: false,
        muted: true,
      };
    }
    return {
      title: false,
      muted: false,
    };
  }

  mounted(): void {
    this.$nextTick(() => {
      const playerElement = this.$refs.player as unknown as Vue;
      if (playerElement) {
        this.player = new Player(playerElement.$el as HTMLElement, {
          title: false,
        });
      }
      this.updateSizeOption();
    });
    this.$watch('windowWidth', this.updateSizeOption, { deep: true });
    this.$watch('windowHeight', this.updateSizeOption, { deep: true });
  }

  @Watch('videoEmbedUrl')
  private onVideoChange(): void {
    setTimeout(() => {
      this.isError = false;
    }, 500);
  }

  private goToDetail(): void {
    if (this.handleBaseUrl) {
      this.$router.push(this.handleBaseUrl);
    } else {
      this.$router.push({
        name: 'session-detail',
        params: { sessionId: this.session.uid },
      })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
    }
  }

  @Watch('embedded')
  @Watch('toggleChat')
  private updateSizeOption(): void {
    const container = this.$el;
    if (this.player) {
      const height = (this.player as unknown as { element: HTMLIFrameElement }).element.offsetHeight;
      const chatWrapper = document.querySelector('.player-wrapper ~ .chat-wrapper');
      if (height && chatWrapper) {
        if (!this.embedded) {
          (chatWrapper as HTMLElement).style.maxHeight = '100%';
        } else {
          (chatWrapper as HTMLElement).style.maxHeight = `${height > 525 ? height : 525}px`;
        }
      }
    }
    if (container) {
      this.sizeOptions.maxWidth = `${(container.getBoundingClientRect().height * 16) / 9}px`;
      this.sizeOptions.maxHeight = `${container.getBoundingClientRect().height}px`;
    }
  }
}
