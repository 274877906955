import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const COMMUNITY_USER_CONNECTION_FULL_FRAGMENT = `
  fragment communityUserConnectionFullFragment on CommunityUserConnection {
    uid
    schemaCode
    message
    linkState
    user {
     ...communityUserBaseFragment
    }
    connectedUser {
      ...communityUserBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
`;
