import { render, staticRenderFns } from "./SessionInfoComponent.vue?vue&type=template&id=a2ca031c&scoped=true&"
import script from "./SessionInfoComponent.vue?vue&type=script&lang=ts&"
export * from "./SessionInfoComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/EntityInfoComponent.scss?vue&type=style&index=0&id=a2ca031c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2ca031c",
  null
  
)

export default component.exports