



























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';

@Component({
  components: { CheckboxSwitchComponent },
  inheritAttrs: false,
})
export default class EntitySearchToggleWidget extends Vue {
  @Prop({ required: true })
  private buttonLabel!: string;

  @Prop({ required: true })
  private code!: string;

  @Prop({ required: false, default: false })
  private isSwitcher!: boolean;

  @Prop({ required: false, default: false })
  private isChecked!: boolean;

  @Prop({ required: false, default: false })
  private pillStyle!: boolean;

  @Prop({ required: false, default: '' })
  private filterQuery!: string;

  @Prop({ required: false, default: null })
  private entityType!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  private selected = this.isChecked;

  created(): void {
    this.initSelection();
  }

  @Watch('$route')
  private initSelection(): void {
    const { query } = this.$route;
    if ('toggles' in query && query.toggles && ((query as EntitySearchQuery).toggles ?? {})[this.code]) {
      this.selected = ((query as EntitySearchQuery).toggles ?? {})[this.code] === 'true';
    } else {
      this.selected = false;
    }
  }

  private onToggle(): void {
    const { query } = this.$route;
    this.selected = !this.selected;
    if (this.selected) {
      if (!('toggles' in query && query.toggles)) {
        Object.assign(query, { toggles: {} });
      }
      const filters: Record<string, Array<string | number> | number | string | boolean> = {};
      filters[this.code] = this.selected;
      Object.assign(query.toggles, { ...filters });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.FILTER,
        this.code,
        -1,
        '',
        this.entityType,
        this.$i18n.locale,
      );
    } else {
      delete ((query as EntitySearchQuery).toggles ?? {})[this.code];
      if ('toggles' in query && query.toggles && Object.keys(query.toggles).length === 0) {
        delete query.toggles;
      }
    }
    this.$emit('on-change');
    // eslint-disable-next-line no-restricted-globals
    history.pushState({ [this.code]: ((query as EntitySearchQuery).toggles ?? {})[this.code] },
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href);
  }
}
