





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';

@Component({ components: { RecommendationBadgeComponent } })
export default class SpeakerPageHeaderComponent extends Vue {
  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: '' })
  readonly prefix!: string;

  @Prop({ required: false, default: '' })
  readonly suffix!: string;

  @Prop({ required: false, default: null })
  protected readonly firstName!: string | null;

  @Prop({ required: false, default: null })
  protected readonly lastName!: string | null;

  @Prop({ required: false, default: null })
  protected readonly employerName!: string | null;

  @Prop({ required: false, default: null })
  protected readonly jobTitle!: string | null;

  @Prop({ required: false, default: false })
  protected readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: 'Speaker' })
  private readonly __typename!: string;

  @Prop({ required: false, default: () => [] })
  private readonly photoFileResource!: FileResource;

  private get logoSrc(): string {
    return FileResourceHelper.getFullPath(this.photoFileResource as unknown as FileResource, 'w256');
  }

  private get fullName(): string {
    let fullName = '';
    if (this.prefix) {
      fullName = `${this.prefix}`;
    }
    if (this.firstName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.firstName}`;
    }
    if (this.lastName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.lastName}`;
    }
    if (this.suffix) {
      const separator = fullName ? ', ' : '';
      fullName = `${fullName}${separator}${this.suffix}`;
    }
    return fullName.trim();
  }
}
