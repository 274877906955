enum ErrorPageType {
  INVALID_TOKEN = 'invalid-token',
  FIND_ACCOUNT_EMAIL_SUCCESS = 'find-account-email-success',
  FIND_ACCOUNT_VERIFY = 'find-account-verify',
  PASSWORD_EMAIL_SUCCESS = 'password-email-success',
  CREATE_ACCOUNT_SUCCESS = 'create-account-success',
  EXPIRED_LINK = 'expired-link',
  EMAIL_CHANGED = 'email-changed',
}

export default ErrorPageType;
