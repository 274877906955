var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.display === 'dynamic mosaic')?_c('div',{staticClass:"container-fluid",staticStyle:{"display":"grid","grid-gap":"1.875rem"}},_vm._l((_vm.mosaic),function(tiles,index){return _c('div',{key:index},[(tiles.length === 1)?_c('one-tile-mosaic-component',{attrs:{"route":_vm.tileRoute,"tile":tiles[0],"type":_vm.type}}):_vm._e(),(tiles.length === 2)?_c('two-tile-mosaic-component',{attrs:{"route":_vm.tileRoute,"tiles":tiles,"type":_vm.type}}):_vm._e(),(tiles.length === 3)?_c('three-tile-mosaic-component',{attrs:{"route":_vm.tileRoute,"tiles":tiles,"type":_vm.type}}):_vm._e(),(tiles.length === 4)?_c('four-tile-mosaic-component',{attrs:{"route":_vm.tileRoute,"tiles":tiles,"type":_vm.type}}):_vm._e()],1)}),0):_c('div',{staticClass:"row",class:{
      'row-gap-4': _vm.isTablet,
      'row-gap-8': _vm.isLargeDevice
    }},[_vm._l((_vm.mosaic),function(groupTiles,index){return [(groupTiles.length < 4)?_vm._l((groupTiles),function(tile){return _c('div',{key:tile.uid,staticClass:"col-12",class:{
            'col-md-6 col-lg-6 col-xl-6': groupTiles.length === 2,
            'col-md-4 col-lg-4 col-xl-4': groupTiles.length === 3,
            'col-md-6 col-lg-4': groupTiles.length === 4,
          }},[_c('tile-component',{key:tile.uid,attrs:{"default-height":_vm.tileDefaultHeight(groupTiles.length, 0),"image":tile.image && tile.image.path ? tile.image.path : null,"label":tile.label,"max-width":_vm.getGridMaxWidth(groupTiles.length),"route":tile.route}})],1)}):_vm._e(),(groupTiles.length === 4)?[_c('div',{key:("left-side-" + index),staticClass:"col-12 col-md-6 col-lg-6 col-xl-6"},[_c('tile-component',{key:groupTiles[0].uid,class:{
              'mb-32': _vm.isLargeDevice,
              'mb-3': _vm.isTablet
            },attrs:{"default-height":_vm.tileDefaultHeight(groupTiles.length, 0),"image":groupTiles[0].image && groupTiles[0].image.path
              ? groupTiles[0].image.path : null,"label":groupTiles[0].label,"route":groupTiles[0].route,"max-width":"700"}}),_c('tile-component',{key:groupTiles[2].uid,attrs:{"default-height":_vm.tileDefaultHeight(groupTiles.length, 2),"image":groupTiles[2].image && groupTiles[2].image.path
              ? groupTiles[2].image.path : null,"label":groupTiles[2].label,"route":groupTiles[2].route,"max-width":"700"}})],1),_c('div',{key:("right-side-" + index),staticClass:"col-12 col-md-6 col-lg-6 col-xl-6"},[_c('tile-component',{key:groupTiles[1].uid,class:{
              'mb-32': _vm.isLargeDevice,
              'mb-3': _vm.isTablet
            },attrs:{"default-height":_vm.tileDefaultHeight(groupTiles.length, 1),"image":groupTiles[1].image && groupTiles[1].image.path
              ? groupTiles[1].image.path : null,"label":groupTiles[1].label,"route":groupTiles[1].route,"max-width":"700"}}),_c('tile-component',{key:groupTiles[3].uid,attrs:{"default-height":_vm.tileDefaultHeight(groupTiles.length, 3),"image":groupTiles[3].image && groupTiles[3].image.path
              ? groupTiles[3].image.path : null,"label":groupTiles[3].label,"route":groupTiles[3].route,"max-width":"700"}})],1)]:_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }