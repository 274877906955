
















































































import { Component, Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import TileComponent from '@/components/mosaic/tiles/TileComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MosaicComponent from '@/components/mosaic/MosaicComponent.vue';
import ChannelTileComponent from '@/components/mosaic/tiles/ChannelTileComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import EntityType from '@/utils/enums/EntityType';
import CategoryTileComponent from '@/components/mosaic/tiles/CategoryTileComponent.vue';

@Component({
  components: {
    ButtonComponent,
    CategoryTileComponent,
    ChannelTileComponent,
    MosaicComponent,
    TileComponent,
  },
  inheritAttrs: false,
})
export default class ImageTilesComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'ImageTileListWidgetStore';

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: 'Grid' })
  private readonly display!: string;

  @Prop({ required: false, default: () => [] })
  private readonly tiles!: Data[];

  @Prop({ required: false, default: null })
  private readonly type!: string;

  private entityType = EntityType;

  created(): void {
    this.setDataConfig();
  }

  private navigateTo(url: string): string {
    if (url) {
      let r = url;
      const matches = url.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return '/';
  }
}
