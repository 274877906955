import { Component, Vue } from 'vue-property-decorator';
import PollDynamicBaseStore from '@/store/poll/PollDynamicBaseStore';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Component({
  inheritAttrs: false,
})
export default class PollRegisterStore extends Vue {
  protected storeContext!: string;

  created(): void {
    if (this.storeContext && !this.$store.hasModule(this.storeContext)) {
      this.$store.registerModule(this.storeContext, PollDynamicBaseStore);
    }
  }

  destroyed(): void {
    if (this.storeContext && !this.$store.hasModule(this.storeContext)) {
      this.$store.unregisterModule(this.storeContext);
    }
  }

  protected dispatch<T>(name: string, payload?: BasicTypes): Promise<T> {
    return this.$store.dispatch(`${this.storeContext}/${name}`, payload);
  }

  protected commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`${this.storeContext}/${name}`, payload);
  }

  protected getter<T>(name: string): T {
    return this.$store.getters[`${this.storeContext}/${name}`];
  }

  protected state<T>(name: string): T {
    return this.$store.state[this.storeContext][name];
  }
}
