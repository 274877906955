import { PRODUCT_BASE_FRAGMENT } from '@/graphql/_Fragments/Product/Base';

export const CATEGORY_WITH_PRODUCT_FRAGMENT = `
  fragment categoryWithProductFragment on Category {
    uid
    schemaCode
    domain
    name
    displaySequence
    contexts {
      uid
      context
    }
    products {
      ...productBaseFragment
    }
  }
  ${PRODUCT_BASE_FRAGMENT}
`;
