var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.showMenu = false; }),expression:"() => showMenu = false"}],staticClass:"message-box-menu position-relative",on:{"on-click-stop":function($event){_vm.showMenu = false}}},[(_vm.isActionButton)?_c('button-component',{class:[_vm.isMobile ? 'small-button': 'medium-button'],attrs:{"no-border":_vm.noBorder,"rounded":_vm.rounded,"size":_vm.size,"text":_vm.label ? _vm.label : '',"variant":_vm.variant},on:{"on-click":function($event){_vm.showMenu = !_vm.showMenu; _vm.$emit('click')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.icon,"icon-classes":"icon-12 icon-md-16"}})]},proxy:true}],null,false,3589052426)}):_c('button-icon-component',{class:[_vm.isMobile && _vm.mobileSize === 'sm' ? 'small-button': 'medium-button'],style:(_vm.isMobile && _vm.mobileSize === 'lg' ? 'width: 2.5rem; height: 2.5rem;' : ''),attrs:{"size":_vm.isMobile ? _vm.mobileSize : _vm.size,"text":_vm.label ? _vm.label : '',"variant":_vm.variant,"shape":"round"},on:{"on-click":function($event){_vm.showMenu = !_vm.showMenu; _vm.$emit('click')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":_vm.iconContainerClasses,"icon-classes":_vm.iconClasses,"icon":"fa-regular fa-ellipsis"}})]},proxy:true}])}),_c('div',{staticClass:"options",on:{"mouseleave":function($event){_vm.showMenu = false}}},[_c('transition',[(_vm.showMenu)?_c('div',{class:[
          'menu d-flex flex-column bg-white rounded position-absolute shadows-md py-2',
          {'flip': _vm.flipMenu}, {'menu-lg': _vm.dropdownSpacing === 'lg'},
          {'menu-sm': _vm.dropdownSpacing === 'sm'},
          {'menu-md': _vm.dropdownSpacing === 'md'},
          {'centered': _vm.isMobile && _vm.mobileCentered},
          {'right': !_vm.isMobile || !_vm.mobileCentered}
        ],attrs:{"id":("menu-" + _vm.itemId)}},[_vm._t("default",null,{"close":function () { return _vm.showMenu = false; }})],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }