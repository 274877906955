var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.internalUrl)?_c('b-link',{staticClass:"nav-bar-drop-item w-100 d-flex align-items-top\n  rounded px-4 text-decoration-none",class:{
    'justify-content-between': _vm.isDesktop,
    'justify-content-center': _vm.isTablet || _vm.isMobile,
    'py-3': _vm.isDesktop,
    'nav-bar-drop-item-md': _vm.isTablet,
    'nav-bar-drop-item-sm': _vm.isMobile,
    'text-blue-b-3-primary-blue': _vm.isActive,
  },attrs:{"to":_vm.internalUrl},on:{"click":function($event){return _vm.$emit('close-hamburger-menu-from-sub' )}}},[_c('div',{staticClass:"d-flex flex-column content"},[(_vm.title)?_c('p',{staticClass:"title m-0",class:{
        'text-neutral-n-8-dark-ink font-weight-bold': _vm.isDesktop,
        'medium font-weight-bold': _vm.isTablet || _vm.isMobile,
        'text-neutral-n-6-label': !_vm.isActive,
        'text-blue-b-3-primary-blue': _vm.isActive
      }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),(_vm.subtitle && _vm.showSubTitle)?_c('p',{staticClass:"regular text-neutral-n-6-label m-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.subtitle))+" ")]):_vm._e()])]):_c('b-link',{staticClass:"nav-bar-drop-item w-100 d-flex align-items-top\n  rounded px-4 text-decoration-none",class:{
    'justify-content-between': _vm.isDesktop,
    'justify-content-center': _vm.isTablet || _vm.isMobile,
    'py-3': _vm.isDesktop,
    'nav-bar-drop-item-md': _vm.isTablet,
    'nav-bar-drop-item-sm': _vm.isMobile,
  },on:{"click":_vm.navigate}},[_c('div',{staticClass:"d-flex flex-column content"},[(_vm.title)?_c('p',{staticClass:"title m-0",class:{
        'text-neutral-n-8-dark-ink font-weight-bold': _vm.isDesktop,
        'medium font-weight-bold': _vm.isTablet || _vm.isMobile,
        'text-neutral-n-6-label': !_vm.isActive,
        'text-blue-b-3-primary-blue': _vm.isActive
      }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),(_vm.subtitle && _vm.showSubTitle)?_c('p',{staticClass:"regular text-neutral-n-6-label m-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.subtitle))+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }