







































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { format } from 'date-fns';
import Geozone from '@/models/graphql/Geozone';
import { Getter, State } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ExportToCalendarComponent from '@/components/ExportToCalendarComponent.vue';
import { CalendarEvent } from 'calendar-link';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  components: {
    ExportToCalendarComponent,
    FontAwesomeComponent,
  },
})
export default class SessionInfoComponent extends Vue {
  @Prop({ required: false, default: null })
  private readonly uid!: string;

  @Prop({ required: false, default: false })
  private readonly displayHall!: boolean;

  @Prop({ required: false, default: '' })
  private readonly name!: string;

  @Prop({ required: false, default: '' })
  private readonly description!: string;

  @Prop({ required: false })
  private startTime!: string;

  @Prop({ required: false })
  private endTime!: string;

  @Prop({ required: false, default: null })
  private geozones!: Geozone[];

  @Prop({ required: false })
  private credits!: string | null;

  @Prop({ required: false })
  private creditsUnit!: string | null;

  @Prop({ required: false })
  private price!: string | null;

  @Prop({ required: false })
  private free!: boolean;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private FeatureKeys = FeatureKeys;

  private time = '';

  get date(): string | null {
    if (this.startTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.startTime}Z`,
        this.selectedTzName,
      );
      return format(
        startTime,
        `${this.$t('app.date.dayOfWeek')}, ${this.$t('app.date.defaultDateFormat')}`,
        { locale: this.dateLocale },
      );
    }
    return null;
  }

  get location(): string | null {
    if (this.geozones && this.geozones.length > 0) {
      const geozone = this.geozones[0];
      if (this.displayHall && geozone.exhibitHall && geozone.exhibitHall.name) {
        if (geozone.name) {
          return `${geozone.exhibitHall.name} · ${geozone.name}`;
        }
        return geozone.exhibitHall.name;
      }
      if (geozone && geozone.name) {
        return geozone.name;
      }
    }
    return null;
  }

  private get addToCalendarEvent(): CalendarEvent {
    const route = this.$router.resolve({
      name: 'session-detail',
      params: { sessionId: this.uid },
    });
    return {
      title: this.name,
      description: `\n ${window.location.origin}${route.href} \n ${this.description}`,
      location: this.location || '',
      start: this.startTime ? `${this.startTime}Z` : '',
      end: this.endTime ? `${this.endTime}Z` : '',
      guests: [],
    };
  }

  mounted(): void {
    this.setTime();
  }

  @Watch('isEventTimeZone')
  private setTime(): void {
    if (this.startTime && this.endTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.startTime}Z`,
        this.selectedTzName,
      );
      const endTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.endTime}Z`,
        this.selectedTzName,
      );
      this.time = `${format(startTime, `${this.$t('app.date.defaultTimeFormat')}`)}
       ${this.$t('app.date.dateTimeSeparator')}
       ${format(endTime, `${this.$t('app.date.defaultTimeFormat')}`)}
      ${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
      || !(this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE) as CommunityFeature).enabled
    ? `(${this.selectedTzAbbreviation})`
    : ''}`;
    }
  }
}
