




















import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ButtonComponent,
  },
})
export default class SignInChangeEmail extends Vue {
  @Prop({ required: false, default: '' })
  emailToken!: string | null;

  @communityUserStore.Action
  private changeUserEmail!: (payload: {
    uploadToken: string;
  }) => Promise<boolean>;

  private responseType: string | null = null;

  created(): void {
    if (this.emailToken) {
      this.changeUserEmail({ uploadToken: this.emailToken })
        .then((response) => {
          if (response) {
            this.responseType = 'success';
          } else {
            this.responseType = 'failed';
          }
        });
    }
  }

  onButtonClick(): void {
    this.$router.push({ path: '/' });
  }
}
