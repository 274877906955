











import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ExtraPropertiesTypes from '@/utils/enums/ExtraPropertiesTypes';

@Component({
})
export default class ExtraPropertyElement extends BreakpointWrapper {
  @Prop({ required: true })
  protected readonly label!: string | null;

  @Prop({ required: false })
  protected readonly value!: string | boolean | number | null;

  @Prop({ required: true })
  private type!: string;

  // eslint-disable-next-line consistent-return, getter-return
  private get processValue(): string | undefined {
    if (!this.value) {
      return `${this.$t('extra-property-section.null')}`;
    }
    if (this.value) {
      switch (this.type) {
        case ExtraPropertiesTypes.STRING || ExtraPropertiesTypes.NUMBER:
          return this.value as string;
        case ExtraPropertiesTypes.BOOLEAN:
        // eslint-disable-next-line max-len
          return (this.value === 'true') ? `${this.$t('extra-property-section.yes')}` : `${this.$t('extra-property-section.no')}`;
        default:
          return `${this.$t('extra-property-section.null')}`;
      }
    }
  }
}
