import { SUB_EDITION_AUTH_USER_FRAGMENT } from '@/graphql/_Fragments/SubEdition/AuthUser';

export const SUB_EDITION_USER_ROLE_FULL_FRAGMENT = `
  fragment subEditionUserRoleFullFragment on SubEditionUserRole {
    uid
    schemaCode
    canPost
    canModerateChat
    subEdition {
      ...subEditionAuthUserFragment
    }
  }
  ${SUB_EDITION_AUTH_USER_FRAGMENT}
`;
