










































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CommunityUser from '@/models/graphql/CommunityUser';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import Poll from '@/models/graphql/Poll';
import PollAnswer from '@/models/graphql/PollAnswer';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import ActionType from '@/utils/enums/ActionType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SubEdition from '@/models/graphql/SubEdition';
import Exhibitor from '@/models/graphql/Exhibitor';
import Channel from '@/models/graphql/Channel';
import Topic from '@/models/graphql/Topic';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const pollUserAnswerStore = namespace('PollUserAnswerStore');
const notificationStore = namespace('NotificationStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    FontAwesomeComponent,
    IllustrationComponent,
    ButtonComponent,
  },
})
export default class LivePollPostComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @permissionManagerStore.Getter
  protected canPostInCompany!: (companyUid: string) => boolean;

  @permissionManagerStore.Getter
  protected canPostInSubEdition!: (subEditionId: string) => boolean;

  @Prop({ required: true })
  private initiator!: CommunityUser | SubEdition | Exhibitor | Channel | Topic;

  @Prop({ required: true })
  private readonly poll!: Poll;

  @Prop({ required: true })
  private readonly postUid!: string;

  @Prop({ required: false, default: false })
  private isFeedItem!: boolean;

  @Prop({ required: false, default: false })
  private isModalRepost!: boolean;

  @Getter
  private authUser!: CommunityUser;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @pollUserAnswerStore.Action(ActionType.CREATE)
  private createPollUserAnswer!: (payload: {
    userUid: string;
    pollAnswerUid: string;
  })
    => Promise<PollUserAnswer | undefined>;

  private currentAnsweredPoll: Poll | null = null;

  private partialAuthUser = {} as CommunityUser;

  private now = DateTimeHelper.getCurrentDateTime();

  private get pollIsClosed(): boolean {
    const endTime = this.poll.endTime ? DateTimeHelper.toLocal(new Date(this.poll.endTime)) : null;
    if (endTime) {
      return this.now > endTime;
    }
    return true;
  }

  private get timeLeft(): string {
    let result = '';
    if (this.pollIsClosed) {
      result = this.$t('session-polls.poll-list-item.voting-closed').toString();
    } else if (this.poll && this.poll.endTime) {
      const localEndTime = DateTimeHelper.toLocal(new Date(this.poll.endTime as string));
      const today = this.now;
      if (localEndTime >= today) {
        let daysDifference = differenceInDays(today, localEndTime);
        daysDifference = daysDifference < 0 ? -daysDifference : daysDifference;
        let hoursDifference = differenceInHours(today, localEndTime);
        hoursDifference = hoursDifference < 0 ? -hoursDifference : hoursDifference;
        let minutesDifference = differenceInMinutes(today, localEndTime);
        minutesDifference = minutesDifference < 0 ? -minutesDifference : minutesDifference;
        if (daysDifference >= 1) {
          result = this.$tc('session-polls.poll-list-item.left.days', 0, {
            timeLeftDiff: daysDifference,
          });
        } else if (hoursDifference <= 24 && hoursDifference >= 1) {
          result = this.$tc('session-polls.poll-list-item.left.hours', 0, {
            timeLeftDiff: hoursDifference,
          });
        } else if (minutesDifference >= 0) {
          result = this.$tc('session-polls.poll-list-item.left.minutes', 0, {
            timeLeftDiff: minutesDifference,
          });
        }
      }
    }
    return result;
  }

  private get isOwner(): boolean {
    if (!this.authUser) {
      return false;
    }
    return (this.initiator.uid === this.authUser.uid
        || this.canPostInCompany(this.initiator.uid)
        || this.canPostInSubEdition(this.initiator.uid)
    );
  }

  private get getPollAnswersCount(): number {
    if (this.poll && this.poll.pollAnswers && this.poll.pollAnswers.length) {
      return this.poll.pollAnswers
        .reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return 0;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.POLL_ANSWER,
    ];
  }

  mounted(): void {
    if (this.authUser) {
      this.partialAuthUser = {
        uid: this.authUser.uid,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        pictureFileResource: this.authUser.pictureFileResource,
      } as CommunityUser;
    }
    if (!this.isModalRepost) {
      this.genericEvent({ channel: `poll-channel-post-${this.postUid}` });
      setInterval(() => {
        this.now = DateTimeHelper.getCurrentDateTime();
      }, 5000);
    }
  }

  beforeDestroy(): void {
    if (!this.isModalRepost) {
      this.unsubscribeGenericEventPointer(`poll-channel-post-${this.postUid}`);
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    if (this.isModalRepost) return;
    let pollAnswer: PollAnswer | null = null;
    if (event && event.type === NotificationEventType.POLL_ANSWER) {
      if (!this.currentAnsweredPoll
        || (this.currentAnsweredPoll && event.entityId !== this.currentAnsweredPoll.uid)) {
        const extraData = JSON.parse(event.extra);
        pollAnswer = PollAnswer.hydrate(extraData.pollAnswer);
        this.onAnswerFromEvent(event.entityId, pollAnswer);
      }
      this.currentAnsweredPoll = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getPollAnswerCount(answer: PollAnswer): number {
    return answer.answerCount || 0;
  }

  // eslint-disable-next-line class-methods-use-this
  private getPollAnswersTotalCount(): number {
    if (this.poll && this.poll.pollAnswers && this.poll.pollAnswers.length) {
      return this.poll.pollAnswers
        .reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return 0;
  }

  private getAnswerPercentage(pollAnswer: PollAnswer): string {
    const pollAnswersTotalCount = this.getPollAnswersTotalCount();
    const pollAnswerCount = this.getPollAnswerCount(pollAnswer);
    return `${this.$tc('session-polls.answer-percent',
      0,
      {
        count: pollAnswerCount > 0
          ? (parseInt(((100 * pollAnswerCount) / pollAnswersTotalCount).toString(), 10))
          : 0,
      })
    }`;
  }

  private onAnswerFromEvent(pollUid: string, pollAnswer: PollAnswer): void {
    if (pollUid === this.poll.uid) {
      const { pollAnswers } = this.poll;
      const localPollAnswerIndex = pollAnswers ? pollAnswers.findIndex((p) => p.uid === pollAnswer.uid) : -1;
      if (localPollAnswerIndex >= 0) {
        const localPollAnswer = pollAnswers && pollAnswers.length ? pollAnswers[localPollAnswerIndex] : null;
        if (localPollAnswer) {
          localPollAnswer.answerCount = localPollAnswer.answerCount
            ? localPollAnswer.answerCount + 1
            : 1;
          if (this.poll.answerCountByAnswerId && this.poll.answerCountByAnswerId.length) {
            const answerCountsIndex = this.poll.answerCountByAnswerId
              ? this.poll.answerCountByAnswerId
                .findIndex((p) => p.key === (pollAnswer && pollAnswer.id ? pollAnswer.id.toString() : ''))
              : -1;
            if (answerCountsIndex >= 0) {
              const { answerCountByAnswerId } = this.poll;
              if (answerCountByAnswerId && answerCountByAnswerId.length) {
                const { value } = answerCountByAnswerId[answerCountsIndex];
                answerCountByAnswerId[answerCountsIndex].value = (parseInt(value || '0', 10) + 1).toString();
              }
            } else if (pollAnswer && pollAnswer.id) {
              this.poll.answerCountByAnswerId.push({
                key: pollAnswer.id.toString(),
                value: '1',
              });
            }
          } else if (pollAnswer && pollAnswer.id) {
            this.poll.answerCountByAnswerId = [{
              key: pollAnswer.id.toString(),
              value: '1',
            }];
          }
        }
      }
    }
  }

  private getUserAnswerCount(pollAnswer: PollAnswer): number {
    if (this.poll.answerCountByAnswerId && this.poll.answerCountByAnswerId.length) {
      const answerCount = this.poll.answerCountByAnswerId
        .find((item) => parseInt(item.key, 10) === pollAnswer.id);
      if (answerCount) {
        return answerCount.value ? parseInt(answerCount.value, 10) : 0;
      }
    }
    return 0;
  }

  private onAnswerClick(pollAnswer: PollAnswer): void {
    this.currentAnsweredPoll = this.poll;
    // eslint-disable-next-line no-underscore-dangle
    if (this.poll && this.poll._myAnswer) {
      // eslint-disable-next-line no-underscore-dangle
      this.poll._myAnswer = [{
        uid: '',
        pollAnswer,
        user: this.authUser,
      } as PollUserAnswer];
    }
    const { pollAnswers } = this.poll;
    const localPollAnswerIndex = pollAnswers ? pollAnswers.findIndex((p) => p.uid === pollAnswer.uid) : -1;
    if (localPollAnswerIndex >= 0) {
      const localPollAnswer = pollAnswers && pollAnswers.length ? pollAnswers[localPollAnswerIndex] : null;
      // eslint-disable-next-line no-underscore-dangle
      if (localPollAnswer) {
        localPollAnswer.answerCount = localPollAnswer.answerCount
          ? localPollAnswer.answerCount + 1
          : 1;
      }
    }

    this.createPollUserAnswer({
      userUid: this.authUser.uid,
      pollAnswerUid: pollAnswer.uid,
    }).then((pollUserAnswer) => {
      if (pollUserAnswer && pollUserAnswer.pollAnswer) {
        this.triggerGenericEvent({
          channels: [`poll-channel-post-${this.postUid}`],
          type: NotificationEventType.POLL_ANSWER,
          entityId: this.poll.uid,
          extra: JSON.stringify({
            user: this.partialAuthUser,
            pollAnswer,
          }),
        });
      }
    });
  }
}
