
























import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import QrcodeVue from 'qrcode.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

@Component({
  components: {
    QrcodeVue,
  },
})
export default class QrLiveComponent extends VueBaseWidget {
    @Prop({ required: false, default: '' })
    private label!: string;

    @Prop({ required: false, default: null })
    private route!: string;

    @Prop({ required: false, default: null })
    private entityCode!: string;

    private get imageSrc(): string {
      if (this.community && this.community.logoFileResource) {
        return FileResourceHelper.getFullPath(this.community.logoFileResource, 'w250');
      }
      return '';
    }
}
