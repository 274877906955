























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { BSkeleton, BSkeletonWrapper } from 'bootstrap-vue';
import { PortalTarget } from 'portal-vue';
import ActionButtonGroupRegisterStore from '@/components/action-buttons/ActionButtonGroupRegisterStore';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
    BSkeleton,
    BSkeletonWrapper,
    PortalTarget,
  },
  inheritAttrs: false,
  name: 'ActionButtonGroupComponent',
})
export default class ActionButtonGroupComponent extends mixins(
  VueBaseWidget,
  VueRegisterStoreWidget,
  ActionButtonGroupRegisterStore,
) {
  baseStoreName = 'ActionButtonGroupWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly actionButtons!: Data[];

  @Prop({ required: false })
  private detailPageInfos!: Data;

  @Prop({
    required: false,
    default: 5,
  })
  private displayedButtonCount!: number;

  @Prop({
    required: false,
    default: true,
  })
  private showMoreText!: boolean;

  private showMoreMenu = false;

  private popoverActive = false;

  private portalName = `action-button-modal-portal-${+new Date()}`;

  private get fetchButtonList(): Data[] {
    return this.getter<Data[]>('fetchButtonList');
  }

  private get fetchMenuList(): Data[] {
    return this.getter<Data[]>('fetchMenuList');
  }

  private get buttonLoading(): boolean {
    return this.getter<boolean>('buttonLoading');
  }

  private get entityName(): string {
    if (this.detailPageInfos) {
      return [EntityType.USER, EntityType.SPEAKER]
      // eslint-disable-next-line no-underscore-dangle
        .includes(this.detailPageInfos.__typename as unknown as EntityType)
        ? `${this.detailPageInfos.firstName} ${this.detailPageInfos.lastName}`
        : this.detailPageInfos.name as unknown as string;
    }
    return '';
  }

  private get currentUser(): CommunityUser | null {
    if (this.detailPageInfos && [EntityType.USER, EntityType.SPEAKER]
    // eslint-disable-next-line no-underscore-dangle
      .includes(this.detailPageInfos.__typename as unknown as EntityType)) {
      return CommunityUser
        .hydrate(Object.fromEntries(Object.entries(this.detailPageInfos)
          .filter(([k]) => [
            'uid',
            'schemaCode',
            'firstName',
            'lastName',
            'jobTitle',
            'employerName',
            'pictureFileResource'].includes(k))));
    }
    return null;
  }

  private get currentExhibitor(): Exhibitor | null {
    if (this.detailPageInfos && [EntityType.EXHIBITOR, EntityType.COMPANY]
    // eslint-disable-next-line no-underscore-dangle
      .includes(this.detailPageInfos.__typename as unknown as EntityType)) {
      return Exhibitor
        .hydrate(Object.fromEntries(Object.entries(this.detailPageInfos)
          .filter(([k]) => [
            'uid',
            'name'].includes(k))));
    }
    return null;
  }

  private get entityId(): number {
    return this.detailPageInfos ? this.detailPageInfos.id as unknown as number : 0;
  }

  private get entityUid(): string {
    return this.detailPageInfos ? this.detailPageInfos.uid as unknown as string : '';
  }

  beforeCreate(): void {
    this.visibilityStoreName = `ActionButtonVisibilityStore${+new Date()}`;
  }

  created(): void {
    this.setDataConfig();
    this.init();
    this.$eventsBus.on('render-action-button-group', this.reRenderButtons);
  }

  @Watch('isReadyToDisplay')
  @Watch('actionButtons')
  @Watch('displayedButtonCount')
  init(): void {
    if (this.isReadyToDisplay && this.actionButtons.length > 0) {
      this.commit('initialize', {
        buttons: this.actionButtons,
        count: this.displayedButtonCount,
      });
      this.reRenderButtons();
    }
  }

  private reRenderButtons(): void {
    this.commit('reRenderButtons');
  }

  private toggleMoreMenu(): void {
    this.showMoreMenu = !this.showMoreMenu;
  }
}
