





































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ProductImage from '@/models/graphql/ProductImage';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import EntityType from '@/utils/enums/EntityType';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedSessionCard extends Vue {
  @communityUserAgendaEntryStore.Action
  addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private sessionName!: string;

  @Prop({ required: false })
  private sessionDescription!: string;

  @Prop({ required: false })
  private imageFileResource!: ProductImage;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  @Prop({ required: false, default: null })
  private _isInAgenda!: string | null;

  private isInAgenda = this._isInAgenda;

  private entityType = EntityType.SESSION;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  private get imageSrc(): string {
    return FileResourceHelper.getFullPath(this.imageFileResource, 'w250');
  }

  @Watch('_isInAgenda')
  private updateIsInAgenda(): void {
    this.isInAgenda = this._isInAgenda;
  }

  private toggleAgenda(): void {
    if (this.isInAgenda) {
      this.removeFromAgenda({ uid: this.isInAgenda });
      this.isInAgenda = null;
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.entityType,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.uid,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    } else {
      this.addToAgenda({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: this.entityType,
      }).then((response) => {
        this.isInAgenda = response?.uid || '';
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          this.entityType,
          StatLoggerActions.ADD_TO_AGENDA,
          'addAppointment',
          -1,
          this.uid,
          StatLoggerCategories.ADD,
          this.$i18n.locale,
        );
      });
    }
  }
}
