



































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import { namespace, State } from 'vuex-class';
import SessionsCalendarDateTabsComponent from '@/components/sessions-calendar/SessionsCalendarDateTabsComponent.vue';
import SessionsCalendarRowComponent from '@/components/sessions-calendar/SessionsCalendarRowComponent.vue';
import SessionCalendarTab from '@/utils/types/session/calendar/SessionCalendarTab';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { BasicTypes } from '@/utils/types/BasicTypes';

const sessionBrowserCalendarStore = namespace('SessionBrowserCalendarStore');

@Component({
  components: {
    IllustrationComponent,
    SessionsCalendarRowComponent,
    SessionsCalendarDateTabsComponent,
  },
})
export default class SessionsCalendarComponent extends Vue {
  @Prop({
    required: false,
    default: true,
  })
  readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayFeaturedBorder!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayLiveBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayInterestedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displaySpeakers!: boolean;

  @Prop({
    required: true,
    default: null,
  })
  private readonly cardRoute!: string;

  @Prop({
    required: true,
    default: () => ({}),
  })
  private readonly filter!: object;

  private IllustrationType = IllustrationType;

  @sessionBrowserCalendarStore.State
  private selectedDate!: string;

  @sessionBrowserCalendarStore.State
  private isEmpty!: boolean;

  @sessionBrowserCalendarStore.Action
  private loadSessionCalendarDates!: (payload: { filter: object; tz: string }) => Promise<void>;

  @sessionBrowserCalendarStore.Action
  private loadSessionsByDate!: (payload: { filter: object; tz: string }) => Promise<void>;

  @sessionBrowserCalendarStore.State
  private content!: Record<string, Record<string, Session[]>>;

  @sessionBrowserCalendarStore.Mutation
  private setSelectedDate!: (selected: string) => void;

  @sessionBrowserCalendarStore.Mutation
  private setSelectedKey!: (key: SessionCalendarTab) => void;

  @sessionBrowserCalendarStore.Getter
  private dateTabs!: SessionCalendarTab[];

  @State
  private selectedTzName!: string;

  private groupedSessions: Record<string, Session[]> | null = null;

  beforeDestroy(): void {
    const queries = this.$route.query;
    delete queries.calendar;
    this.$router
      .replace({ query: queries })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
  }

  private created(): void {
    this.updateSelectedDate();
  }

  private updateSelectedDate(): void {
    const queryCalendar = this.$route.query.calendar as string;
    this.setSelectedDate(queryCalendar);
    const foundKey = this.dateTabs.find((t) => t.selected);
    if (foundKey) {
      this.setSelectedKey(foundKey);
    } else {
      this.setSelectedKey(this.dateTabs[0]);
    }
  }

  @Watch('content', { deep: true })
  @Watch('selectedDate')
  private loadSessionsCalendar(): void {
    if (this.selectedDate) {
      this.groupedSessions = null;
      const fullFilter = this.replaceMagicArgs(
        JSON.stringify(this.filter || {}),
        { ...this.$route.query, ...this.$route.params },
      );
      this.loadSessionsByDate({
        filter: JSON.parse(fullFilter),
        tz: this.selectedTzName,
      })
        .finally(() => {
          this.groupedSessions = this.content[this.selectedDate];
        });
    }
  }

  @Watch('filter', { immediate: true })
  private filterChange(): void {
    const result = this.replaceMagicArgs(JSON.stringify(this.filter || {}), { ...this.$route.query, ...this.$route.params });
    this.loadSessionCalendarDates({
      filter: JSON.parse(result),
      tz: this.selectedTzName,
    })
      .then(() => {
        const calendar = this.selectedDate || this.dateTabs[0]?.key;
        window.history.pushState(null,
          '',
          this.$router.resolve({
            path: this.$route.path,
            params: this.$route.params,
            query: {
              ...this.$route.query,
              ...(calendar ? { calendar } : {}),
            },
          }).href);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  private replaceMagicArgs(query: string, args: Record<string, BasicTypes> | undefined): string {
    let result = query;
    if (args && Object.keys(args).length > 0) {
      Object.keys(args)
        .forEach((k) => {
          const regex = new RegExp(`"*%${k}%"*`, 'gm');
          if (k.endsWith('_int') && !Number.isNaN(args[k]) && typeof args[k] === 'string') {
            result = result.replaceAll(regex, `${args && args[k] ? parseInt(args[k] as string, 10) : ''}`);
          } else if (k.endsWith('_bool')
                && typeof args[k] === 'string'
                && ['true', 'false'].includes((args[k] as string).toLowerCase())) {
            result = result.replaceAll(regex, `${args && args[k] ? (args[k] as string).toLowerCase() === 'true' : false}`);
          } else if (typeof args[k] === 'number') {
            result = result.replaceAll(regex, `${args && args[k] ? args[k] : ''}`);
          } else if (Array.isArray(args[k])) {
            const value = `[${(args[k] as []).map((val) => `"${val}"`)
              .join(', ')}]`;
            result = result.replaceAll(regex, `${args && args[k] && value ? value : ''}`);
          } else if (k === 'filter') {
            result = result.replaceAll(regex, `${args && args[k] ? args[k] : ''}`);
          } else {
            result = result.replaceAll(regex, `"${args && args[k] ? args[k] : ''}"`);
          }
        });
      return result;
    }
    return result;
  }
}
