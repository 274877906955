













































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import TagAddCreateModal from '@/components/modals/TagAddCreateModal.vue';
import { Portal } from 'portal-vue';

const communityUserTagStore = namespace('CommunityUserTagStore');

@Component({
  components: {
    TagAddCreateModal,
    ButtonComponent,
    FontAwesomeComponent,
    Portal,
  },
})
export default class ActionButtonTags extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @communityUserTagStore.Action
  private loadTagLinkedEntityCount!: (entityUid: string) => Promise<void>;

  @communityUserTagStore.State
  private stateTagCount!: number;

  private tagModalId = `tag-add-create${+new Date()}`;

  private get isDisplayed(): boolean {
    return this.actionType === this.ActionButtonType.TAGS;
  }

  private get btnText(): string {
    return this.stateTagCount === 0
      ? `${this.$t('actions.tags')}`
      : `${this.$tc('actions.tags-count', 0, { tagCount: this.stateTagCount })}`;
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private toggleTags(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    this.$bvModal.show(this.tagModalId);
  }
}
