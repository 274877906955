




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Mutation, State } from 'vuex-class';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import RadioInput from '@/components/RadioInput.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    RadioInput,
    CheckboxSwitchComponent,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class ChangeTimeZoneModalComponent extends Vue {
  @State
  selectedTzName!: string;

  @State
  communityTzName!: string;

  @State
  localTzName!: string;

  @Mutation
  setSelectedTimeZone!: (timezone: string) => void;

  @Prop({ default: '' })
  private modalId!: string;

  private newSelectedTimeZone = '';

  created(): void {
    this.newSelectedTimeZone = this.selectedTzName;
  }

  private toggleTimeZone(timeZone: string): void {
    this.newSelectedTimeZone = timeZone;
  }

  private saveTimeZone(): void {
    this.setSelectedTimeZone(this.newSelectedTimeZone);
    this.onCancel();
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }
}
