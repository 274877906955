






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import FormatHelper from '@/utils/helpers/FormatHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

@Component({
  components: {
    AvatarSoloWidget,
    StandardModal,
    ButtonComponent,
  },
})
export default class SalePackageVariantModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ required: true, default: null })
  salePackageVariants!: SalesPackage[];

  @Prop({ required: true, default: null })
  variant!: SalesPackage | null;

  // eslint-disable-next-line class-methods-use-this
  private formatCurrency(price: number): string {
    return FormatHelper.formatCurrency((price || 0) / 100);
  }

  private onVariantSelected(variant: SalesPackage): void {
    this.$bvModal.hide(this.modalId);
    this.$emit('on-variant-select', variant);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.$emit('on-cancel');
  }

  // eslint-disable-next-line class-methods-use-this
  private getSrc(fileResource?: FileResource): string {
    if (fileResource) {
      return FileResourceHelper.getFullPath(fileResource, 'w350');
    }
    return '/img/banner/empty.svg';
  }
}
