

















import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ColorListComponent from '@/components/ColorListComponent.vue';

@Component({
  components: {
    ColorListComponent,
  },
})
export default class ExtraPropertyElementColors extends BreakpointWrapper {
  @Prop({ required: true })
  protected readonly label!: string;

  @Prop({ required: true })
  protected readonly value!: string[];
}
