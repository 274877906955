
































import { Component, Inject, Prop } from 'vue-property-decorator';
import PillComponent from '@/components/pill/PillComponent.vue';
import FeedItemComponent from '@/components/feed/FeedItemComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import FeedType from '@/utils/enums/feed/FeedType';

@Component({
  components: {
    FeedItemComponent,
    PillComponent,
  },
})
export default class FeedItemWrapperComponent extends VueBaseWidget {
  @Inject({ from: 'feedType' })
  private feedType!: { value: string };

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: true })
  private items!: FeedItemWrapper[];

  get pinType(): string {
    if (this.feedType.value === FeedType.TOPIC) {
      return 'pinnedInTopic';
    }

    if (this.feedType.value === FeedType.CHANNEL) {
      return 'pinnedInChannel';
    }

    if (this.feedType.value === FeedType.GLOBAL) {
      return 'pinnedInGlobal';
    }

    return 'pinnedInEntity';
  }

  private get itemsWithFeeds(): FeedItemWrapper[] {
    // eslint-disable-next-line no-underscore-dangle
    return this.items.filter((f) => f._feedItems && f._feedItems.length > 0);
  }
}
