




































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ButtonIconComponent,
    StandardModal,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        valid(value: string): boolean {
          const containsUppercase = value ? value.match(/[A-Z]/) : false;
          const containsLowercase = value ? value.match(/[a-z]/) : false;
          const containsNumber = value ? value.match(/[0-9]/) : false;
          return !!containsUppercase && !!containsLowercase && !!containsNumber;
        },
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
})
export default class ChangePasswordModalComponent extends VueBaseWidget {
  @Prop({ default: '' })
  private modalId!: string;

  @communityUserStore.Action
  private changePassword!: (
    payload: {
      uid: string;
      password: string;
      newPassword: string;
    }
  ) => Promise<Response>;

  private loading = false;

  private hasError = false;

  private form = {
    password: '',
    repeatPassword: '',
  };

  private currentPassword = '';

  private passwordFieldFocused = false;

  private passwordFieldIsDirty = false;

  private repeatPasswordFieldFocused = false;

  private repeatPasswordFieldIsDirty = false;

  private get isLoading(): boolean {
    return this.loading;
  }

  private get isUppercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[A-Z]/) : false;
  }

  private get isLowercase(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[a-z]/) : false;
  }

  private get hasNumber(): boolean {
    return this.form && this.form.password ? !!this.form.password.match(/[0-9]/) : false;
  }

  private get disabledSubmit(): boolean {
    return this.$v.$invalid
      || (this.needCurrentPassword ? this.currentPassword.length === 0 : false);
  }

  private get needCurrentPassword(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return !(this.authUser && this.authUser._needsPasswordCreated);
  }

  private clearFields(): void {
    this.$v.$reset();
    this.form = {
      password: '',
      repeatPassword: '',
    };
  }

  private onDiscard(): void {
    this.clearFields();
    this.$bvModal.hide(this.modalId);
  }

  private validateState(name: string): boolean {
    const $dirty = this.$v.form[name]?.$dirty;
    const $error = this.$v.form[name]?.$error;
    return $dirty ? !$error : true;
  }

  private onBlur(fieldType: string): void {
    const $error = this.$v.form[fieldType]?.$error;
    const $dirty = this.$v.form[fieldType]?.$dirty;
    if ($dirty) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldFocused = false;
          this.passwordFieldIsDirty = !!$error;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldFocused = false;
          this.repeatPasswordFieldIsDirty = !!$error;
          break;
        default:
          break;
      }
    }
  }

  private checkEmptyForm(fieldType: string): void {
    const $invalid = this.$v.form[fieldType]?.$invalid;
    const $required = this.$v.form[fieldType]?.required;
    if ($invalid) {
      switch (fieldType) {
        case 'password':
          this.passwordFieldIsDirty = !$required;
          break;
        case 'repeatPassword':
          this.repeatPasswordFieldIsDirty = !$required;
          break;
        default:
          break;
      }
    }
  }

  private onSubmit(): void {
    if (this.$v.$invalid) {
      this.checkEmptyForm('password');
      this.checkEmptyForm('repeatPassword');
    } else {
      this.hasError = false;
      this.changePassword({
        uid: this.authUser.uid,
        password: this.currentPassword,
        newPassword: this.form.password,
      }).then((responseData) => {
        if (responseData) {
          // eslint-disable-next-line no-underscore-dangle
          this.authUser._needsPasswordCreated = false;
          this.clearFields();
          this.$emit('reset-password-success');
          this.$bvModal.hide(this.modalId);
        } else {
          this.hasError = true;
        }
      }).catch(() => {
        this.hasError = true;
      });
    }
  }
}
