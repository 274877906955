













































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Variant from '@/utils/enums/Variant';
import Size from '@/utils/enums/Size';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import TitleComponent from '@/components/TitleComponent.vue';

@Component({
  components: { TitleComponent, PillWidget, ButtonComponent },
  inheritAttrs: false,
})
export default class PillListComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'PillListWidgetStore';

  @Prop({ required: false, default: () => [] })
  private readonly pills!: Data[];

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string | null;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: null })
  private readonly titleSize!: string;

  @Prop({ required: false, default: null })
  private readonly size!: string;

  @Prop({ required: false, default: () => [] })
  private readonly highlightPills!: [];

  private get titleSizeClass(): string {
    const classes: Record<string, string> = {
      title1: 'h1 font-weight-normal',
      title2: 'h2 font-weight-normal',
      title3: 'h3 font-weight-normal',
      title4: 'h4 font-weight-normal',
      title1Bold: 'h1 font-weight-bold',
      title2Bold: 'h2 font-weight-bold',
      title3Bold: 'h3 font-weight-bold',
      title4Bold: 'paragraph paragraph-1 font-weight-bold',
      paragraphUnderline: 'paragraph paragraph-1 paragraph-underline mb-2',
    };

    return classes[this.titleSize];
  }

  private get handleSize(): Size {
    const mapping: Record<string, Size> = { normal: Size.MEDIUM, small: Size.SMALL };

    return mapping[this.size] || Size.MEDIUM;
  }

  created(): void {
    this.setDataConfig();
  }

  private getVariantColorOfPill(interestUid: string): Variant {
    if (this.isUserInterest(interestUid)) {
      return Variant.YELLOW;
    }

    switch (this.size) {
      case 'normal':
        return Variant.WHITE;
      case 'small':
        return Variant.GRAY;
      default:
        return Variant.WHITE;
    }
  }

  private handleBaseUrl(uid: string): void {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          } else {
            r = r.replace(prop, '');
          }
        });
        r = r.replaceAll('%', '');
        this.$router.push(`${r}${uid}`);
      } else if (r[r.length - 1] === '/') {
        this.$router.push(`${r}${uid}`);
      } else {
        this.$router.push(`${r}/${uid}`);
      }
    }
  }

  private isUserInterest(companyInterestUid: string): boolean {
    return !!this.highlightPills.find(
      (userInterest: CommunityUserInterest) => userInterest.uid === companyInterestUid,
    );
  }
}
