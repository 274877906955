import FileResourceParams from '@/utils/types/FileResourceParams';
import axios from 'axios';

export default class UploadFileHandler {
  static async uploadFileResourceHandler(params: FileResourceParams): Promise<XMLHttpRequest> {
    const arr = (params.base64Path || '').split(',');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const mime = arr[0].match(/:(.*?);/)![1];

    const response = await fetch(params.base64Path as string);
    const blob = await response.blob();
    const fileToUpload = new File([blob], params.fileName as string, { type: mime });

    const uploadUrl: string = process.env.VUE_APP_GRAPHQL_FILE_UPLOAD ?? '';

    const formData = new FormData();
    if (params.schemaCode) {
      formData.append('communityCode', params.schemaCode);
    }
    formData.append('file', fileToUpload);

    const request = new XMLHttpRequest();
    request.open('POST', uploadUrl);
    request.send(formData);

    return request;
  }

  static async getS3Info(params: FileResourceParams): Promise<{ src: string; type: string }> {
    const s3InfoUrl: string = process.env.VUE_APP_GRAPHQL_GET_S3_INFO ?? '';
    const uploadUrl: string = process.env.VUE_APP_S3_VIDEO_AMAZONAWS ?? '';

    const arr = (params.base64Path || '').split(',');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const mime = arr[0].match(/:(.*?);/)![1];

    const response = await fetch(params.base64Path as string);
    const blob = await response.blob();
    const fileToUpload = new File([blob], params.fileName as string, { type: mime });

    const formData = new FormData();
    if (params.file?.name) {
      formData.append('filename', params.file?.name);
    }
    if (params.token) {
      formData.append('authToken', params.token);
    }

    return axios.post(s3InfoUrl, formData).then((s3InfoResponse) => {
      const s3Infos = s3InfoResponse.data.s3Info;

      const uploadFormData = new FormData();
      uploadFormData.append('key', s3Infos.fileKey);
      uploadFormData.append('AWSAccessKeyId', s3Infos.s3AccessKeyId);
      uploadFormData.append('policy', s3Infos.policy);
      uploadFormData.append('acl', 'public-read');
      uploadFormData.append('signature', s3Infos.signature);
      uploadFormData.append('file', fileToUpload);
      return axios.post(uploadUrl, uploadFormData, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:8080/',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => ({
        src: `${s3Infos.s3Url}${s3Infos.fileKey}`,
        type: mime,
      }))
        .catch((err) => err);
    });
  }

  static getMime(url: string): string {
    return url.split('?')[0].split('#')[0].split('.').pop() || '';
  }
}
