




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FlagComponent extends Vue {
  @Prop({ required: true, default: '' })
  private readonly text!: string;

  @Prop({ required: false, default: 'md' })
  private readonly size!: string;

  @Prop({ required: false, default: 'outline' })
  private readonly shape!: string;

  @Prop({ required: false, default: 'red' })
  private readonly variant!: string;
}
