export const EXTRA_PROPERTY_CONFIGURATION_BASE_FRAGMENT = `
  fragment extraPropertyConfigurationBaseFragment on ExtraPropertyConfiguration {
    uid
    schemaCode
    valueType
    targetType
    name
    displaySequence
    key
    isMandatory
  }
`;
