var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left admin-header"},[_c('div',{staticClass:"container d-flex align-items-center flex-row"},[_c('div',{staticClass:"d-flex align-items-center w-100 column-gap-6"},[_c('avatar-solo-widget',{attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.company.logoFileResource, 'w56'),"with-borders":true,"default-icon":"fa-regular fa-buildings","icon-classes":"icon-24 text-neutral-n-6-label","size":"56"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"text-neutral-n-6-label mb-0"},[_vm._v(" "+_vm._s(_vm.$tc('app.admin-panel.welcome', 0, {username: _vm.authUser.fullName}))+" ")]),_c('h3',{staticClass:"text-neutral-n-8-dark-ink mb-0"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")])])],1),(_vm.isMobile)?_c('div',{staticClass:"d-flex align-items-start position-relative"},[_c('button-component',{staticClass:"ml-3 p-3",attrs:{"size":"sm","variant":"tertiary"},on:{"on-click":_vm.onToggleHambugerMenu},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('div',{staticClass:"nav-bar d-flex d-xl-none align-items-center position-relative"},[_c('div',{staticClass:"hamburger-menu d-flex align-items-center justify-content-between"},[_c('input',{attrs:{"id":"menu","type":"checkbox"},domProps:{"checked":_vm.isHambugerMenuOpen},on:{"on-click":_vm.onToggleHambugerMenu}}),_c('label',{attrs:{"for":"menu"}},[_c('div'),_c('div'),_c('div')])])])]},proxy:true}],null,false,1834421183)}),(_vm.numberOfItemInCart > 0
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
          && _vm.canManageSales(_vm.company.uid))?_c('label',{staticClass:"fa-layers-counter bg-red-r-3-primary-red mt-n1 mr-n2 p-3 cart-count"},[_vm._v(" "+_vm._s(_vm.numberOfItemInCart > 99 ? '99+' : _vm.numberOfItemInCart)+" ")]):_vm._e(),(_vm.isHambugerMenuOpen)?_c('div',{staticClass:"hamburger-cms-menu d-flex flex-column"},[_c('button-component',{staticClass:"col-12",attrs:{"no-border":true,"text":("" + (_vm.$t('app.admin-panel.preview'))),"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.onPreviewClick()}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-arrow-up-right"}})]},proxy:true}],null,false,408493198)}),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
            && _vm.canManageSales(_vm.company.uid))?_c('button-component',{staticClass:"col-12",attrs:{"no-border":true,"text":("" + (_vm.$t('order.title'))),"size":"md","variant":"tertiary"},on:{"on-click":function($event){_vm.$router.push({ name: 'company-cms-order', params: { companyId: _vm.company.uid } });
                     _vm.isHambugerMenuOpen = false}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fas fa-list-check"}})]},proxy:true}],null,false,3062778074)}):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
            && _vm.canManageSales(_vm.company.uid))?_c('button-component',{staticClass:"ml-0 col-12",attrs:{"no-border":true,"text":("" + (_vm.$t('cart.title'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onCartButtonClick},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon":"fa-regular fa-cart-shopping","icon-classes":"icon-24 text-neutral-n-8-dark-ink"}})],1)]},proxy:true}],null,false,1798550815)}):_vm._e()],1):_vm._e()],1):_c('div',{staticClass:"d-flex align-items-start"},[_c('button-component',{attrs:{"text":("" + (_vm.$t('app.admin-panel.preview'))),"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.onPreviewClick()}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"far fa-arrow-up-right"}})]},proxy:true}])}),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
          && _vm.canManageSales(_vm.company.uid))?_c('button-component',{attrs:{"text":("" + (_vm.$t('order.title'))),"size":"md","variant":"tertiary ml-3"},on:{"on-click":function($event){return _vm.$router.push({ name: 'company-cms-order', params: { companyId: _vm.company.uid } })}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fas fa-list-check"}})]},proxy:true}],null,false,3062778074)}):_vm._e(),_c('div',{staticClass:"cart-button-container"},[(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
            && _vm.canManageSales(_vm.company.uid))?_c('button-icon-component',{staticClass:"ml-3",attrs:{"text":("" + (_vm.$t('cart.title'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onCartButtonClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon":"fa-regular fa-cart-shopping","icon-classes":"icon-24 text-neutral-n-8-dark-ink"}})],1)]},proxy:true}],null,false,1629490025)}):_vm._e(),(_vm.numberOfItemInCart > 0
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
            && _vm.canManageSales(_vm.company.uid))?_c('label',{staticClass:"fa-layers-counter bg-red-r-3-primary-red mt-n1 mr-n2 p-3 cart-count"},[_vm._v(" "+_vm._s(_vm.numberOfItemInCart > 99 ? '99+' : _vm.numberOfItemInCart)+" ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }