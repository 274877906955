












import { Component, Prop } from 'vue-property-decorator';
import TabMenuItemParams from '@/utils/types/TabMenuItemParams';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component
export default class TabMenuItemComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private config!: TabMenuItemParams;

  private get elementIsVisibleInViewport(): boolean {
    return this.$el.getBoundingClientRect().left >= 0
      && !!this.$el.parentElement
      && this.$el.getBoundingClientRect().left <= this.$el.parentElement.getBoundingClientRect().right;
  }

  mounted(): void {
    this.$nextTick(() => {
      if (this.$el
        && this.$el.classList.contains('router-link-exact-active')) {
        if (!this.elementIsVisibleInViewport) {
          this.$el.scrollIntoView({
            block: 'nearest',
            inline: 'center',
          });
        }
      }
    });
  }
}
