import { render, staticRenderFns } from "./FeedItemComponent.vue?vue&type=template&id=4ac7f7bc&scoped=true&"
import script from "./FeedItemComponent.vue?vue&type=script&lang=ts&"
export * from "./FeedItemComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/feed/FeedItem.scss?vue&type=style&index=0&id=4ac7f7bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac7f7bc",
  null
  
)

export default component.exports