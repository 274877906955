



























































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const communityUserStore = namespace('CommunityUserStore');
const communityUserChatStore = namespace('CommunityUserChatStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    IllustrationComponent,
    LoadingSpinnerComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
    StandardModal,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class UnblockUserModalComponent extends BreakpointWrapper {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Prop({ default: '' })
  private modalId!: string;

  @communityUserChatStore.Action
  private unBlockCommunityUsers!: (usersUid: CommunityUser[]) => void;

  @communityUserStore.Action
  private loadBlockedCommunityUsers!: () => void;

  @communityUserStore.Getter
  private fetchBlockedCommunityUsers!: CommunityUser[];

  @communityUserStore.Getter
  private isLoading!: boolean;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private unblockedUsers: CommunityUser[] = [];

  private IllustrationType = IllustrationType;

  // eslint-disable-next-line class-methods-use-this
  private userLogo(user: CommunityUser): string | null {
    if (user && user.pictureFileResource) {
      return FileResourceHelper.getFullPath(user.pictureFileResource, 'w40');
    }
    return null;
  }

  private onShowModal(): void {
    this.unblockedUsers = [];
    this.loadBlockedCommunityUsers();
  }

  private onSubmit(): void {
    if (this.unblockedUsers.length > 0) {
      this.unBlockCommunityUsers(this.unblockedUsers);
    } else {
      this.$bvModal.hide(this.modalId);
    }
  }

  private onDiscard(): void {
    this.$bvModal.hide(this.modalId);
  }

  @Watch('isLoading')
  private onLoadingSaveChange(): void {
    if (!this.isLoading) {
      this.$bvModal.hide(this.modalId);
      this.showToast(ToastActionType.UNBLOCK_USERS);
    }
  }

  private jobTitleEmployer(user: CommunityUser): string {
    const jobTitleEmployer: string[] = [];
    if (user.jobTitle) {
      jobTitleEmployer.push(user.jobTitle as string);
    }

    if (user.companyRoles && user.companyRoles.length > 0) {
      const selectedCompanyUserRole = (user.companyRoles as unknown as CompanyUserRole[])
        .find((companyRole: CompanyUserRole) => companyRole.defaultCompany);
      if (selectedCompanyUserRole
        && selectedCompanyUserRole.company
        && selectedCompanyUserRole.company.name) {
        jobTitleEmployer.push(`${selectedCompanyUserRole.company.name}`);
      } else {
        jobTitleEmployer.push(`${user.employerName}`);
      }
    } else if (user.employerName) {
      jobTitleEmployer.push(`${user.employerName}`);
    }
    return jobTitleEmployer.join(` ${this.$tc('actions.at', 0, { employer: '' })} `);
  }

  private isUnblocked(user: CommunityUser): boolean {
    const blockedUserIndex = this.unblockedUsers
      .findIndex((u: CommunityUser) => u.uid === user.uid);
    return blockedUserIndex > -1;
  }

  private onUnblockClick(user: CommunityUser): void {
    if (this.isUnblocked(user)) {
      const blockedUserIndex = this.unblockedUsers
        .findIndex((u: CommunityUser) => u.uid === user.uid);
      this.unblockedUsers.splice(blockedUserIndex, 1);
    } else {
      this.unblockedUsers.push(user);
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
