































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ProfilePrivacyType from '@/utils/enums/ProfilePrivacyType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const communityUserStore = namespace('CommunityUserStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    LoadingSpinnerComponent,
    ButtonIconComponent,
    StandardModal,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ProfileVisibilityModalComponent extends BreakpointWrapper {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Prop({ default: '' })
  private modalId!: string;

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private ProfilePrivacyType = ProfilePrivacyType;

  private selectedPrivacyProfile = '';

  mounted(): void {
    this.selectedPrivacyProfile = this.authUser.profilePrivacyMode || ProfilePrivacyType.PUBLIC;
  }

  private onSubmit(): void {
    this.updateUserProfile({ profilePrivacyMode: this.selectedPrivacyProfile });
    this.$emit('on-submit', this.selectedPrivacyProfile);
    this.$bvModal.hide(this.modalId);
  }

  private onCancel(): void {
    this.selectedPrivacyProfile = this.authUser.profilePrivacyMode || ProfilePrivacyType.PUBLIC;
    this.$bvModal.hide(this.modalId);
  }

  private onSelect(e: Event): void {
    this.selectedPrivacyProfile = (e.target as HTMLInputElement).value;
    if (this.isMobile) {
      this.onSubmit();
    }
  }
}
