























































































































































































































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import PillComponent from '@/components/pill/PillComponent.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import { deepGet } from '@/utils/ObjectHelpers';
import { CommunityUserVisitedFilter } from '@/graphql/_Filters/CommunityUserVisitedFilter';
import CommunityUserVisited from '@/models/graphql/CommunityUserVisited';
import Category from '@/models/graphql/Category';
import LargeProduct from '@/models/graphql/LargeProduct';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import Session from '@/models/graphql/Session';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format } from 'date-fns';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');
const communityUserVisitedStore = namespace('CommunityUserVisitedStore');

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonComponent,
    ButtonIconComponent,
    PillComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class MapDetailPopupComponent extends Vue {
  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserVisitedStore.Action
  markAsVisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @communityUserVisitedStore.Action
  markUnvisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private readonly exhibitors!: Exhibitor[];

  @Prop({ required: true })
  private readonly largeProducts!: LargeProduct[];

  @Prop({ required: true })
  private readonly sessions!: Session[];

  @Prop({ required: true })
  private readonly breakpoint!: string;

  @Prop({ required: true })
  private readonly baseUrl!: string;

  @Prop({ required: true })
  private readonly authUser!: string;

  @Prop({ required: true })
  private readonly hall!: string;

  @Prop({ required: false, default: '' })
  private readonly roomName!: string;

  @Prop({ required: true })
  private readonly booth!: string;

  @Prop({ required: true })
  private readonly loading!: boolean;

  @Prop({ required: true })
  private readonly featureType!: string;

  @Getter('authUser')
  private authenticatedUser!: CommunityUser

  @Getter
  private community!: Community

  private selectedDetailIndex = 1;

  private renderVisitedAction = 1;

  private renderBookmarkedAction = 10;

  private isExhibitorDetails = true;

  private actions: Record<string, { bookmarked: string | null; visited: string | null; followed: string | null }> = {};

  private FileResourceHelper = FileResourceHelper;

  private FeatureKeys = FeatureKeys;

  private get sortedExhibitors(): Exhibitor[] | undefined {
    return this.exhibitors.sort((a, b) => {
      if (a?.editionMappings
        && a.editionMappings[0]
        && a.editionMappings[0]?.editionExhibitor
        && b?.editionMappings
        && b.editionMappings[0]
        && b.editionMappings[0].editionExhibitor) {
        if (a.editionMappings[0].editionExhibitor.mainExhibitor) {
          return -1;
        }
        if (b.editionMappings[0].editionExhibitor.mainExhibitor) {
          return 1;
        }
      }
      return 0;
    });
  }

  created(): void {
    this.setActions();
    this.isExhibitorDetails = this.exhibitors.length > 0;
  }

  @Watch('exhibitors')
  @Watch('largeProducts')
  private setActions(): void {
    this.exhibitors.forEach((e) => {
      Object.assign(this.actions, {
        [e.uid]: {
          bookmarked: e._isBookmarked || null,
          visited: e._isVisited || null,
        },
      });
    });

    this.largeProducts.forEach((e) => {
      Object.assign(this.actions, {
        [e.uid]: {
          bookmarked: e._isBookmarked || null,
          visited: e._isVisited || null,
        },
      });
    });
  }

  private toggleBookmark(uid: string): void {
    if (this.actions[uid].bookmarked) {
      const temp = this.actions[uid].bookmarked;
      this.actions[uid].bookmarked = null;
      this.unBookmark({ uid: temp as string })
        .catch(() => {
          this.actions[uid].bookmarked = temp;
        });
    } else {
      this.actions[uid].bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser,
        linkedUserId: uid,
        entityType: this.isExhibitorDetails ? EntityType.EXHIBITOR : EntityType.LARGE_PRODUCT,
      }).then((response) => {
        this.actions[uid].bookmarked = response?.uid || '';
        this.$logger.logMatomoStats(
          this.authenticatedUser,
          this.community.code as string,
          this.isExhibitorDetails ? EntityType.EXHIBITOR : EntityType.LARGE_PRODUCT,
          StatLoggerActions.ADD,
          '',
          -1,
          uid,
          StatLoggerCategories.BOOKMARK,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.actions[uid].bookmarked = null;
      });
    }
    this.renderBookmarkedAction += 1;
  }

  private toggleVisited(uid: string): void {
    if (this.actions[uid].visited) {
      const temp = this.actions[uid].visited;
      this.actions[uid].visited = null;
      this.markUnvisited({ uid: temp as string | undefined })
        .catch(() => {
          this.actions[uid].visited = temp;
        });
    } else {
      this.actions[uid].visited = 'visited';
      this.markAsVisited({
        userId: this.authUser,
        linkedEntityId: uid,
        entityType: this.isExhibitorDetails ? EntityType.EXHIBITOR : EntityType.LARGE_PRODUCT,
      })
        .then((response) => {
          this.actions[uid].visited = response?.uid || '';
        }).catch(() => {
          this.actions[uid].visited = null;
        });
    }
    this.renderVisitedAction += 1;
  }

  private categories = (exhibitor: Exhibitor): string => {
    const categories = '';
    if (exhibitor
      && exhibitor.categoriesInContext) {
      if (Array.isArray(exhibitor.categoriesInContext)
        && (exhibitor.categoriesInContext as Category[]).length > 0) {
        const entity = exhibitor.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 3).map((c) => c.name).join(', ')}
          ${entity.length > 3 ? ` + ${entity.length - 3}` : ''}`;
        }
      }
    }
    return categories;
  }

  private handleBaseUrl(uid: string): void {
    if (this.baseUrl) {
      let r = this.baseUrl;
      const matches = this.baseUrl.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        this.$router.push(r);
      } else if (r[r.length - 1] === '/') {
        this.$router.push(`${r}${uid}`);
      } else {
        this.$router.push(`${r}/${uid}`);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private largeProductImage(largeProduct: LargeProduct): string | null {
    if (largeProduct.images && largeProduct.images.length > 0) {
      return FileResourceHelper.getFullPath(largeProduct.images[0].fullFileResource, 'w96');
    }
    return null;
  }

  private getSessionDate(sessionStartTime: string, sessionEndTime: string): string {
    const dateFormat = this.$t('app.date.monthDayShort').toString();
    const timeFormat = this.$t('app.date.defaultTimeFormat').toString();
    const separator = this.$t('app.date.dateTimeSeparator').toString();

    let result = '';
    let date = '';
    let time = '';
    if (sessionStartTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${sessionStartTime}Z`,
        this.selectedTzName,
      );
      date = `${format(startTime, dateFormat, { locale: this.dateLocale })}`;
      time = `${format(startTime, timeFormat, { locale: this.dateLocale })}`;
    }
    if (sessionEndTime) {
      const endTime = DateTimeHelper.utcToZonedTimeDate(
        `${sessionEndTime}Z`,
        this.selectedTzName,
      );
      date = date || `${format(endTime, timeFormat, { locale: this.dateLocale })}`;

      const sep = time ? separator : '';

      time = `${time}${sep}${format(endTime, timeFormat, { locale: this.dateLocale })}`;
    }

    if (date && time) {
      result = `${date}, ${time}`;
    }
    return result;
  }
}
