















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class FloorplanFilterItemComponent extends Vue {
  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: false, default: false })
  private readonly selected!: boolean;

  private checked = this.selected;

  @Watch('selected')
  private updateSelected(): void {
    this.checked = this.selected;
  }
}
