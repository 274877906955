













































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import { Getter, namespace, State } from 'vuex-class';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import CommunityUser from '@/models/graphql/CommunityUser';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addMinutes,
  format,
  getHours,
  getMinutes,
  parseISO,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import PickerDateComponent from '@/components/calendar/PickerDateComponent.vue';
import PickerTimeComponent from '@/components/calendar/PickerTimeComponent.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import UserSelectorField from '@/components/UserSelectorField.vue';
import EntityListItemComponent from '@/components/EntityListItemComponent.vue';
import EntityListWidgetHelper from '@/utils/helpers/widgets/EntityListWidgetHelper';
import { Data } from '@/utils/types/WidgetData';
import { DataWithNull } from '@/utils/types/WidgetDataWithNull';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';
import Community from '@/models/graphql/Community';

const toastStore = namespace('ToastStore');
const meetingRequestStore = namespace('MeetingRequestStore');

/* eslint-disable @typescript-eslint/camelcase */

@Component({
  components: {
    EntityListItemComponent,
    UserSelectorField,
    TextAreaComponent,
    PickerTimeComponent,
    PickerDateComponent,
    FontAwesomeComponent,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class CompanyRequestMeetingModal extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @State
  private selectedTzName!: string;

  @Prop({ required: true, default: '' })
  private readonly modalId!: string;

  @Prop({ required: false, default: '' })
  private readonly exhibitorUid!: string;

  @Prop({ required: false, default: null })
  private readonly exhibitorId!: number;

  @Prop({ required: false, default: () => [] })
  private readonly companyReps!: CommunityUser[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @meetingRequestStore.Action
  private createMeetingRequest!: (payload: {
    creatorUid: string;
    exhibitorUid: string;
    companyRepUid: string | null;
    guests: string[];
    entity: Partial<MeetingRequest>;
  }) => Promise<MeetingRequest | null | undefined>;

  private pickerDateInput = format(DateTimeHelper.getCurrentDateTime(), 'yyyy-MM-dd');

  private selectedPickerStartTime = (getHours(DateTimeHelper.getCurrentDateTime()) * 60)
    + getMinutes(roundToNearestMinutes(DateTimeHelper.getCurrentDateTime(), { nearestTo: 15, roundingMethod: 'ceil' }));

  private selectedPickerEndTime = this.selectedPickerStartTime + 15;

  private startDate = '';

  private endDate = '';

  private message = '';

  private selectedCompanyRep = this.exhibitorUid;

  private members: CommunityUser[] = [];

  private get reps(): DataWithNull[] {
    return EntityListWidgetHelper.mapData(this.companyReps as unknown as Data[]);
  }

  private get canSendRequest(): boolean {
    return this.exhibitorUid.length > 0
      && this.selectedCompanyRep.length > 0
      && this.startDate.length > 0
      && this.endDate.length > 0;
  }

  private get excludedUsersUid(): string[] {
    return this.companyReps.map((user) => user.uid);
  }

  @Watch('isEventTimeZone')
  private calculateDates(): void {
    this.startDate = DateTimeHelper.toISO8601(
      DateTimeHelper.zonedToUTCTimeDate(
        addMinutes(
          startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerStartTime,
        ),
        this.selectedTzName,
      ),
    );
    this.endDate = DateTimeHelper.toISO8601(
      DateTimeHelper.zonedToUTCTimeDate(
        addMinutes(
          startOfDay(parseISO(this.pickerDateInput)), this.selectedPickerEndTime,
        ),
        this.selectedTzName,
      ),
    );
  }

  private removeDuplicateUsers = (arr: CommunityUser[]): CommunityUser[] => {
    const uniqueValues = new Map();
    return arr.filter((user) => {
      const propValue = user.uid;
      if (!uniqueValues.has(propValue)) {
        uniqueValues.set(propValue, true);
        return true;
      }
      return false;
    });
  }

  private onUserListChange(userList: CommunityUser[]): void {
    this.members = this.removeDuplicateUsers(userList);
  }

  private onSelected(uid: string): void {
    this.selectedCompanyRep = uid;
  }

  private onHide(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onShow(): void {
    this.message = '';
    this.pickerDateInput = format(DateTimeHelper.getCurrentDateTime(), 'yyyy-MM-dd');
    this.selectedPickerStartTime = (getHours(DateTimeHelper.getCurrentDateTime()) * 60)
      + getMinutes(roundToNearestMinutes(DateTimeHelper.getCurrentDateTime(), { nearestTo: 15, roundingMethod: 'ceil' }));
    this.selectedPickerEndTime = this.selectedPickerStartTime + 15;
    this.selectedCompanyRep = this.exhibitorUid;
    this.startDate = '';
    this.endDate = '';
    this.members = [];
    this.calculateDates();
  }

  private selectedStartTime(minutes: number): void {
    this.selectedPickerStartTime = minutes;
    if (this.selectedPickerEndTime < this.selectedPickerStartTime) {
      this.selectedPickerEndTime = this.selectedPickerStartTime + 15;
    }
    this.calculateDates();
  }

  private selectedEndTime(minutes: number): void {
    this.selectedPickerEndTime = minutes;
    this.calculateDates();
  }

  private selectedDate(timestamps: string[]): void {
    const [selectedDate] = timestamps;
    this.pickerDateInput = selectedDate;
    this.calculateDates();
  }

  private onSendRequest(): void {
    if (this.canSendRequest) {
      const meetingRequest = {
        timezoneName: this.selectedTzName,
        readState: false,
        trash: false,
        startTime: this.startDate,
        endTime: this.endDate,
        message: this.message,
      } as unknown as MeetingRequest;
      this.createMeetingRequest({
        creatorUid: this.authUser.uid,
        exhibitorUid: this.exhibitorUid,
        companyRepUid: this.selectedCompanyRep === this.exhibitorUid ? null : this.selectedCompanyRep,
        guests: this.members.map((m) => m.uid),
        entity: meetingRequest,
      }).then(() => {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          EntityType.COMPANY,
          StatLoggerActions.ADD,
          '',
          this.exhibitorId,
          this.exhibitorUid,
          StatLoggerCategories.MEET,
          this.$i18n.locale,
        );
      });
      this.showToast(ToastActionType.MEETING_REQUEST_SENT);
      this.onHide();
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
