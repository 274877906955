
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const exhibitorStore = namespace('ExhibitorStore');
const toastStore = namespace('ToastStore');

@Component({
  inheritAttrs: false,
})
export default class CompanyCmsProfileBaseWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  protected fetchExhibitor!: Exhibitor;

  @exhibitorStore.Action
  private loadAdminPanelExhibitor!: (payload: { uid: string; authUser: string }) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  created(): void {
    this.setDataConfig();
    const { companyId } = this.$route.params;
    if (companyId
      && ((this.fetchExhibitor && !this.fetchExhibitor.uid)
        || (this.fetchExhibitor && this.fetchExhibitor.uid !== companyId))) {
      this.loadAdminPanelExhibitor({ uid: companyId, authUser: this.authUser.uid });
    }
  }

  protected showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  public get company(): Exhibitor {
    return this.fetchExhibitor;
  }
}
