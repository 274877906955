import { SESSION_BASE_FRAGMENT } from '@/graphql/_Fragments/Session/Base';
import { SPONSOR_FULL_FRAGMENT } from '@/graphql/_Fragments/Sponsor/Full';

export const SPONSOR_ROLE_FULL_FRAGMENT = `
  fragment sponsorRoleFullFragment on SponsorRole {
    uid
    schemaCode
    role
    session {
      ...sessionBaseFragment
    }
    sponsor {
      ...sponsorFullFragment
    }
  }
  ${SESSION_BASE_FRAGMENT}
  ${SPONSOR_FULL_FRAGMENT}
`;
