











































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ActionButtonConnect from '@/components/action-buttons/ActionButtonConnect.vue';
import { PortalTarget } from 'portal-vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import GroupType from '@/utils/enums/chat/GroupType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { addHours } from 'date-fns';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import Event from '@/utils/types/Event';
import EntityType from '@/utils/enums/EntityType';
import ExhibitorTag from '@/models/graphql/ExhibitorTag';
import TagComponent from '@/components/tag/TagComponent.vue';
import ExhibitorTagModal from '@/components/tag/ExhibitorTagModal.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';

/* eslint-disable no-underscore-dangle */

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const agendaStore = namespace('AgendaStore');
const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    DropdownMenuItem,
    DropdownMenu,
    ExhibitorTagModal,
    TagComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    PortalTarget,
    ActionButtonConnect,
    AvatarSoloWidget,
    SocialLinksComponent,
  },
})
export default class ExhibitorPortalUserDetailAboutComponent extends BreakpointWrapper {
  @Prop({
    required: true,
    default: null,
  })
  private user!: CommunityUser;

  @Prop({
    required: true,
    default: () => [],
  })
  private exhibitorTags!: ExhibitorTag[];

  @Prop({
    required: false,
    default: false,
  })
  private isInPortal!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private pageRoute!: string;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @communityUserStore.Getter
  private fetchBlockedCommunityUserUids!: string[];

  private localTags = this.exhibitorTags;

  private exhibitorTagModalId = `exhibitor-tag-modal-${+new Date()}`;

  private isBlocked = false;

  private get text(): string {
    return `${this.isBlocked ? this.$t('actions.unblock') : this.$t('actions.block')}`;
  }

  get canShowMessageButton(): boolean {
    return !!(this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
            && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)?.enabled)
        && this.isConnected
        && (!this.isBlockedByOrIsBlockingUser || (this.isBlockedByOrIsBlockingUser && this.isConnected));
  }

  get canShowTags(): boolean {
    return !!(this.featureByKey(FeatureKeys.TAGS)
        && this.featureByKey(FeatureKeys.TAGS)?.enabled);
  }

  get canShowMeetButton(): boolean {
    return !!(this.featureByKey(FeatureKeys.AGENDA)
            && this.featureByKey(FeatureKeys.AGENDA)?.enabled)
        && this.isConnected
        && (!this.isBlockedByOrIsBlockingUser || (this.isBlockedByOrIsBlockingUser && this.isConnected));
  }

  get logoSrc(): string {
    return CommunityUser.hydrate(this.user).profilePicture;
  }

  get fullPrefixedName(): string {
    return CommunityUser.hydrate(this.user).getFullPrefixedName;
  }

  get location(): string {
    const loc: string[] = [];
    if (this.user.address?.city) {
      loc.push(this.user.address?.city);
    } else if (this.user.address?.state) {
      loc.push(this.user.address?.state);
    }
    if (this.user.address?.country?.printableName) {
      loc.push(this.user.address?.country.printableName);
    }
    return loc.join(', ');
  }

  get contacts(): {
    website: string[];
    socials: { url: string; icon: string; type: string }[];
  } | null {
    const website: string[] = [];
    const socials: { url: string; icon: string; type: string }[] = [];
    this.user.urls
      .filter((url) => !['mobile', 'phone'].includes(url.type))
      .forEach((url) => {
        if (url.type === 'website' && url.url) {
          if (!url.url.match(/^[a-zA-Z]+:\/\//)) {
            website.push(`http://${url.url}`);
          } else {
            website.push(url.url);
          }
        } else if (url.type && url.url) {
          socials.push({
            url: url.url,
            icon: (AppSocialMediaIcons as Record<string, string>)[url.type.toLowerCase()]
                  || (AppSocialMediaIcons as Record<string, string>).website,
            type: '',
          });
        }
      });
    return {
      website,
      socials,
    };
  }

  get isPublicAccount(): boolean {
    return CommunityUser.hydrate(this.user).isProfileVisible;
  }

  get phone(): string {
    const numbers = this.user.urls.filter((url) => ['mobile', 'phone'].includes(url.type) && !!url.url);
    if (numbers.length > 0) {
      return numbers[0].url;
    }
    return '';
  }

  get company(): { uid: string; name: string } | null {
    if (this.user.companyRoles && this.user.companyRoles.length > 0) {
      return {
        name: this.user.companyRoles[0].company?.name || '',
        uid: this.user.companyRoles[0].company?.uid || '',
      };
    }
    if (this.user.employerName) {
      return {
        name: this.user.employerName as unknown as string,
        uid: '',
      };
    }
    return null;
  }

  get isConnected(): boolean {
    return !!(this.user
        && this.user._ourConnection
        && this.user._ourConnection.linkState === ConnectionStatusType.ACCEPTED);
  }

  get isBlockedByOrIsBlockingUser(): boolean {
    return this.authUser
        && (this.authUser.isBlockedByUser(this.user.uid)
            || this.authUser.haveBlockedUser(this.user.uid));
  }

  beforeDestroy(): void {
    if (this.isInPortal && this.$route.name === this.pageRoute) {
      this.$emit('go-back-to-default-page');
    }
  }

  created(): void {
    if (this.authUser) {
      this.isBlocked = this.authUser.haveBlockedUser(this.user.uid);
    }
  }

  private setNewTagList(tags: ExhibitorTag[]): void {
    this.localTags = tags;
  }

  private openUrl = (url: string, type: string): void => {
    switch (type) {
      case 'email':
        this.urlLogStats(`mailto:${url}`);
        window.open(`mailto:${url}`);
        break;
      case 'phone':
        this.urlLogStats(`tel:${url}`);
        window.open(`tel:${url}`);
        break;
      default:
        this.urlLogStats(url);
        window.open(url, '_blank');
    }
  };

  private openConversation(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    this.logStats(StatLoggerCategories.MESSAGE, StatLoggerActions.ADD, '');
    this.openSelectedConversation({
      myUid: this.authUser.uid,
      users: [
        this.authUser,
        this.user,
      ],
      groupType: [GroupType.DIRECT],
    });
  }

  private createUserMeeting(): void {
    const participant = { user: this.user };
    const creator = CommunityUser
      .hydrate(Object.fromEntries(Object.entries(this.authUser)
        .filter(([k]) => [
          'uid',
          'schemaCode',
          'firstName',
          'lastName',
          'name',
          'jobTitle',
          'employerName',
          'pictureFileResource'].includes(k))));
    const startTime = DateTimeHelper
      .roundToNearest15(DateTimeHelper.getCurrentDateTime());
    const endTime = DateTimeHelper
      .roundToNearest15(addHours(DateTimeHelper.getCurrentDateTime(), 1));
    this.setViewMode(ViewMode.CREATE);
    this.setEventEdited({
      creator,
      isCancelled: false,
      participants: [participant as MeetingParticipant],
      tzStartTime: startTime,
      tzEndTime: endTime,
    });
    this.logStats(StatLoggerCategories.MEET, StatLoggerActions.ADD, '');
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
  }

  private urlLogStats(url: string): void {
    this.logStats(StatLoggerCategories.SOCIAL_LINK, StatLoggerActions.VIEW, url);
  }

  private logStats(category: string, action: string, name: string): void {
    this.$logger.logMatomoStats(
      this.authUser,
        this.community.code as string,
        EntityType.USER,
        action,
        name,
        -1,
        this.user.uid,
        category,
        this.$i18n.locale,
    );
  }

  @Watch('fetchBlockedCommunityUserUids', { deep: true })
  private setIsBlocked(): void {
    this.isBlocked = this.fetchBlockedCommunityUserUids
      .filter((userUid: string) => userUid === this.user.uid).length > 0;
  }

  private toggleBlockModal(): void {
    if (this.authUser) {
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: [{
          uid: this.user.uid,
          fullName: this.fullPrefixedName,
        }],
        groupId: this.user.uid,
        actionType: this.isBlocked ? MessageMenuItem.UNBLOCK : MessageMenuItem.BLOCK,
      });
    } else {
      this.$bvModal.show('sign-in-action-modal');
    }
  }
}
