





























































































import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import Session from '@/models/graphql/Session';
import { mixins } from 'vue-class-component';
import { format } from 'date-fns';
import { Getter, State } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  components: { FontAwesomeComponent, ButtonIconComponent, ButtonComponent },
})
export default class SessionCmsPageHeaderComponent extends mixins(BreakpointWrapper, VueRegisterStoreWidget) {
  baseStoreName = 'SessionCmsPageHeaderWidgetStore';

  @Prop({ required: false, default: null })
  private readonly session!: Session;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Session[];

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: false })
  private readonly editButton!: boolean;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  private FeatureKeys = FeatureKeys;

  private get ratingsData(): {icon: string; value: number}[] {
    return [
      {
        icon: 'heart',
        value: this.session?.loveCount || 0,
      },
      {
        icon: 'thumbs-up',
        value: this.session?.likeCount || 0,
      },
      {
        icon: 'thumbs-down',
        value: this.session?.dislikeCount || 0,
      },
    ];
  }

  private get subtitle(): string {
    if (this.session
      && this.session.startTime
      && this.session.endTime) {
      const start = DateTimeHelper.utcToZonedTimeDate(
        `${this.session.startTime}Z`,
        this.selectedTzName,
      );
      const end = DateTimeHelper.utcToZonedTimeDate(
        `${this.session.endTime}Z`,
        this.selectedTzName,
      );
      const parts: string[] = [];
      parts.push(format(start, `${this.$t('app.date.dayOfWeekShort')}`, { locale: this.dateLocale }));
      parts.push(format(start, `${this.$t('app.date.monthDayShort')}`, { locale: this.dateLocale }));
      parts.push(`
      ${format(start, `${this.$t('app.date.year')}`, { locale: this.dateLocale })}
       ·
      ${format(start, `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale })}
      ${this.$t('app.date.dateTimeSeparator')}
      ${format(end, `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale })}
      `);
      parts.push(this.selectedTzAbbreviation);
      return parts.join(', ');
    }
    return '';
  }

  created(): void {
    this.setDataConfig();
  }

  private onViewDetailPageClick(): void {
    if (this.session && this.session.uid) {
      const sessionDetailsUrl = this.$router.resolve({
        name: 'session-detail',
        params: { sessionId: this.session.uid },
      }).href;
      window.open(sessionDetailsUrl, '_blank');
    }
  }
}
