import { render, staticRenderFns } from "./CompanyContactInformation.vue?vue&type=template&id=486af064&scoped=true&"
import script from "./CompanyContactInformation.vue?vue&type=script&lang=ts&"
export * from "./CompanyContactInformation.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/CompanyContactInformation.scss?vue&type=style&index=0&id=486af064&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486af064",
  null
  
)

export default component.exports