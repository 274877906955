






















































































































import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import {
  format,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
  subDays,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Venue from '@/models/graphql/Venue';
import { State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import EventStateEnum from '@/utils/enums/EventStateEnum';

@Component({
  components: {
    AvatarSoloWidget,
    RecommendationBadgeComponent,
  },
})
export default class SubEditionPageHeaderComponent extends BreakpointWrapper {
  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFollowerCountBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayLiveBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly archived!: boolean;

  @Prop({ required: false, default: null })
  protected readonly name!: string | null;

  @Prop({ required: false, default: false })
  protected readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: false })
  protected readonly autoFollow!: boolean;

  @Prop({ required: false, default: null })
  protected readonly _followerCount!: string | null;

  @Prop({ required: false, default: [] })
  private readonly bannerFileResource!: FileResource;

  @Prop({ required: false, default: [] })
  private readonly logoFileResource!: FileResource;

  @Prop({ required: false, default: '' })
  private readonly startTime!: string;

  @Prop({ required: false, default: '' })
  private readonly endTime!: string;

  @Prop({ required: false, default: null })
  private readonly startTimestamp!: number;

  @Prop({ required: false, default: null })
  private readonly endTimestamp!: number;

  @Prop({ required: false, default: null })
  private readonly venue!: Venue;

  @State
  private dateLocale!: Locale;

  private statesEnum = EventStateEnum;

  private get bannerSrc(): string {
    return FileResourceHelper.getFullPath(this.bannerFileResource, 'w1110');
  }

  private get logoSrc(): string {
    return FileResourceHelper.getFullPath(this.logoFileResource, 'w136');
  }

  private get eventState(): string {
    if (this.archived) {
      return this.statesEnum.ARCHIVED;
    }
    if (this.startTimestamp && this.endTimestamp) {
      const localStartTimestamp = this.startTimestamp
        ? getUnixTime(DateTimeHelper
          .toUTC(fromUnixTime(this.startTimestamp as unknown as number)))
        : null;

      const localEndTimestamp = this.endTimestamp
        ? getUnixTime(DateTimeHelper
          .toUTC(fromUnixTime(this.endTimestamp as unknown as number)))
        : null;
      const today = new Date();
      if (
        localStartTimestamp
        && localEndTimestamp
        && isWithinInterval(today, {
          start: fromUnixTime(localStartTimestamp),
          end: fromUnixTime(localEndTimestamp),
        })
      ) {
        return this.statesEnum.LIVE;
      }
      if (localStartTimestamp) {
        const dayBefore = subDays(fromUnixTime(localStartTimestamp), 1);
        if (isSameDay(dayBefore, today) || isAfter(dayBefore, today)) {
          return this.statesEnum.SOON;
        }
      }
      if (localEndTimestamp && isBefore(fromUnixTime(localEndTimestamp), today)) {
        return this.statesEnum.ARCHIVED;
      }
    }
    return '';
  }

  private get eventDates(): string {
    if (this.startTime && this.endTime) {
      const start = new Date(this.startTime as unknown as string);
      const end = new Date(this.endTime as unknown as string);
      if (format(start, 'dd-MM-yyyy') === format(end, 'dd-MM-yyyy')) {
        return format(start, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale });
      }
      return `${format(start, this.$t('app.date.monthDayShort') as string, { locale: this.dateLocale })}
      ${this.$t('app.date.dateTimeSeparator')}
      ${format(end, this.$t('app.date.defaultDateFormat') as string, { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get eventLocation(): string {
    const location: string[] = [];
    if (this.venue && this.venue.name) {
      location.push(this.venue.name as string);
    }
    if (this.venue
      && this.venue.address
      && this.venue.address.city) {
      location.push(this.venue.address.city as string);
    }
    return location.join(', ');
  }
}
