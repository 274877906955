










import { Component, Prop, Vue } from 'vue-property-decorator';
import { BRow } from 'bootstrap-vue';

@Component({
  components: {
    BRow,
  },
})
export default class FooterSignature extends Vue {
  @Prop({ required: true })
  private readonly content!: string;
}
