

































































































































































































































































































import Component from 'vue-class-component';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { Prop, Watch } from 'vue-property-decorator';
import EditFileResourceModal from '@/components/modals/EditFileResourceModal.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { Getter, namespace } from 'vuex-class';
import DetailPageHeaderSaveHelper from '@/utils/helpers/DetailPageHeaderSaveHelper';
import Community from '@/models/graphql/Community';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import VideoComponent from '@/components/VideoComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Address from '@/models/graphql/Address';
import { Location } from 'vue-router';

const toastStore = namespace('ToastStore');
const fileResourceStore = namespace('FileResourceStore');

@Component({
  data(): object {
    return {
      profileAvatar: '',
      newProfileAvatar: '',
      coverAvatar: '',
      newCoverAvatar: '',
      uploadedCoverAvatar: '',
      urlUploadedImgProfile: '',
      uploadingCover: false,
      uploadingAvatar: false,
      coverPayloadTooLarge: false,
      avatarPayloadTooLarge: false,
    };
  },
  components: {
    ButtonIconComponent,
    VideoComponent,
    AvatarSoloWidget,
    RecommendationBadgeComponent,
    EditFileResourceModal,
    PillComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class CommunityUserPageHeaderComponent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: null,
  })
  readonly companyRoles!: CompanyUserRole[];

  @Prop({
    required: false,
    default: true,
  })
  readonly displayFollowerCountBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayFeaturedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayRecommendedBadge!: boolean;

  @Getter
  protected readonly community!: Community;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly uid!: string;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly firstName!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly lastName!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly prefix!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly suffix!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly profilePrivacyMode!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly jobTitle!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly employerName!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly autoFollow!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly featured!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly _followerCount!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly _connectionCount!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly _sharedConnectionCount!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly _isRecommendedForMe!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly isAuthUser!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly isPrivate!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  protected readonly videoPresentationS3Url!: string;

  @Getter
  private authUser!: CommunityUser;

  @fileResourceStore.Action
  private createProfileFileResourceForCommunityUser!: (uploadToken: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createBannerFileResourceForCommunityUser!: (uploadToken: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Prop({
    required: false,
    default: null,
  })
  private readonly address!: Address;

  @Prop({
    required: false,
    default: [],
  })
  private bannerFileResource!: FileResource | undefined;

  @Prop({
    required: false,
    default: [],
  })
  private pictureFileResource!: FileResource | undefined;

  private localVideoPresentationUrl = this.videoPresentationS3Url;

  private showPopup = false;

  get getFullPrefixedName(): string {
    let fullName = '';
    if (this.prefix) {
      fullName = `${this.prefix}`;
    }
    if (this.firstName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.firstName}`;
    }
    if (this.lastName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.lastName}`;
    }
    if (this.suffix) {
      const separator = fullName ? ', ' : '';
      fullName = `${fullName}${separator}${this.suffix}`;
    }
    return fullName.trim();
  }

  private get mutualPath(): Location {
    return this.$router.resolve({
      name: 'member-connections',
      query: {
        page: '1',
        toggles: { mutuals: 'true' } as unknown as string,
      },
      params: { memberId: this.uid },
    }).location;
  }

  private get bannerSrc(): string {
    if (this.bannerFileResource) {
      return FileResourceHelper.getFullPath(this.bannerFileResource, 'w1110');
    }
    if (this.community.defaultUserBannerFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultUserBannerFileResource, 'w1110');
    }
    return '/img/banner/empty.svg';
  }

  private get backgroundUrlBannerSrc(): string {
    return `url(${this.bannerSrc})`;
  }

  private get logoSrc(): string {
    return FileResourceHelper.getFullPath(this.pictureFileResource, 'w136');
  }

  private get fullName(): string {
    if (this.isAuthUser) {
      return `${this.authUser.getFullPrefixedName}`;
    }
    return this.getFullPrefixedName;
  }

  private get company(): { uid: string; name: string } | null {
    let company: { uid: string; name: string } | null = null;
    if (this.companyRoles && this.companyRoles.length > 0) {
      const selectedCompanyUserRole = (this.companyRoles as unknown as CompanyUserRole[])
        .find((companyRole: CompanyUserRole) => companyRole.defaultCompany);
      if (selectedCompanyUserRole
          && selectedCompanyUserRole.company
          && selectedCompanyUserRole.company.name) {
        company = {
          name: selectedCompanyUserRole.company.name ?? '',
          uid: selectedCompanyUserRole.company.uid ?? '',
        };
      }
    } else if (this.employerName) {
      company = {
        name: this.employerName as unknown as string,
        uid: '',
      };
    }
    return company;
  }

  private get connectionsMessage(): string {
    return `${this._connectionCount} ${this.$t('actions.connections')}`;
  }

  private get mutual(): string {
    let connections = '';
    if (this._sharedConnectionCount) {
      connections = `${this._sharedConnectionCount} ${this.$t('actions.mutual')}`;
    }
    return connections;
  }

  onDeleteProfileImg(showToast = true): void {
    if ((this
        && this.pictureFileResource
        && this.pictureFileResource.uid)) {
      this.deleteFileResource(this.pictureFileResource.uid);
      if (!this.$data.uploadingAvatar || !this.$data.uploadingCover) {
        this.pictureFileResource = undefined;
        if (this.isAuthUser && this.authUser) {
          this.authUser.pictureFileResource = this.pictureFileResource;
        }
        this.$data.newProfileAvatar = '';
        this.$bvModal.hide('edit-user-avatar');
      }
      if (showToast) {
        this.addNewAction(
          { type: ToastActionType.REMOVE_AVATAR },
        );
        this.showToast(ToastActionType.REMOVE_AVATAR);
      }
    }
  }

  onDeleteBannerImg(showToast = true): void {
    if (this
        && this.bannerFileResource
        && this.bannerFileResource.uid) {
      this.deleteFileResource(this.bannerFileResource.uid);
      if (!this.$data.uploadingCover) {
        this.bannerFileResource = undefined;
        if (this.isAuthUser && this.authUser) {
          this.authUser.bannerFileResource = this.bannerFileResource;
        }
        this.$data.newCoverAvatar = '';
        this.$bvModal.hide('edit-user-profile-cover');
      }
      if (showToast) {
        this.showToast(ToastActionType.REMOVE_COVER_IMAGE);
      }
    }
  }

  onLocalProfileSave(deleteBefore: boolean): void {
    this.$data.uploadingAvatar = true;
    this.$data.profileAvatar = (this.$refs.editorImageProfile as EditFileResourceModal).showImage();
    if (this.community.code) {
      DetailPageHeaderSaveHelper.onSave(
        this.community.code,
        this.$data.profileAvatar,
        `avatar_${this.uid}.jpeg`,
      )
        .then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status === 413) {
              this.$data.avatarPayloadTooLarge = true;
              this.$data.uploadingAvatar = false;
            } else {
              this.$data.newProfileAvatar = this.$data.profileAvatar;
              this.$data.avatarPayloadTooLarge = false;
              this.$data.uploadingAvatar = false;
              if (deleteBefore) {
                this.onDeleteProfileImg(false);
              }
              this.$bvModal.hide('edit-user-avatar');
              this.showToast(ToastActionType.UPDATE_AVATAR);
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.createProfileFileResourceForCommunityUser(uploadToken)
                  .then((response) => {
                    this.pictureFileResource = response;
                    if (this.isAuthUser && this.authUser) {
                      this.authUser.pictureFileResource = this.pictureFileResource;
                    }
                  });
              }
            }
          };
        });
    }
  }

  onLocalBannerSave(deleteBefore: boolean): void {
    this.$data.uploadingCover = true;
    this.$data.coverAvatar = (this.$refs.editorImageCover as EditFileResourceModal).showImage();
    if (this.community.code) {
      DetailPageHeaderSaveHelper.onSave(this.community.code, this.$data.coverAvatar, `banner_${this.uid}.jpeg`)
        .then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status === 413) {
              this.$data.coverPayloadTooLarge = true;
              this.$data.uploadingCover = false;
            } else {
              this.$data.coverPayloadTooLarge = false;
              if (deleteBefore) {
                this.onDeleteBannerImg(false);
              }
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.createBannerFileResourceForCommunityUser(uploadToken)
                  .then((response) => {
                    if (response && response.uid) {
                      this.$data.newCoverAvatar = this.$data.coverAvatar;
                      this.bannerFileResource = response;
                      if (this.isAuthUser && this.authUser) {
                        this.authUser.bannerFileResource = this.bannerFileResource;
                      }
                      this.$data.uploadingCover = false;
                      this.$bvModal.hide('edit-user-profile-cover');
                      this.showToast(ToastActionType.UPDATE_COVER_IMAGE);
                    } else {
                      this.$data.uploadingCover = false;
                      this.$bvModal.hide('edit-user-profile-cover');
                      this.showToast(ToastActionType.UPDATE_COVER_IMAGE_ERROR);
                    }
                  });
              }
            }
          };
        });
    }
  }

  @Watch('authUser.videoPresentationS3Url')
  private setVideoPresentation(): void {
    if (this.uid === this.authUser.uid) {
      this.localVideoPresentationUrl = this.authUser.videoPresentationS3Url ?? '';
    }
  }

  private openModal(): void {
    this.$bvModal.show('edit-user-avatar');
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
