












































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import MonthlyCalendarComponent from '@/components/full-calendar/MonthlyCalendarComponent.vue';
import WeeklyCalendarComponent from '@/components/full-calendar/WeeklyCalendarComponent.vue';
import DailyCalendarComponent from '@/components/full-calendar/DailyCalendarComponent.vue';
import ListCalendarComponent from '@/components/full-calendar/ListCalendarComponent.vue';
import View from '@/utils/enums/calendar/View';
import { namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays, addMonths, addWeeks, endOfWeek, format, startOfDay, startOfMonth, startOfWeek,
} from 'date-fns';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import CompanyCalendarViewComponent from '@/components/company-meeting/CompanyCalendarViewComponent.vue';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Meeting from '@/models/graphql/Meeting';
import PillWidget from '@/components/pill/PillWidget.vue';
import DynamicTextComponent from '@/components/DynamicTextComponent.vue';
import CalendarDetailComponent from '@/components/full-calendar/CalendarDetailComponent.vue';

const fullCalendarStore = namespace('FullCalendarStore');

@Component({
  components: {
    CalendarDetailComponent,
    DynamicTextComponent,
    PillWidget,
    AvatarSoloWidget,
    SegmentedControlsComponent,
    CompanyCalendarViewComponent,
    StandardModal,
    CheckboxSwitchComponent,
    ButtonComponent,
    InputSearchComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    ListCalendarComponent,
    DailyCalendarComponent,
    WeeklyCalendarComponent,
    MonthlyCalendarComponent,
  },
})
export default class FullCalendarComponent extends BreakpointWrapper {
  @Prop({ default: null })
  private companyUid!: string;

  @fullCalendarStore.State
  private selectedView!: View;

  @fullCalendarStore.State
  private selectedUsers!: string[];

  @fullCalendarStore.State
  private search!: string;

  @fullCalendarStore.State
  private selectedDate!: Date;

  @fullCalendarStore.State
  private selectedMeeting!: Meeting;

  @fullCalendarStore.State
  private companyReps!: CommunityUser[];

  @fullCalendarStore.State
  private meetings!: Meeting[];

  @fullCalendarStore.Action
  private loadMeetings!: () => Promise<void>;

  @fullCalendarStore.Action
  private loadCompanyReps!: () => Promise<void>;

  @fullCalendarStore.Mutation
  private setSearchableString!: (str: string) => void;

  @fullCalendarStore.Mutation
  private setSelectedCompany!: (str: string) => void;

  @fullCalendarStore.Mutation
  private setSelectedDate!: (date: Date) => void;

  @fullCalendarStore.Mutation
  private setSelectedView!: (view: View) => void;

  @fullCalendarStore.Mutation
  private setSelectedUsers!: (users: string[]) => void;

  @fullCalendarStore.Mutation
  private setSelectedMeeting!: (meeting: string | null) => void;

  @fullCalendarStore.Mutation
  private resetStates!: () => void;

  @State
  private authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  private View = View;

  private FileResourceHelper = FileResourceHelper;

  private showSearch = false;

  private prevView = this.selectedView;

  private get formattedSelectedDate(): string {
    if (this.selectedView === View.DAILY) {
      return format(
        this.selectedDate,
        `${this.$t(`app.date.${this.isMobile ? 'dayOfWeekShort' : 'dayOfWeek'}`)}, ${this.$t('app.date.monthDayShort')}`,
      );
    }
    if (this.selectedView === View.WEEKLY) {
      const start = format(startOfWeek(this.selectedDate), `${this.$t('app.date.monthDayShort')}`);
      const end = format(endOfWeek(this.selectedDate), `${this.$t('app.date.monthDayShort')}`);
      return `${start} ${this.$t('app.date.dateTimeSeparator')} ${end}`;
    }
    return format(this.selectedDate, `${this.$t('app.date.monthYearFull')}`);
  }

  private get companyLogo(): string {
    if (this.authUser && this.authUser.companyRoles.length > 0) {
      const companyRole = this.authUser.companyRoles.find((cr) => cr.company?.uid === this.companyUid);
      if (companyRole) {
        return FileResourceHelper.getFullPath(companyRole.company?.logoFileResource, 'w256');
      }
    }
    return '';
  }

  private get calendarViewList(): SegmentedElementParams[] {
    return [
      {
        key: View.MONTHLY as unknown as string,
        text: this.$t(`full-calendar.views.view${View.MONTHLY}`) as string,
        activeState: this.selectedView === View.MONTHLY,
      },
      {
        key: View.WEEKLY as unknown as string,
        text: this.$tc(`full-calendar.views.view${View.WEEKLY}`) as string,
        activeState: this.selectedView === View.WEEKLY,
      },
      {
        key: View.DAILY as unknown as string,
        text: this.$tc(`full-calendar.views.view${View.DAILY}`) as string,
        activeState: this.selectedView === View.DAILY,
      },
      {
        key: View.LIST as unknown as string,
        text: this.$tc(`full-calendar.views.view${View.LIST}`) as string,
        activeState: this.selectedView === View.LIST,
      },
    ];
  }

  private get filterText(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.selectedUsers.length === 0
      ? `${this.$t('full-calendar.assignees')}`
      : this.selectedUsers.length === 1
        ? `${CommunityUser.hydrate(this.companyReps.find((cr) => cr.uid === this.selectedUsers[0]) || {}).fullName}`
        : `${this.$tc('full-calendar.nb-selected', 0, { count: this.selectedUsers.length })}`;
  }

  private created(): void {
    this.setSelectedCompany(this.companyUid);
    this.loadMeetings();
    this.loadCompanyReps();
  }

  private onSearch(payload: { query: string }): void {
    if (payload.query.length > 2) {
      this.setSearchableString(payload.query);
      this.prevView = this.selectedView;
      this.setSelectedView(View.LIST);
    } else {
      this.setSearchableString('');
      this.setSelectedView(this.prevView || View.MONTHLY);
    }
    this.loadMeetings();
  }

  private setSelectedDateToToday(): void {
    this.setSelectedDate(
      DateTimeHelper.zonedToUTCTimeDate(
        DateTimeHelper.getCurrentDateTime(),
        this.selectedTzName,
      ),
    );
    this.loadMeetings();
  }

  private updateSelectedReps(uid: string | null): void {
    if (uid) {
      const doesExist = this.selectedUsers.find((u) => u === uid);
      if (!doesExist) {
        this.setSelectedUsers([...this.selectedUsers, uid]);
      } else {
        this.setSelectedUsers(this.selectedUsers.filter((u) => u !== uid));
      }
    } else {
      this.setSelectedUsers([]);
    }
    this.loadMeetings();
  }

  private changeSelectedDate(direction: -1 | 1): void {
    if ([View.MONTHLY, View.LIST].includes(this.selectedView)) {
      this.setSelectedDate(startOfMonth(addMonths(this.selectedDate, direction)));
    } else if (this.selectedView === View.WEEKLY) {
      this.setSelectedDate(startOfWeek(addWeeks(this.selectedDate, direction)));
    } else if (this.selectedView === View.DAILY) {
      this.setSelectedDate(startOfDay(addDays(this.selectedDate, direction)));
    }
    this.loadMeetings();
  }

  @Watch('meetings')
  private changeSelectedMeeting(): void {
    if (this.$route.query.meetingId) {
      this.setSelectedMeeting(this.$route.query.meetingId as string | null);
    }
  }

  private switchView(view: View | string): void {
    this.setSelectedView(view as View);
    this.loadMeetings();
  }
}
