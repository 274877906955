



















































































/* eslint-disable no-underscore-dangle */
import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import InputText from '@/components/InputText.vue';
import { namespace } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import Channel from '@/models/graphql/Channel';
import FeedPostReportInput from '@/models/graphql/FeedPostReportInput';
import ReportType from '@/utils/enums/feed/ReportType';
import FeedItemReportFurtherActionModal
  from '@/components/feed/FeedItemReportFurtherActionModal.vue';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');

@Component({
  data(): object {
    return {
      reason: '',
      reasonOtherText: '',
    };
  },
  components: {
    ButtonIconComponent,
    StandardModal,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
    FeedItemReportFurtherActionModal,
  },
})
export default class FeedItemReportModal extends VueBaseWidget {
  @Prop({ default: '' })
  private modalId!: string;

  @Prop({ required: false })
  private feedItemWrapper!: FeedItemWrapper;

  @feedItemWrapperStore.Action
  private reportPost!: (payload: {
    postFeedPostUid: string;
    entity: Partial<FeedPostReportInput>;
  }) => Promise<FeedPostReportInput | undefined>;

  @feedItemWrapperStore.Getter
  private fetchFeedInitiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  private reportType = ReportType;

  private reportTypes = [
    ReportType.SUSPICIOUS,
    ReportType.VIOLENCE,
    ReportType.ADULT,
    ReportType.PROPERTY,
    ReportType.OTHER,
  ];

  private offenseType = '';

  private loading = false;

  private content = '';

  private get isLoading(): boolean {
    return this.loading;
  }

  private clear(): void {
    this.offenseType = '';
    this.content = '';
  }

  private sendReport(): void {
    if (
      this.feedItemWrapper
      && this.feedItemWrapper._feedItems
      && this.feedItemWrapper._feedItems[0].triggered
    ) {
      this.loading = true;
      const payload = {
        postFeedPostUid: this.feedItemWrapper._feedItems[0].triggered.uid,
        entity: {
          reason: this.$data.reason,
          reasonOtherText: this.$data.reasonOtherText,
        },
        reporterCommunityUserUid: this.authUser.uid,
      };
      this.reportPost(payload).then(() => {
        this.loading = false;
        this.$bvModal.show(`${this.modalId}-further-action`);
        this.$bvModal.hide(this.modalId);
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  private onDiscard(): void {
    this.clear();
    this.$bvModal.hide(this.modalId);
  }
}
