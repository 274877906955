















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import ErrorPageType from '@/utils/enums/ErrorPageType';
import TranslateResult = VueI18n.TranslateResult;

@Component({
  components: {
    IllustrationComponent,
  },
})
export default class MessagePage extends Vue {
  @Prop({ required: false, default: '' })
  private readonly pageType!: string;

  @Prop({ required: false, default: '' })
  private readonly email!: string;

  @Prop({ required: false })
  private readonly illustrationType!: IllustrationType;

  private ErrorPageType = ErrorPageType;

  private get pageTitle(): TranslateResult | string {
    switch (this.pageType) {
      case ErrorPageType.FIND_ACCOUNT_EMAIL_SUCCESS:
        return this.$t('app.global.find-account.email-success.title');
      case ErrorPageType.FIND_ACCOUNT_VERIFY:
        return this.$t('app.global.find-account.verify.title');
      case ErrorPageType.PASSWORD_EMAIL_SUCCESS:
        return this.$t('app.global.reset-password.email.title');
      case ErrorPageType.CREATE_ACCOUNT_SUCCESS:
        return this.$t('app.global.find-account.verify.title');
      case ErrorPageType.EXPIRED_LINK:
        return this.$t('app.global.expired-link.title');
      case ErrorPageType.INVALID_TOKEN:
        return this.$t('app.global.invalid-token.title');
      case ErrorPageType.EMAIL_CHANGED:
        return this.$t('settings-page.account-section.email-modal.email-changed.title');
      default:
        return '';
    }
  }

  private get pageSubtitle(): TranslateResult {
    switch (this.pageType) {
      case ErrorPageType.FIND_ACCOUNT_EMAIL_SUCCESS:
        return `${this.$t('app.global.find-account.email-success.message.before-email')}`;
      case ErrorPageType.FIND_ACCOUNT_VERIFY:
        return this.$t('app.global.find-account.verify.message');
      case ErrorPageType.PASSWORD_EMAIL_SUCCESS:
        return `${this.$t('app.global.reset-password.email.message')}`;
      case ErrorPageType.CREATE_ACCOUNT_SUCCESS:
        return this.$t('app.global.find-account.verify.message');
      case ErrorPageType.EXPIRED_LINK:
        return this.$t('app.global.expired-link.message');
      case ErrorPageType.INVALID_TOKEN:
        return this.$t('app.global.invalid-token.message');
      case ErrorPageType.EMAIL_CHANGED:
        return this.$tc('settings-page.account-section.email-modal.email-changed.message',
          0, { email: this.email });
      default:
        return '';
    }
  }
}
