


























































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ColorListComponent from '@/components/ColorListComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import tinycolor from 'tinycolor2';

@Component({
  components: { FontAwesomeComponent, ColorListComponent },
})
/* eslint-disable new-cap */
export default class InputColorComponent extends Vue {
  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: () => [] })
  readonly colorList!: string[];

  @Prop({ default: false })
  readonly isList!: boolean;

  @Prop({ required: false, default: false })
  private readonly isMandatory!: boolean;

  private render = 1;

  private localColors = (this.colorList || ['#000000']).map((c) => ({
    changed: !!this.colorList && this.colorList.length > 0,
    color: new tinycolor(c).toHexString(),
  }));

  private get backgroundStyle(): (i: number) => object {
    return (i) => ({
      background:
        this.localColors[i].color.length > 0 && this.localColors[i].color.length === 7
          ? this.localColors[i].color
          : '#000000',
    });
  }

  @Watch('colorList')
  updateColorList(): void {
    this.localColors = (this.colorList || ['#000000']).map((c) => ({
      changed: true,
      color: new tinycolor(c).toHexString(),
    }));
  }

  private setColor(index: number, value: string): void {
    this.localColors[index] = { changed: true, color: value };
    this.render += 1;
  }

  @Watch('localColors', { deep: true })
  private setLocalColor(): void {
    this.$emit('on-change', this.localColors.filter((c) => c.changed)
      .map((c) => new tinycolor(c.color).toRgbString()));
  }
}
