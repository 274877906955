
































































import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';

@Component({
  components: { ButtonComponent, ButtonIconComponent, FontAwesomeComponent },
})
export default class DropdownMenu extends Vue {
  @Prop({ required: false, default: 'tertiary' })
  private readonly variant!: string;

  @Prop({ required: false, default: false })
  private readonly closeMenu!: boolean;

  @Prop({ required: false, default: 'lg' })
  private readonly dropdownSpacing!: string;

  @Prop({ required: false, default: '' })
  private readonly parentId!: string;

  @Prop({ required: false, default: '' })
  private readonly itemId!: string;

  @Prop({ required: false, default: '' })
  private readonly label!: string;

  @Prop({ required: false, default: 'fa-regular fa-ellipsis' })
  private readonly icon!: string;

  @Prop({ required: false, default: false })
  private readonly isActionButton!: boolean;

  @Prop({ required: false, default: false })
  private readonly mobileCentered!: boolean;

  @Prop({ required: false, default: 'sm' })
  private readonly mobileSize!: string;

  @Prop({ required: false, default: true })
  private readonly rounded!: boolean;

  @Prop({ required: false, default: false })
  private readonly noBorder!: boolean;

  @Prop({ required: false, default: 'md' })
  private readonly size!: string;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  private showMenu = false;

  private flipMenu = false;

  protected get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private get iconClasses(): string {
    if (this.isMobile) {
      if (this.mobileSize === 'sm') {
        return 'icon-12';
      }
      if (this.mobileSize === 'lg') {
        return 'icon-16';
      }
      return 'icon-12';
    }
    return 'icon-16';
  }

  private get iconContainerClasses(): string {
    if (this.size && this.size !== 'md' && this.size !== 'sm') {
      return 'icon-32';
    }
    return '';
  }

  @Watch('closeMenu')
  private updateCloseMenu(): void {
    if (this.closeMenu) {
      this.showMenu = false;
    }
  }

  @Watch('showMenu')
  private flipMenuIfNeeded(): void {
    if (this.parentId
      && this.parentId !== ''
      && this.itemId
      && this.itemId !== ''
      && this.showMenu) {
      this.$nextTick(() => {
        const parent = document.getElementById(this.parentId);
        const lastItem = Array.from(
          document.querySelectorAll(`#menu-${this.itemId}`),
        ).pop();
        if (parent && parent.getBoundingClientRect && lastItem && lastItem.getBoundingClientRect) {
          if (parent.getBoundingClientRect().bottom
            < lastItem.getBoundingClientRect().bottom) {
            this.flipMenu = true;
          }
        }
      });
    }
  }
}
