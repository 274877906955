








































import { Component, Prop } from 'vue-property-decorator';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import CompanyCalendarListItemComponent
  from '@/components/company-meeting/CompanyCalendarListItemComponent.vue';
import CrmUserCardWidget from '@/components/cards/CrmUserCardWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { namespace, State } from 'vuex-class';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import Meeting from '@/models/graphql/Meeting';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import Event from '@/utils/types/Event';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const companyCalendarStore = namespace('CompanyCalendarStore');
const agendaStore = namespace('AgendaStore');

/* eslint-disable no-underscore-dangle */

@Component({
  components: {
    CrmUserCardWidget,
    CompanyCalendarListItemComponent,
    IllustrationComponent,
  },
})
export default class CompanyCalendarListComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private groupedMeetingRequests!: Map<string, MeetingRequest[]>;

  @chatStore.Action
  private openSelectedConversation!: (payload: {
    myUid: string;
    users: CommunityUser[];
    groupType: string[];
    targetUid?: string | null;
    targetType?: string | null;
    targetData?: object;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @companyCalendarStore.Action
  private archiveMeetingRequest!: (payload: { uid: string; trash: boolean }) => void;

  @companyCalendarStore.Action
  private markMeetingRequestAsRead!: (uid: string) => Promise<void>;

  @State
  private dateLocale!: Locale;

  @State
  private authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  private IllustrationType = IllustrationType;

  private onMeetingRequestClick(meetingRequest: MeetingRequest): void {
    this.$emit('on-request-meeting-selected', meetingRequest);
  }

  private onArchive(meetingRequest: MeetingRequest): void {
    this.archiveMeetingRequest({ uid: meetingRequest.uid, trash: true });
    if (!meetingRequest.readState) {
      this.markMeetingRequestAsRead(meetingRequest.uid)
        .then(() => {
          this.$eventsBus.emit('on-update-company-meeting-request-count');
        });
    }
  }

  private onSchedule(meetingRequest: MeetingRequest): void {
    if (!meetingRequest.meeting) {
      let participants: MeetingParticipant[] = [];
      participants.push(
        ...meetingRequest.guests.map((g) => ({ user: g } as MeetingParticipant)),
        { user: meetingRequest.user } as MeetingParticipant,
      );
      if (meetingRequest.companyRep
        && meetingRequest.companyRep.uid !== this.authUser.uid) {
        participants.push({ user: this.authUser } as MeetingParticipant);
      }
      participants = participants.filter((obj, index, self) => index === self.findIndex((t) => (
          t.user?.uid === obj.user?.uid
      )));
      const meeting: Meeting = {
        uid: 'new-meeting',
        subject: `${this.$t('calendar.tabs.requests.meeting-with')} ${CommunityUser.hydrate(meetingRequest.user || {}).fullName}`,
        description: meetingRequest.message as string | undefined,
        creatorUser: meetingRequest.companyRep
        && meetingRequest.companyRep.uid !== this.authUser.uid
          ? meetingRequest.companyRep
          : this.authUser,
        isCancelled: false,
        participants,
        startTime: meetingRequest.startTime,
        endTime: meetingRequest.endTime,
        startTimestamp: meetingRequest.startTimestamp,
        endTimestamp: meetingRequest.endTimestamp,
        timeZoneName: `${meetingRequest.timezoneName}`,
      };
      const event = AgendaStoreHelper.convertMeetingToEvent(meeting, `${meetingRequest.timezoneName}`);
      event.meetingRequest = meetingRequest;
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
      setTimeout(() => {
        this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(
          DateTimeHelper.utcToZonedTimeDate(
            (meetingRequest.startTimestamp as number) * 1000,
            this.selectedTzName,
          ),
        ));
        this.setViewMode(ViewMode.CREATE);
        this.setEventEdited(event as Partial<Event>);
      }, 50);
    }
    if (!meetingRequest.readState) {
      this.markMeetingRequestAsRead(meetingRequest.uid)
        .then(() => {
          this.$eventsBus.emit('on-update-company-meeting-request-count');
        });
    }
  }

  private mapMeetingRequest(meetingRequest: MeetingRequest): object {
    const user = CommunityUser.hydrate(meetingRequest.user as CommunityUser);
    const route = this.$router.resolve({ name: 'exhibitor-portal-user', params: { memberId: user.uid } }).href;
    return {
      uid: user.uid,
      id: user.id,
      name: user.name,
      firstName: user.firstName,
      lastName: user.firstName,
      jobTitle: user.jobTitle,
      employerName: user.employerName,
      profilePrivacyMode: user.profilePrivacyMode,
      pictureFileResource: user.pictureFileResource,
      country: user.country,
      _isRecommendedForMe: user._isRecommendedForMe,
      cardRoute: route.substring(0, route.lastIndexOf('/')),
      displayRecommendedBadge: true,
      _actions: user._actions,
      prefix: user.prefix,
      suffix: user.suffix,
      isScheduled: !!meetingRequest.meeting,
      isArchived: meetingRequest.trash,
      _ourConnection: user._ourConnection,
      _ourSharedInterestsCompanyCount: user._ourSharedInterestsCompanyCount,
      _commentCount: meetingRequest._commentCount,
      _connections: user._companyUsersConnections.slice(0, 3),
      _connectionCount: user._companyUsersConnections.length,
      cardContext: 'meetingRequest',
      urlOpenNewWindow: true,
    };
  }
}
