import { render, staticRenderFns } from "./CompanyResources.vue?vue&type=template&id=092107b8&scoped=true&"
import script from "./CompanyResources.vue?vue&type=script&lang=ts&"
export * from "./CompanyResources.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/CompanyResources.scss?vue&type=style&index=0&id=092107b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092107b8",
  null
  
)

export default component.exports