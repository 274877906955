









import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import TileComponent from '@/components/mosaic/tiles/TileComponent.vue';

@Component({
  components: { TileComponent },
})
export default class CategoryTileComponent extends Vue {
  @Prop({ default: null })
  readonly route!: string;

  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: true })
  private bannerFileResource!: FileResource;

  @Prop({ required: false, default: null })
  private size!: number;

  private get bannerImage(): string {
    if (this.bannerFileResource) {
      return FileResourceHelper.getFullPath(this.bannerFileResource, 'w160');
    }
    return '';
  }

  private get handleBaseUrl(): string | null {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.uid) {
            r = r.replaceAll(m, this.uid);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }
}
