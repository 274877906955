











































import { Component, Prop } from 'vue-property-decorator';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  inheritAttrs: false,
})
export default class TileComponent extends BreakpointWrapper {
  @Prop({ required: false, default: null })
  private readonly label!: string;

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  @Prop({ required: false, default: null })
  private readonly image!: string;

  @Prop({ required: false, default: null })
  private readonly maxWidth!: number;

  @Prop({ required: false, default: null })
  private readonly defaultHeight!: string;

  @Prop({ required: false, default: null })
  private readonly type!: number;

  private imageSrc(image: string | null): string | null {
    if (image) {
      const width = this.getWidth();
      return FileResourceHelper.getImagePathWithSize(image, width);
    }
    return image;
  }

  private getWidth(): string {
    let width = this.maxWidth ? `w${this.maxWidth}` : 'w1110';
    if (this.type) {
      switch (this.type) {
        case 1:
          width = 'w1110';
          break;
        case 2:
          width = 'w510';
          break;
        case 3:
        case 4:
          width = 'w350';
          break;
        default:
          break;
      }
    }
    return width;
  }

  private onClick(): void {
    if (this.route) {
      let external = this.route.startsWith('//');
      if (external) {
        window.open(this.route, '_blank');
      } else {
        try {
          const url = new URL(this.route);
          external = !!url.protocol;
          if (external) {
            window.open(this.route, '_blank');
          }
        } catch {
          let r = this.route;
          const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.$router.push(r);
        }
      }
    }
  }
}
