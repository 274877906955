export const COUNTRY_BASE_FRAGMENT = `
  fragment countryBaseFragment on Country {
    uid
    schemaCode
    name
    printableName
    iso
    iso3
    numCode
  }
`;
