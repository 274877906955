






















import { Component, Prop, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class UserTitleUpdateComponent extends Vue {
  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly subtitle!: string;
}
