var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.slides.length > 0)?_c('div',{class:['detail-page-header product d-flex flex-column align-items-center justify-content-center',
                   _vm.__typename === _vm.EntityType.LARGE_PRODUCT && 'large-product'
          ]},[(_vm.slides.length === 1)?_c('div',{staticClass:"w-100 slide",class:{
              'desktop': _vm.isDesktop,
              'tablet': _vm.isTablet,
              'mobile': _vm.isMobile
            }},[_c('div',{staticClass:"rounded-lg img-container d-flex"},[_c('img',{staticClass:"rounded-lg h-auto",attrs:{"alt":_vm.slides[0].alt,"src":_vm.slides[0].src}}),((_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBadge)?_c('div',{staticClass:"box single"},[_c('font-awesome-component',{staticClass:"content",attrs:{"icon":"fa-solid fa-award","icon-classes":"icon-32 text-white-white-100"}})],1):_vm._e()])]):_vm._e(),(_vm.slides.length > 1)?[(_vm.slides.length > 1)?_c('div',{staticClass:"w-100 slide",class:{
                'desktop': _vm.isDesktop,
                'tablet': _vm.isTablet,
                'mobile': _vm.isMobile
              }},[_c('splide',{key:_vm.reloadPrimarySlide,ref:"primary",staticClass:"primary",staticStyle:{"width":"100%"},attrs:{"options":_vm.slidePrimaryOptions}},_vm._l((_vm.slides),function(slide){return _c('splide-slide',{key:slide.src,staticStyle:{"width":"100%"}},[_c('div',{staticClass:"rounded-lg img-container"},[_c('img',{staticClass:"rounded-lg",attrs:{"alt":slide.alt,"src":slide.src}}),((_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBadge)?_c('div',{staticClass:"box"},[_c('font-awesome-component',{staticClass:"content",attrs:{"icon":"fa-solid fa-award","icon-classes":"icon-32 text-white-white-100"}})],1):_vm._e()])])}),1),_c('splide',{key:_vm.reloadSecondarySlide,ref:"secondary",class:!_vm.isMobile ? 'secondary' : '',attrs:{"options":_vm.slideSecondaryOptions}},_vm._l((_vm.slides),function(slide){return _c('splide-slide',{key:slide.src,staticClass:"rounded"},[(!_vm.isMobile)?[_c('img',{attrs:{"alt":slide.alt,"src":slide.src}})]:_vm._e()],2)}),1)],1):_vm._e()]:_vm._e()],2):_vm._e()])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-column align-items-center mt-4 mt-md-5"},[(((_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBadge)
          || (_vm._isRecommendedForMe && _vm.displayRecommendedBadge))?_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-2 mb-4"},[((_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.featured'),"size":"xlg","variant":"blue"}}):_vm._e(),(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.recommended-for-you'),"size":"xlg","variant":"orange-gradient"}}):_vm._e()],1):_vm._e(),(_vm.name)?_c('div',{staticClass:"w-fit d-inline-flex align-items-center"},[_c('h1',{staticClass:"m-0 name"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]):_vm._e(),(_vm.categories)?_c('div',{staticClass:"w-fit mt-2"},[_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.categories)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }