





















import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  StripeAddressElementOptions, StripeElements, StripeLinkAuthenticationElementOptions,
} from '@stripe/stripe-js';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';

@Component({ components: { LoadingSpinnerComponent } })
export default class CheckoutBillingInformationComponent extends VueBaseWidget {
  @Prop({ required: true, default: null })
  private stripeElements!: StripeElements;

  private isCreditCardValid = false;

  private emailValid = false;

  private addressValid = false;

  private isLoading = true;

  mounted(): void {
    this.initiateStripeCard();
  }

  private initiateStripeCard(): void{
    const authOptions: StripeLinkAuthenticationElementOptions = {
      defaultValues: {
        email: (this.authUser.email) ? this.authUser.email : '',
      },
    };
    const linkAuthenticationElement = this.stripeElements.create('linkAuthentication', authOptions);
    linkAuthenticationElement.mount('#link-authentication-element');
    linkAuthenticationElement.on('change', (event) => {
      this.emailValid = event.complete;
      this.setCardElement();
    });

    const paymentElement = this.stripeElements.create('payment');
    paymentElement.mount('#stripe-card');
    paymentElement.on('change', (event) => {
      this.isCreditCardValid = event.complete;
      this.setCardElement();
    });

    const optionsAddress: StripeAddressElementOptions = {
      mode: 'billing',
      display: {
        name: 'full',
      },
    };
    const addressElement = this.stripeElements.create('address', optionsAddress);
    addressElement.mount('#address-element');
    addressElement.on('change', (event) => {
      if (this.isLoading) this.isLoading = false;
      this.addressValid = event.complete;
      this.setCardElement();
    });
  }

  private setCardElement(): void{
    this.$emit('set-element', (this.emailValid && this.addressValid && this.isCreditCardValid));
  }
}
