































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent,
  },
})
export default class CheckoutOrderSummaryComponent extends Vue {
  @Prop({ required: true, default: '0' })
  private total!: string;

  @Prop({ required: true, default: '' })
  private btnText!: string;

  @Prop({ required: true, default: 'checkout' })
  private mode!: string;

  @Prop({ required: false, default: false })
  private disable!: boolean;

  @Prop({ required: false, default: false })
  private loadingCheckout!: boolean;

  @Prop({ required: false, default: false })
  private orderIsNew!: boolean;

  private summaryAction(): void {
    this.$emit('order-navigate');
  }

  private goToCart(): void{
    this.$emit('edit-order');
  }
}
