



















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import VueSecureHtml from 'vue-html-secure';
import VueContextLoading from '@/components/VueContextLoading';

Vue.use(VueSecureHtml);

@Component
export default class CollapseComponent extends VueContextLoading {
  @Prop({ required: true, default: 'title' })
  private readonly title!: string;

  @Prop({ required: true, default: 'description' })
  private readonly content!: string;

  @Prop({ required: false, default: false })
  private readonly expand!: boolean;

  private isCollapsed = false;

  private computedHeight = '0';

  private initHeight(): void {
    const contentElement = this.$refs.content as HTMLElement;
    if (contentElement) {
      contentElement.style.height = 'auto';
      const { height } = getComputedStyle(contentElement);
      this.computedHeight = height;
      contentElement.style.height = '0';
    }
  }

  private mounted(): void {
    this.initHeight();
    if (this.expand) {
      this.isCollapsed = this.expand;
    }
  }
}
