

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BCol, BRow } from 'bootstrap-vue';
import InformationPageType from '@/utils/enums/InformationPageType';
import Category from '@/models/graphql/Category';
import CollapseComponent from '@/components/CollapseComponent.vue';

@Component({
  components: {
    CollapseComponent,
    BRow,
    BCol,
  },
})
export default class InformationComponent extends Vue {
  @Prop({ required: true, default: '' })
  private page!: InformationPageType;

  private categories!: Category[];
}
