import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { PROFILE_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/ProfileRole/Full';

export const COMMUNITY_USER_PROFILE_ROLE_FULL_FRAGMENT = `
  fragment communityUserProfileRoleFullFragment on CommunityUserProfileRole {
    uid
    schemaCode
    relation
    user {
     ...communityUserBaseFragment
    }
    profileRole {
      ...profileRoleFullFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${PROFILE_ROLE_FULL_FRAGMENT}
`;
