

























import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import UrlType from '@/utils/enums/UrlType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class SocialLinksComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'SpeakerSocialLinksWidgetStore';

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly sites!: { icon: string; url: string; type: string }[];

  @Prop({
    required: false,
    default: 'tertiary',
  })
  private readonly variant!: string;

  @Prop({
    required: false,
    default: 'lg',
  })
  private readonly size!: string;

  created(): void {
    this.setDataConfig();
  }

  private openUrl = (site: { icon: string; url: string; type: string }): void => {
    if (site.url && site.url.length > 0) {
      let siteUrl = site.url;
      switch (site.type) {
        case UrlType.EMAIL:
          siteUrl = `mailto:${site.url}`;
          window.open(siteUrl);
          break;
        case UrlType.PHONE:
          siteUrl = `tel:${site.url}`;
          window.open(siteUrl);
          break;
        default:
          window.open(siteUrl, '_blank');
      }
      this.$emit('log-stats', siteUrl);
    }
  };
}
