
























































import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { StateChanger } from 'vue-infinite-loading';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import { BasicTypes } from '@/utils/types/BasicTypes';
import Message from '@/models/graphql/Message';
import MessageType from '@/utils/enums/chat/MessageType';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessageGroup from '@/models/graphql/MessageGroup';
import ActionType from '@/utils/enums/ActionType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';

const messageStore = namespace('MessageStore');
const messageGroupStore = namespace('MessageGroupStore');
const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    IllustrationComponent,
    LoadingSpinnerComponent,
  },
})
export default class LiveQuestionComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref('infiniteLoading') infiniteLoading!: any;

  @Prop({ required: false, default: null })
  private readonly sessionUid!: string;

  @Getter
  private authUser!: CommunityUser;

  @messageStore.Action
  private loadPaginatedMessagesPresentation!: (payload: {
    data: Record<string, BasicTypes>;
    offset: number;
    limit: number;
  }) => Promise<number | null>;

  @messageStore.Action
  private resetPage!: () => void;

  @messageGroupStore.Action(ActionType.GET_ONE)
  private getMessageGroup!: (payload: Record<string, BasicTypes>) => Promise<MessageGroup | undefined>;

  @messageGroupStore.Getter
  private messageGroup!: MessageGroup;

  @messageStore.Getter
  private getMessages!: Message[];

  @messageStore.Getter
  private getQnAcount!: number;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @State
  private dateLocale!: Locale;

  @notificationStore.Getter
  private genericEvents!: Record<string, { unsubscribe: () => void }>;

  private MessageEntity = Message;

  private localMessages: Message[] = [];

  private filtered = false;

  private localMessageGroup: MessageGroup | null = null;

  private offset = 0;

  private limit = 10;

  private refresh = 0;

  private overHallCount = 0;

  private isIncrease = false;

  private IllustrationType = IllustrationType;

  private partialAuthUser = {} as CommunityUser;

  private get LiveMessage(): Message[] {
    this.localMessages.forEach((el, index) => {
      this.localMessages[index] = this.MessageEntity.hydrate(el);
    });
    return this.localMessages;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.QA_NEW_QUESTION,
      NotificationEventType.QA_DELETE_QUESTION,
    ];
  }

  destroyed(): void {
    this.genericEvents[`qa-channel-${this.sessionUid}`].unsubscribe();
  }

  mounted(): void {
    if (this.authUser) {
      this.partialAuthUser = {
        uid: this.authUser.uid,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        pictureFileResource: this.authUser.pictureFileResource,
      } as CommunityUser;
    }
    this.offset = 0;
    this.resetPage();
    if (this.sessionUid) {
      this.genericEvent({ channel: `qa-channel-${this.sessionUid}` });
      this.getMessageGroup({
        filter: {
          target: {
            uid: this.sessionUid,
          },
          type: MessageType.QNA,
        },
      }).then((messageGroup) => {
        if (messageGroup) {
          this.localMessageGroup = messageGroup;
        }
      });
    }
    setInterval(() => {
      this.refresh += 1;
    }, 60000);
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.user && event) {
      switch (event.type) {
        case NotificationEventType.QA_NEW_QUESTION:
          this.newQuestionFromEvent(event);
          break;
        case NotificationEventType.QA_DELETE_QUESTION:
          this.deleteQuestionFromEvent(event);
          break;
        default:
          break;
      }
    }
  }

  private deleteQuestionFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
      && this.localMessageGroup
      && extraData.messageGroupUid === this.localMessageGroup.uid) {
      const messageIndex = this.localMessages
        .findIndex((message) => message.uid === extraData.messageUid);
      if (messageIndex >= 0) {
        this.localMessages.splice(messageIndex, 1);
      }
    }
  }

  @Watch('getMessages')
  private onMessagesChange(): void {
    this.localMessages = this.getMessages;
  }

  @Watch('getQnAcount')
  private onCountChange(): void {
    this.overHallCount = this.getQnAcount;
  }

  private infiniteHandler(state: StateChanger): void {
    const filterUnanswered = this.filtered ? { childMessages: null } : '';
    this.loadPaginatedMessagesPresentation({
      data: {
        filter: {
          group: {
            target: {
              uid: this.sessionUid,
            },
            type: MessageType.QNA,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          userDeleted_not: true,
          parentMessage: null,
          type: 'QNA',
          ...filterUnanswered,
        },
        orderBy: [
          'senttimestamp_asc',
        ],
      },
      limit: this.limit,
      offset: this.offset,
    }).then((questionsCount) => {
      if (questionsCount === this.limit) {
        state.loaded();
        this.offset += 10;
      } else {
        state.complete();
        this.offset += 10;
      }
    }).catch(() => state.complete());
  }

  private newQuestionFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
      && this.localMessageGroup
      && extraData.messageGroupUid === this.localMessageGroup.uid) {
      extraData.user.name = `${extraData.user.firstName} ${extraData.user.lastName}`;
      if (!this.isIncrease) this.startFlash();
      if (this.localMessages.length >= (this.overHallCount - 10)) {
        let newMessage = {
          uid: extraData.newMessageUid,
          group: this.localMessageGroup,
          user: extraData.user,
          content: extraData.newMessageContent,
        } as Message;
        newMessage = this.MessageEntity.hydrate(newMessage);
        if (!this.isIncrease) this.startFlash();
        this.overHallCount += 1;
        this.offset += 1;
        this.localMessages.push(newMessage);
      } else {
        this.overHallCount += 1;
      }
    }
  }

  private startFlash(): void {
    this.isIncrease = true;
    setTimeout(() => {
      this.isIncrease = false;
    }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  private dateReceived(message: Message): string {
    const currentTime = new Date();
    const pastTime = new Date(message.date);
    const timeDifference = currentTime.getTime() - pastTime.getTime();
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${this.$t('app.feed-page.time-ago.days', { number: days })}`;
    } if (hours > 0) {
      return `${this.$t('app.feed-page.time-ago.hours', {
        number: hours,
      })}`;
    } if (minutes > 0) {
      return `${this.$t('app.feed-page.time-ago.minutes', {
        number: minutes,
      })}`;
    }
    return `${this.$t('app.feed-page.time-ago.now')}`;
  }
}
