var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type !== _vm.FeedAction.FEEDPOST_CREATED && !_vm.displayCreatedUpdate)?_c('div',{staticClass:"feed-title"},[_c('p',{staticClass:"paragraph paragraph-1 normal text-neutral-n-8-dark-ink mb-0"},[_vm._v(" "+_vm._s(_vm.action)+" ")])]):_vm._e(),(_vm.isFileResourceActionUpdate
      || (_vm.hasMoreThanOneActionType && _vm.type !== _vm.FeedAction.USER_CONNECTIONS_UPDATED))?_c('div',{staticClass:"generic-feed"},[_c('feed-file-resource-update-component',{attrs:{"banner":_vm.fileResource.banner,"default-icon":_vm.fileResource.defaultIcon,"logo":_vm.fileResource.logo}})],1):_c('div',{staticClass:"container-detail",class:[_vm.type === _vm.FeedAction.FEEDPOST_CREATED && 'p-0']},[_c('div',{staticClass:"d-flex flex-column row-gap-4"},[(_vm.type === _vm.FeedAction.USER_CONNECTIONS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-connection-card',_vm._b({key:item.uid,attrs:{"action":item.action}},'feed-connection-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.USER_BOOKMARKS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-bookmark-card',_vm._b({key:item.uid},'feed-bookmark-card',item,false))}),1):(_vm.type === _vm.FeedAction.EXHIBITOR_PRODUCTS_UPDATED
          || _vm.type === _vm.FeedAction.EXHIBITOR_PRODUCT_UPDATED
          || _vm.type === _vm.FeedAction.CHANNEL_PRODUCTS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-product-card',_vm._b({key:item.uid},'feed-product-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.CHANNEL_DEALS_UPDATED
          || _vm.type === _vm.FeedAction.EXHIBITOR_DEAL_UPDATED
          || _vm.type === _vm.FeedAction.EXHIBITOR_DEALS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-deal-card',_vm._b({key:item.uid},'feed-deal-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.SUBEDITION_SESSIONS_UPDATED
          || _vm.type === _vm.FeedAction.CHANNEL_SESSIONS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-session-card',_vm._b({key:item.uid},'feed-session-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.USER_URLS_UPDATED
          || _vm.type === _vm.FeedAction.EXHIBITOR_URLS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},[_c('feed-url-card',{attrs:{"action":_vm.type,"feed-items":_vm.feedItems}})],1):(_vm.type === _vm.FeedAction.EXHIBITOR_HANDOUTS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-document-card',_vm._b({key:item.uid},'feed-document-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.EXHIBITOR_CONTACTS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-contact-card',_vm._b({key:item.uid},'feed-contact-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.EXHIBITOR_EVENT_PARTICIPATION)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-exhibiting-at-card',_vm._b({key:item.uid},'feed-exhibiting-at-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.EXHIBITOR_ADDRESSES_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-address-card',_vm._b({key:item.uid,attrs:{"initiator":_vm.initiator}},'feed-address-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.FEEDPOST_CREATED)?_c('div',_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-post-card',_vm._b({key:item.uid,attrs:{"is-modal-repost":_vm.isModalRepost,"initiator":_vm.initiator}},'feed-post-card',item.triggered.type === _vm.FeedPostType.REPOST
            ? item.triggered.repostPost
            : item.triggered,false))}),1):(_vm.type === _vm.FeedAction.SUBEDITION_SPEAKERS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-speaker-card',_vm._b({key:item.uid},'feed-speaker-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.CHANNEL_EXHIBITORS_UPDATED
          || _vm.type === _vm.FeedAction.SUBEDITION_EXHIBITORS_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-exhibitor-card',_vm._b({key:item.uid},'feed-exhibitor-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.CHANNEL_ARTICLES_UPDATED)?_c('div',{staticClass:"d-flex flex-column row-gap-4"},_vm._l((_vm.itemsToDisplay),function(item){return _c('feed-article-card',_vm._b({key:item.uid},'feed-article-card',item.triggered,false))}),1):(_vm.type === _vm.FeedAction.USER_TITLE_UPDATED
          || _vm.type === _vm.FeedAction.USER_COMPANY_UPDATED)?_c('div',{staticClass:"feed-item-detail"},[_c('user-title-update-component',{attrs:{"subtitle":_vm.userTitleUpdateSubtitle,"title":_vm.initiator.jobTitle}})],1):(_vm.isDynamicTextUpdate)?_c('div',{staticClass:"feed-item-detail"},[_c('dynamic-text-update-component',{attrs:{"text":_vm.dynamicText}})],1):(_vm.isCategoryActionUpdate)?_c('div',[_c('category-update-component',{attrs:{"list":_vm.categoryList},on:{"on-view-all":function () { return _vm.$router.push(_vm.navigateTo); }}})],1):(_vm.displayCreatedUpdate)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-48 mt-4 mb-2","icon":"fa-regular fa-hand-wave","icon-classes":"icon-32 text-yellow-y-3-primary-yellow"}}),_c('p',{staticClass:"mb-0 text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('app.feed-page.joined'))+" ")]),_c('p',{staticClass:"mb-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.createdTime)+" ")])],1):_vm._e(),(_vm.showAll)?_c('button-component',{attrs:{"text":("" + (_vm.$t('app.feed-page.more'))),"size":"md","variant":"alt-2"},on:{"on-click":_vm.displayAll}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }