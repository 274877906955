
























import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component
export default class TopicPageHeaderComponent extends BreakpointWrapper {
  @Getter
  protected readonly community!: Community;

  @Prop({ required: false, default: () => [] })
  private readonly imageFileResource!: FileResource;

  protected get communityCode(): string {
    return this.community.code as string;
  }

  private get bannerSrc(): string {
    if (this.imageFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.imageFileResource, 'w1110')})`;
    }
    if (this.community.defaultExhibitorBannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.community.defaultExhibitorBannerFileResource, 'w1110')})`;
    }
    return 'url(/img/banner/empty.svg)';
  }
}
