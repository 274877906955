





















































import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import InputText from '@/components/InputText.vue';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import Poll from '@/models/graphql/Poll';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';

@Component({
  components: {
    StandardModal,
    ButtonIconComponent,
    ButtonComponent,
    InputText,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(1),
      },
      choice1: {
        required,
        minLength: minLength(1),
      },
      choice2: {
        required,
        minLength: minLength(1),
      },
    },
  },
})
export default class PollCreationFormComponent extends BreakpointWrapper {
  @Getter
  authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Prop({ required: false, default: null })
  private readonly poll!: Poll;

  @Prop({ required: false, default: true })
  private isModal!: boolean;

  @Prop({ required: false, default: false })
  private disabled!: boolean;

  @Prop({ required: false, default: 0 })
  private renderKey!: number;

  private form = {
    title: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',
    choice5: '',
  };

  private canValidateChoice1 = false;

  private canValidateChoice2 = false;

  private titleError = false;

  private choice1Error = false;

  private choice2Error = false;

  private saveWithTimeLoading = false;

  private saveWithoutTimeLoading = false;

  private get hasError(): boolean {
    return this.titleError || this.choice1Error || this.choice2Error
       || !this.canValidateChoice1 || !this.canValidateChoice2;
  }

  @Watch('poll')
  @Watch('renderKey')
  private onShow(): void {
    this.form.title = '';
    this.form.choice1 = '';
    this.form.choice2 = '';
    this.form.choice3 = '';
    this.form.choice4 = '';
    this.form.choice5 = '';
    if (this.poll) {
      this.form.title = this.poll.title || '';
      if (this.poll.pollAnswers && this.poll.pollAnswers.length) {
        this.form.choice1 = this.poll.pollAnswers.length >= 1
          ? this.poll.pollAnswers[0].title || ''
          : '';
        this.form.choice2 = this.poll.pollAnswers.length >= 2
          ? this.poll.pollAnswers[1].title || ''
          : '';
        this.form.choice3 = this.poll.pollAnswers.length >= 3
          ? this.poll.pollAnswers[2].title || ''
          : '';
        this.form.choice4 = this.poll.pollAnswers.length >= 4
          ? this.poll.pollAnswers[3].title || ''
          : '';
        this.form.choice5 = this.poll.pollAnswers.length >= 5
          ? this.poll.pollAnswers[4].title || ''
          : '';
      }
    }
    this.titleError = false;
    this.choice1Error = false;
    this.choice2Error = false;
  }

  @Watch('renderKey')
  private onModalShow(): void {
    this.canValidateChoice1 = false;
    this.canValidateChoice2 = false;
  }

  private validateField(fieldType: string, fieldValue: string, canBeValidated: boolean): boolean {
    switch (fieldType) {
      case 'title':
        this.titleError = fieldValue === '' && canBeValidated;
        this.onUpdatePoll();
        break;
      case 'choice1':
        this.choice1Error = fieldValue === '' && canBeValidated;
        this.onUpdatePoll();
        break;
      case 'choice2':
        this.choice2Error = fieldValue === '' && canBeValidated;
        this.onUpdatePoll();
        break;
      default:
        break;
    }
    return fieldValue === '' && canBeValidated;
  }

  private onBlur(fieldType: string): void {
    if (this.$v) {
      this.onChange(fieldType);
      this.onUpdatePoll();
    }
  }

  private onChange(fieldType: string): void {
    if (this.$v) {
      switch (fieldType) {
        case 'choice1':
          this.canValidateChoice1 = true;
          break;
        case 'choice2':
          this.canValidateChoice2 = true;
          break;
        default:
          break;
      }
    }
  }

  private onUpdatePoll(): void {
    this.$emit('update-poll', {
      form: {
        title: this.form.title,
        choice1: this.form.choice1,
        choice2: this.form.choice2,
        choice3: this.form.choice3,
        choice4: this.form.choice4,
        choice5: this.form.choice5,
      },
      error: this.hasError,
    });
  }
}
