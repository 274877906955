


















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { HallType } from '@/components/map/FloorplanComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: { FontAwesomeComponent },
})
export default class MapChangeFloorComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private readonly hallList!: Array<HallType>;

  @Prop({ required: true })
  private readonly mapLoadedStoreKey!: string;

  private halls: Array<HallType> = [];

  private showList = false;

  private get selectedHall(): HallType | undefined {
    return this.halls.find((f) => f.selected);
  }

  private get isFilterResults(): boolean {
    return this.halls
      .reduce((previousValue, currentValue) => previousValue + currentValue.resultFilterCount, 0)
      !== 0;
  }

  created(): void {
    this.halls = this.hallList;
    if (!(this.$route.query
      && this.selectedHall
    && this.$route.query.hall
    && this.$route.query.hall === `${this.selectedHall.id}`)) {
      const query = Object.assign(this.$route.query, { hall: `${this.selectedHall?.id}` });
      const route = this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href;
      window.history.replaceState(
        query,
        document.title,
        route,
      );
    }
  }

  @Watch('selectedHall.id')
  updateUrlHall(): void {
    let hallFromUrl = -1;
    if (this.$route.query.hall) {
      hallFromUrl = parseInt(this.$route.query.hall as string, 10);
    }
    if (this.selectedHall?.id !== hallFromUrl) {
      const query = Object.assign(this.$route.query, { hall: `${this.selectedHall?.id}` });
      const route = this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href;
      window.history.pushState(
        query,
        document.title,
        route,
      );
    }
  }

  @Watch('hallList')
  private updateHallList(): void {
    this.halls = this.hallList;
  }

  private toggleHall(id: number): void {
    if (this.halls.length > 1) {
      this.$emit('on-change-floor', id);
      this.showList = false;
    }
  }
}
