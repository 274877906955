



























































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import CommunityUser from '@/models/graphql/CommunityUser';
import RespondPopupComponent from '@/components/RespondPopupComponent.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MobileMenuModal from '@/components/modals/MobileMenuModal.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import { Getter, namespace } from 'vuex-class';
import MobileMenuItemParams from '@/utils/types/MobileMenuItemParams';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import { Portal } from 'portal-vue';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    SendConnectionRequestModal,
    ConfirmModal,
    MobileMenuModal,
    FontAwesomeComponent,
    ButtonComponent,
    RespondPopupComponent,
    Portal,
  },
})
/* eslint-disable no-underscore-dangle */
export default class ActionButtonConnect extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserConnectionStore.Action
  private fullyAcceptConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDeclineConnection!: (payload: {
    uid: string; messageLookupArgs: object; toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyConnect!: (payload: {
    connectionArgs: object; newConversationArgs: object;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Getter
  private isLoading!: boolean;

  private userConnection: CommunityUser = this.actionResult;

  private respondMenuOpen = false;

  private respondMenuButtons: MobileMenuItemParams[] = [
    {
      icon: 'far fa-user-plus',
      label: 'actions.accept-connection-request-menu',
      action: 'on-accept-connection',
    },
    {
      icon: 'far fa-user-xmark',
      label: 'actions.decline-connection-request-menu',
      action: 'on-decline-connection',
    },
  ];

  private modalId = +new Date();

  private get isAccepted(): boolean {
    return !!(this.userConnection
        && this.userConnection._ourConnection
        && this.userConnection._ourConnection.linkState === ConnectionStatusType.ACCEPTED);
  }

  private get isInvited(): boolean {
    return !!(this.userConnection
        && this.userConnection._ourConnection
        && this.userConnection._ourConnection.connectedUser
        && this.userConnection._ourConnection.connectedUser.uid === this.authUser.uid
        && this.userConnection._ourConnection.linkState === ConnectionStatusType.INVITED);
  }

  private get isPending(): boolean {
    return !!(this.userConnection
        && this.userConnection._ourConnection
        && this.userConnection._ourConnection.user
        && this.userConnection._ourConnection.user.uid === this.authUser.uid
        && this.userConnection._ourConnection.linkState === ConnectionStatusType.INVITED);
  }

  private get isDisplayed(): boolean {
    return this.actionType === this.ActionButtonType.CONNECT
        && this.entityType === this.EntityTypeEnum.USER
        && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE).enabled
        && !this.isBlockedByOrIsBlockingUser;
  }

  private get isBlockedByOrIsBlockingUser(): boolean {
    return this.authUser
        && (this.authUser.isBlockedByUser(this.userConnection.uid) || this.authUser.haveBlockedUser(this.userConnection.uid));
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.CONNECTION_REQUEST];
    this.buttonsDisplay();
  }

  protected notificationCallback(event: SubscriptionEvent): void {
    if (event.type === NotificationEventType.CONNECTION_REQUEST) {
      const notification = Notification.hydrate(event.data);
      if (notification
          && notification.initiator
          && (notification.initiator as CommunityUser).uid === this.entityUid
          && notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1) {
        const connection = notification.triggered as unknown as CommunityUserConnection;
        if (connection
            && connection.linkState
            && [ConnectionStatusType.INVITED, ConnectionStatusType.ACCEPTED]
              .includes(connection.linkState)) {
          this.$set(this.userConnection, '_ourConnection', connection);
          if (connection.linkState === ConnectionStatusType.ACCEPTED) {
            this.dispatch('setIsAccepted', true);
            this.commit('reRenderButtons');
          }
        } else {
          this.dispatch('visibilityOnBlock', !this.isBlockedByOrIsBlockingUser);
          this.$set(this.userConnection, '_ourConnection', null);
        }
      }
    }
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
    this.dispatch('setIsAccepted', this.isAccepted);
  }

  @Watch('isAccepted')
  private updateAllButtonIsAccepted(): void {
    this.dispatch('setIsAccepted', this.isAccepted);
  }

  private toggleRespondMenu(): void {
    if (!this.isLoading) {
      if (this.isMobile && this.respondMenuOpen) {
        document.body.classList.remove('modal-open');
      } else if (this.isMobile && !this.respondMenuOpen) {
        document.body.classList.add('modal-open');
      }
      this.respondMenuOpen = !this.respondMenuOpen;
    }
  }

  private toggleSendRequestModal(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (!this.isLoading) {
      if (this.userConnection._ourConnection
          && this.userConnection._ourConnection.linkState === ConnectionStatusType.INVITED) {
        return;
      }
      if (this.userConnection._ourConnection) {
        this.$bvModal.show(`user-card-remove-connection-${this.modalId}`);
      } else {
        this.$bvModal.show(`user-card-issue-connection-request-${this.modalId}`);
      }
    }
  }

  private onAccept(): void {
    if (this.isInvited && this.userConnection._ourConnection) {
      const connectedUser = CommunityUser.hydrate(this.userConnection._ourConnection.user);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.ACCEPTED);
      this.fullyAcceptConnection({
        uid: this.userConnection._ourConnection.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: connectedUser.uid,
            firstName: connectedUser.firstName,
            lastName: connectedUser.lastName,
            pictureFileResource: connectedUser.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: connectedUser.fullName,
      })
        .then(() => {
          this.commit('reRenderButtons');
        })
        .catch(() => {
          if (this.userConnection._ourConnection) {
            this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.INVITED);
          }
        });
    }
  }

  private onDecline(): void {
    if (this.isInvited && this.userConnection._ourConnection) {
      const connectedUser = CommunityUser.hydrate(this.userConnection._ourConnection.user);
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.DECLINED);
      this.fullyDeclineConnection({
        uid: this.userConnection._ourConnection.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: connectedUser.uid,
            firstName: connectedUser.firstName,
            lastName: connectedUser.lastName,
            pictureFileResource: connectedUser.pictureFileResource,
          } as CommunityUser],
          groupType: [GroupType.CONNECTION, GroupType.RECONNECTION],
        },
        toastUserFullName: connectedUser.fullName,
      })
        .catch(() => {
          if (this.userConnection._ourConnection) {
            this.$set(this.userConnection._ourConnection, 'linkState', ConnectionStatusType.INVITED);
          }
        });
      this.commit('reRenderButtons');
    }
  }

  private toggleConnection(description: string | null = null): void {
    if (this.userConnection) {
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      if (this.userConnection._ourConnection) {
        this.fullyDisconnect({
          uid: this.userConnection._ourConnection.uid,
          messageLookupArgs: {
            myUid: this.authUser.uid,
            users: [auth, {
              uid: this.entityUid,
              firstName: this.userConnection.firstName,
              lastName: this.userConnection.lastName,
            } as CommunityUser],
            groupType: [GroupType.DIRECT],
          },
          toastUserFullName: `${this.userConnection.firstName} ${this.userConnection.lastName}`,
        })
          .then((connection) => {
            this.$eventsBus.emit('click-reset');
            this.userConnection = CommunityUser.hydrate({
              uid: this.entityUid,
              firstName: this.userConnection.firstName,
              lastName: this.userConnection.lastName,
              _ourConnection: connection,
            });
            this.commit('reRenderButtons');
          });
      } else {
        this.fullyConnect({
          connectionArgs: {
            userId: this.authUser.uid,
            linkedUserId: this.entityUid,
            message: description,
          },
          newConversationArgs: {
            message: description,
            users: [CommunityUser.hydrate({ uid: this.entityUid }),
              CommunityUser.hydrate({ uid: this.authUser.uid })],
            tempId: '',
            messageTempId: '',
          },
        })
          .then((connection) => {
            this.logStats(StatLoggerCategories.CONNECT, StatLoggerActions.ADD, '');
            this.userConnection = CommunityUser.hydrate({
              uid: this.entityUid,
              firstName: this.userConnection.firstName,
              lastName: this.userConnection.lastName,
              _ourConnection: connection,
            });
            this.commit('reRenderButtons');
          });
      }
    }
  }
}
