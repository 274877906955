


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ClipboardHelper from '@/utils/helpers/ClipboardHelper';
import { namespace } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';

const toastStore = namespace('ToastStore');

@Component({
  components: {
    FontAwesomeComponent,
    DropdownMenuItem,
  },
})
export default class PollItemMenuComponent extends Vue {
  @Prop({ required: true })
  private readonly uid!: string;

  @Prop({ required: true })
  private readonly sessionUid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private startTimestamp!: number | null;

  @Prop({
    required: false,
    default: null,
  })
  private endTimestamp!: number | null;

  @Prop({
    required: false,
    default: false,
  })
  private canCreate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isModerator!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isPollClosed!: boolean;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private async copySessionId(hide: Function): Promise<void> {
    try {
      await ClipboardHelper.copyTextToClipboard(this.sessionUid);
      this.showToast(ToastActionType.COPY_SESSION_ID_TO_CLIPBOARD);
    } catch (err) {
      this.showToast(ToastActionType.FAILED_COPY_TO_CLIPBOARD);
    }
    hide();
  }
}
