import { render, staticRenderFns } from "./FeedContent.vue?vue&type=template&id=4bd69efd&scoped=true&"
import script from "./FeedContent.vue?vue&type=script&lang=ts&"
export * from "./FeedContent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/feed/FeedItem.scss?vue&type=style&index=0&id=4bd69efd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd69efd",
  null
  
)

export default component.exports