import { render, staticRenderFns } from "./TagComponent.vue?vue&type=template&id=6597ffce&scoped=true&"
import script from "./TagComponent.vue?vue&type=script&lang=ts&"
export * from "./TagComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/TagComponent.scss?vue&type=style&index=0&id=6597ffce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6597ffce",
  null
  
)

export default component.exports