















































































































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResource from '@/models/graphql/FileResource';
import PillWidget from '@/components/pill/PillWidget.vue';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import EditFileResourceModal from '@/components/modals/EditFileResourceModal.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { namespace } from 'vuex-class';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import DetailPageHeaderSaveHelper from '@/utils/helpers/DetailPageHeaderSaveHelper';
import EntityType from '@/utils/enums/EntityType';
import {
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  isSameDay,
  isWithinInterval,
  subDays,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';

const toastStore = namespace('ToastStore');
const fileResourceStore = namespace('FileResourceStore');

@Component({
  data(): object {
    return {
      profileAvatar: '',
      newProfileAvatar: '',
      coverAvatar: '',
      newCoverAvatar: '',
      uploadedCoverAvatar: '',
      urlUploadedImgProfile: '',
      uploadingCover: false,
      uploadingAvatar: false,
      coverPayloadTooLarge: false,
      avatarPayloadTooLarge: false,
    };
  },
  components: {
    RecommendationBadgeComponent,
    PillWidget,
    AvatarSoloWidget,
    Splide,
    SplideSlide,
    EditFileResourceModal,
    PillComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
  },
  inheritAttrs: false,
})
export default class DetailPageHeaderComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'DetailPageHeaderWidgetStore';

  @Prop({ required: false, default: false })
  readonly archived!: boolean;

  @Prop({ required: false, default: null })
  protected readonly entityType!: string;

  @Prop({ required: false, default: null })
  protected readonly type!: string;

  @Prop({ required: false, default: () => [] })
  protected readonly logo!: Data | string;

  @Prop({ required: false, default: null })
  protected readonly firstName!: string | null;

  @Prop({ required: false, default: null })
  protected readonly lastName!: string | null;

  @Prop({ required: false, default: false })
  protected readonly _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: null })
  protected readonly _followerCount!: number | null;

  @Prop({ required: false, default: false })
  protected readonly autoFollow!: boolean;

  @Prop({ required: false, default: false })
  protected readonly featured!: boolean;

  @Prop({ required: false, default: () => [] })
  protected readonly banners!: Data[] | string;

  @Prop({ required: false, default: false })
  protected readonly editabledImage!: boolean;

  @Prop({ required: false, default: null })
  private readonly startTimestamp!: number;

  @Prop({ required: false, default: null })
  private readonly endTimestamp!: number;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @fileResourceStore.Action
  private createBannerFileResourceForCommunityUser!: (uploadToken: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createProfileFileResourceForCommunityUser!: (uploadToken: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  private states = {
    archived: 'archived',
    live: 'live',
    soon: 'soon',
  };

  private today = new Date();

  private EntityType = EntityType;

  private reloadSlide = true;

  private reloadPrimarySlide = 1;

  private reloadSecondarySlide = 0;

  private localStartTimestamp = this.startTimestamp
    ? getUnixTime(DateTimeHelper.toUTC(fromUnixTime(this.startTimestamp)))
    : null;

  private localEndTimestamp = this.endTimestamp
    ? getUnixTime(DateTimeHelper.toUTC(fromUnixTime(this.endTimestamp)))
    : null;

  private get logoSrc(): string {
    if (this.logo) {
      if (typeof this.logo === 'string') {
        return this.logo;
      }
      return FileResourceHelper.getFullPath(this.logo as unknown as FileResource, 'w136');
    }
    return '';
  }

  private get bannerSrc(): string {
    if (this.banners && !Array.isArray(this.banners)) {
      if (typeof this.banners === 'string') {
        return this.banners;
      }
      return FileResourceHelper.getFullPath(this.banners as unknown as FileResource, 'w1110');
    }
    if (this.type === 'magazineArticle') {
      return FileResourceHelper.getFullPath(this.banners[0] as unknown as FileResource, 'w1110');
    }
    return '';
  }

  private get slides(): { src: string; alt: string }[] {
    if (this.banners && typeof this.banners !== 'string') {
      return this.banners.map((item) => ({
        src: FileResourceHelper.getFullPath(item.fullFileResource as FileResource, 'w1110'),
        alt: '',
      }));
    }
    return [];
  }

  private get defaultIcon(): string {
    switch (this.entityType) {
      case 'Exhibitor':
        return 'far fa-buildings';
      default:
        break;
    }
    return '';
  }

  private get eventState(): string {
    if (this.archived) {
      return this.states.archived;
    }
    if (
      this.localStartTimestamp
      && this.localEndTimestamp
      && isWithinInterval(this.today, {
        start: fromUnixTime(this.localStartTimestamp),
        end: fromUnixTime(this.localEndTimestamp),
      })
    ) {
      return this.states.live;
    }
    if (this.localStartTimestamp) {
      const dayBefore = subDays(fromUnixTime(this.localStartTimestamp), 1);
      if (isSameDay(dayBefore, this.today) || isAfter(dayBefore, this.today)) {
        return this.states.soon;
      }
    }
    if (this.localEndTimestamp && isBefore(fromUnixTime(this.localEndTimestamp), this.today)) {
      return this.states.archived;
    }
    return '';
  }

  @Watch('breakpoint', { deep: true })
  private get slidePrimaryOptions(): object {
    return {
      type: 'slide',
      rewind: true,
      autoWidth: true,
      pagination: false,
      arrows: !this.isMobile,
    };
  }

  @Watch('breakpoint', { deep: true })
  private get slideSecondaryOptions(): object {
    if (this.isMobile) {
      return {
        type: 'slide',
        rewind: true,
        arrows: false,
        autoWidth: true,
        pagination: true,
      };
    }

    return {
      type: 'slide',
      rewind: true,
      gap: '1rem',
      pagination: false,
      fixedWidth: 60,
      fixedHeight: 60,
      cover: true,
      focus: 'center',
      isNavigation: true,
      arrows: false,
    };
  }

  created(): void {
    this.setDataConfig();
  }

  onDeleteProfileImg(showToast = true): void {
    if ((this.authUser
      && this.authUser.pictureFileResource
      && this.authUser.pictureFileResource.uid)) {
      this.deleteFileResource(this.authUser.pictureFileResource.uid);
      if (!this.$data.uploadingAvatar || !this.$data.uploadingCover) {
        this.authUser.pictureFileResource = undefined;
        this.$data.newProfileAvatar = '';
        this.$bvModal.hide('edit-user-avatar');
      }
      if (showToast) {
        this.addNewAction(
          { type: ToastActionType.REMOVE_AVATAR },
        );
        this.showToast(ToastActionType.REMOVE_AVATAR);
      }
    }
  }

  onDeleteBannerImg(showToast = true): void {
    if (this.authUser
      && this.authUser.bannerFileResource
      && this.authUser.bannerFileResource.uid) {
      this.deleteFileResource(this.authUser.bannerFileResource.uid);
      if (!this.$data.uploadingCover) {
        this.authUser.bannerFileResource = undefined;
        this.$data.newCoverAvatar = '';
        this.$bvModal.hide('edit-user-profile-cover');
      }
      if (showToast) {
        this.showToast(ToastActionType.REMOVE_COVER_IMAGE);
      }
    }
  }

  onLocalProfileSave(deleteBefore: boolean): void {
    this.$data.uploadingAvatar = true;
    this.$data.profileAvatar = (this.$refs.editorImageProfile as EditFileResourceModal).showImage();
    if (this.community.code) {
      DetailPageHeaderSaveHelper.onSave(
        this.community.code,
        this.$data.profileAvatar,
        `avatar_${this.authUser.uid}.jpeg`,
      )
        .then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status === 413) {
              this.$data.avatarPayloadTooLarge = true;
              this.$data.uploadingAvatar = false;
            } else {
              this.$data.newProfileAvatar = this.$data.profileAvatar;
              this.$data.avatarPayloadTooLarge = false;
              this.$data.uploadingAvatar = false;
              if (deleteBefore) {
                this.onDeleteProfileImg(false);
              }
              this.$bvModal.hide('edit-user-avatar');
              this.showToast(ToastActionType.UPDATE_AVATAR);
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.createProfileFileResourceForCommunityUser(uploadToken).then((response) => {
                  this.authUser.pictureFileResource = response;
                });
              }
            }
          };
        });
    }
  }

  onLocalBannerSave(deleteBefore: boolean): void {
    this.$data.uploadingCover = true;
    this.$data.coverAvatar = (this.$refs.editorImageCover as EditFileResourceModal).showImage();
    if (this.community.code) {
      DetailPageHeaderSaveHelper.onSave(this.community.code, this.$data.coverAvatar, `banner_${this.authUser.uid}.jpeg`)
        .then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status === 413) {
              this.$data.coverPayloadTooLarge = true;
              this.$data.uploadingCover = false;
            } else {
              this.$data.coverPayloadTooLarge = false;
              if (deleteBefore) {
                this.onDeleteBannerImg(false);
              }
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.createBannerFileResourceForCommunityUser(uploadToken).then((response) => {
                  if (response && response.uid) {
                    this.$data.newCoverAvatar = this.$data.coverAvatar;
                    this.authUser.bannerFileResource = response;
                    this.$data.uploadingCover = false;
                    this.$bvModal.hide('edit-user-profile-cover');
                    this.showToast(ToastActionType.UPDATE_COVER_IMAGE);
                  } else {
                    this.$data.uploadingCover = false;
                    this.$bvModal.hide('edit-user-profile-cover');
                    this.showToast(ToastActionType.UPDATE_COVER_IMAGE_ERROR);
                  }
                });
              }
            }
          };
        });
    }
  }

  @Watch('breakpoint', { deep: true })
  private updateBreakpoints(): void {
    this.reloadSlide = true;
    this.reloadPrimarySlide += 1;
    this.reloadSecondarySlide += 10;
    this.syncSlides();
  }

  private syncSlides(): void {
    if (this.reloadSlide && this.$refs.primary && this.$refs.secondary) {
      const primary = this.$refs.primary as Vue & { sync: (splide: object) => void };
      const secondary = this.$refs.secondary as Vue & { splide: object };
      primary.sync(secondary.splide);
      this.reloadSlide = false;
    }
  }

  private updated(): void {
    this.syncSlides();
  }

  private mounted(): void {
    this.syncSlides();
  }

  private canShowEmptyBannerSection(type: string): boolean {
    switch (type) {
      case 'LargeProduct':
      case 'Product':
        return this.slides.length > 0;

      case 'Article':
        if (this.type === 'magazineArticle') {
          return this.banners.length > 0;
        }
        return false;
      case 'Session':
      case 'SubEdition':
      case 'Deal':
        return !!this.logoSrc || !!this.bannerSrc;
      case 'Channel':
        return !!this.logoSrc || !!this.bannerSrc;

      case 'Exhibitor':
      case 'CommunityUser':
      case 'Speaker':
        return true;

      default:
        break;
    }
    return false;
  }

  private openModal(): void {
    this.$bvModal.show('edit-user-avatar');
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
