




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedDocumentCard extends Vue {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Prop({ required: false })
  private name!: string;

  @Prop({ required: false })
  private handoutFileResource!: FileResource;

  @Prop({ required: false, default: null })
  private readonly relatedEntity!: { uid: string; entityType: string };

  // eslint-disable-next-line class-methods-use-this
  private get size(): string {
    let fileSize: string | null = '';
    const http = new XMLHttpRequest();
    http.open('HEAD', FileResourceHelper.getFullPath(
        this.handoutFileResource as unknown as FileResource,
        undefined,
        undefined,
        true,
    ), false);
    http.send(null);

    if (http.status === 200) {
      fileSize = http.getResponseHeader('content-length');
    }

    const parsedFileSize = parseInt(fileSize as string, 0);
    if (parsedFileSize) {
      const megabyte = 1000000;
      const kilobyte = 1000;
      if (parseInt(fileSize as string, 0) >= megabyte) {
        return `${(parsedFileSize - (parsedFileSize % megabyte)) / megabyte} mb`;
      }
      return `${(parsedFileSize - (parsedFileSize % kilobyte)) / kilobyte} kb`;
    }
    return '0 kb';
  }

  private showPreview(): void {
    if (this.handoutFileResource) {
      window.open(FileResourceHelper.getFullPath(
        this.handoutFileResource as unknown as FileResource,
        undefined,
        undefined,
        true,
      ), '_blank');
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.relatedEntity.entityType,
        StatLoggerActions.VIEW,
        'viewHandout',
        -1,
        this.relatedEntity.uid,
        StatLoggerCategories.HANDOUT,
        this.$i18n.locale,
      );
    }
  }
}
