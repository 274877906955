var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay && _vm.session)?_c('div',{staticClass:"session-cms-page-header container-fluid position-relative"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[(_vm.session && _vm.session.name && !_vm.isMobile)?_c('h3',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.session.name)+" ")]):_vm._e(),(_vm.session && _vm.session.name && _vm.isMobile)?_c('h5',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.session.name)+" ")]):_vm._e(),_c('button-icon-component',{staticClass:"ml-6",attrs:{"size":_vm.isMobile ? 'sm' : 'lg',"variant":"tertiary"},on:{"on-click":_vm.onViewDetailPageClick},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-16' : 'icon-24',"icon-classes":_vm.isMobile ? 'icon-12' : 'icon-16',"icon":"far fa-arrow-up-right-from-square"}})]},proxy:true}],null,false,2166503618)})],1),_c('div',{staticClass:"d-flex align-items-center mt-2 mx-0 justify-content-between"},[(_vm.subtitle && !_vm.isMobile)?_c('p',{staticClass:"text-neutral-n-6-label text-left m-0"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.subtitle && _vm.isMobile)?_c('span',{staticClass:"text-neutral-n-6-label text-left m-0 label label-4 medium"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SESSION_RATING).enabled)?_c('div',{class:[
            'd-flex',
            {'column-gap-2': _vm.isMobile},
            {'column-gap-4': !_vm.isMobile}
          ]},_vm._l((_vm.ratingsData),function(item){return _c('div',{key:item.icon,staticClass:"d-flex column-gap-1 align-items-center"},[_c('font-awesome-component',{attrs:{"container-classes":_vm.isMobile ? 'icon-12' : 'icon-16',"icon":("fa-solid fa-" + (item.icon)),"icon-classes":_vm.isMobile
                ? 'text-neutral-n-8-dark-ink icon-8'
                : 'text-neutral-n-6-label icon-16'}}),(!_vm.isMobile)?_c('p',{staticClass:"text-neutral-n-6-label m-0"},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e(),(_vm.isMobile)?_c('span',{staticClass:"text-neutral-n-8-dark-ink text-left m-0 label label-4 medium"},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e()],1)}),0):_vm._e()])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }