






























import { Component, Prop, Vue } from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import PillWidget from '@/components/pill/PillWidget.vue';
import SessionCardWidget from '@/components/cards/SessionCardWidget.vue';

@Component({
  components: { SessionCardWidget, PillWidget },
})
export default class SessionsCalendarRowComponent extends Vue {
  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBorder!: boolean;

  @Prop({ required: false, default: true })
  readonly displayLiveBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayInterestedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displaySpeakers!: boolean;

  @Prop({ required: true, default: null })
  private readonly time!: string;

  @Prop({ required: true, default: () => [] })
  private readonly sessions!: Session[];

  @Prop({ required: true, default: null })
  private readonly cardRoute!: string;
}
