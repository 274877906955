



































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import InputText from '@/components/InputText.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FileType from '@/utils/enums/FileType';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    InputText,
  },
})
export default class VideoSelectorComponent extends BreakpointWrapper {
  @Prop({ required: false, default: true })
  private hasName!: boolean;

  @Prop({ required: false, default: '' })
  private defaultName!: string;

  @Prop({ required: false, default: '' })
  private defaultUrl!: string;

  @Prop({ required: false, default: 'url' })
  private defaultSelection = 'url';

  @Prop({ default: false })
  private disabled!: boolean;

  private name = this.defaultName;

  private selected = this.defaultSelection;

  private url = this.defaultUrl;

  private fileType = `${FileType.MP4},${FileType.WEBM}`;

  private file: File | undefined | null = null;

  private videoPreviewHeight = '0';

  private sherpaBaseVideoUrl: string = process.env.VUE_APP_S3_VIDEO_AMAZONAWS ?? '';

  private isFirstLoad = true;

  private get invalidUrl(): boolean {
    return !RouteHelper.isUrlValid(this.url);
  }

  mounted(): void {
    this.initFirstLoad();
  }

  @Watch('defaultUrl')
  public initFirstLoad(): void {
    this.url = this.defaultUrl;
    if (this.defaultUrl && this.defaultUrl.startsWith(this.sherpaBaseVideoUrl)) {
      this.isFirstLoad = true;
      this.selected = 'upload';
      this.file = null;
      this.hideVideoPreview();
      this.createFile();
    } else {
      this.isFirstLoad = false;
      this.file = null;
      this.selected = 'url';
      this.hideVideoPreview();
    }
  }

  createFile(): void {
    const request = new XMLHttpRequest();
    request.open('GET', this.defaultUrl, true);
    request.responseType = 'blob';
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = async (e) => {
        if (e && e.target) {
          const arr = ((e.target.result as string) || '').split(',');
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const mime = arr[0].match(/:(.*?);/)![1];

          const response = await fetch(e.target.result as string);
          const blob = await response.blob();
          this.file = new File([blob], 'video', { type: mime });
          this.setFilePreview();
        }
      };
    };
    request.send();
  }

  @Watch('defaultName')
  private onNameChange(): void {
    this.name = this.defaultName;
  }

  @Watch('selected')
  private onSelectionChange(): void {
    if (this.selected === 'url') {
      this.isFirstLoad = false;
      this.file = null;
      this.hideVideoPreview();
    }
  }

  @Watch('name')
  @Watch('url')
  @Watch('selected')
  @Watch('file')
  private onChange(): void {
    if (!this.isFirstLoad) {
      this.$emit('on-change', {
        selected: this.selected,
        url: this.url,
        file: this.file,
        ...(this.hasName ? {
          name: this.name,
        } : {}),
      });
    }
  }

  @Watch('url')
  private onUrlChange(): void {
    this.$emit('url-change', this.url);
  }

  private onFocusOut(): void {
    if (!this.isFirstLoad) {
      this.$emit('on-focus-out', {
        selected: this.selected,
        url: this.url,
        ...(this.hasName ? {
          name: this.name,
        } : {}),
      });
    }
  }

  private onFileUpload(): void {
    this.isFirstLoad = false;
    this.setFilePreview();
  }

  private setFilePreview(): void {
    if (this.file) {
      const blobURL = URL.createObjectURL(this.file);
      const videoEl = document.getElementById('video-preview');
      if (videoEl) {
        this.videoPreviewHeight = '180';
        videoEl.style.visibility = 'visible';
        (videoEl as HTMLVideoElement).src = blobURL;
      }
    }
  }

  private onDeleteVideoClick(): void {
    this.file = null;
    this.url = '';
    this.hideVideoPreview();
    this.$emit('on-delete-video');
  }

  private hideVideoPreview(): void {
    const videoEl = document.getElementById('video-preview');
    if (videoEl) {
      this.videoPreviewHeight = '0';
      videoEl.style.visibility = 'hidden';
      (videoEl as HTMLVideoElement).src = '';
    }
  }
}
