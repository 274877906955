





































































































































































/* eslint-disable no-underscore-dangle */
import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import InputText from '@/components/InputText.vue';
import { namespace } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import Channel from '@/models/graphql/Channel';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import GroupType from '@/utils/enums/chat/GroupType';
import ActivationStateType from '@/utils/enums/ActivationState';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const communityUserFollowStore = namespace('CommunityUserFollowStore');
const communityUserConnectionStore = namespace('CommunityUserConnectionStore');
const communityUserChatStore = namespace('CommunityUserChatStore');

@Component({
  data(): object {
    return {
      reason: '',
      reasonOtherText: '',
    };
  },
  components: {
    ButtonIconComponent,
    StandardModal,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class FeedItemReportFurtherActionModal extends VueBaseWidget {
  @Prop({ default: '' })
  private modalId!: string;

  @Prop({ required: false })
  private feedItemWrapper!: FeedItemWrapper;

  @feedItemWrapperStore.Action
  private hidePost!: (payload: { userUid: string; wrapperUid: string }) => void;

  @communityUserFollowStore.Action
  private unFollowEntity!: (payload: { userId: string; entityId: string }) => Promise<void>;

  @communityUserConnectionStore.Action
  private fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserChatStore.Action
  private blockCommunityUsers!: (users: CommunityUser[]) => Promise<void>;

  @feedItemWrapperStore.Getter
  private fetchFeedInitiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  private activationStateType = ActivationStateType;

  private hidingState: ActivationStateType = ActivationStateType.ACTIVE;

  private followingState: ActivationStateType = ActivationStateType.ACTIVE;

  private blockingState: ActivationStateType = ActivationStateType.ACTIVE;

  private connectionState: ActivationStateType = ActivationStateType.ACTIVE;

  private connected: CommunityUserConnection | null = null

  private hasConnection = false;

  private initiator: CommunityUser | null = null;

  private onShowModal(): void {
    if (this.feedItemWrapper && this.feedItemWrapper.initiator
      && (this.feedItemWrapper.initiator as CommunityUser)._ourConnection !== null
      // eslint-disable-next-line max-len
      && ((this.feedItemWrapper.initiator as CommunityUser)._ourConnection as CommunityUserConnection).linkState === ConnectionStatusType.ACCEPTED
    ) {
      this.hasConnection = true;
    }
  }

  private clickHidePost(): void {
    if (this.hidingState === ActivationStateType.ACTIVE) {
      this.hidingState = ActivationStateType.INACTIVE;
    }
  }

  private unfollowUser(): void {
    if (this.followingState === ActivationStateType.ACTIVE) {
      this.followingState = ActivationStateType.LOADING;
      this.unFollowEntity({
        userId: this.authUser.uid,
        entityId: this.feedItemWrapper.initiator?.uid as string,
      })
        .then(() => {
          this.followingState = ActivationStateType.INACTIVE;
        });
    }
  }

  private blockUser(): void {
    if (this.blockingState === ActivationStateType.ACTIVE) {
      this.blockingState = ActivationStateType.LOADING;
      this.initiator = this.feedItemWrapper.initiator as CommunityUser;
      this.blockCommunityUsers([CommunityUser.hydrate({ uid: this.initiator.uid })])
        .then(() => {
          this.blockingState = ActivationStateType.INACTIVE;
        });
    }
  }

  private disconnectUser(): void {
    if (this.connectionState === ActivationStateType.ACTIVE) {
      this.connectionState = ActivationStateType.LOADING;
      this.initiator = this.feedItemWrapper.initiator as CommunityUser;
      const auth = (({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }) => ({
        uid,
        firstName,
        lastName,
        pictureFileResource,
      }))(this.authUser) as CommunityUser;
      this.connected = null;
      const temp = (this.initiator as CommunityUser)._ourConnection as CommunityUserConnection;
      this.connected = null;
      const user = this.initiator as CommunityUser;
      this.fullyDisconnect({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.initiator.uid,
            firstName: user.firstName,
            lastName: user.lastName,
            pictureFileResource: user.pictureFileResource,
          } as CommunityUser],
          groupType: GroupType.DIRECT,
        },
        toastUserFullName: `${user.firstName} ${user.lastName}`,
      })
        .then(() => {
          this.connected = null;
          this.connectionState = ActivationStateType.INACTIVE;
        })
        .catch(() => {
          this.connectionState = ActivationStateType.ACTIVE;
          this.connected = temp;
        });
    }
  }

  private onDiscard(): void {
    if (this.hidingState === ActivationStateType.INACTIVE) {
      this.hidePost({ userUid: this.authUser.uid, wrapperUid: this.feedItemWrapper.uid });
    }
    this.$bvModal.hide(this.modalId);
  }
}
