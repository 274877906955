










































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RadioInput extends Vue {
@Prop({ default: false })
private value!: boolean
}
