



































import { Component, Prop, Vue } from 'vue-property-decorator';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class TagListItem extends Vue {
  @Prop({
    required: true,
    default: null,
  })
  private tag!: CommunityUserTag;

  @Prop({
    required: false,
    default: false,
  })
  private selected!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private showCount!: boolean;
}
