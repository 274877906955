import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const SPEAKER_BASE_FRAGMENT = `
  fragment speakerBaseFragment on Speaker {
    uid
    firstName
    lastName
    name
    bio
    prefix
    suffix
    jobTitle
    employerName
    homepageUrl
    twitterUrl
    facebookUrl
    instagramUrl
    pinterestUrl
    photoFileResource {
      ...fileResourceBaseFragment
    }
    user {
      ...communityUserBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
