import { EXHIBIT_HALL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitHall/Base';

export const GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT = `
  fragment geozoneWithExhibitHallFragment on Geozone {
    uid
    schemaCode
    id
    name
    shortName
    exhibitHall {
      ...exhibitHallBaseFragment
    }
  }
  ${EXHIBIT_HALL_BASE_FRAGMENT}
`;
