import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const COMMUNITY_USER_TAG_BASE_FRAGMENT = `
  fragment communityUserTagBaseFragment on CommunityUserTag {
    uid
    schemaCode
    name
    color
    _linkCount
    user {
      ...communityUserBaseFragment
    }
    links {
      uid
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
`;
