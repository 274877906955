




























































































































import { Component, Prop } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import Order from '@/models/graphql/Order';
import OrderStatus from '@/utils/enums/OrderStatus';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format } from 'date-fns';
import FormatHelper from '@/utils/helpers/FormatHelper';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const localStorageCartStore = namespace('LocalStorageCartStore');

@Component({
  components: { ButtonIconComponent },
})
export default class OrderCardComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @State
  private dateLocale!: Locale;

  @Prop({
    required: true,
    default: null,
  })
  private order!: Order;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  @localStorageCartStore.Action
  private setCartWithData!: (payload: {cart: Order; cartUid: string}) => string;

  private orderStatus = OrderStatus;

  mounted(): void {
    if (this.order) {
      this.setCart(this.order.exhibitor?.uid as string);
    }
  }

  private get getOrderStatus(): string {
    return this.$t(`sales.order.status.${this.order.status}`).toString();
  }

  private get orderCreationTime(): string {
    if (!this.order.creationTime) return '';
    return format(
      DateTimeHelper.toLocal(new Date(this.order.creationTime)),
      `${this.$t('app.date.defaultDateFormat')}`, { locale: this.dateLocale },
    );
  }

  private invoiceClick(order: Order): void {
    const salesInvoiceUrl = this.$router.resolve({
      name: 'sales-invoice', params: { orderId: order.uid as string },
    }).href;
    window.open(salesInvoiceUrl, '_blank');
  }

  private orderDetailClick(order: Order): void {
    this.$router.push({
      name: 'order-detail',
      params: { orderId: order.uid as string, companyId: this.order.exhibitor?.uid || '' },
    });
  }

  private mainButtonClick(order: Order): void {
    if (!order.exhibitor) return;
    if (order.status === OrderStatus.PENDING || order.status === OrderStatus.WAITING_FOR_PAYMENT) {
      if (!order.paymentIntentId) {
        this.generatePaymentIntentId(order);
      }
      this.$router.push({
        name: 'company-cms-checkout',
        params: {
          companyId: order.exhibitor.uid,
          orderId: order.uid,
        },
      });
    } else {
      this.$router.push({
        name: 'company-cms-cart',
        params: {
          companyId: this.setCartWithData({
            cart: order,
            cartUid: order.exhibitor.uid,
          }),
        },
      });
    }
  }

  private generatePaymentIntentId(order: Order): void {
    if (CookieService.getCookie(AUTH_TOKEN) === null) {
      this.failSentry('User not login:', 'CookieService failed');
    } else {
      const uploadUrl: string = process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL ?? '';
      const stripeIntentUrl = `${uploadUrl}stripe/create-payment-intent?orderUid=${order.uid}`;
      const request = new XMLHttpRequest();
      request.open('POST', stripeIntentUrl);
      request.setRequestHeader('jwt-token', CookieService.getCookie(AUTH_TOKEN) as string);
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          const response = JSON.parse(request.responseText);
          if (!response.paymentIntentId) {
            this.failSentry('Stripe generate PaymentIntentId', 'Id not return or failed');
          }
        } else {
          this.failSentry('Request failed in OrderCardComponent:', request.statusText);
        }
      };

      request.onerror = () => {
        this.failSentry('Stripe generate PaymentIntentId', 'Network error occurred');
      };
      request.send();
    }
  }

  private get totalPrice(): string {
    return FormatHelper.formatCurrency((this.order.totalPrice || 0) / 100 || 0);
  }

  private get orderNumber(): string {
    return FormatHelper.formatOrderNumber(this.order.id || 0);
  }

  private failSentry(process = '', failMessage = ''): void {
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Error);
      Sentry.captureException(`Process: ${process} | Message: ${failMessage}`
      || `They was an error with stripe in the ${this.widget?.type} widget with id ${this.widget?.id}`);
    });
  }
}
