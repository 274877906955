var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('standard-modal',{staticClass:"tag-create-add-modal",attrs:{"modal-id":_vm.modalId,"size":"lg"},on:{"on-show-modal":function($event){return _vm.onShow()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-3 search-section"},[_c('div',{staticClass:"w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"search",staticClass:"search-field text-neutral-n-8-dark-ink w-100 border-0\n              bg-transparent py-0 paragraph large",attrs:{"placeholder":_vm.$t('entity-tags.search-placeholder'),"autofocus":"autofocus","type":"text"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value},_vm.onSearch]}})]),(_vm.canCreateNewTag && !!_vm.searchQuery.trim())?_c('button-component',{attrs:{"disabled":_vm.isLoading,"text":("" + (_vm.$t('tags.create-modal.create-button'))),"size":"md","variant":"white-blue"},on:{"on-click":function($event){return _vm.$bvModal.show('create-tag-modal')}}}):(_vm.searchQuery.length > 0)?_c('font-awesome-component',{staticClass:"cursor-pointer",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-xmark","icon-classes":"icon-24 text-neutral-n-6-label"},on:{"on-click":function($event){_vm.searchQuery = ''; _vm.onSearch()}}}):_vm._e()],1),((_vm.localTagList && _vm.localTagList.length > 0) || _vm.searchQuery.length > 0 || (_vm.isLoading && _vm.searchQuery.length > 0))?_c('div',{staticClass:"d-flex flex-column row-gap-4 shadows-line-up p-3"},[_c('span',{staticClass:"text-left caps caps-4 bold m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('entity-tags.selected-tags'))+" ")]),(_vm.localSelectedList.length)?_c('div',{class:[
              'd-flex row-gap-2 mt-2 my-selected-list hide-scrollbar',
              {'flex-wrap': !_vm.isMobile},
              {'selected-tags-sm hide-scrollbar': _vm.isMobile}
            ]},_vm._l((_vm.localSelectedList),function(tag){return _c('div',{key:tag.uid},[_c('tag-component',{staticClass:"mr-2",attrs:{"clearable":true,"text":tag.name},on:{"on-clear":function($event){return _vm.toggleTagItem(tag)}}})],1)}),0):_c('p',{staticClass:"m-0 text-neutral-n-5-placeholder text-left"},[_vm._v(" "+_vm._s(_vm.$t('entity-tags.no-selected-tags'))+" ")]),_c('div',{staticClass:"d-flex"},[(_vm.hasShowMoreButton)?_c('button-component',{attrs:{"no-border":true,"text":_vm.showMore
                ? ("" + (_vm.$t('entity-tags.show-less-button')))
                : ("" + (_vm.$t('entity-tags.show-more-button'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onShowMoreButtonClick},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"icon":_vm.showMore ? 'fa-regular fa-angle-up' : 'fa-regular fa-angle-down',"container-classes":"icon-24","icon-classes":"icon-16 text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,1398234264)}):_vm._e()],1)]):_vm._e()])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"section d-flex flex-column h-100"},[[(_vm.localTagList.length > 0)?_c('span',{staticClass:"text-left caps caps-4 bold m-0 px-3 pt-3 pb-2 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('entity-tags.all-my-tags'))+" ")]):_vm._e(),_c('div',{staticClass:"w-100 d-flex flex-column tag-list hide-scrollbar",class:[
              {'h-100': _vm.localTagList.length > 0}
            ]},[_vm._l((_vm.localTagList),function(tag,key){return [_c('tag-list-item',{key:key,attrs:{"selected":_vm.isTagSelected(tag),"tag":tag},on:{"on-click":function($event){return _vm.toggleTagItem(tag)}}})]}),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"identifier":_vm.renderTagList},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"spinner",fn:function(){return [_c('loading-spinner-component',{staticClass:"py-4"})]},proxy:true},{key:"no-results",fn:function(){return [_c('div')]},proxy:true},{key:"no-more",fn:function(){return [_c('div')]},proxy:true}])})],2)],(_vm.localTagList && _vm.localTagList.length === 0 && _vm.renderTagList != 0 && !_vm.isLoading)?_c('div',{staticClass:"w-100 h-100 d-flex flex-column align-items-center justify-content-center"},[_c('p',{staticClass:"m-0 font-weight-bold text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.searchQuery.length ? _vm.$t('entity-tags.search-no-result.title') : _vm.$t('entity-tags.no-tags.title'))+" ")]),_c('p',{staticClass:"m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.searchQuery.length ? _vm.$t('entity-tags.search-no-result.subtitle') : _vm.$t('entity-tags.no-tags.subtitle'))+" ")]),_c('div',{staticClass:"d-flex mt-2"},[_c('button-component',{attrs:{"text":_vm.searchQuery.length
                ? ("" + (_vm.$tc('entity-tags.search-no-result.create', 0, {tagName: _vm.searchQuery})))
                : _vm.$t('entity-tags.no-tags.button'),"size":"md","variant":"white-blue"},on:{"on-click":function($event){return _vm.$bvModal.show('create-tag-modal')}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"container-classes":"icon-24","icon":"fa-regular fa-plus","icon-classes":"icon-16 text-blue-b-3-primary-blue"}})]},proxy:true}],null,false,960683401)})],1)]):_vm._e()],2)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{class:[ 'd-flex column-gap-2 align-self-end justify-content-end',
                  {'w-100 flex-row': _vm.isMobile}]},[_c('div',[_c('button-component',{attrs:{"text":("" + (_vm.$t('tags.add-modal.buttons.cancel'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onCancel}})],1),_c('div',[_c('button-component',{attrs:{"disabled":_vm.isSaveBtnDisable || _vm.saveNewTagLoading,"loading":_vm.saveLoading,"text":("" + (_vm.$t('tags.add-modal.buttons.save'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onConfirm}})],1)])]},proxy:true}])}),_c('create-rename-tag-modal',{attrs:{"is-create":true,"tag-name":_vm.searchQuery.trim(),"modal-id":"create-tag-modal"},on:{"tag-created":_vm.createNewTag}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }