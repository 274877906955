

































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import TranslateResult = VueI18n.TranslateResult;

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
  },
})
export default class MessageButtonPage extends Vue {
  @Prop({ required: false, default: '' })
  private readonly pageType!: string;

  private get pageTitle(): TranslateResult | string {
    switch (this.pageType) {
      case 'find-account-activated':
        return this.$t('app.global.find-account.activated.title');
      case 'find-account-error':
        return this.$t('app.global.find-account.activation-error.title');
      case 'password-success':
        return this.$t('app.global.reset-password.success.title');
      case 'password-expired':
        return this.$t('app.global.reset-password.expired.title');
      default:
        return '';
    }
  }

  private get pageSubtitle(): TranslateResult {
    switch (this.pageType) {
      case 'find-account-activated':
        return this.$t('app.global.find-account.activated.subtitle');
      case 'find-account-error':
        return this.$t('app.global.find-account.activation-error.subtitle');
      case 'password-success':
        return this.$t('app.global.reset-password.success.subtitle');
      case 'password-expired':
        return this.$t('app.global.reset-password.expired.subtitle');
      default:
        return '';
    }
  }

  private get buttonText(): TranslateResult {
    switch (this.pageType) {
      case 'find-account-activated':
        return this.$t('app.global.find-account.activated.button');
      case 'find-account-error':
        return this.$t('app.global.find-account.activation-error.button');
      case 'password-success':
        return this.$t('app.global.reset-password.success.btnText');
      case 'password-expired':
        return this.$t('app.global.reset-password.expired.btnText');
      default:
        return '';
    }
  }

  private onButtonClick(): void {
    switch (this.pageType) {
      case 'find-account-activated':
        this.$emit('button-click');
        break;
      case 'find-account-error':
        this.$emit('button-click');
        break;
      case 'password-success':
        this.$router.push({ name: 'login' });
        break;
      case 'password-expired':
        this.$router.push({ name: 'reset-password-email' });
        break;
      default:
        break;
    }
  }
}
