










































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import DateRadioFieldComponent from '@/components/DateRadioFieldComponent.vue';
import { Getter, namespace, State } from 'vuex-class';
import { format } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Deal from '@/models/graphql/Deal';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import FileResource from '@/models/graphql/FileResource';
import UploadFileHandler from '@/utils/UploadFileHandler';
import Community from '@/models/graphql/Community';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import DealProductLinkSelectorComponent from '@/components/DealProductLinkSelectorComponent.vue';
import { ProductFilter } from '@/graphql/_Filters/ProductFilter';
import Product from '@/models/graphql/Product';
import Exhibitor from '@/models/graphql/Exhibitor';
import { LargeProductFilter } from '@/graphql/_Filters/LargeProductFilter';
import LargeProduct from '@/models/graphql/LargeProduct';
import { Data } from '@/utils/types/WidgetData';
import LocaleModel from '@/models/LocaleModel';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';

const productStore = namespace('ProductStore');
const largeProductStore = namespace('LargeProductStore');
const fileResourceStore = namespace('FileResourceStore');
const dealStore = namespace('DealStore');
const toastStore = namespace('ToastStore');
const exhibitorStore = namespace('ExhibitorStore');

@Component({
  data(): object {
    return {
      name: '',
      oldCategories: [],
      newCategories: [],
      oldProductLink: '',
      newProductLink: '',
      content: '',
      oldDisplayFileResource: null,
      newDisplayFileResource: null,
      startTime: null,
      endTime: null,
    };
  },
  components: {
    UploadAssetComponent,
    DateRadioFieldComponent,
    CategorySelectorComponent,
    DealProductLinkSelectorComponent,
    TextAreaComponent,
    InputText,
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class PromotionEditModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly promotion!: Deal;

  @Prop({ required: false, default: '' })
  private readonly fields!: string;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @productStore.Action
  private loadProducts!: (filter: ProductFilter) => Promise<Product[]>;

  @largeProductStore.Action
  private loadLargeProducts!: (filter: LargeProductFilter) => Promise<LargeProduct[]>;

  @dealStore.Action
  private editPromotion!: (payload: {
    exhibitorId: string;
    promotion: object;
  }) => Promise<Deal | undefined>;

  @dealStore.Action
  private updatePromotionByLocale!: (payload: {
    promotion: {
      uid: string;
      translations: Record<string, string>;
    };
  }) => Promise<Deal | undefined>;

  @dealStore.Action
  private updatePromotionCategories!: (payload: { promotion: object }) => Promise<void>;

  @dealStore.Action
  private updatePromotionLinksProductAndLargeProduct!: (payload: { promotion: object }) => Promise<Deal | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private createDisplayLogoFileResourceForDeal!: (data: {
    uid: string;
    uploadToken: string;
    fileName: string;
    fileType: string;
  }) => Promise<FileResource | undefined>;

  @State
  private readonly locales!: LocaleModel[];

  private promotionToEdit: Deal | null = null;

  private updatedTranslations: Record<string, string> = {};

  private isSaveBtnDisabled = true;

  private FileType = FileType;

  private listProductAndLargeProduct: Data[] = [];

  private localProductList: Product[] = [];

  private fieldTable: string[] = [];

  private saveLoading = false;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint && ['xs', 'sm'].includes(this.currentBootstrapBreakpoint);
  }

  private get nameTranslations(): EntityTranslationParams[] {
    if (this.promotionToEdit && this.promotionToEdit.uid !== '' && this.locales && this.locales.length > 1) {
      return this.promotionToEdit.translationsByField('title');
    }
    return this.locales ? this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'title',
    })) : [];
  }

  private get descriptionTranslations(): EntityTranslationParams[] {
    if (this.promotionToEdit && this.promotionToEdit.uid !== '' && this.locales && this.locales.length > 1) {
      return this.promotionToEdit.translationsByField('content');
    }
    return this.locales ? this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'content',
    })) : [];
  }

  created(): void {
    this.updatePromotion();
    this.createListProductAndLargeProduct();
  }

  @Watch('promotion')
  private updatePromotion(): void {
    this.promotionToEdit = Deal.hydrate(this.promotion);
    this.setData();
  }

  private createListProductAndLargeProduct(): void {
    if (!this.adminPanelExhibitor.uid) return;
    this.loadProducts({
      exhibitor: {
        uid: this.adminPanelExhibitor.uid,
      },
    }).then((products) => {
      if (products) {
        this.localProductList = products;
        this.listProductAndLargeProduct = this.listProductAndLargeProduct.concat(products as unknown as Data[]);
      }
    });
    this.loadLargeProducts({
      exhibitor: {
        uid: this.adminPanelExhibitor.uid,
      },
    }).then((largeProducts) => {
      if (largeProducts) {
        this.listProductAndLargeProduct = this.listProductAndLargeProduct.concat(largeProducts as unknown as Data[]);
      }
    });
  }

  private setDisplayFileResource(file: { readerResult: string; fileName: string } | null): void {
    this.$data.newDisplayFileResource = file;
  }

  private setData(): void {
    this.$data.name = '';
    this.$data.content = '';
    this.$data.startTime = null;
    this.$data.endTime = null;
    this.$data.oldDisplayFileResource = null;
    this.$data.newDisplayFileResource = null;
    this.$data.newCategories = [];
    this.$data.oldCategories = [];
    this.$data.newProductLink = '';
    this.$data.oldProductLink = '';
    this.isSaveBtnDisabled = true;

    if (this.fields) {
      this.fieldTable = this.fields.split(',');
    }

    if (this.promotionToEdit) {
      this.$data.name = this.promotionToEdit.name;
      this.$data.content = this.promotionToEdit.content;
      this.$data.startTime = this.promotionToEdit.startTime;
      this.$data.endTime = this.promotionToEdit.endTime;
      this.$data.newDisplayFileResource = this.promotionToEdit.displayFileResource;
      this.$data.oldDisplayFileResource = this.promotionToEdit.displayFileResource;
      this.$data.newCategories = this.promotionToEdit.categories
        ? [...this.promotionToEdit.categories]
        : [];
      this.$data.oldCategories = this.promotionToEdit.categories
        ? [...this.promotionToEdit.categories]
        : [];
      // eslint-disable-next-line no-nested-ternary
      this.$data.newProductLink = this.promotionToEdit.product
        ? this.promotionToEdit.product.uid
        : this.promotionToEdit.largeProduct
          ? this.promotionToEdit.largeProduct.uid
          : '';
      // eslint-disable-next-line no-nested-ternary
      this.$data.oldProductLink = this.promotionToEdit.product
        ? this.promotionToEdit.product.uid
        : this.promotionToEdit.largeProduct
          ? this.promotionToEdit.largeProduct.uid
          : '';
      this.isSaveBtnDisabled = !this.promotionToEdit.name || this.promotionToEdit.name === '';
    }
  }

  private onCategoryUpdate(payload: {
    ids?: string[];
  }): void {
    this.$data.newCategories = payload.ids;
  }

  private onLinkUpdate(payload: {
    id?: string | null;
  }): void {
    this.$data.newProductLink = payload.id;
  }

  @Watch('$data.name')
  private nameFieldUpdate(): void {
    this.isSaveBtnDisabled = !this.$data.name || this.$data.name === '';
  }

  private onPublicationTimeChange(date: Date | null): void {
    this.$data.startTime = date || new Date();
  }

  private onEndPublicationTimeChange(date: Date | null): void {
    this.$data.endTime = date;
  }

  private fieldEnable(field: string): boolean {
    if (this.fieldTable.length > 0) {
      return this.fieldTable.includes(field);
    }
    return true;
  }

  private updateTranslations(field: string, translation: EntityTranslationParams): void {
    if (this.locales && this.locales.length > 1 && this.promotionToEdit) {
      let oldTranslations: EntityTranslationParams[] = [];
      if (field === 'name') {
        oldTranslations = this.promotionToEdit.translationsByField('title');
        if (translation.locale === this.$i18n.locale) {
          this.$data.name = translation.value || '';
        }
      }
      if (field === 'description') {
        oldTranslations = this.promotionToEdit.translationsByField('content');
        if (translation.locale === this.$i18n.locale) {
          this.$data.content = translation.value || '';
        }
      }
      const trans = oldTranslations.find((oldTrans) => translation.locale === oldTrans.locale);
      if (!trans || trans.value !== translation.value) {
        Object.assign(this.updatedTranslations, { [`${translation.field}-${translation.locale}`]: translation.value });
      } else {
        delete this.updatedTranslations[`${translation.field}-${translation.locale}`];
      }
    }
  }

  private onConfirm(): void { // Update
    this.saveLoading = true;
    let uid = '';
    const startTime = this.$data.startTime
      ? format(new Date(this.$data.startTime), DateTimeHelper.TIME_FORMAT_ISO_8601)
      : format(DateTimeHelper.toUTC(new Date()), DateTimeHelper.TIME_FORMAT_ISO_8601);
    const endTime = this.$data.endTime
      ? format(new Date(this.$data.endTime), DateTimeHelper.TIME_FORMAT_ISO_8601)
      : null;
    const translations: Record<string, string> = {};
    Object.keys(this.updatedTranslations).forEach((key) => {
      if (!key.includes(this.$i18n.locale)) {
        Object.assign(translations, { [key]: this.updatedTranslations[key] || '' });
      }
    });
    const promotion = {
      uid: this.promotion ? this.promotion.uid : null,
      name: this.$data.name,
      title: this.$data.name,
      content: this.$data.content,
      startTime,
      endTime,
      oldCategories: this.$data.oldCategories,
      newCategories: this.$data.newCategories,
      oldProductLink: this.$data.oldProductLink,
      newProductLink: this.$data.newProductLink,
    };
    this.editPromotion({
      exhibitorId: this.exhibitorId,
      promotion,
    }).then((response) => {
      if (response) {
        if (response.uid) {
          uid = response.uid;
        }
        const promises: Promise<FileResource | undefined | void>[] = [];
        promises.push(this.updatePromotionByLocale({
          promotion: {
            uid,
            translations,
          },
        }));
        promises.push(this.updatePromotionCategories({
          promotion: {
            uid,
            oldCategories: promotion.uid ? promotion.oldCategories : [],
            newCategories: promotion.newCategories,
          },
        }));
        const isProduct = this.localProductList.find((obj) => obj.uid === promotion.newProductLink);
        promises.push(this.updatePromotionLinksProductAndLargeProduct({
          promotion: {
            PromotionUid: uid,
            ProductUid: (promotion.newProductLink) ? promotion.newProductLink : '',
            isProduct: !!(isProduct),
          },
        }));
        if (this.$data.newDisplayFileResource
          && this.$data.newDisplayFileResource.readerResult
          && this.$data.newDisplayFileResource.fileName) {
          UploadFileHandler.uploadFileResourceHandler({
            base64Path: this.$data.newDisplayFileResource.readerResult,
            fileName: this.$data.newDisplayFileResource.fileName,
            schemaCode: this.community?.code as string,
          }).then((request) => {
            request.onload = () => {
              if (request?.status !== 413) {
                const uploadToken = JSON.parse(request.response)?.uploadToken;
                if (uploadToken) {
                  if (this.$data.oldDisplayFileResource && this.$data.oldDisplayFileResource.uid) {
                    promises.push(this.deleteFileResource(this.$data.oldDisplayFileResource.uid));
                  }
                  promises.push(this.createDisplayLogoFileResourceForDeal({
                    uid,
                    uploadToken,
                    fileName: this.$data.newDisplayFileResource.fileName,
                    fileType: this.$data.newDisplayFileResource.fileType,
                  }));
                  Promise.all(promises)
                    .then(() => {
                      if (this.promotion) {
                        this.showToast(ToastActionType.UPDATE_DEAL);
                      } else {
                        this.showToast(ToastActionType.CREATE_DEAL);
                      }
                    }).then(() => {
                      this.saveLoading = false;
                      this.onSaveEnd();
                    });
                }
                return Promise.resolve(undefined);
              }
              return Promise.resolve(undefined);
            };
          });
        } else if (this.$data.newDisplayFileResource
          && !this.$data.newDisplayFileResource.readerResult
          && !this.$data.newDisplayFileResource.fileName
          && this.$data.oldDisplayFileResource
          && this.$data.oldDisplayFileResource.uid) {
          promises.push(this.deleteFileResource(this.$data.oldDisplayFileResource.uid));
          Promise.all(promises)
            .then(() => {
              if (this.promotion) {
                this.showToast(ToastActionType.UPDATE_DEAL);
              } else {
                this.showToast(ToastActionType.CREATE_DEAL);
              }
            }).then(() => {
              this.saveLoading = false;
              this.onSaveEnd();
            });
        } else {
          Promise.all(promises)
            .then(() => {
              if (this.promotion) {
                this.showToast(ToastActionType.UPDATE_DEAL);
              } else {
                this.showToast(ToastActionType.CREATE_DEAL);
              }
            }).then(() => {
              this.saveLoading = false;
              this.onSaveEnd();
            });
        }
      }
    });
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onDelete(): void {
    this.$emit('on-delete', {
      promotionId: this.promotion.uid,
    });
  }

  private onShow(): void {
    this.updatePromotion();
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
