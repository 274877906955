import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';

export const ADDRESS_BASE_FRAGMENT = `
  fragment addressBaseFragment on Address {
    uid
    schemaCode
    tag
    address1
    address2
    address3
    city
    state
    postalCode
    country {
      ...countryBaseFragment
    }
  }
  ${COUNTRY_BASE_FRAGMENT}
`;
