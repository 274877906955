










































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ChoiceParams from '@/utils/types/ChoiceParams';

@Component({
  components: { AvatarSoloWidget, FontAwesomeComponent },
})
export default class DropdownComponent extends Vue {
  @Prop({ required: false, default: null })
  private readonly selectedChoice!: ChoiceParams;

  @Prop({ required: false, default: 'Select an option' })
  private readonly placeholder!: string;

  @Prop({ required: false, default: '' })
  private readonly img!: string;

  @Prop({ required: false, default: false })
  private readonly useAvatar!: boolean;

  @Prop({ required: false, default: 'md' })
  private readonly size!: string;

  @Prop({ required: false, default: false })
  private readonly clearable!: boolean;

  @Prop({ required: false, default: false })
  private readonly hasError!: boolean;

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: false })
  private readonly isFullWidth!: boolean;

  @Prop({ required: true, default: null })
  private readonly choices!: ChoiceParams[];

  @Prop({ required: false, default: false })
  private readonly dropup!: boolean;

  private localSelectedChoice: ChoiceParams | null = null;

  mounted(): void {
    this.localSelectedChoice = this.selectedChoice;
  }

  @Watch('selectedChoice')
  private selectedChoiceChange(): void {
    this.localSelectedChoice = this.selectedChoice;
  }

  private onItemClick(choice: ChoiceParams): void {
    this.localSelectedChoice = choice;
    this.$emit('change', choice);
  }

  private onClear(): void {
    this.$emit('change', null);
  }
}
