var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"pill-container"},[_c('b-skeleton',{staticClass:"rounded-pill",attrs:{"height":_vm.small ? '1.5rem' : '2.5rem',"width":_vm.small ? '3rem' : '6rem',"type":"button"}})],1)]},proxy:true},{key:"default",fn:function(){return [(_vm.isAddTag)?_c('div',{class:[
        'add-pill-container cursor-pointer white',
        _vm.small ? 'add-tag-sm' : ''
      ],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('on-click')}}},[_c('font-awesome-component',{attrs:{"container-classes":[_vm.small ? 'icon-16' : 'icon-24'],"icon-classes":{ 'icon-12': _vm.small },"icon":"far fa-tags"}})],1):_c('div',{class:[
        'pill-container cursor-pointer white',
        _vm.small ? 'sm' : 'md'
      ],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('on-click')}}},[(_vm.text)?_c('p',{class:[
          'p-0 m-0 text-center text content-text no-wrap',
          _vm.small ? 'label label-3' : '' ]},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.clearable)?_c('font-awesome-component',{attrs:{"container-class":"clear icon-16","icon":"far fa-xmark","icon-classes":"icon-12"},on:{"on-click":function($event){return _vm.$emit('on-clear')}}}):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }