import { SPEAKER_BASE_FRAGMENT } from '@/graphql/_Fragments/Speaker/Base';

export const SPEAKER_ROLE_FOR_SESSION_FRAGMENT = `
  fragment speakerRoleForSessionFragment on SpeakerRole {
    uid
    schemaCode
    role
    disclosure
    displaySequence
    speaker {
      ...speakerBaseFragment
    }
  }
  ${SPEAKER_BASE_FRAGMENT}
`;
