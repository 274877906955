






















































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import CompanyResourceItem from '@/components/company/CompanyResourceItem.vue';
import ExhibitorContact from '@/models/graphql/ExhibitorContact';
import { namespace } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const exhibitorContactStore = namespace('ExhibitorContactStore');

@Component({
  components: {
    FontAwesomeComponent,
    CompanyResourceItem,
    ButtonIconComponent,
    StandardModal,
    ButtonComponent,
  },
  data() {
    return {
      isEdit: false,
      isContactResourceModalLoading: false,
      selectedContact: {},
      modifiedContact: {},
      contacts: [],
    };
  },
})
export default class CompanyResources extends BreakpointWrapper {
  @Prop({ required: true })
  protected readonly company!: Exhibitor;

  @Prop({ required: false })
  protected readonly contactUsLimit!: number;

  @Prop({ default: false })
  protected readonly disabled!: boolean;

  @exhibitorContactStore.Action
  private filterExhibitorContact!: (filter?: object) => void;

  @exhibitorContactStore.Action
  private createExhibitorContact!: (payload: {
    uid: string;
    exhibitorContact: ExhibitorContact;
  }) => Promise<ExhibitorContact | undefined>;

  @exhibitorContactStore.Action
  private updateExhibitorContact!: (exhibitorContact: ExhibitorContact) => Promise<ExhibitorContact | undefined>

  @exhibitorContactStore.Action
  private deleteExhibitorContact!: (uid: string) => void

  @exhibitorContactStore.Getter('fetchExhibitorContacts')
  private exhibitorContacts!: ExhibitorContact[];

  private get isDisabled(): boolean {
    return !(this.$data.modifiedContact.name || this.$data.modifiedContact.type
        || this.$data.modifiedContact.phone || this.$data.modifiedContact.fax
        || this.$data.modifiedContact.email)
      // eslint-disable-next-line max-len
      || (JSON.stringify(this.$data.modifiedContact) === JSON.stringify(this.$data.selectedContact));
  }

  @Watch('company.uid')
  getCompanyContacts(): void {
    if (this.company.uid) {
      this.filterExhibitorContact({
        exhibitor: {
          uid: this.company.uid,
        },
      });
    }
  }

  @Watch('exhibitorContacts')
  setExhibitorContacts(): void {
    this.$data.contacts = this.exhibitorContacts;
  }

  private mounted(): void {
    this.getCompanyContacts();
  }

  private openResourceModal(): void {
    this.$data.isEdit = false;
    this.$data.modifiedContact = {};
    this.$bvModal.show('company-resource-modal');
  }

  private onEdit(contact: ExhibitorContact): void {
    this.$data.isEdit = true;
    this.$data.selectedContact = contact;
    this.$data.modifiedContact = { ...contact };
    this.$bvModal.show('company-resource-modal');
  }

  private onDelete(index: number, uid: string): void {
    this.deleteExhibitorContact(uid);
    this.$data.contacts.splice(index, 1);
  }

  private onAdd(contact: ExhibitorContact): Promise<ExhibitorContact | undefined> {
    return this.createExhibitorContact({ uid: this.company.uid, exhibitorContact: contact });
  }

  private onUpdate(updatedContact: ExhibitorContact): Promise<ExhibitorContact | undefined> {
    const index = this.$data.contacts.findIndex((
      contact: ExhibitorContact,
    ) => contact.uid === updatedContact.uid);
    this.$data.contacts.splice(index, 1, updatedContact);
    return this.updateExhibitorContact(updatedContact);
  }

  private onCancel(): void {
    this.$bvModal.hide('company-resource-modal');
  }

  private onHide(): void {
    this.$data.modifiedContact = {};
    this.$data.selectedContact = {};
  }

  private onConfirm(): void {
    this.$data.isContactResourceModalLoading = true;
    const exhibitorContact: ExhibitorContact = {
      uid: this.$data.modifiedContact.uid,
      name: this.$data.modifiedContact.name,
      type: this.$data.modifiedContact.type,
      fax: this.$data.modifiedContact.fax,
      phone: this.$data.modifiedContact.phone,
      email: this.$data.modifiedContact.email,
      isPublic: true,
    };

    let confirmPromise: Promise<ExhibitorContact | undefined>;
    if (this.$data.isEdit) {
      confirmPromise = this.onUpdate(exhibitorContact);
    } else {
      confirmPromise = this.onAdd(exhibitorContact);
    }
    confirmPromise.then(() => {
      this.$emit('on-show-toast', ToastActionType.UPDATE_COMPANY_INFORMATION);
      this.$bvModal.hide('company-resource-modal');
    }).finally(() => {
      this.$data.isContactResourceModalLoading = false;
    });
  }
}
