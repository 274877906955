var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-bar-menu-container",on:{"click":_vm.closeHamburgerMenu}},[_c('div',{staticClass:"d-none d-xl-flex"},[(_vm.subMenu.length > 0)?_c('b-link',{class:['nav-bar-menu link d-flex justify-content-center align-items-center py-2 px-3',
               'rounded-sm text-decoration-none justify-content-center',
               {'active-link': _vm.isActive}],attrs:{"id":("sub-menu-" + _vm.uid)},on:{"click":function($event){return _vm.navigate();},"mouseleave":function($event){_vm.isMenuActive = false}}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),(_vm.subMenu.length !== 0)?_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-solid fa-caret-down","icon-classes":"icon-12"}}):_vm._e()],1),(_vm.subMenu.length > 0)?_c('div',{attrs:{"id":("nav-bar-menu-overlay-" + _vm.uid)}},[_c('b-popover',{ref:("sub-menu-" + _vm.uid),attrs:{"container":("nav-bar-menu-overlay-" + _vm.uid),"custom-class":("nav-bar-drop-menu bs-popover-bottom " + (_vm.subMenu.length > 5 ? 'two-col' : '')),"show":_vm.isMenuActive,"target":("sub-menu-" + _vm.uid),"boundary":"viewport","placement":"bottom","triggers":"click"},on:{"update:show":function($event){_vm.isMenuActive=$event}}},_vm._l((_vm.subMenu),function(menu){return _c('navbar-drop-item-component',_vm._b({key:menu.uid,on:{"close-hamburger-menu-from-sub":_vm.closeHamburgerMenuFromSub}},'navbar-drop-item-component',menu,false))}),1)],1):_vm._e()]):(_vm.internalUrl)?_c('b-link',{class:['nav-bar-menu link d-flex justify-content-center align-items-center py-2 px-3',
               'rounded-sm text-decoration-none justify-content-center',
               {'active-link': _vm.isActive}],attrs:{"to":_vm.internalUrl}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])]):_c('b-link',{class:['nav-bar-menu link d-flex justify-content-center align-items-center py-2 px-3',
               'rounded-sm text-decoration-none justify-content-center',
               {'active-link': _vm.isActive}],on:{"click":_vm.navigate}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])])],1),_c('div',{staticClass:"d-flex d-xl-none nav-bar-menu-mobile"},[(_vm.subMenu.length > 0)?_c('b-link',{class:['nav-bar-menu link d-flex flex-column align-items-start',
               'text-decoration-none justify-content-center',
               {'active-link': _vm.isActive}, {'p-3': _vm.isDesktop}],on:{"click":function($event){_vm.toggleSubmenuMobile = !_vm.toggleSubmenuMobile}}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" "),(_vm.subMenu.length !== 0)?_c('font-awesome-component',{attrs:{"icon":("fa-solid " + (_vm.toggleSubmenuMobile ? 'fa-caret-up' : 'fa-caret-down')),"container-classes":"icon-20 icon-burger"}}):_vm._e()],1)]),_c('transition',{attrs:{"name":"slide-down"}},[(_vm.toggleSubmenuMobile)?_c('div',{staticClass:"sub-menu d-flex flex-column justify-content-start align-items-start"},_vm._l((_vm.subMenu),function(menu){return _c('navbar-drop-item-component',_vm._b({key:menu.uid,attrs:{"show-sub-title":_vm.showSubTitle},on:{"close-hamburger-menu-from-sub":_vm.closeHamburgerMenuFromSub}},'navbar-drop-item-component',menu,false))}),1):_vm._e()])],1):(_vm.internalUrl)?_c('b-link',{class:['nav-bar-menu link d-flex align-items-center text-decoration-none',
               'justify-content-center',
               {'active-link': _vm.isActive}, {'p-3': _vm.isDesktop}],attrs:{"to":_vm.internalUrl}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])]):_c('b-link',{class:['nav-bar-menu link d-flex align-items-center text-decoration-none',
               'justify-content-center',
               {'active-link': _vm.isActive}, {'p-3': _vm.isDesktop}],on:{"click":_vm.navigate}},[_c('div',{staticClass:"link"},[_c('p',{staticClass:"m-0",class:{
            'font-weight-bold': _vm.isDesktop,
            'large': _vm.isTablet
          }},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }