enum OrderStatus {
  CART = 'CART',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',

  CREDIT_CARD = 'CREDIT_CARD',
  BILL_LATER = 'BILL_LATER',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  APPROVE = 'APPROVE',
  MANUAL = 'MANUAL',
  REFUND = 'REFUND',
  NEW_PENDING = 'NEW_PENDING',
}

export default OrderStatus;
