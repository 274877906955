


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import Handout from '@/models/graphql/Handout';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import UploadFileHandler from '@/utils/UploadFileHandler';
import FileResource from '@/models/graphql/FileResource';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';

@Component({
  components: {
    UploadAssetComponent,
  },
  data(): object {
    return {
      assets: [],
    };
  },
})
export default class ResourcesFormComponent extends Vue {
  @Getter
  authUser!: CommunityUser

  @Getter
  protected readonly community!: Community;

  @Prop({ required: false, default: '' })
  private readonly type!: string;

  @Prop({ required: false, default: '' })
  private readonly entityType!: string;

  @Prop({ required: false, default: '' })
  private readonly uid!: string;

  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: true, default: '' })
  private readonly subtitle!: string;

  @Prop({ required: false, default: FileType.PDF })
  private readonly fileType!: string;

  @Prop({ required: false, default: 5 })
  private readonly size!: number;

  @Prop({ required: false })
  private readonly handouts!: Handout[];

  @Prop({ required: true })
  private create!: (payload: object) => Promise<FileResource | Handout | undefined>

  @Prop({ required: true })
  private delete!: (uid: string) => Promise<boolean>;

  created(): void {
    this.$data.assets = this.handouts;
  }

  updateSpeakerAssets(): void {
    if (this.entityType === EntityType.SPEAKER && this.authUser.speakers && this.authUser.speakers[0]) {
      this.authUser.speakers[0].handouts = this.$data.assets;
    }
  }

  private onFileUpload(
    event: { readerResult: string; fileName: string; fileType: string; name: string } | null,
    index: number,
  ): void {
    if (event && event.readerResult) {
      const base64Path = event?.readerResult.replace(`data:${event.fileType};base64,`, '');
      if (this.type === 'handout') {
        this.$data.assets[index] = {
          base64Path,
          fileName: event.fileName,
          name: event.name,
        };
        this.create({
          entityId: this.uid,
          entityType: this.entityType,
          handout: {
            handoutFileResourceBase64: base64Path,
            handoutFileResourceFilename: event.fileName,
            type: this.entityType,
            name: event.name,
            schemaCode: this.community.code,
          },
        }).then((response: Handout | undefined) => {
          if (response) {
            this.$data.assets[index] = response;
            this.updateSpeakerAssets();
          }
        });
      } else if (this.community.code) {
        UploadFileHandler.uploadFileResourceHandler({
          base64Path: event.readerResult,
          fileName: `session_banner_photo_${this.uid}.jpeg`,
          schemaCode: this.community.code,
        }).then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status !== 413) {
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.create({
                  uploadToken,
                  uid: this.uid,
                  fileType: event.fileType,
                  fileName: `session_banner_photo_${this.uid}.jpeg`,
                }).then((response: FileResource | undefined) => {
                  if (response) {
                    this.$data.assets[index].uid = response.uid;
                  }
                });
              }
            }
          };
        });
      }
    } else if (event && event.readerResult === null && this.$data.assets[index]) {
      const { uid } = this.$data.assets[index];
      if (uid) {
        this.delete(uid).then(() => {
          this.$data.assets.splice(index, 1);
          this.updateSpeakerAssets();
        });
      }
    }
  }
}
