




















































import LargeProduct from '@/models/graphql/LargeProduct';
import Product from '@/models/graphql/Product';
import EntityType from '@/utils/enums/EntityType';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { Data } from '@/utils/types/WidgetData';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import Category from '@/models/graphql/Category';
import Deal from '@/models/graphql/Deal';
import RouteHelper from '@/utils/helpers/RouteHelper';

@Component({
  components: {
    AvatarSoloWidget,
  },
})
export default class EntityInfoLinksComponent extends Vue {
  @Prop({ default: null })
  readonly entityInfoRoute!: string;

  @Prop({ required: false })
  private product!: Product;

  @Prop({ required: false })
  private largeProduct!: LargeProduct;

  @Prop({ required: false })
  private deal!: Deal;

  @State
  private dateLocale!: Locale;

  private entityType = EntityType.PRODUCT;

  private entityId: string | null = null;

  private EntityType = EntityType;

  private RouteHelper = RouteHelper;

  private extraCategories = 0;

  private visibleCategories: Category[] = [];

  private refKey = `entityInfoLinks${+new Date()}`;

  get element(): Product | LargeProduct | Deal | null {
    if (this.product) {
      this.entityType = EntityType.PRODUCT;
      this.entityId = this.product.uid;
      return this.product;
    }
    if (this.deal) {
      this.entityType = EntityType.DEAL;
      this.entityId = this.deal.uid;
      return this.deal;
    }

    if (this.largeProduct) {
      this.entityType = EntityType.LARGE_PRODUCT;
      this.entityId = this.largeProduct.uid;
      return this.largeProduct;
    }
    return null;
  }

  get categories(): string {
    const str = this.visibleCategories.map((c) => c.name)
      .join(' · ');
    return `${str}${this.extraCategories > 0 ? ` + ${this.extraCategories}` : ''}`;
  }

  get detailUrl(): string {
    let url = '';
    if (this.entityType && this.entityId) {
      url = RouteHelper.internalDetailRouteNavigation(
        this.$router,
        this.entityId,
        this.entityType,
        true,
      ) as string;
    }
    return url;
  }

  private mounted(): void {
    this.setVisibleCategories();
  }

  private setVisibleCategories(): void {
    let totalWidth = 0;
    this.visibleCategories = [];
    this.extraCategories = 0;
    const entityLinkEl = this.$refs[this.refKey] as Vue | undefined;
    if (this.element && this.element.categories && entityLinkEl && entityLinkEl.$el) {
      const containerWidth = (entityLinkEl.$el as HTMLElement).offsetWidth;
      const categories = this.element.categories.sort((a, b) => (a.name.length > b.name.length ? 1 : -1));
      for (let i = 0; i < categories.length; i++) {
        const categoryWidth = categories[i].name.length * 10; // your font size;
        if (totalWidth + categoryWidth <= containerWidth) {
          this.visibleCategories.push(categories[i]);
          totalWidth += categoryWidth;
        } else {
          break;
        }
      }
      this.extraCategories = this.element.categories.length - this.visibleCategories.length;
    }
  }

  private getItemImage(item: Data): string | null {
    let result = null;
    let product = null;
    let deal = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
        product = item as unknown as Product;
        if (product.images && product.images.length > 0) {
          result = FileResourceHelper.getFullPath(product.images[0].fullFileResource, 'w32');
        }
        break;
      case EntityType.DEAL:
        deal = item as unknown as Deal;
        if (deal.displayFileResource) {
          result = FileResourceHelper.getFullPath(deal.displayFileResource, 'w32');
        }
        break;
      default:
        result = null;
        break;
    }
    return result;
  }
}
