















































































import { Component, Prop } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import EntityFilterDate from '@/utils/types/entity-search/EntityFilterDate';
import EntitySearchToggleComponent from '@/components/entity-search/EntitySearchToggleWidget.vue';
import EntitySearchDateComponent from '@/components/entity-search/EntitySearchDateWidget.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FloorplanSearchListWidget from '@/components/entity-search/FloorplanSearchListWidget.vue';

export type ResultFilterData = Record<string, string | object | Array<string | object>>;

@Component({
  components: {
    FloorplanSearchListWidget,
    ButtonComponent,
    EntitySearchDateComponent,
    EntitySearchToggleComponent,
    InputSearchComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable @typescript-eslint/camelcase,max-len,no-restricted-globals,no-underscore-dangle */
export default class FloorplanSearchFilterWidget extends VueBaseWidget {
  @Prop({ required: true })
  private search!: boolean;

  @Prop({ required: false, default: null })
  private storeName!: string;

  @Prop({ required: true })
  private placeholder!: string;

  @Prop({ required: false, default: 'gray' })
  private readonly searchColor!: string;

  @Prop({ required: false, default: false })
  private togglePillStyle!: boolean;

  @Prop({ required: false, default: () => [] })
  private readonly listConfigs!: EntityFilterList[];

  @Prop({ required: false, default: () => [] })
  private readonly dateConfigs!: EntityFilterDate[];

  @Prop({ required: false, default: () => [] })
  private readonly toggleConfigs!: EntityFilterToggle[];

  private searchValue = '';

  private resultFilterData: ResultFilterData = {};

  private showClearButton = false;

  private clearFilterTriggered = false;

  applyFilters(): void {
    this.showClearButton = this.toggleShowClearFilter();
    if (this.$route.query?.filters
      && 'geozones' in (this.$route.query.filters as object)
      && Object.keys(this.$route.query?.filters).length > 1) {
      this.removeGeozoneIds();
    }
    this.$emit('apply-entity-search');
  }

  applyDates(): void {
    this.showClearButton = this.toggleShowClearFilter();
    if (this.$route.query?.filters
      && 'geozones' in (this.$route.query.filters as object)
      && Object.keys(this.$route.query?.dates).length > 0) {
      this.removeGeozoneIds();
    }
    this.$emit('apply-entity-search');
  }

  applyToggle(): void {
    if (this.$route.query?.filters
      && 'geozones' in (this.$route.query.filters as object)
      && Object.keys(this.$route.query?.toggles).length > 0) {
      const q = this.$route.query as unknown as { filters: { geozones: string[] } };
      delete q.filters.geozones;
    }
    this.$emit('apply-entity-search');
  }

  applySearch(payload?: { query: string }): void {
    const { query } = this.$route;
    if (payload && payload.query && payload.query.length > 0) {
      Object.assign(query, { search: payload?.query });
      if (this.$route.query?.filters && 'geozones' in (this.$route.query.filters as object)) {
        this.removeGeozoneIds();
      }
    } else {
      delete query.search;
    }
    const route = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query,
    }).href;
    window.history.pushState(
      query,
      document.title,
      route,
    );
    this.$emit('apply-entity-search');
  }

  mounted(): void {
    this.searchValue = this.$route.query.search as string;
    this.showClearButton = this.toggleShowClearFilter();
  }

  private toggleShowClearFilter(): boolean {
    return Object.keys(this.$route.query.filters ?? []).length > 0
      || Object.keys(this.$route.query.dates ?? []).length > 0;
  }

  private clearFilters(): void {
    delete this.$route.query.filters;
    delete this.$route.query.dates;
    this.$emit('clear-entity-search');
    this.$emit('apply-entity-search');
    this.clearFilterTriggered = true;
    this.showClearButton = false;
  }

  private removeGeozoneIds(): void {
    const q = this.$route.query as unknown as { filters: { geozones: string[] } };
    delete q.filters.geozones;
  }
}
