import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const SPONSOR_FULL_FRAGMENT = `
  fragment sponsorFullFragment on Sponsor {
    uid
    name
    description
    url
    type
    subEdition
    logoFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
  }
  ${EXHIBITOR_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
