import {
  Component,
  Inject,
  Vue,
} from 'vue-property-decorator';
import WizardValidationRuleType from '@/utils/types/WizardValidationRuleType';

@Component({
  inheritAttrs: false,
})
export default class WizardWidgetWrapper extends Vue {
  @Inject({ from: 'wizardStoreName', default: null })
  protected wizardStoreName!: { value: string };

  protected addValidator(): void {
    if (this.wizardStoreName && this.wizardStoreName.value && this.validationRule) {
      this.$store.commit(
        `${this.wizardStoreName.value}/addValidator`,
        this.validationRule(),
      );
    }
  }

  protected setStep(position: string): void {
    this.$store.dispatch(`${this.wizardStoreName.value}/gotoStep`, position);
  }

  protected nextStep(): void {
    this.$store.dispatch(`${this.wizardStoreName.value}/nextStep`);
  }

  protected prevStep(): void {
    this.$store.dispatch(`${this.wizardStoreName.value}/prevStep`);
  }

  // eslint-disable-next-line class-methods-use-this
  validationRule(): WizardValidationRuleType | null {
    return null;
  }
}
