
























































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
    InputText,
    StandardModal,
    ButtonComponent,
  },
})
export default class SessionVideoSettingsModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ default: 'md' })
  size!: string;

  @Prop({
    required: true,
    default: '',
  })
  sessionVideoUrl!: string;

  @Prop({
    required: true,
    default: false,
  })
  isLive!: boolean;

  @Prop({
    required: true,
    default: false,
  })
  isRecorded!: boolean;

  private selected: string | null = null;

  private videoUrl = this.sessionVideoUrl;

  private videoType = {
    LIVE: 'live',
    RECORDED: 'recorded',
  };

  // eslint-disable-next-line class-methods-use-this
  private isUrlValid(url: string): boolean {
    return RouteHelper.isUrlValid(url);
  }

  private onModalShow(): void {
    if (this.isLive) {
      this.selected = this.videoType.LIVE;
    } else if (this.isRecorded) {
      this.selected = this.videoType.RECORDED;
    }
    this.videoUrl = this.sessionVideoUrl;
  }

  private onConfirm(): void {
    this.$bvModal.hide(`${this.modalId}`);
    this.$emit('on-confirm', {
      videoUrl: this.videoUrl,
      selectedType: this.selected,
    });
  }

  private onCancel(): void {
    this.$bvModal.hide(`${this.modalId}`);
    this.$emit('on-cancel');
  }
}
