import { PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/ProductImage/Base';

export const PRODUCT_BASE_FRAGMENT = `
  fragment productBaseFragment on Product {
    uid
    schemaCode
    name
    description
    featured
    url
    videoUrl
    dimensions
    manufacturer
    model
    serie
    serialNumber
    price
    publicationTime
    endPublicationTime
    images {
      ...productImageBaseFragment
    }
  }
  ${PRODUCT_IMAGE_BASE_FRAGMENT}
`;
