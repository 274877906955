

















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import ScheduleOfEventType from '@/utils/enums/ScheduleOfEventType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { utcToZonedTime } from 'date-fns-tz';
import { Getter, namespace, State } from 'vuex-class';
import { format } from 'date-fns';
import RouteHelper from '@/utils/helpers/RouteHelper';
import PillWidget from '@/components/pill/PillWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import ScheduleEvent from '@/models/graphql/ScheduleEvent';
import EntityType from '@/utils/enums/EntityType';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
const sessionStore = namespace('SessionStore');

/* eslint-disable no-underscore-dangle */
@Component({
  components: { ButtonIconComponent, PillWidget, FontAwesomeComponent },
  inheritAttrs: false,
})
export default class ScheduleOfEventItemComponent extends BreakpointWrapper {
  @communityUserAgendaEntryStore.Action
  private addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Prop({ default: null })
  private uid!: string;

  @Prop({ default: null })
  private type!: ScheduleOfEventType;

  @Prop({ default: null })
  private name!: string | null;

  @Prop({ default: null })
  private description!: string| null;

  @Prop({ default: null })
  private url!: string| null;

  @Prop({ default: null })
  private urlLabel!: string| null;

  @Prop({ default: null })
  private startTime!: string| null;

  @Prop({ default: null })
  private endTime!: string| null;

  @Prop({ default: null })
  private startTimestamp!: number| null;

  @Prop({ default: null })
  private endTimestamp!: number| null;

  @Prop({ default: null })
  private childSessionsOrderBy!: string| null;

  @Prop({ default: null })
  private childSessionsFilter!: string| null;

  @Prop({ default: null })
  private session!: Session | null;

  @Prop({ default: () => [] })
  private childSessions!: Session[];

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private dateLocale!: Locale;

  @sessionStore.Action
  loadScheduleEventSubSession!: (payload: { filter: string; orderBy: string }) => Promise<Session[] | undefined>;

  @sessionStore.Getter
  private fetchScheduleEventSessions!: Session[];

  private FeatureKeys = FeatureKeys;

  private ScheduleOfEventType = ScheduleOfEventType;

  private isCollapsed = false;

  private scheduleEventUidWithFilter = '';

  private entity: Session | ScheduleEvent | null = null;

  private groupOfSessions: Array<{
    uid: string;
    name: string | undefined;
    session: Session;
    date: string;
    location: string | null;
    isInAgenda: string | null | undefined;
  }> = [];

  @Prop({ required: false, default: null })
  private _isInAgenda!: string | null | undefined;

  private inAgenda = this._isInAgenda;

  private get isInAgenda(): boolean {
    if (this.type !== ScheduleOfEventType.GROUP_OF_SESSIONS) {
      return !!this.inAgenda;
    }
    return false;
  }

  private get speakers(): string {
    if (this.session && this.session.speakerRoles && this.session.speakerRoles.length > 0) {
      const names: string[] = [];
      this.session.speakerRoles
        .forEach((speakerRole) => {
          const speakerName = speakerRole ? `${speakerRole.speaker.firstName} ${speakerRole.speaker.lastName}` : '';
          if (speakerName) {
            names.push(speakerName);
          }
        });
      return names.join(', ');
    }
    return '';
  }

  private get location(): string {
    if (this.session
      && this.session.geozonesOfEdition
      && this.session.geozonesOfEdition.length > 0
      && this.session.geozonesOfEdition[0]
      && this.session.geozonesOfEdition[0].exhibitHall
      && this.session.geozonesOfEdition[0].exhibitHall.name
    ) {
      return this.session.geozonesOfEdition[0].exhibitHall.name;
    }
    return '';
  }

  private get categories(): string {
    if (this.session && this.session.categoriesInContext && this.session.categoriesInContext.length > 0) {
      const categories: string[] = [];
      this.session.categoriesInContext
        .slice(0, 3)
        .forEach((category) => {
          if (category.name) {
            categories.push(category.name);
          }
        });
      // eslint-disable-next-line max-len
      return `${categories.join(' · ')}${this.session.categoriesInContext.length > 3 ? ` · +${this.session.categoriesInContext.length - 3}` : ''}`;
    }
    return '';
  }

  private get dateInterval(): string {
    const tzStartDateTime = utcToZonedTime(`${this.type === ScheduleOfEventType.SESSIONS
      ? (this.session as Session).startTime as string
      : this.startTime}Z`, this.selectedTzName);
    const tzEndDateTime = utcToZonedTime(`${this.type === ScheduleOfEventType.SESSIONS
      ? (this.session as Session).endTime as string
      : this.endTime}Z`, this.selectedTzName);
    let startDate = format(tzStartDateTime, 'p', { locale: this.dateLocale }).toLowerCase();
    const endDate = format(tzEndDateTime, 'p', { locale: this.dateLocale }).toLowerCase();
    if (startDate.includes('am') && endDate.includes('am')) {
      startDate = startDate.replace('am', '');
    }
    if (startDate.indexOf('pm') && endDate.indexOf('pm')) {
      startDate = startDate.replace('pm', '');
    }
    return `${startDate.toUpperCase()} – ${endDate.toUpperCase()}${this.location ? `, ${this.location}` : ''}`;
  }

  private get text(): string {
    if (this.description) {
      return this.description.trim();
    }
    if (this.session && this.session.description) {
      return this.session.description.trim();
    }
    return '';
  }

  private get subSessions(): Session[] {
    if (this.childSessionsFilter) {
      return this.fetchScheduleEventSessions;
    }
    return this.childSessions;
  }

  mounted(): void {
    this.setInAgenda();
  }

  @Watch('fetchScheduleEventSessions', { immediate: true })
  @Watch('childSessions', { immediate: true })
  private setGroupOfSessions(): void {
    this.groupOfSessions = [];
    if (this.type === ScheduleOfEventType.GROUP_OF_SESSIONS && this.childSessions.length > 0) {
      this.groupOfSessions = this.subSessions.map((session) => ({
        uid: session.uid,
        name: session.name,
        session,
        date: this.childSessionIntervalDate(session),
        location: this.childSessionLocation(session),
        isInAgenda: session._isInAgenda,
      }));
    }
  }

  @Watch('session', { immediate: true })
  @Watch('_isInAgenda')
  private setInAgenda(): void {
    if (this.type !== ScheduleOfEventType.GROUP_OF_SESSIONS) {
      if (this.type === ScheduleOfEventType.SESSIONS && this.session) {
        this.entity = this.session;
        this.inAgenda = this.session._isInAgenda;
      } else {
        this.entity = ScheduleEvent.hydrate(this.$props);
        this.inAgenda = this._isInAgenda;
      }
    }
  }

  private handleBaseUrl(): void {
    if (this.url) {
      RouteHelper.universalNavigation(
        this.url,
        this.$route.params,
        this.$router,
        undefined,
        undefined,
      );
    }
  }

  private openSessionDetail(entity: Session | null): void {
    const session = entity || this.session;
    if (session) {
      this.$router.push({
        name: 'session-detail',
        params: {
          sessionId: session.uid,
        },
      });
    }
  }

  private toggleSubSessionList(): void {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed && this.childSessionsFilter && this.scheduleEventUidWithFilter !== this.uid) {
      this.loadScheduleEventSubSession(
        {
          filter: this.childSessionsFilter,
          orderBy: this.childSessionsOrderBy || '',
        },
      );
      this.scheduleEventUidWithFilter = this.uid;
    }
  }

  private toggleAgenda(entity: Session | ScheduleEvent | null,
    isSubSession = false,
    subSessionAgenda: string | undefined | null = null): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }

    if (!entity) {
      return;
    }

    const event = this.type === ScheduleOfEventType.SESSIONS
      ? AgendaStoreHelper.convertSessionToEvent(entity as Session, this.selectedTzAbbreviation)
      : AgendaStoreHelper.convertScheduleOfEventsToEvent(entity as ScheduleEvent, this.selectedTzAbbreviation);

    const agendaUid = isSubSession ? subSessionAgenda : this.inAgenda;

    if (agendaUid) {
      this.removeFromAgenda({
        event,
        uid: agendaUid,
      });
      if (isSubSession) {
        const childSession = this.groupOfSessions.find((s) => s.uid === entity.uid);
        if (childSession) {
          childSession.isInAgenda = null;
          this.$emit('set-is-agenda', this.uid, childSession.uid, null);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.SESSION,
            StatLoggerActions.REMOVE_FROM_AGENDA,
            'removeAppointment',
            -1,
            childSession.uid,
            StatLoggerCategories.REMOVE,
            this.$i18n.locale,
          );
        }
      } else {
        this.inAgenda = null;
        this.$emit('set-is-agenda', this.uid, null, null);
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          event.entityType,
          StatLoggerActions.REMOVE_FROM_AGENDA,
          'removeAppointment',
          -1,
          event.entityId,
          StatLoggerCategories.REMOVE,
          this.$i18n.locale,
        );
      }
    } else {
      this.addToAgenda({
        event,
        userId: this.authUser.uid,
        linkedUserId: event.entityId,
        entityType: event.entityType,
      }).then((response) => {
        if (isSubSession) {
          const childSession = this.groupOfSessions.find((s) => s.uid === entity.uid);
          if (childSession) {
            childSession.isInAgenda = response?.uid || '';
            this.$emit('set-is-agenda', this.uid, childSession.uid, response?.uid);
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.SESSION,
              StatLoggerActions.ADD_TO_AGENDA,
              'addAppointment',
              -1,
              childSession.uid,
              StatLoggerCategories.ADD,
              this.$i18n.locale,
            );
          }
        } else {
          this.inAgenda = response?.uid || '';
          this.$emit('set-is-agenda', this.uid, null, response?.uid);
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            event.entityType,
            StatLoggerActions.ADD_TO_AGENDA,
            'addAppointment',
            -1,
            event.entityId,
            StatLoggerCategories.ADD,
            this.$i18n.locale,
          );
        }
      });
    }
  }

  private childSessionIntervalDate(session: Session): string {
    const tzStartDateTime = utcToZonedTime(`${session.startTime as string}Z`, this.selectedTzName);
    const tzEndDateTime = utcToZonedTime(`${session.endTime as string}Z`, this.selectedTzName);
    let startDate = format(tzStartDateTime, 'p', { locale: this.dateLocale });
    const endDate = format(tzEndDateTime, 'p', { locale: this.dateLocale });
    if (startDate.includes('AM') && endDate.includes('AM')) {
      startDate = startDate.replace('AM', '').trim();
    }
    if (startDate.indexOf('PM') && endDate.indexOf('PM')) {
      startDate = startDate.replace('PM', '').trim();
    }
    return `${startDate} – ${endDate}`;
  }

  // eslint-disable-next-line class-methods-use-this
  private childSessionLocation(session: Session): string {
    if (session
      && session.geozonesOfEdition
      && session.geozonesOfEdition.length > 0
      && session.geozonesOfEdition[0]
      && session.geozonesOfEdition[0].exhibitHall
      && session.geozonesOfEdition[0].exhibitHall.name
    ) {
      return session.geozonesOfEdition[0].exhibitHall.name;
    }
    return '';
  }
}
