var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poll-item d-flex flex-column row-gap-4"},[_c('div',{staticClass:"header d-flex align-items-center justify-content-between column-gap-4"},[(_vm.startTimestamp)?_c('pill-component',{staticClass:"d-inline-block my-1",attrs:{"clickable":false,"text":_vm.isPollClosed
        ? _vm.$t('polls.state.closed')
        : _vm.$t('polls.state.active'),"variant":_vm.isPollClosed ? _vm.Variant.LIGHTRED : _vm.Variant.GREEN,"size":"sm"}}):_c('pill-component',{staticClass:"d-inline-block my-1",attrs:{"clickable":false,"text":_vm.$t('polls.state.unpublished'),"variant":_vm.Variant.GRAY,"size":"sm"}}),(!!_vm.teleportMenu && _vm.isSelected)?_c('portal',{attrs:{"to":_vm.teleportMenu}},[(_vm.isModerator && !_vm.hideActions)?_c('poll-item-menu-component',_vm._g({attrs:{"can-create":_vm.canCreate,"end-timestamp":_vm.endTimestamp,"is-poll-closed":_vm.isPollClosed,"session-uid":_vm.session.uid,"start-timestamp":_vm.startTimestamp,"uid":_vm.uid}},_vm.$listeners)):_vm._e()],1):(_vm.isModerator && !_vm.hideActions)?_c('poll-item-menu-component',_vm._g({attrs:{"can-create":_vm.canCreate,"end-timestamp":_vm.endTimestamp,"is-poll-closed":_vm.isPollClosed,"session-uid":_vm.session.uid,"start-timestamp":_vm.startTimestamp,"uid":_vm.uid}},_vm.$listeners)):_vm._e()],1),_c('div',{staticClass:"content d-flex flex-column row-gap-4"},[_c('p',{staticClass:"mb-0 text-neutral-n-8-dark-ink text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"choices d-flex flex-column row-gap-2"},[(_vm.isModerator)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},_vm._l((_vm.pollAnswers),function(answer,index){return _c('div',{key:index},[_c('b-progress',{staticClass:"position-relative rounded-sm border-1 border-neutral-n-4-line bg-white w-100",attrs:{"height":"100%","min-height":"56px"}},[_c('b-progress-bar',{staticClass:"position-absolute h-100",class:answer.correctAnswer
                ? 'text-green-g-1-light-green bg-green-g-1-light-green'
                :'text-blue-b-1-light-blue bg-blue-b-1-light-blue',attrs:{"max":_vm.pollAnswersTotalCount,"value":answer.answerCount || 0}}),_c('div',{staticClass:"d-flex align-items-start justify-content-between z-index-0 p-3 w-100 column-gap-4"},[_c('p',{class:['m-0 paragraph-1 d-flex gap-2 align-items-start text-left',
                         answer.correctAnswer ? 'text-green-g-3-primary-green' : 'text-neutral-n-8-dark-ink']},[(answer.correctAnswer)?_c('font-awesome-component',{staticStyle:{"margin-top":"0.125rem"},attrs:{"container-classes":"icon-20","icon":"fa-solid fa-check-circle","icon-classes":"icon-16 text-green-g-3-primary-green"}}):_vm._e(),_vm._v(" "+_vm._s(answer.title)+" ")],1),_c('p',{class:['m-0 text-neutral-n-8-dark-ink paragraph-1', {'font-weight-bold': answer.correctAnswer}]},[_vm._v(" "+_vm._s(_vm.getAnswerPercentage(answer))+" ")])])],1)],1)}),0):(_vm.isPollClosed)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},_vm._l((_vm.pollAnswers),function(answer){return _c('div',{key:answer.uid,staticClass:"d-flex flex-column row-gap-1"},[_c('div',{staticClass:"d-flex align-items-start justify-content-between column-gap-2"},[_c('p',{class:['label-2 medium text-left text-neutral-n-8-dark-ink m-0',
                       'd-flex align-items-start justify-content-start column-gap-2']},[(answer.correctAnswer)?_c('font-awesome-component',{staticStyle:{"margin-top":"0.063rem"},attrs:{"container-classes":"icon-20","icon":"fa-solid fa-check-circle","icon-classes":"icon-16 text-green-g-3-primary-green"}}):_vm._e(),_vm._v(" "+_vm._s(answer.title)+" ")],1),_c('div',{staticClass:"d-flex align-items-center justify-content-start flex-nowrap column-gap-1"},[_c('p',{staticClass:"m-0 label-2 medium text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.getAnswerPercentage(answer))+" ")]),_c('p',{staticClass:"m-0 label label-3 text-neutral-n-6-label text-nowrap"},[_vm._v(" "+_vm._s(("(" + (_vm.$tc('video-player.theater.polls.vote-count', 0, { count: answer.answerCount })) + ")"))+" ")])])]),_c('b-progress',{class:['border-1 border-neutral-n-4-line rounded-lg',
                     answer.correctAnswer
                       ? 'bg-green-g-1-light-green'
                       : _vm._myAnswer.length > 0 && _vm._myAnswer[0].pollAnswer && _vm._myAnswer[0].pollAnswer.uid === answer.uid
                         ? 'bg-blue-b-2-medium-blue'
                         : 'bg-neutral-n-3-disable' ],attrs:{"height":"20px"}},[_c('b-progress-bar',{class:[
                answer.correctAnswer
                  ? 'text-green-g-3-primary-green bg-green-g-3-primary-green'
                  : _vm._myAnswer.length > 0 && _vm._myAnswer[0].pollAnswer && _vm._myAnswer[0].pollAnswer.uid === answer.uid
                    ? 'text-blue-b-3-primary-blue bg-blue-b-3-primary-blue'
                    : 'text-neutral-n-5-placeholder bg-neutral-n-5-placeholder' ],attrs:{"max":_vm.pollAnswersTotalCount,"value":answer.answerCount || 0}})],1)],1)}),0):_c('div',{staticClass:"d-flex flex-column row-gap-2"},_vm._l((_vm.pollAnswers),function(answer){return _c('button-component',{key:answer.uid,class:['rounded-lg', {'border-blue-b-3-primary-blue bg-blue-b-1-light-blue': _vm._myAnswer.length > 0
                     && _vm._myAnswer[0].pollAnswer
                     && _vm._myAnswer[0].pollAnswer.uid === answer.uid},
                   {'pointer-event-none': !!_vm.realTimeAnswers
                     && _vm._myAnswer.length > 0
                     && _vm._myAnswer[0].pollAnswer
                     && _vm._myAnswer[0].pollAnswer.uid === answer.uid}],attrs:{"disabled":!!_vm.realTimeAnswers
            && _vm._myAnswer.length > 0
            && _vm._myAnswer[0].pollAnswer
            && _vm._myAnswer[0].pollAnswer.uid !== answer.uid,"rounded":false,"text":answer.title,"size":"md","text-classes":"text-wrap text-left","variant":"secondary"},on:{"on-click":function($event){return _vm.$emit('on-answer', answer)}},scopedSlots:_vm._u([(_vm._myAnswer.length > 0 && _vm._myAnswer[0].pollAnswer.uid && _vm._myAnswer[0].pollAnswer.uid === answer.uid)?{key:"left-icon",fn:function(){return [_c('font-awesome-component',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-check-circle","icon-classes":"icon-16"}})]},proxy:true}:null],null,true)})}),1)])]),_c('div',{staticClass:"footer d-flex align-items-center justify-content-start"},[_c('span',{staticClass:"mb-0 label label-3 text-neutral-n-6-label text-nowrap"},[_vm._v(" "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.timeLeft)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }