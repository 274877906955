


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ProductImage from '@/models/graphql/ProductImage';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedDealCard extends Vue {
  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private dealTitle!: string;

  @Prop({ required: false })
  private content!: string;

  @Prop({ required: false })
  private displayFileResource!: ProductImage;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  private entityType = EntityType.DEAL;

  private get imageSrc(): string {
    if (this.displayFileResource) {
      return FileResourceHelper.getFullPath(this.displayFileResource, 'w250');
    }
    return '';
  }
}
