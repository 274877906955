



















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import {
  format, fromUnixTime, getUnixTime, isBefore, set,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import addDays from 'date-fns/fp/addDays';
import FilterPickerCalendarComponent from '@/components/FilterPickerCalendarComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';

@Component({
  components: { FilterPickerCalendarComponent },
  inheritAttrs: false,
})
export default class EntitySearchDateWidget extends Vue {
  @Prop({ required: true })
  private buttonLabel!: string;

  @Prop({ required: true })
  private code!: string;

  @Prop({ required: false, default: false })
  private isDouble!: boolean;

  @Prop({ required: false, default: false })
  private isRange!: boolean;

  @Prop({ required: false, default: false })
  private isSingleSelection!: boolean;

  @Prop({ required: false, default: () => [] })
  private eventsQuery!: Record<string, number>[];

  @Prop({ required: true })
  private clearFilterTriggered!: boolean;

  private selectedDates: number[] = [];

  private events: number[] = [];

  private PickerCalendarType = PickerCalendarType;

  created(): void {
    if (this.eventsQuery) {
      this.eventsQuery.forEach((item) => {
        this.events.push(...this.datesBetweenTwoDates(item.startTimestamp, item.endTimestamp));
      });
    }
    this.loadDatesFormQuery();
  }

  private loadDatesFormQuery(): void {
    const { query } = this.$route;
    if ('dates' in query && query.dates && ((query as EntitySearchQuery).dates ?? {})[this.code]) {
      this.selectedDates = Object.values(((query as EntitySearchQuery).dates ?? {})[this.code]).map(
        (date) => getUnixTime(
          set(
            new Date(
              format(
                DateTimeHelper.toUTC(fromUnixTime(date.startTimestamp as number)),
                this.$t('app.date.defaultDateFormat') as string,
              ),
            ),
            {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            },
          ),
        ),
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private onDatesSelected(dates: number[]): void {
    let newDates: Array<{ startTimestamp: number; endTimestamp: number }> = [];
    newDates = dates.map((d) => ({
      startTimestamp: getUnixTime(
        set(DateTimeHelper.toUTC(fromUnixTime(d)), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
      ),
      endTimestamp: getUnixTime(
        set(DateTimeHelper.toUTC(fromUnixTime(d)), {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        }),
      ),
    }));

    const { query } = this.$route;
    if (newDates.length > 0) {
      if (!('dates' in query && query.dates)) {
        Object.assign(query, { dates: {} });
      }
      // eslint-disable-next-line max-len
      const filters: Record<string,
        Array<string | number> | number | string | boolean | object> = {};
      filters[this.code] = newDates;
      Object.assign(query.dates, { ...filters });
    } else {
      delete ((query as EntitySearchQuery).dates ?? {})[this.code];
      if ('dates' in query && query.dates && Object.keys(query.dates).length === 0) {
        delete query.dates;
      }
    }
    this.$emit('on-route-query-changed', this.$route.query);
    // eslint-disable-next-line no-restricted-globals
    history.pushState(
      { [this.code]: ((query as EntitySearchQuery).dates ?? {})[this.code] },
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href,
    );
  }

  private datesBetweenTwoDates = (start: number, end: number): number[] => {
    const startDate = format(fromUnixTime(start), this.$t('app.date.defaultDateFormat') as string);
    const endDate = format(fromUnixTime(end), this.$t('app.date.defaultDateFormat') as string);
    const result: number[] = [];
    if (start < end) {
      result.push(
        getUnixTime(
          set(new Date(startDate), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        ),
      );
      while (isBefore(new Date(startDate), new Date(endDate))) {
        result.push(
          getUnixTime(
            set(addDays(new Date(startDate), 1), {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ),
        );
      }
    } else if (end < start) {
      result.push(end);
      while (isBefore(new Date(endDate), new Date(startDate))) {
        result.push(
          getUnixTime(
            set(addDays(new Date(endDate), 1), {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          ),
        );
      }
    } else {
      result.push(
        getUnixTime(
          set(new Date(startDate), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        ),
      );
    }
    return result;
  };

  @Watch('clearFilterTriggered', { deep: true })
  private initSelectedList(): void {
    this.selectedDates = [];
    this.$emit('reset-clear-filter-triggered');
  }
}
