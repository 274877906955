
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import MobileMenuModalItemComponent from '@/components/MobileMenuModalItemComponent.vue';
import MobileMenuFooterVariantEnum from '@/utils/enums/MobileMenuFooterVariantEnum';
import MobileMenuItemParams from '@/utils/types/MobileMenuItemParams';

@Component({
  components: {
    ButtonComponent,
    ButtonIconComponent,
    MobileMenuModalItemComponent,
  },
})
export default class MobileMenuModal extends Vue {
  @Prop({ required: true, default: () => [] })
  menuItems!: MobileMenuItemParams[];

  @Prop({ required: false, default: 2 })
  shortMenuLength!: number;

  @Prop({ required: false, default: '' })
  title!: string;

  @Prop({ required: false, default: '' })
  variant!: string;

  @Prop({ required: false, default: 'none' })
  footerVariant!: string;

  @Prop({ required: false, default: 'text-neutral-n-6-label' })
  private readonly iconClass!: string;

  private footerVariantEnum = MobileMenuFooterVariantEnum;

  private showExtendedMenu = false;

  private toggleExtendedMenu(): void {
    this.showExtendedMenu = !this.showExtendedMenu;
  }

  private onClick(action: string): void {
    this.$emit(action);
  }

  private onCancel(): void {
    this.$emit('on-cancel');
  }

  private onApply(): void {
    this.$emit('on-apply');
  }

  private onClose(): void {
    this.$emit('on-close');
  }
}
