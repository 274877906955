import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { Data } from '@/utils/types/WidgetData';
import { cloneDeep } from '@apollo/client/utilities';

interface ActiveButtonState extends LoadableState {
  activeButton: Data[];
  buttonsLoading: boolean;
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class ActionButtonVisibilityBaseStore extends LoadableStore<ActiveButtonState> {
  protected displayedButtonCount = 5;

  protected actionButtonsProcessLength = 0;

  protected isVisibleAndNotBlock = true;

  private originalButtonsList: Data[] = [];

  private buttonsLoading = true;

  private buttonList: Data[] = [];

  private menuList: Data[] = [];

  private IsAccepted = false;

  private get fetchIsVisibleAndNotBlock(): boolean {
    return this.isVisibleAndNotBlock;
  }

  private get fetchIsIsAccepted(): boolean {
    return this.IsAccepted;
  }

  private get fetchButtonList(): Data[] {
    return this.buttonList;
  }

  private get fetchMenuList(): Data[] {
    return this.menuList;
  }

  private get buttonLoading(): boolean {
    return this.buttonsLoading;
  }

  @Mutation
  updateIsVisibleAndNotBlock(visible: boolean): void {
    this.isVisibleAndNotBlock = visible;
  }

  @Mutation
  updateIsAccepted(accepted: boolean): void {
    this.IsAccepted = accepted;
  }

  @Mutation
  initialize(payload: { buttons: Data[]; count: number }): void {
    this.buttonsLoading = true;
    payload.buttons.forEach((i) => {
      i.isVisible = true;
      i.reRender = false;
    });
    this.originalButtonsList = payload.buttons;
    this.buttonList = payload.buttons;
    this.actionButtonsProcessLength = payload.buttons.length;
    this.menuList = [];
    this.displayedButtonCount = payload.count;
  }

  @Mutation
  updateButtons(payload: { index: number; visible: boolean }): void {
    const updateButton = this.originalButtonsList.find((button) => button.index === payload.index);
    if (updateButton) {
      const index = this.originalButtonsList.indexOf(updateButton);
      updateButton.isVisible = payload.visible;
      updateButton.reRender = false;
      this.originalButtonsList[index] = updateButton;
    }
    if (this.actionButtonsProcessLength) {
      this.actionButtonsProcessLength -= 1;
    }
    if (this.actionButtonsProcessLength === 0) {
      this.buttonList = [];
      this.menuList = [];
      let buttonListLocal: Data[] = [];
      const buttonListMenuLocal: Data[] = [];
      const validButtons = this.originalButtonsList.filter((button) => button.isVisible === true);
      if (validButtons.length <= this.displayedButtonCount) {
        buttonListLocal = validButtons;
      } else {
        validButtons.forEach((item, index) => {
          if (index < this.displayedButtonCount - 1) {
            buttonListLocal.push(item);
          } else {
            buttonListMenuLocal.push(item);
          }
        });
      }
      this.buttonList = buttonListLocal;
      this.menuList = buttonListMenuLocal;
      this.buttonsLoading = false;
    }
  }

  @Mutation
  reRenderButtons(): void {
    const original = cloneDeep(this.originalButtonsList);
    this.buttonList = original;
    this.actionButtonsProcessLength = original.length;
    this.menuList = [];
    this.buttonsLoading = true;
    this.buttonList.forEach((i) => {
      i.isVisible = false;
      i.reRender = true;
    });
    this.menuList = [];
  }

  @Action
  setActiveButtonValues(index: number): void {
    this.context.commit('load', true);
    this.context.commit('updateActiveButtonsElements', index);
    this.context.commit('load', false);
  }

  @Action
  visibilityOnBlock(visible: boolean): void {
    this.context.commit('load', true);
    this.context.commit('updateIsVisibleAndNotBlock', visible);
    this.context.commit('load', false);
  }

  @Action
  setIsAccepted(accepted: boolean): void {
    this.context.commit('load', true);
    this.context.commit('updateIsAccepted', accepted);
    this.context.commit('load', false);
  }
}
