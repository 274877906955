

















































import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ChannelPageHeader extends BreakpointWrapper {
  @Prop({ required: false, default: null })
  protected readonly name!: string | null;

  @Prop({ required: false, default: null })
  protected readonly _followerCount!: string | null;

  @Prop({ required: false, default: [] })
  private readonly imageFileResource!: FileResource;

  private get bannerSrc(): string {
    return FileResourceHelper.getFullPath(this.imageFileResource, 'w1110');
  }
}
