var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showLiveSession)?_c('live-session-component',{attrs:{"can-view-video":_vm.canViewVideo,"session":_vm.session}}):(_vm.bannerSrc)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row position-relative"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"detail-page-header session d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"banner position-relative rounded",class:{
              'desktop': _vm.isDesktop,
              'tablet': _vm.isTablet,
              'mobile': _vm.isMobile
            },style:({backgroundImage: _vm.bannerSrc})})])]),(_vm.showRecordedAlert)?_c('div',{class:['position-absolute left-0 bottom-0 w-100 d-flex justify-content-center',
                 _vm.isMobile ? 'px-4 py-2' : 'p-4']},[_c('div',{class:['recorded-alert bg-neutral-n-3-disable rounded-pill d-flex align-items-center',
                   _vm.isMobile ? 'column-gap-2' : 'column-gap-4', 'py-2 py-md-3 pl-3 pr-3 pr-md-4']},[_c('font-awesome-component',{attrs:{"icon":"far fa-play-circle","icon-classes":"icon-16 icon-md-24 icon-xl-40 text-neutral-n-7-light-ink"}}),(_vm.isDesktop)?_c('h4',{staticClass:"text-neutral-n-7-light-ink font-weight-normal m-0"},[_vm._v(" "+_vm._s(_vm.$t('actions.available'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.date))]),_vm._v(" "+_vm._s(_vm.$t('actions.only-at'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.time))])]):(_vm.isTablet)?_c('h6',{staticClass:"text-neutral-n-7-light-ink font-weight-normal m-0"},[_vm._v(" "+_vm._s(_vm.$t('actions.available'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.date))]),_vm._v(" "+_vm._s(_vm.$t('actions.only-at'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.time))])]):_c('p',{staticClass:"paragraph-3 text-neutral-n-7-light-ink font-weight-normal m-0"},[_vm._v(" "+_vm._s(_vm.$t('actions.available'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.date))]),_vm._v(" "+_vm._s(_vm.$t('actions.only-at'))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.recordedAlertDateTime.time))])])],1)]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-column align-items-center mt-4 mt-md-5"},[((_vm._isRecommendedForMe && _vm.displayRecommendedBadge)
          || (_vm._bookmarkCount && _vm._bookmarkCount > 0 && _vm.displayInterestedBadge)
          || (_vm.sessionState && _vm.displayLiveBadge
            && (_vm.sessionState === 'live' || _vm.sessionState === 'archived')))?_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-2 mb-4"},[(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('recommendation-badge-component',{attrs:{"label":("" + (_vm.$t('actions.for-you'))),"size":"xlg","variant":"border-gradient"}}):_vm._e(),(_vm.sessionState && _vm.sessionState === 'live' && _vm.displayLiveBadge)?_c('recommendation-badge-component',{attrs:{"label":("" + (_vm.$t('actions.live'))),"size":"xlg","variant":"light-red"}}):_vm._e(),(_vm.featured && _vm.displayFeaturedBadge)?_c('recommendation-badge-component',{attrs:{"label":("" + (_vm.$t('actions.featured'))),"size":"xlg","variant":"light-gray"}}):_vm._e(),(_vm._bookmarkCount && _vm._bookmarkCount > 0 && _vm.displayInterestedBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$tc('component.session-card.interested', 0, { n: _vm._bookmarkCount }),"size":"xlg","variant":"light-gray"}}):_vm._e(),(_vm.sessionState && _vm.sessionState === 'archived' && _vm.displayLiveBadge)?_c('recommendation-badge-component',{attrs:{"label":("" + (_vm.$t('actions.archived'))),"size":"xlg","variant":"light-gray"}}):_vm._e()],1):_vm._e(),(_vm.name)?_c('div',{staticClass:"w-fit d-inline-flex align-items-center"},[_c('h1',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.name),expression:"name"}],staticClass:"m-0 name"})]):_vm._e(),(_vm.categories)?_c('div',{staticClass:"w-fit mt-2"},[_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.categories)+" ")])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }