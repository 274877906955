











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ColorListComponent extends Vue {
  @Prop({ required: true, default: () => [] })
  private readonly colorList!: string[];
}
