





















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FilterComponent from '@/components/FilterComponent.vue';
import SelectedListParams from '@/utils/types/SelectedListParams';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';

@Component({
  components: { FilterComponent },
  inheritAttrs: false,
})
export default class EntitySearchListWidget extends Vue {
  @Prop({ required: false, default: null })
  private storeName!: string;

  @Prop({ required: true })
  private buttonLabel!: string;

  @Prop({ required: true })
  private code!: string;

  @Prop({ required: true })
  private value!: string;

  @Prop({ required: true })
  private text!: string;

  @Prop({ required: true })
  private dataQuery!: SelectedListParams[];

  @Prop({ required: true })
  private clearFilterTriggered!: boolean;

  @Prop({ required: false, default: false })
  private isDateFilter!: boolean;

  @Prop({ required: false, default: '' })
  private filterQuery!: string;

  @Prop({ required: false, default: false })
  private state!: boolean;

  @Prop({ required: false, default: null })
  private entityType!: string;

  private selectedList: string[] = [];

  created(): void {
    this.$root.$on(`toggle-filter-${this.code}`, this.toggleFilter);
  }

  mounted(): void {
    this.selectedList = this.fetchDataFromQuery();
    this.$emit('filters-update', this.selectedList, this.code);
  }

  fetchDataFromQuery(): string[] {
    const { query } = this.$route;
    if ('filters' in query && query.filters && ((query as EntitySearchQuery).filters ?? {})[this.code]) {
      return ((query as EntitySearchQuery).filters ?? {})[this.code] as string[];
    }
    return [];
  }

  onFilter(selected: string[]): void {
    this.selectedList = selected;
    const { query } = this.$route;
    if (selected.length > 0) {
      if (!('filters' in query && query.filters)) {
        Object.assign(query, { filters: {} });
      }
      const filters:
        Record<string, Array<string | number> | number | string | boolean | object> = {};
      if (this.isDateFilter) {
        filters[this.code] = selected.length > 0 ? [selected[0]] : [];
      } else {
        filters[this.code] = selected;
      }
      Object.assign(query.filters, { ...filters });
    } else {
      delete ((query as EntitySearchQuery).filters ?? {})[this.code];
      if ('filters' in query && query.filters && Object.keys(query.filters).length === 0) {
        delete query.filters;
      }
    }
    this.$emit('on-route-query-changed', this.$route.query);
    this.$emit('filters-update', this.selectedList, this.code);

    // eslint-disable-next-line no-restricted-globals
    history.pushState({ [this.code]: ((query as EntitySearchQuery).filters ?? {})[this.code] },
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href);
  }

  @Watch('clearFilterTriggered', { deep: true })
  private initSelectedList(): void {
    this.selectedList = [];
    this.$emit('reset-clear-filter-triggered');
  }

  private toggleFilter(isOpen: boolean): void {
    this.$emit('toggle-filter-state', this.code, isOpen);
  }
}
