
























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { StateChanger } from 'vue-infinite-loading';
import LargeProduct from '@/models/graphql/LargeProduct';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import InputText from '@/components/InputText.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import LargeProductCopyResponseParams from '@/utils/types/LargeProductCopyResponseParams';
import { BasicTypes } from '@/utils/types/BasicTypes';
import CommunityUser from '@/models/graphql/CommunityUser';

const largeProductStore = namespace('LargeProductStore');

@Component({
  components: {
    InputSearchComponent,
    InputText,
    FontAwesomeComponent,
    IllustrationComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class LargeProductAddModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly exhibitorId!: string;

  @Prop({ required: false, default: 0 })
  private readonly canAddCount!: number;

  @largeProductStore.Action
  private loadPaginatedLargeProducts!: (payload: Record<string, BasicTypes>) => Promise<number | null>;

  @largeProductStore.Action
  private resetPage!: () => void;

  @largeProductStore.State
  private itemsPerPage!: number;

  @largeProductStore.Action
  private copyLargeProduct!: (params: {
    largeProductUids: string[];
    exhibitorUid: string;
    communityCode: string;
  }) => Promise<LargeProductCopyResponseParams>;

  @largeProductStore.Getter
  private getPaginatedLargeProducts!: LargeProduct[];

  @largeProductStore.Getter
  private getAllPromisesLoading!: boolean;

  private searchQuery = '';

  private IllustrationType = IllustrationType;

  private isSaveBtnDisabled = true;

  private reset!: boolean;

  private timer: NodeJS.Timeout | null = null;

  private debounceTime = 500;

  private FileResourceHelper = FileResourceHelper;

  private filter = {
    entityFts: '',
    exhibitor: null,
    categories: {
      foreignId: 'BOAT_DATA_PROVIDER_NMMA',
    },
  };

  private selectedList: LargeProduct[] = [];

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private onConfirm(): void {
    if (this.selectedList.length > 0) {
      this.copyLargeProduct({
        largeProductUids: this.selectedList.map((largeProduct: LargeProduct) => largeProduct.uid),
        exhibitorUid: this.exhibitorId,
        communityCode: this.community.code || '',
      }).then(() => {
        this.onSaveEnd();
      });
    }
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
    this.$bvModal.hide(this.modalId);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.selectedList = [];
    this.searchQuery = '';
  }

  private onSearch(payload: { query: string }): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      if ('entityFts' in this.filter) {
        this.filter.entityFts = payload.query;
      }
      this.reset = true;
      this.resetPage();
      (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:reset');
    }, this.debounceTime);
  }

  private infiniteHandler(state: StateChanger): void {
    this.loadPaginatedLargeProducts({
      filter: this.filter,
      authUser: this.authUser.uid,
    }).then((largeProductCount) => {
      if (largeProductCount === this.itemsPerPage) {
        state.loaded();
      } else {
        state.complete();
      }
    }).catch(() => state.complete());
  }

  private onShow(): void {
    if (this.exhibitorId) {
      this.filter = {
        entityFts: '',
        exhibitor: null,
        categories: {
          foreignId: 'BOAT_DATA_PROVIDER_NMMA',
        },
      };
    }
    this.selectedList = [];
    this.searchQuery = '';
    this.onSearch({ query: '' });
  }

  private isSelected(item: LargeProduct): boolean {
    const selected = this.selectedList
      .filter((selectedItem: LargeProduct) => selectedItem.uid === item.uid);
    return !!selected[0];
  }

  private onListItemClick(item: LargeProduct): void {
    if (this.isSelected(item)) {
      const index = this.selectedList
        .findIndex((selectedItem) => selectedItem.uid === item.uid);
      if (index >= 0) {
        this.selectedList.splice(index, 1);
      }
    } else if (this.canAddCount === -1 || this.selectedList.length < this.canAddCount) {
      this.selectedList.push(item);
    }
    this.isSaveBtnDisabled = this.selectedList.length === 0;
  }

  // eslint-disable-next-line class-methods-use-this
  private getItemImage(largeProduct: LargeProduct): string | null {
    let result = null;
    if (largeProduct.images && largeProduct.images.length > 0) {
      result = FileResourceHelper.getFullPath(largeProduct.images[0].fullFileResource, 'w32');
    }
    return result;
  }
}
