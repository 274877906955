import BaseModel from '@/models/BaseModel';
/* eslint-disable import/no-cycle */
import Community from '@/models/graphql/Community';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import Poll from '@/models/graphql/Poll';

export default class PollAnswer extends BaseModel {
  id?: number;

  community?: Community;

  title?: string;

  poll?: Poll;

  pollUserAnswers?: Array<PollUserAnswer>;

  answerCount?: number;

  correctAnswer?: boolean;

  displaySequence?: number;

  static hydrate(item: object): PollAnswer {
    const result = new PollAnswer();
    Object.assign(result, item);
    return result;
  }
}
