import {
  CATEGORY_DOMAIN_CONTEXT_BASE_FRAGMENT,
} from '@/graphql/_Fragments/CategoryDomainContext/Base';

export const CATEGORY_WITH_CONTEXT_FRAGMENT = `
  fragment categoryWithContextFragment on Category {
    uid
    schemaCode
    domain
    name
    displaySequence
    contexts {
      ...categoryDomainContextBaseFragment
    }
  }
  ${CATEGORY_DOMAIN_CONTEXT_BASE_FRAGMENT}
`;
