















































































import Category from '@/models/graphql/Category';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { Component, Prop } from 'vue-property-decorator';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays, differenceInHours, differenceInMinutes, isBefore,
} from 'date-fns';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';

@Component({
  components: {
    RecommendationBadgeComponent,
  },
})
export default class DealPageHeaderComponent extends BreakpointWrapper {
  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayExpirationBadge!: boolean;

  @Prop({ required: false })
  private readonly name!: string;

  @Prop({ required: false })
  private readonly endTime!: string;

  @Prop({ required: false })
  private readonly displayFileResource!: FileResource;

  @Prop({ required: false, default: () => [] })
  private readonly categoriesInContext!: Category[];

  @Prop({ required: false, default: false })
  private readonly _isRecommendedForMe!: boolean;

  @Getter
  private readonly community!: Community;

  protected get isDesktop(): boolean {
    return this.breakpoint && this.breakpoint.value === 'xl';
  }

  protected get isTablet(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'lg' || this.breakpoint.value === 'md');
  }

  protected get isMobile(): boolean {
    return this.breakpoint && (this.breakpoint.value === 'sm' || this.breakpoint.value === 'xs');
  }

  private get bannerSrc(): string {
    if (this.displayFileResource) {
      return FileResourceHelper.getFullPath(this.displayFileResource as unknown as FileResource, 'w1110');
    }
    return FileResourceHelper.getFullPath(this.community.defaultDealBannerFileResource, 'w1110');
  }

  private get categories(): string {
    const categories = '';
    if (this
      && this.categoriesInContext) {
      if (Array.isArray(this.categoriesInContext)
        && (this.categoriesInContext as Category[]).length > 0) {
        const entity = this.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 3).map((c) => c.name).join(', ')}
          ${entity.length > 3 ? ` + ${entity.length - 3}` : ''}`;
        }
      }
    }
    return categories;
  }

  private get dealState(): string {
    if (this.offerEndDate && isBefore(this.offerEndDate, new Date())) {
      return 'expired';
    }
    return this.expireSoonTime ? 'expire-soon' : '';
  }

  private get offerEndDate(): Date | null {
    if (this.endTime) {
      return DateTimeHelper.toLocal(new Date(this.endTime.toString()));
    }
    return null;
  }

  private get expireSoonTime(): string | null {
    let result = null;
    if (this.endTime && this.endTime.toString()) {
      const localEndTime = DateTimeHelper.toLocal(new Date(this.endTime.toString() as string));
      const today = DateTimeHelper.getCurrentDateTime();
      if (localEndTime >= today) {
        let daysDifference = differenceInDays(today, localEndTime);
        daysDifference = daysDifference < 0 ? -daysDifference : daysDifference;
        let hoursDifference = differenceInHours(today, localEndTime);
        hoursDifference = hoursDifference < 0 ? -hoursDifference : hoursDifference;
        let minutesDifference = differenceInMinutes(today, localEndTime);
        minutesDifference = minutesDifference < 0 ? -minutesDifference : minutesDifference;
        if (daysDifference <= 3 && daysDifference >= 0) {
          if (daysDifference >= 1 && daysDifference <= 3) {
            result = this.$tc('deal-card-component.left.days', 0, {
              timeLeftDiff: daysDifference,
            });
          } else if (hoursDifference <= 24 && hoursDifference >= 1) {
            result = this.$tc('deal-card-component.left.hours', 0, {
              timeLeftDiff: hoursDifference,
            });
          } else if (minutesDifference >= 0) {
            result = this.$tc('deal-card-component.left.minutes', 0, {
              timeLeftDiff: minutesDifference,
            });
          }
        }
      }
    }
    return result;
  }
}
