
















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { HallType } from '@/components/map/FloorplanComponent.vue';

@Component
export default class MapOverviewComponent extends Vue {
  @Prop({ required: false, default: null })
  private readonly overview!: Record<string, string | number>;

  @Prop({ required: false, default: () => [] })
  private readonly halls!: HallType[];

  @Prop({ default: false })
  private readonly isFilterActive!: boolean;

  private panStarted = false;

  private hallMouseDown = false;

  @Watch('overview', { deep: true, immediate: true })
  private setOverView(): void {
    if (this.overview) {
      fetch(this.overview.path as string).then((r) => r.text()).then((text) => {
        const el = document.getElementById('map-overview-svg');
        if (el) {
          el.innerHTML = text;
          el.addEventListener('click', () => {
            this.panStarted = false;
            this.hallMouseDown = false;
          });
        }
      }).then(() => {
        this.manageSVGTooltip();
        this.halls.map((h) => h.id).forEach((h) => {
          const hall = document.getElementById(`map-overview-hall-${h}`);
          if (hall) {
            hall.addEventListener('mousedown', () => {
              this.hallMouseDown = true;
            });
            hall.addEventListener('click', () => {
              if (!this.panStarted && this.hallMouseDown) {
                this.$emit('switch-floor', h);
              }
              this.panStarted = false;
              this.hallMouseDown = false;
            });
          }
        });
      });
    }
  }

  @Watch('halls', { deep: true })
  private manageSVGTooltip(): void {
    const haveResults = this.halls.filter((hall) => hall.resultFilterCount > 0).length > 0;
    this.halls.forEach((hall) => {
      const tooltip = document.getElementById(`map-overview-tooltip-${hall.id}`);
      if (tooltip) {
        if ((haveResults && hall.resultFilterCount > 0)
        || (this.isFilterActive && !haveResults)) {
          tooltip.style.display = 'block';
          const text = tooltip.querySelector('text.h5');
          if (text) {
            text.textContent = `${hall.resultFilterCount}`;
          }
        } else {
          tooltip.style.display = 'none';
        }
      }
    });
  }
}
