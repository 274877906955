import UploadFileHandler from '@/utils/UploadFileHandler';

export default class DetailPageHeaderSaveHelper {
  static async onSave(
    communityCode: string,
    path: string,
    fileName: string,
  ): Promise<XMLHttpRequest> {
    return UploadFileHandler.uploadFileResourceHandler({
      base64Path: path,
      fileName,
      schemaCode: communityCode,
    });
  }
}
