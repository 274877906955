






































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class TitleComponent extends Vue {
  @Inject({ from: 'context', default: '' })
  private context!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly titleSize!: string;

  @Prop({ required: false, default: '' })
  private readonly classes!: string;

  private get titleSizeClass(): string {
    const classes: Record<string, string> = {
      title1: 'h1 font-weight-normal text-neutral-n-8-dark-ink',
      title2: 'h2 font-weight-normal text-neutral-n-8-dark-ink',
      title3: 'h3 font-weight-normal text-neutral-n-8-dark-ink',
      title4: 'h4 font-weight-normal text-neutral-n-8-dark-ink',
      title5: 'h5 font-weight-normal text-neutral-n-8-dark-ink',
      title6: 'h6 font-weight-normal text-neutral-n-8-dark-ink',
      title1Bold: 'h1 font-weight-bold text-neutral-n-8-dark-ink',
      title2Bold: 'h2 font-weight-bold text-neutral-n-8-dark-ink',
      title3Bold: 'h3 font-weight-bold text-neutral-n-8-dark-ink',
      title4Bold: 'h4 font-weight-bold text-neutral-n-8-dark-ink',
      title5Bold: 'h5 font-weight-bold text-neutral-n-8-dark-ink',
      title6Bold: 'h5 font-weight-bold text-neutral-n-8-dark-ink',
      paragraph: 'font-weight-normal text-neutral-n-6-label',
      paragraphUnderline: 'paragraph paragraph-1 paragraph-underline text-neutral-n-6-label',
    };

    return `${classes[this.titleSize]} title-component`;
  }

  private highlightText(text: string): string {
    const translatedText = this.$t(text) as string;
    if (this.context === 'onboarding') {
      return translatedText.replace(/`([^`]+)`/g, '<span class="onboarding-title-highlight">$1</span>');
    }
    return translatedText;
  }
}
