









































































import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

@Component({
  components: { FontAwesomeComponent },
})
export default class NavbarDropItemComponent extends VueBaseWidget {
  @Prop({ required: false, default: '' })
  private uid!: string;

  @Prop({ required: false, default: '' })
  private title!: string;

  @Prop({ required: false, default: '' })
  private subtitle!: string;

  @Prop({ required: false, default: null })
  private internalUrl!: RawLocation;

  @Prop({ required: false, default: null })
  private externalUrl!: string;

  @Prop({ required: false, default: true })
  private showSubTitle!: boolean;

  private isActive = false;

  created(): void {
    this.watchRoute();
  }

  @Watch('$route.path')
  private watchRoute(): void {
    this.isActive = this.internalUrl === this.$route.path;
  }

  private navigate(): void {
    window.open(this.externalUrl, '_blank');
  }
}
