

































































































































































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import Product from '@/models/graphql/Product';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import DateRadioFieldComponent from '@/components/DateRadioFieldComponent.vue';
import { Getter, namespace, State } from 'vuex-class';
import { format } from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import FileType from '@/utils/enums/FileType';
import ProductImage from '@/models/graphql/ProductImage';
import Community from '@/models/graphql/Community';
import Handout from '@/models/graphql/Handout';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import Category from '@/models/graphql/Category';
import LargeProduct from '@/models/graphql/LargeProduct';
import LargeProductImage from '@/models/graphql/LargeProductImage';
import EntityType from '@/utils/enums/EntityType';
import InputNumberComponent from '@/components/InputNumberComponent.vue';
import DealProductLinkSelectorComponent from '@/components/DealProductLinkSelectorComponent.vue';
import Deal from '@/models/graphql/Deal';
import { Data } from '@/utils/types/WidgetData';
import ProductCardWidget from '@/components/cards/ProductCardWidget.vue';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import LocaleModel from '@/models/LocaleModel';

const productStore = namespace('ProductStore');
const dealStore = namespace('DealStore');
const largeProductStore = namespace('LargeProductStore');
const toastStore = namespace('ToastStore');
const categoryStore = namespace('CategoryStore');

@Component({
  computed: {
    ProductCardWidget() {
      return ProductCardWidget;
    },
  },
  data(): object {
    return {
      name: '',
      url: '',
      dimensions: '',
      manufacturer: '',
      model: '',
      serie: '',
      serialNumber: '',
      price: null,
      videoUrl: '',
      oldCategories: [],
      newCategories: [],
      oldDealLink: '',
      newDealLink: '',
      oldImages: [],
      oldHandouts: [],
      imagesToAdd: [],
      imagesToDelete: [],
      handoutsToAdd: [],
      handoutsToDelete: [],
      description: '',
      publicationTime: null,
      endPublicationTime: null,
      _translations: [],
    };
  },
  components: {
    InputNumberComponent,
    UploadAssetComponent,
    DateRadioFieldComponent,
    CategorySelectorComponent,
    TextAreaComponent,
    InputText,
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
    DealProductLinkSelectorComponent,
  },
})
export default class ProductEditModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly product!: Product | LargeProduct;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @Prop({ required: false, default: '' })
  private readonly fields!: string;

  @Prop({ required: false, default: false })
  private readonly isLargeProduct!: boolean;

  @Prop({ required: false, default: 5 })
  private readonly imageLimit!: number | null;

  @Prop({ required: false, default: 5 })
  private readonly handoutLimit!: number | null;

  @categoryStore.Getter
  private fetchProductCategoryForCmsTable!: Category;

  @productStore.Action
  private editProduct!: (payload: { exhibitorId: string; product: object }) => Promise<Product | undefined>;

  @productStore.Action
  private updateProductLinkedEntities!: (payload: {
    product: object; communityCode: string;
  }) => Promise<void>;

  @largeProductStore.Action
  private editLargeProduct!: (payload: {
    exhibitorId: string; largeProduct: object;
  }) => Promise<void | LargeProduct | undefined>;

  @largeProductStore.Action
  private updateLargeProductLinkedEntities!: (payload: {
    largeProduct: object; communityCode: string;
  }) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @dealStore.Action
  private loadDeals!: (filter: Data) => Promise<Deal[]>;

  @dealStore.Action
  private updatePromotionLinksProductAndLargeProduct!: (payload: { promotion: object }) => Promise<Deal | undefined>;

  @State
  private readonly locales!: LocaleModel[];

  private productToEdit: Product | LargeProduct | null = null;

  private isSaveBtnDisabled = true;

  private dealList: Deal[] = [];

  private FileType = FileType;

  private saveLoading = false;

  private saveDealLoading = false;

  private fieldTable: string[] = [];

  private EntityType = EntityType;

  private isValidVideoUrl = false;

  private resetChildUpload = 0;

  private updatedTranslations: Record<string, string> = {};

  private isNameFieldEmpty = true;

  private get isValidUrl(): boolean {
    return !this.$data.url || this.validateUrl(this.$data.url);
  }

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private get nameTranslations(): EntityTranslationParams[] {
    if (this.productToEdit && this.locales && this.locales.length > 1) {
      return (this.isLargeProduct ? LargeProduct.hydrate(this.productToEdit as LargeProduct)
        : Product.hydrate(this.productToEdit as Product)
      ).translationsByField('name');
    }
    return [];
  }

  private get descriptionTranslations(): EntityTranslationParams[] {
    if (this.productToEdit && this.locales && this.locales.length > 1) {
      return (this.isLargeProduct ? LargeProduct.hydrate(this.productToEdit as LargeProduct)
        : Product.hydrate(this.productToEdit as Product)
      ).translationsByField('description');
    }
    return [];
  }

  mounted(): void {
    this.productToEdit = JSON.parse(JSON.stringify(this.product));
    this.setData();
  }

  created(): void{
    this.loadDeals({
      exhibitor: {
        uid: this.exhibitorId,
      },
    }).then((deals) => {
      if (deals) {
        this.dealList = deals;
      }
    });
  }

  @Watch('product')
  private updateProduct(): void {
    this.updatedTranslations = {};
    // eslint-disable-next-line no-underscore-dangle
    this.$data._translations = [];
    this.productToEdit = JSON.parse(JSON.stringify(this.product));
    this.setData();
  }

  private fieldEnable(field: string): boolean {
    if (this.fieldTable.length > 0) {
      return this.fieldTable.includes(field);
    }
    return true;
  }

  private onLinkUpdate(payload: {
    id?: string | null;
  }): void {
    this.$data.newDealLink = payload.id;
  }

  private updateTranslations(field: string, translation: EntityTranslationParams): void {
    if (this.locales && this.locales.length > 1) {
      const productOrLargeProduct: Product | LargeProduct = this.isLargeProduct
        ? LargeProduct.hydrate(this.productToEdit as LargeProduct)
        : Product.hydrate(this.productToEdit as Product);
      let oldTranslations: EntityTranslationParams[] = [];
      if (field === 'name') {
        oldTranslations = productOrLargeProduct
          .translationsByField('name');
        if (translation.locale === this.$i18n.locale) {
          this.$data.name = translation.value;
        }
      }
      if (field === 'description') {
        oldTranslations = productOrLargeProduct
          .translationsByField('description');
        if (translation.locale === this.$i18n.locale) {
          this.$data.description = translation.value;
        }
      }
      const trans = oldTranslations.find((oldTrans) => translation.locale === oldTrans.locale);
      if (trans && trans.value !== translation.value) {
        Object.assign(this.updatedTranslations, { [`${translation.field}-${translation.locale}`]: translation.value });
      } else {
        delete this.updatedTranslations[`${translation.field}-${translation.locale}`];
      }
    }
    this.checkNameFieldEmpty();
  }

  private setData(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.$data._translations = [];
    if (this.fields) {
      this.fieldTable = this.fields.split(',');
    }
    if (this.productToEdit) {
      this.isValidVideoUrl = this.$data.videoUrl === '' || this.$data.videoUrl === null || this.validateUrl(this.$data.videoUrl);
      this.$data.name = this.productToEdit.name;
      this.$data.url = this.productToEdit.url;
      this.$data.dimensions = this.productToEdit.dimensions;
      this.$data.manufacturer = this.productToEdit.manufacturer;
      this.$data.model = this.productToEdit.model;
      this.$data.serie = this.productToEdit.serie;
      this.$data.serialNumber = this.productToEdit.serialNumber;
      this.$data.price = this.productToEdit.price;
      this.$data.videoUrl = this.productToEdit.videoUrl;
      this.$data.description = this.productToEdit.description;
      this.$data.publicationTime = this.productToEdit.publicationTime;
      this.$data.endPublicationTime = this.productToEdit.endPublicationTime;
      this.$data.newCategories = this.productToEdit.categories
        ? [...this.productToEdit.categories]
        : [];
      this.$data.oldCategories = this.productToEdit.categories
        ? [...this.productToEdit.categories]
        : [];
      // eslint-disable-next-line max-len
      this.$data.newDealLink = (this.productToEdit.deals && this.productToEdit.deals.length > 0) ? this.productToEdit.deals[0].uid : '';
      // eslint-disable-next-line max-len
      this.$data.oldDealLink = (this.productToEdit.deals && this.productToEdit.deals.length > 0) ? this.productToEdit.deals[0].uid : '';
      this.isSaveBtnDisabled = this.productToEdit.name === '';
      this.$data.oldImages = this.productToEdit.images ? this.productToEdit.images.slice(0, 5) : [];
      this.$data.oldHandouts = this.productToEdit.handouts
        ? this.productToEdit.handouts.slice(0, 5)
        : [];
      this.$data.imagesToDelete = [];
      this.$data.imagesToAdd = [];
      this.$data.handoutsToAdd = [];
      this.$data.handoutsToDelete = [];
      // eslint-disable-next-line no-underscore-dangle
      this.$data._translations = (this.productToEdit as Product | LargeProduct)._translations;
    } else {
      this.isValidVideoUrl = true;
      this.$data.name = '';
      this.$data.url = '';
      this.$data.dimensions = '';
      this.$data.manufacturer = '';
      this.$data.model = '';
      this.$data.serie = '';
      this.$data.serialNumber = '';
      this.$data.price = null;
      this.$data.videoUrl = '';
      this.$data.description = '';
      this.$data.publicationTime = null;
      this.$data.endPublicationTime = null;
      this.$data.newCategories = [];
      this.$data.oldCategories = [];
      this.$data.newDealLink = '';
      this.$data.oldDealLink = '';
      this.$data.oldImages = [];
      this.$data.oldHandouts = [];
      this.$data.imagesToDelete = [];
      this.$data.imagesToAdd = [];
      this.$data.handoutsToAdd = [];
      this.$data.handoutsToDelete = [];
      if (this.locales && this.locales.length > 1) {
        this.locales.forEach((l) => {
          // eslint-disable-next-line no-underscore-dangle
          this.$data._translations.push({
            locale: l.locale,
            values: [{
              key: 'name',
              value: null,
            },
            {
              key: 'description',
              value: null,
            }],
          });
        });
        // eslint-disable-next-line no-underscore-dangle
        this.productToEdit = { _translations: this.$data._translations } as Product | LargeProduct;
      }
      this.isSaveBtnDisabled = true;
    }
  }

  private onCategoryUpdate(payload: {
    ids?: Category[];
  }): void {
    this.$data.newCategories = payload.ids;
  }

  @Watch('$data.name')
  @Watch('$data.url')
  @Watch('isNameFieldEmpty')
  @Watch('$data.videoUrl')
  private fieldUpdate(): void {
    this.checkNameFieldEmpty();
    this.isValidVideoUrl = this.$data.videoUrl === '' || this.$data.videoUrl === null || this.validateUrl(this.$data.videoUrl);
    if (this.isNameFieldEmpty) {
      this.isSaveBtnDisabled = true;
    } else if ((this.$data.url !== '' && this.$data.url !== null)
      || (this.$data.videoUrl !== '' && this.$data.videoUrl !== null)) {
      this.isSaveBtnDisabled = !this.isValidUrl || !this.isValidVideoUrl;
    } else {
      this.isSaveBtnDisabled = false;
    }
  }

  private checkNameFieldEmpty(): void {
    if (this.locales.length === 1) {
      this.isNameFieldEmpty = this.$data.name === '';
    } else if (this.nameTranslations.length > 0) {
      this.isNameFieldEmpty = this.nameTranslations.findIndex((trans) => !trans.value) > -1;
    }
  }

  private onPublicationTimeChange(date: Date | null): void {
    this.$data.publicationTime = date || new Date();
  }

  private onEndPublicationTimeChange(date: Date | null): void {
    this.$data.endPublicationTime = date;
  }

  private onConfirm(): void {
    this.saveLoading = true;
    let uid = '';
    const publicationTime = this.$data.publicationTime
      ? format(new Date(this.$data.publicationTime), DateTimeHelper.TIME_FORMAT_ISO_8601)
      : format(DateTimeHelper.toUTC(new Date()), DateTimeHelper.TIME_FORMAT_ISO_8601);
    const endPublicationTime = this.$data.endPublicationTime
      ? format(new Date(this.$data.endPublicationTime), DateTimeHelper.TIME_FORMAT_ISO_8601)
      : null;
    if (this.fetchProductCategoryForCmsTable.domain) {
      this.$data.newCategories.push(this.fetchProductCategoryForCmsTable);
    }
    const translations: Record<string, string> = {};
    Object.keys(this.updatedTranslations).forEach((key) => {
      if (!key.includes(this.$i18n.locale)) {
        Object.assign(translations, { [key]: this.updatedTranslations[key] });
      }
    });
    const product = {
      uid: this.product ? this.product.uid : null,
      name: this.$data.name,
      url: this.$data.url,
      videoUrl: this.$data.videoUrl,
      dimensions: this.$data.dimensions,
      manufacturer: this.$data.manufacturer,
      model: this.$data.model,
      serie: this.$data.serie,
      serialNumber: this.$data.serialNumber,
      price: this.$data.price === '' ? null : this.$data.price,
      description: this.$data.description,
      publicationTime,
      endPublicationTime,
      oldCategories: this.$data.oldCategories,
      newCategories: this.$data.newCategories,
      oldDealLink: this.$data.oldDealLink,
      newDealLink: this.$data.newDealLink,
    };
    if (this.isLargeProduct) {
      this.editLargeProduct({
        exhibitorId: this.exhibitorId,
        largeProduct: product,
      }).then((response) => {
        if (response) {
          if (response.uid) {
            uid = response.uid;
          }
          if (product.newDealLink) {
            this.updatePromotionLinksProductAndLargeProduct({
              promotion: {
                PromotionUid: product.newDealLink,
                ProductUid: uid,
                isProduct: !this.isLargeProduct,
              },
            });
          } else if (product.oldDealLink && !product.newDealLink) {
            this.updatePromotionLinksProductAndLargeProduct({
              promotion: {
                PromotionUid: product.oldDealLink,
                ProductUid: '',
                isProduct: !this.isLargeProduct,
              },
            });
          }
          if (this.community.code) {
            this.updateLargeProductLinkedEntities({
              largeProduct: {
                uid,
                publicationTime: product.publicationTime,
                endPublicationTime: product.endPublicationTime,
                oldCategories: product.uid ? product.oldCategories : [],
                newCategories: product.newCategories,
                imagesToDelete: this.$data.imagesToDelete,
                imagesToAdd: this.$data.imagesToAdd,
                handoutsToDelete: this.$data.handoutsToDelete,
                handoutsToAdd: this.$data.handoutsToAdd,
                propertiesToDelete: [],
                propertiesToAdd: [],
                propertiesToUpdate: [],
                translations,
              },
              communityCode: this.community.code,
            }).then(() => {
              if (this.product) {
                this.showToast(ToastActionType.UPDATE_PRODUCT);
              } else {
                this.showToast(ToastActionType.CREATE_PRODUCT);
              }
              this.saveLoading = false;
              this.onSaveEnd();
            });
          } else {
            this.onSaveEnd();
            this.saveLoading = false;
          }
        }
      });
    } else {
      this.editProduct({
        exhibitorId: this.exhibitorId,
        product,
      }).then((response) => {
        if (response) {
          if (response && response.uid) {
            uid = response.uid;
          }
          if (product.newDealLink) {
            this.updatePromotionLinksProductAndLargeProduct({
              promotion: {
                PromotionUid: product.newDealLink,
                ProductUid: uid,
                isProduct: !this.isLargeProduct,
              },
            });
          } else if (product.oldDealLink && !product.newDealLink) {
            this.updatePromotionLinksProductAndLargeProduct({
              promotion: {
                PromotionUid: product.oldDealLink,
                ProductUid: '',
                isProduct: !this.isLargeProduct,
              },
            });
          }
          if (this.community.code) {
            this.updateProductLinkedEntities({
              product: {
                uid,
                publicationTime: product.publicationTime,
                endPublicationTime: product.endPublicationTime,
                oldCategories: product.uid ? product.oldCategories : [],
                newCategories: product.newCategories,
                imagesToDelete: this.$data.imagesToDelete,
                imagesToAdd: this.$data.imagesToAdd,
                handoutsToDelete: this.$data.handoutsToDelete,
                handoutsToAdd: this.$data.handoutsToAdd,
                translations,
              },
              communityCode: this.community.code,
            }).then(() => {
              if (this.product) {
                this.showToast(ToastActionType.UPDATE_PRODUCT);
              } else {
                this.showToast(ToastActionType.CREATE_PRODUCT);
              }
              this.saveLoading = false;
              this.onSaveEnd();
            });
          } else {
            this.onSaveEnd();
            this.saveLoading = false;
          }
        }
      });
    }
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
  }

  private onCancel(): void {
    this.resetChildUpload += 1;
    this.$bvModal.hide(this.modalId);
  }

  private onDelete(): void {
    this.$emit('on-delete', {
      productId: this.product.uid,
    });
  }

  private onShow(): void {
    this.productToEdit = JSON.parse(JSON.stringify(this.product));
    this.setData();
  }

  private onProductImageUpload(event: { readerResult: string; fileName: string; fileType: string }
    | null, index: number): void {
    const replacedImage = this.$data.oldImages.length >= index + 1
      ? this.$data.oldImages[index]
      : null;
    const imageToDelete = this.$data.imagesToDelete
      .find((image: ProductImage | LargeProductImage) => (replacedImage
        ? image && replacedImage && (replacedImage.uid === image.uid)
        : false));
    if (!imageToDelete && replacedImage) {
      this.$data.imagesToDelete.push(replacedImage);
    }

    if (event && event.readerResult) {
      const base64Path = event?.readerResult.replace(`data:${event.fileType};base64,`, '');
      this.$data.imagesToAdd.push({
        base64Path,
        fileName: event.fileName,
      });
    }
  }

  private onProductHandoutUpload(event: {
      readerResult: string; fileName: string; fileType: string; name: string;
    }
    | null, index: number): void {
    const replacedHandout = this.$data.oldHandouts.length >= index + 1
      ? this.$data.oldHandouts[index]
      : null;
    const handoutToDelete = this.$data.handoutsToDelete.find((handout: Handout) => (replacedHandout
      ? handout && replacedHandout && (replacedHandout.uid === handout.uid)
      : false));
    if (!handoutToDelete && replacedHandout) {
      this.$data.handoutsToDelete.push(replacedHandout);
    }

    if (event && event.readerResult) {
      const base64Path = event?.readerResult.replace(`data:${event.fileType};base64,`, '');
      this.$data.handoutsToAdd[index] = {
        base64Path,
        fileName: event.fileName,
        name: event.name,
      };
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  // eslint-disable-next-line class-methods-use-this
  private validateUrl(value: string): boolean {
    /* eslint-disable max-len */
    return /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }
}
