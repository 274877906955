export const UI_PAGE_WIDGET_FOR_UI_ONBOARDING_FRAGMENT = `
  fragment uiPageWidgetForUiOnboardingFragment on UiPageWidget {
    id
    uid
    type
    payload
    displaySequence
    parentWidget {
        id
        uid
        type
    }
    onboarding {
      uid
      id
    }
  }
`;
