var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"product-card-widget bg-white position-relative d-flex flex-column",class:[{'blue-border': (_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBorder}, _vm.dashenizedCategoriesNames],attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[_c('div',{staticClass:"d-flex position-relative",class:[
      {'header-sm': _vm.isMobile},
      {'header-md': _vm.isTablet},
      {'header-lg': _vm.isDesktop} ]},[(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('pill-widget',{staticClass:"for-you-badge",attrs:{"label":("" + (_vm.$t('actions.for-you'))),"with-borders":true,"size":"sm","variant":"orange-gradient"}}):_vm._e(),_c('button-icon-component',{staticClass:"bookmark w-fit",attrs:{"id":("bookmark-" + _vm.uid),"variant":_vm.bookmarked ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleBookmark},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.bookmarked
            ? 'fa-solid fa-bookmark'
            : 'fa-regular fa-bookmark',"icon-classes":!_vm.bookmarked ? 'text-neutral-n-8-dark-ink' : '',"container-classes":"icon-24"}})]},proxy:true}])}),(!_vm.empty)?_c('div',{staticClass:"gradient"}):_vm._e(),(!_vm.empty)?_c('img',{staticClass:"image",attrs:{"alt":_vm.name || '',"src":_vm.FileResourceHelper.getFullPath(_vm.image, 'w330')},on:{"error":function (e) {_vm.empty = true}}}):_c('div',{staticClass:"empty"})],1),_c('div',{staticClass:"product-infos d-flex flex-column justify-content-start align-items-start"},[(_vm.categoriesInContext)?_c('pill-categories-component',{staticClass:"w-100 pb-2",attrs:{"categories":_vm.categoriesInContext,"entity-id":_vm.uid}}):_vm._e(),(_vm.exhibitor && _vm.exhibitor.name)?_c('p',{staticClass:"medium text-neutral-n-6-label mb-2 sub-title"},[_vm._v(" "+_vm._s(_vm.exhibitor.name)+" ")]):_vm._e(),_c('h6',{staticClass:"title text-left text-neutral-n-8-dark-ink mb-0",class:{more: _vm.empty}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"product-category-image"})],1),(_vm.authUser)?_c('b-tooltip',{attrs:{"target":("bookmark-" + _vm.uid),"title":_vm.bookmarked
      ? _vm.$t('actions.tooltip.bookmark.remove')
      : _vm.$t('actions.tooltip.bookmark.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }