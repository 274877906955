


































































































































































































































import FeedItem from '@/models/graphql/FeedItem';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedConnectionCard from '@/components/feed/card/FeedConnectionCard.vue';
import FeedBookmarkCard from '@/components/feed/card/FeedBookmarkCard.vue';
import FeedProductCard from '@/components/feed/card/FeedProductCard.vue';
import FeedDealCard from '@/components/feed/card/FeedDealCard.vue';
import FeedUrlCard from '@/components/feed/card/FeedUrlCard.vue';
import FeedSessionCard from '@/components/feed/card/FeedSessionCard.vue';
import FeedSpeakerCard from '@/components/feed/card/FeedSpeakerCard.vue';
import FeedExhibitorCard from '@/components/feed/card/FeedExhibitorCard.vue';
import FeedContactCard from '@/components/feed/card/FeedContactCard.vue';
import FeedDocumentCard from '@/components/feed/card/FeedDocumentCard.vue';
import FeedAddressCard from '@/components/feed/card/FeedAddressCard.vue';
import FeedArticleCard from '@/components/feed/card/FeedArticleCard.vue';
import FeedPostCard from '@/components/feed/card/FeedPostCard.vue';
import FeedExhibitingAtCard from '@/components/feed/card/FeedExhibitingAtCard.vue';
import UserTitleUpdateComponent from '@/components/feed/UserTitleUpdateComponent.vue';
import DynamicTextUpdateComponent from '@/components/feed/DynamicTextUpdateComponent.vue';
import CategoryUpdateComponent from '@/components/feed/CategoryUpdateComponent.vue';
import FeedFileResourceUpdateComponent from '@/components/feed/FeedFileResourceUpdateComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FeedAction from '@/utils/enums/feed/FeedAction';
import CommunityUser from '@/models/graphql/CommunityUser';
import SubEdition from '@/models/graphql/SubEdition';
import Exhibitor from '@/models/graphql/Exhibitor';
import FeedType from '@/utils/enums/feed/FeedType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { format, fromUnixTime } from 'date-fns';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import Category from '@/models/graphql/Category';
import { Getter, State } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import Channel from '@/models/graphql/Channel';
import Topic from '@/models/graphql/Topic';

@Component({
  components: {
    FontAwesomeComponent,
    FeedConnectionCard,
    ButtonComponent,
    FeedBookmarkCard,
    FeedProductCard,
    FeedDealCard,
    FeedSessionCard,
    FeedUrlCard,
    FeedDocumentCard,
    FeedContactCard,
    FeedExhibitingAtCard,
    FeedAddressCard,
    FeedPostCard,
    FeedSpeakerCard,
    FeedExhibitorCard,
    FeedArticleCard,
    UserTitleUpdateComponent,
    DynamicTextUpdateComponent,
    CategoryUpdateComponent,
    FeedFileResourceUpdateComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedContent extends Vue {
  @Prop({ required: true })
  private initiator!: CommunityUser | SubEdition | Exhibitor | Channel | Topic;

  @Prop({ required: true, default: null })
  private aggregateKey!: string;

  @Prop({ required: false, default: null })
  private type!: string;

  @Prop({ required: true })
  private feedItems!: FeedItem[];

  @Prop({ required: false, default: 1 })
  private length!: number;

  @Prop({ required: false, default: false })
  private isModalRepost!: boolean;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private navigateTo!: () => object | string;

  @Getter
  private authUser!: CommunityUser;

  private itemsToDisplay: FeedItem[] = [];

  private FeedAction = FeedAction;

  private showAll = false;

  private FeedPostType = FeedPostType;

  private get action(): TranslateResult | string {
    return this.$t(`app.feed-page.action.${this.type}`);
  }

  private get userTitleUpdateSubtitle(): string {
    const user = CommunityUser.hydrate(this.initiator);
    if (
      user.companyRoles
      && user.companyRoles[0]
      && user.companyRoles[0].company
      && user.companyRoles[0].company.name
    ) {
      return user.companyRoles[0].company.name;
    }
    if (user.employerName) {
      return user.employerName;
    }
    return '';
  }

  private get hasMoreThanOneActionType(): boolean {
    return this.feedItems.findIndex((feedItem) => this.feedItems[0].action !== feedItem.action) !== -1
      && this.aggregateKey !== null;
  }

  private get isFileResourceActionUpdate(): boolean {
    return this.type === FeedAction.USER_LOGO_UPDATED
      || this.type === FeedAction.USER_BANNER_UPDATED
      || this.type === FeedAction.EXHIBITOR_LOGO_UPDATED
      || this.type === FeedAction.EXHIBITOR_BANNER_UPDATED
      || this.type === FeedAction.SUBEDITION_LOGO_UPDATED
      || this.type === FeedAction.SUBEDITION_BANNER_UPDATED;
  }

  private get fileResource(): { logo: string; banner: string; defaultIcon: string } {
    // eslint-disable-next-line no-underscore-dangle
    switch (this.initiator.__typename) {
      case FeedType.COMMUNITY_USER:
        return {
          logo: FileResourceHelper.getFullPath(
            (this.initiator as CommunityUser).pictureFileResource,
            'w136',
          ),
          banner: FileResourceHelper.getFullPath(
            (this.initiator as CommunityUser).bannerFileResource,
            'w728',
          ),
          defaultIcon: 'far fa-user',
        };
      case FeedType.EXHIBITOR:
        return {
          logo: FileResourceHelper.getFullPath(
            (this.initiator as Exhibitor).logoFileResource,
            'w136',
          ),
          banner: FileResourceHelper.getFullPath(
            (this.initiator as Exhibitor).bannerFileResource,
            'w728',
          ),
          defaultIcon: 'far fa-buildings',
        };
      case FeedType.SUB_EDITION:
        return {
          logo: FileResourceHelper.getFullPath(
            (this.initiator as SubEdition).logoFileResource,
            'w136',
          ),
          banner: FileResourceHelper.getFullPath(
            (this.initiator as SubEdition).bannerFileResource,
            'w728',
          ),
          defaultIcon: 'far fa-calendar-star',
        };
      default:
        return {
          logo: '',
          banner: '',
          defaultIcon: '',
        };
    }
  }

  private get isCategoryActionUpdate(): boolean {
    return this.feedItems[0].action === FeedAction.USER_INTEREST_UPDATED
      || this.feedItems[0].action === FeedAction.USER_PROFILE_ROLES_UPDATED
      || this.feedItems[0].action === FeedAction.EXHIBITOR_CATEGORIES_UPDATED;
  }

  private get createdTime(): string {
    if (this.feedItems[0].createdTimestamp !== undefined) {
      const createdTime = fromUnixTime(this.feedItems[0].createdTimestamp as unknown as number);
      return format(createdTime, `${this.$t('app.date.defaultDateFormat')}`, {
        locale: this.dateLocale,
      });
    }
    return '';
  }

  private get isDynamicTextUpdate(): boolean {
    return (
      this.type === FeedAction.USER_BIOGRAPHY_UPDATED
      || this.type === FeedAction.EXHIBITOR_DESCRIPTION_UPDATED
      || this.type === FeedAction.SUBEDITION_DESCRIPTION_UPDATED
    );
  }

  private get dynamicText(): string {
    if (this.itemsToDisplay[0]) {
      switch (this.initiator.__typename) {
        case FeedType.COMMUNITY_USER:
          return (this.itemsToDisplay[0].triggered as CommunityUser).bio ?? '';
        case FeedType.EXHIBITOR:
          return (this.itemsToDisplay[0].triggered as Exhibitor).description ?? '';
        case FeedType.SUB_EDITION:
          return (this.itemsToDisplay[0].triggered as SubEdition).description ?? '';
        default:
          return '';
      }
    }
    return '';
  }

  private get categoryList(): Category[] {
    const categories: Category[] = [];
    const interests: CommunityUserInterest[] = [];
    const profileRoles: CommunityUserProfileRole[] = [];
    // eslint-disable-next-line no-underscore-dangle
    switch (this.initiator.__typename) {
      case FeedType.COMMUNITY_USER:
        this.feedItems.forEach((feedItem) => {
          if (feedItem.triggered) {
            if (feedItem.action === FeedAction.USER_INTEREST_UPDATED) {
              interests.push(feedItem.triggered as CommunityUserInterest);
            } else {
              profileRoles.push(feedItem.triggered as CommunityUserProfileRole);
            }
          }
        });
        if (this.feedItems[0].action === FeedAction.USER_INTEREST_UPDATED) {
          interests.forEach((interest) => {
            if (interest && interest.interest !== undefined) {
              categories.push(interest.interest);
            }
          });
        } else {
          profileRoles.forEach((role) => {
            if (role && role.profileRole?.name !== undefined) {
              categories.push({ uid: role.profileRole.uid, name: role.profileRole.name });
            }
          });
        }
        return categories;
      case FeedType.EXHIBITOR:
        this.feedItems.forEach((feedItem) => {
          if (feedItem.triggered) {
            categories.push({
              uid: feedItem.triggered.uid,
              name: (feedItem.triggered as Category).name,
            });
          }
        });
        return categories;
      default:
        return categories;
    }
  }

  private get displayCreatedUpdate(): boolean {
    return this.aggregateKey === FeedAction.USER_CREATED
      || this.aggregateKey === FeedAction.EXHIBITOR_CREATED;
  }

  mounted(): void {
    this.setItemsToDisplay();
  }

  private setItemsToDisplay(): void {
    if (
      this.feedItems.length <= this.length
      || this.isCategoryActionUpdate
      || this.type === FeedAction.USER_URLS_UPDATED
      || this.type === FeedAction.EXHIBITOR_URLS_UPDATED
    ) {
      this.itemsToDisplay = this.feedItems;
    } else {
      this.showAll = true;
      this.itemsToDisplay = this.feedItems.slice(0, this.length);
    }
  }

  private displayAll(): void {
    this.itemsToDisplay = this.feedItems;
    this.showAll = false;
  }
}
