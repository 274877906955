





























import { Component, Prop } from 'vue-property-decorator';
import Category from '@/models/graphql/Category';
import Variant from '@/utils/enums/Variant';
import PillWidget from '@/components/pill/PillWidget.vue';
import { TranslateResult } from 'vue-i18n';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    PillWidget,
  },
})
export default class CategoryUpdateComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private list!: Category[];

  private variantEnum = Variant;

  private showedList = this.list;

  private get viewAllLabel(): TranslateResult {
    if (this.isMobile) {
      return this.$t('category-update-component.small-device.view-all-label');
    }
    return this.$t('category-update-component.view-all-label');
  }

  mounted(): void {
    this.updateStyles();
  }

  private updateStyles(): void {
    const element = this.$el.querySelector('.category-update');
    const list = this.$el.querySelector('.list');

    this.showedList = [...this.list];
    if (list && element) {
      this.$nextTick(() => {
        list.childNodes.forEach((child) => {
          const childHtmlElement = child as HTMLElement;
          if (typeof childHtmlElement.getBoundingClientRect === 'function') {
            const childElementBottom = (child as HTMLElement).getBoundingClientRect().bottom;
            if (childElementBottom > element.getBoundingClientRect().bottom) {
              this.showedList.pop();
            }
          }
        });
        this.$nextTick(() => {
          const moreButton = this.$el.querySelector('.view-all-btn');
          if (moreButton) {
            const moreButtonBottom = moreButton.getBoundingClientRect().bottom;
            if (moreButtonBottom > element.getBoundingClientRect().bottom) {
              this.showedList.pop();
            }
          }
        });
      });
    }
  }
}
