/* eslint-disable import/no-cycle */
import BaseModel from '@/models/BaseModel';
import Category from '@/models/graphql/Category';
import FileResource from '@/models/graphql/FileResource';
import Edition from '@/models/graphql/Edition';
import Venue from '@/models/graphql/Venue';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

class SubEdition extends BaseModel {
  id?: number;

  name?: string;

  edition?: Edition;

  schemaCode?: string;

  code?: string;

  description?: string;

  startTime?: Date;

  endTime?: Date;

  showCategory?: Category;

  venue?: Venue;

  logoFileResource?: FileResource;

  bannerFileResource?: FileResource;

  _myTags?: CommunityUserTag[];

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isInAgenda?: string | null;

  _followerCount?: number;

  autoFollow?: boolean;

  startTimestamp?: number;

  endTimestamp?: number;

  archived?: boolean;

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.logoFileResource, 'w40');
  }

  static hydrate(item: object): SubEdition {
    const result = new SubEdition();
    Object.assign(result, item);

    return result;
  }
}

export default SubEdition;
