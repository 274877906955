















































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter, namespace } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityUser from '@/models/graphql/CommunityUser';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { addHours } from 'date-fns';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import Exhibitor from '@/models/graphql/Exhibitor';
import CompanyRequestMeetingModal from '@/components/modals/meeting-request/CompanyRequestMeetingModal.vue';
import Event from '@/utils/types/Event';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import GUUID from '@/utils/GUUID';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import EntityType from '@/utils/enums/EntityType';
import { Portal } from 'portal-vue';

const agendaStore = namespace('AgendaStore');

/* eslint-disable no-underscore-dangle */

@Component({
  components: {
    CompanyRequestMeetingModal,
    ButtonComponent,
    FontAwesomeComponent,
    Portal,
  },
})
export default class ActionButtonMeet extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: CommunityUser;

  @Prop({ required: false })
  protected readonly filterSlotActionResult!: { type: string; limit: number; code: string }[];

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @Prop({ required: true })
  private currentUser!: CommunityUser;

  @Prop({ required: true })
  private currentExhibitor!: Exhibitor;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private FeatureKeys = FeatureKeys;

  private userConnection: CommunityUser = this.actionResult;

  private companyMeetingModalId = `company-meeting-modal-${GUUID.uuidv4()}`;

  private isMeetingFeatureEnabled = false;

  private get fetchIsVisibleAndNotBlock(): boolean {
    return !!this.getter<boolean>('fetchIsVisibleAndNotBlock');
  }

  private get fetchIsIsAccepted(): boolean {
    return !!this.getter<boolean>('fetchIsIsAccepted');
  }

  private get text(): string {
    return `${this.$t('actions.meet')}`;
  }

  private get companyReps(): CommunityUser[] {
    if (this.actionResult
        && (this.actionResult as unknown as Exhibitor).userRoles
    ) {
      return [
        CommunityUser.hydrate({
          __typename: EntityType.USER,
          uid: this.currentExhibitor.uid,
          firstName: `${this.$t('app.modal.company-meeting-modal.anyone-from')}`,
          lastName: this.currentExhibitor.name,
          pictureFileResource: (this.actionResult as unknown as Exhibitor).logoFileResource,
        }),
        ...((this.actionResult as unknown as Exhibitor).userRoles || [])
          .filter((cr) => cr.user
                && cr.user.uid !== this.authUser.uid
                && cr.state
                && cr.role
                && cr.role.code
                && ((cr.handleMeetings
                        && cr.state === CompanyUserRoleStatusType.ACCEPTED
                        && cr.role.code === CompanyRoleEnum.ADMINISTRATOR)
                    || cr.role.code === CompanyRoleEnum.OWNER))
          .map((cr) => CommunityUser.hydrate({
            ...cr.user,
            __typename: EntityType.USER,
          } || {}))];
    }
    return [];
  }

  private get isDisplayed(): boolean {
    return this.authUser
        && this.actionType === this.ActionButtonType.MEET
        && ((this.currentUser
                && this.entityType === this.EntityTypeEnum.USER
                && this.featureByKey(FeatureKeys.AGENDA)
                && this.featureByKey(FeatureKeys.AGENDA).enabled
                && this.fetchIsIsAccepted
                && (!this.isBlockedByOrIsBlockingUser() || (this.isBlockedByOrIsBlockingUser() && this.fetchIsIsAccepted)))
            || (this.currentExhibitor
                && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)
                && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING).enabled
                && this.companyReps.length > 0
                && this.isMeetingFeatureEnabled
                && this.entityType === this.EntityTypeEnum.EXHIBITOR));
  }

  created(): void {
    if (this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE).enabled) {
      if (this.filterSlotActionResult && this.filterSlotActionResult[0]) {
        this.isMeetingFeatureEnabled = this.filterSlotActionResult[0].limit === -1 || this.filterSlotActionResult[0].limit > 0;
      } else {
        this.isMeetingFeatureEnabled = false;
      }
    } else {
      this.isMeetingFeatureEnabled = true;
    }
    this.buttonsDisplay();
  }

  @Watch('fetchIsVisibleAndNotBlock', { deep: true })
  private updateDisplay(): void {
    if (this.userConnection
        && this.userConnection._ourConnection
        && !this.fetchIsVisibleAndNotBlock) {
      this.userConnection._ourConnection.linkState = ConnectionStatusType.DISCONNECTED;
    }
  }

  @Watch('fetchIsIsAccepted')
  private isAccepted(): void {
    if (this.userConnection
        && this.userConnection._ourConnection
        && !this.fetchIsVisibleAndNotBlock) {
      this.userConnection._ourConnection.linkState = ConnectionStatusType.DISCONNECTED;
    }
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private isBlockedByOrIsBlockingUser(): boolean {
    return this.authUser
        && (this.authUser.isBlockedByUser(this.userConnection.uid)
            || this.authUser.haveBlockedUser(this.userConnection.uid));
  }

  private createMeeting(): void {
    if (this.entityType === this.EntityTypeEnum.USER) {
      this.createUserMeeting();
    }
    if (this.entityType === this.EntityTypeEnum.EXHIBITOR) {
      this.createCompanyMeeting();
    }
  }

  private createUserMeeting(): void {
    const participant = { user: this.currentUser };
    const creator = CommunityUser
      .hydrate(Object.fromEntries(Object.entries(this.authUser)
        .filter(([k]) => [
          'uid',
          'schemaCode',
          'firstName',
          'lastName',
          'name',
          'jobTitle',
          'employerName',
          'pictureFileResource'].includes(k))));
    const startTime = DateTimeHelper
      .roundToNearest15(DateTimeHelper.getCurrentDateTime());
    const endTime = DateTimeHelper
      .roundToNearest15(addHours(DateTimeHelper.getCurrentDateTime(), 1));
    this.setViewMode(ViewMode.CREATE);
    this.setEventEdited({
      creator,
      isCancelled: false,
      participants: [participant as MeetingParticipant],
      tzStartTime: startTime,
      tzEndTime: endTime,
    });
    this.logStats(StatLoggerCategories.MEET, StatLoggerActions.ADD, '');
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
  }

  private createCompanyMeeting(): void {
    this.$bvModal.show(this.companyMeetingModalId);
  }
}
