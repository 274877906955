





























import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import NavbarMenuComponent from '@/components/navbar/NavbarMenuComponent.vue';
import MenuModel from '@/models/MenuModel';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: { FontAwesomeComponent, NavbarMenuComponent },
})
export default class NavbarComponent extends VueBaseWidget {
  toggleHamburgerMenu = false;

  @Prop({ required: true })
  private readonly menus!: MenuModel[];

  @chatStore.Mutation
  private toggleChat!: (close?: boolean) => void;

  @Watch('$route.path')
  private watchRoute(): void {
    if (!this.isDesktop) {
      this.toggleChat(true);
    }
  }

  private onToggleHamburgerMenu(open: boolean): void {
    this.toggleHamburgerMenu = open;
    this.$emit('toggle-hamburger-menu', this.toggleHamburgerMenu);
    this.$eventsBus.emit('toggle-side-menu');
  }
}
