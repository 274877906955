




















import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { EventCallback } from '@/utils/event-bus/EventsBus';
import { deepGet } from '@/utils/ObjectHelpers';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { Data } from '@/utils/types/WidgetData';

@Component
export default class AdvertiseWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false })
  private html!: string;

  @Prop({ required: false })
  private guest!: boolean;

  @Prop({ default: true })
  private isVisibleByDefault!: boolean;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  private isVisible = true;

  private isValid = true;

  private advertisementKey = 1;

  private get canDisplayAdvertise(): boolean {
    const visible = this.isVisibleByDefault ? true : (this.isValid && this.isVisible);
    if (this.guest) {
      return !!this.html && visible;
    }
    return !!this.authUser && !!this.html && visible;
  }

  private get htmlResult(): string {
    let localHtmlResult = this.$t(this.html) as string;
    if (this.data.length > 0) {
      const { data } = this;
      const html = this.$t(this.html) as string;
      const matches = html.match(/{{([a-zA-Z-_.[0-9\]*]+)}}/gs);
      if (matches) {
        matches.forEach((m) => {
          const removeBrackets = m.replaceAll('{', '')
            .replaceAll('}', '')
            .trim();
          data.forEach(() => {
            let newValue = deepGet(this.$props, removeBrackets);
            if (!newValue) {
              newValue = deepGet({ data }, removeBrackets);
            }
            if (newValue) {
              localHtmlResult = localHtmlResult.replaceAll(m, newValue);
            }
          });
        });
      }
    }
    return localHtmlResult;
  }

  created(): void {
    this.setDataConfig();
    this.$eventsBus.on('validate-advertise', this.onValidateAdvertise);
    this.$eventsBus.on('advertise-refresh', this.onAdvertiseRefresh);
    this.$eventsBus.on('toggle-advertise-visibility', this.onToggleAdvertiseVisibility);
  }

  beforeDestroy(): void {
    this.$eventsBus.off('validate-advertise', this.onValidateAdvertise);
    this.$eventsBus.off('advertise-refresh', this.onAdvertiseRefresh);
    this.$eventsBus.off('toggle-advertise-visibility', this.onToggleAdvertiseVisibility);
  }

  private onAdvertiseRefresh(event?: Event, payload?: EventCallback): void {
    const widgetParentUid = this.widget.parentWidget?.uid;
    const { pageNum, uid } = payload as { pageNum: number; uid: string };
    if (widgetParentUid === uid) {
      this.advertisementKey = pageNum;
    }
  }

  private onValidateAdvertise(event?: Event, payload?: EventCallback): void {
    const regex = new RegExp(/zone-id="([0-9]*)"/);
    if (this.html) {
      const matches = this.html.match(regex);
      this.isValid = !!(matches && matches[1] && matches[1] === `${payload}`);
    }
  }

  private onToggleAdvertiseVisibility(event?: Event, payload?: EventCallback): void {
    const widgetParentUid = this.widget.parentWidget?.uid;
    const { visibility, uid } = payload as { visibility: boolean; uid: string };
    if (widgetParentUid === uid) {
      this.isVisible = visibility;
    }
  }
}
