



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ProductImage from '@/models/graphql/ProductImage';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import EntityType from '@/utils/enums/EntityType';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';
import FollowButton from '@/components/buttons/FollowButton.vue';

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
    FollowButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedExhibitorCard extends Vue {
  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private name!: string;

  @Prop({ required: false })
  private description!: string;

  @Prop({ required: false })
  private logoFileResource!: ProductImage;

  @Prop({ required: false, default: null })
  private _isBookmarked!: string | null;

  @Prop({ required: false, default: null })
  private _isFollowed!: string | null;

  @Prop({ required: false, default: false })
  private _isRecommendedForMe!: boolean;

  @Prop({ required: false, default: null })
  private _followerCount!: number;

  private entityType = EntityType.EXHIBITOR;

  @Getter
  private authUser!: CommunityUser;

  private get imageSrc(): string {
    return FileResourceHelper.getFullPath(this.logoFileResource, 'w250');
  }

  private followerSubtitle(count: number): string {
    if (count === 1) {
      return `${count} ${this.$t('app.feed-page.follower')}`;
    }
    return `${count} ${this.$t('app.feed-page.followers')}`;
  }
}
