





























































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import NavbarDropItemComponent from '@/components/navbar/NavbarDropItemComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';

@Component({
  components: { FontAwesomeComponent, NavbarDropItemComponent },
})
export default class NavbarMenuComponent extends VueBaseWidget {
  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private title!: string;

  @Prop({ required: false, default: () => [] })
  private subMenu!: { internalUrl: string }[];

  @Prop({ required: false, default: null })
  private internalUrl!: RawLocation;

  @Prop({ required: false, default: null })
  private externalUrl!: string;

  @Prop({ required: false, default: true })
  private showSubTitle!: boolean;

  private isActive = false;

  private activeMenuUid = '';

  private isMenuActive = false;

  private toggleSubmenuMobile = false;

  created(): void {
    this.watchRoute();
  }

  @Watch('isActive')
  private resetDropMenu(): void {
    if (!this.isActive) {
      this.activeMenuUid = '';
    }
  }

  @Watch('$route.path')
  private watchRoute(): void {
    this.isActive = this.subMenu.findIndex((m) => m.internalUrl === this.$route.path) >= 0;
    this.toggleSubmenuMobile = this.isActive;
  }

  private navigate(): void {
    if (this.subMenu.length > 0) {
      this.isMenuActive = !this.isMenuActive;
    }
    if (this.externalUrl) {
      window.open(this.externalUrl, '_blank');
    }
  }

  private closeHamburgerMenu(): void {
    if (this.subMenu.length <= 0) {
      this.$emit('close-hamburger-menu');
    }
  }

  private closeHamburgerMenuFromSub(): void {
    this.$emit('close-hamburger-menu');
  }
}
