




















































































import { Component, Prop } from 'vue-property-decorator';
import {
  addDays, endOfMonth, format, isSameDay, isToday, startOfMonth,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Meeting from '@/models/graphql/Meeting';
import { State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';

type ListCalendarType = {
  day: Date;
  date: string;
  isToday: boolean;
  meetings: Array<{
    uid: string;
    avatars: Array<{src: string; name: string; abbr: string}>;
    title: string | undefined;
    date: string;
  }>;
};

@Component({
  components: { IllustrationComponent, AvatarSoloWidget },
})
export default class ListCalendarComponent extends BreakpointWrapper {
  @Prop({ default: () => new Date() })
  private date!: Date;

  @Prop({ default: () => [] })
  private meetings!: Meeting[];

  @Prop({ default: null })
  private activeMeeting!: string;

  @Prop({ default: null })
  private searchQuery!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private IllustrationType = IllustrationType;

  get calendar(): Array<ListCalendarType> {
    const start = startOfMonth(this.date);
    const end = endOfMonth(this.date);
    const calendar = [];
    let currentDate = start;

    while (currentDate <= end) {
      const meetings = this.meetings
        // eslint-disable-next-line no-loop-func
        .filter((m) => (m.startTime && isSameDay(utcToZonedTime(`${m.startTime}Z`, this.selectedTzName), currentDate)))
        .map((m) => {
          const startDate = utcToZonedTime(`${m.startTime}Z`, this.selectedTzName);
          const endDate = utcToZonedTime(`${m.endTime}Z`, this.selectedTzName);
          return {
            uid: m.uid,
            avatars: this.avatars(m),
            title: m.subject,
            date: `${format(
              startDate,
              `${this.$t('app.date.defaultTimeFormat')}`,
              { locale: this.dateLocale },
            ).replace(' ', '')} - ${format(
              endDate,
              `${this.$t('app.date.defaultTimeFormat')}`,
              { locale: this.dateLocale },
            ).replace(' ', '')}`,
          };
        });
      if (meetings.length > 0) {
        calendar.push({
          day: currentDate,
          date: format(
            currentDate,
            `${this.$t(`app.date.${this.isMobile ? 'dayOfWeekShort' : 'dayOfWeek'}`)}, ${this.$t('app.date.monthDayShort')}`,
          ),
          isToday: isToday(currentDate),
          meetings,
        });
      }
      currentDate = addDays(currentDate, 1);
    }
    return calendar;
  }

  private avatars = (meeting: Meeting): Array<{src: string; name: string; abbr: string}> => {
    const result = [];
    if (meeting && meeting.creatorUser) {
      result.push({
        src: CommunityUser.hydrate(meeting.creatorUser).profilePicture,
        name: CommunityUser.hydrate(meeting.creatorUser).fullName,
        abbr: CommunityUser.hydrate(meeting.creatorUser).nameAbbr,
      });
    }
    if (meeting && meeting.participants && meeting.participants.length > 0) {
      result.push(...meeting.participants.map((p) => ({
        src: CommunityUser.hydrate(p.user as CommunityUser).profilePicture,
        name: CommunityUser.hydrate(p.user as CommunityUser).fullName,
        abbr: CommunityUser.hydrate(p.user as CommunityUser).nameAbbr,
      })));
    }
    return result;
  }
}
