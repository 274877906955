

























































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import {
  BCol, BContainer, BLink, BRow,
} from 'bootstrap-vue';
import { Action, Getter, State } from 'vuex-class';
import VueContextLoading from '@/components/VueContextLoading';
import FooterSignature from '@/components/FooterSignature.vue';
import MenuType from '@/utils/enums/MenuType';
import MenuModel from '@/models/MenuModel';
import { FooterMenuType } from '@/utils/enums/FooterMenuType';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import LocaleModel from '@/models/LocaleModel';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    BContainer,
    BRow,
    BCol,
    BLink,
    FooterSignature,
  },
})
export default class FooterComponent extends VueContextLoading {
  @Prop()
  private guestUser!: boolean;

  @Getter
  private menuByType!: (menuType: MenuType) => MenuModel[];

  @State
  private locales!: LocaleModel[];

  @Action('switchLang')
  private changeLang!: (locale: string) => void;

  private FooterMenuType = FooterMenuType;

  private FooterAppSocialMediaIcons = AppSocialMediaIcons;

  private get footerMenus(): MenuModel[] {
    return this.menuByType(MenuType.FOOTER);
  }

  private get getRights(): string {
    return this.$tc('footer-component.all-right-reserved', 0, {
      currentYear: new Date().getFullYear(),
    });
  }
}
