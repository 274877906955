var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('standard-modal',{attrs:{"modal-id":_vm.modalId,"size":_vm.isMobile || _vm.isTablet ? 'lg' : 'md'},on:{"on-show-modal":_vm.onShowModal,"on-hide-modal":function($event){return _vm.onDiscard()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('h6',{staticClass:"m-0 font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.title'))+" ")])])]},proxy:true},{key:"body",fn:function(){return [(_vm.isLoading)?_c('loading-spinner-component',{staticClass:"my-3"}):_c('div',{staticClass:"h-100"},[(!_vm.fetchBlockedCommunityUsers
          || (_vm.fetchBlockedCommunityUsers && _vm.fetchBlockedCommunityUsers.length === 0))?_c('div',{staticClass:"unblock-user-modal-body-empty\n          d-flex align-items-center justify-content-center h-100"},[_c('illustration-component',{attrs:{"illustration-type":_vm.IllustrationType.SEARCH_EMPTY,"message":_vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.empty-state.subtitle'),"title":_vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.empty-state.title')}})],1):_c('div',{staticClass:"d-flex flex-column",class:[
          {'unblock-user-modal-body': !_vm.isMobile},
          {'unblock-user-modal-body-sm': _vm.isMobile} ]},_vm._l((_vm.fetchBlockedCommunityUsers),function(user){return _c('div',{key:user.uid,staticClass:"user-list-item\n          d-flex align-items-center justify-content-between column-gap-4 p-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('avatar-solo-widget',{staticClass:"mr-3",attrs:{"firstname":user.firstName,"lastname":user.lastName,"src":_vm.userLogo(user),"with-borders":true,"icon-classes":"text-neutral-n-6-label","size":"40"}}),_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('p',{staticClass:"m-0 text-neutral-n-8-dark-ink paragraph-2"},[_vm._v(" "+_vm._s(user.firstName)+" "+_vm._s(user.lastName)+" ")]),_c('label',{staticClass:"m-0 text-neutral-n-6-label",class:[
                  {'label-2': _vm.isMobile},
                  {'label-3': !_vm.isMobile}
                ]},[_vm._v(" "+_vm._s(_vm.jobTitleEmployer(user))+" ")])])],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button-component',{attrs:{"size":_vm.isMobile ? 'sm' : 'md',"text":_vm.isUnblocked(user) ?
                _vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.block-btn')
                : _vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.unblock-btn'),"variant":_vm.isUnblocked(user) ? 'secondary' : 'tertiary'},on:{"on-click":function($event){return _vm.onUnblockClick(user)}}})],1)])}),0)])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isMobile)?_c('div',{staticClass:"m-0 d-flex align-items-center justify-content-end w-100"},[_c('div',{staticClass:"d-flex column-gap-6"},[_c('button-component',{attrs:{"loading":_vm.isLoading,"text":_vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.submit'),"size":"md","variant":"primary"},on:{"on-click":_vm.onSubmit}})],1)]):_c('div',{staticClass:"d-block align-items-center justify-content-center w-100"},[_c('button-component',{attrs:{"loading":_vm.isLoading,"text":_vm.$t('settings-page.privacy-section.unblock-box.unblock-modal.submit'),"size":"md","variant":"primary"},on:{"on-click":_vm.onSubmit}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }