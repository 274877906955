
























import { Component, Prop } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: { ButtonComponent, InputSearchComponent },
})
export default class GlobalInputSearchComponent extends BreakpointWrapper {
  @Prop({ required: false, default: '' })
  private readonly defaultValue!: string;
}
