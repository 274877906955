import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { POLL_ANSWER_BASE_FRAGMENT } from '@/graphql/_Fragments/PollAnswer/Base';

export const POLL_USER_ANSWER_BASE_FRAGMENT = `
  fragment pollUserAnswerBaseFragment on PollUserAnswer {
    uid
    user {
      ...communityUserBaseFragment
    }
    pollAnswer {
      ...pollAnswerBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${POLL_ANSWER_BASE_FRAGMENT}
`;
