export const EXHIBIT_HALL_BASE_FRAGMENT = `
  fragment exhibitHallBaseFragment on ExhibitHall {
    uid
    id
    foreignId
    schemaCode
    name
    defaultHall
  }
`;
