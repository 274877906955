


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import StandardModal from '@/components/modals/StandardModal.vue';
import FormatHelper from '@/utils/helpers/FormatHelper';
import { namespace } from 'vuex-class';

const localStorageCartStore = namespace('LocalStorageCartStore');

@Component({
  components: {
    StandardModal,
    FontAwesomeComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class ViewCartModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ required: true, default: null })
  salePackage!: SalesPackage;

  @Prop({ required: true, default: null })
  exhibitorId!: string;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @localStorageCartStore.Getter
  private numberOfItemInCart!: number;

  private get formatCurrency(): string {
    return FormatHelper.formatCurrency((this?.salePackage?.price || 0) / 100);
  }

  private onContinueShoppingClick(): void {
    this.onCancel();
  }

  // eslint-disable-next-line class-methods-use-this
  private onViewCartClick(): void {
    this.$router.push({ name: 'company-cms-cart', params: { companyId: this.exhibitorId } });
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.$emit('on-cancel');
  }

  private priceCurrency(): string {
    return `${this.$t('app.currency')}`;
  }
}
