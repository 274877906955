



































































































import FeedPostMedia from '@/models/graphql/FeedPostMedia';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FeedPostLink from '@/models/graphql/FeedPostLink';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import { DOMAIN } from '@/utils/constants/Regex';
import Poll from '@/models/graphql/Poll';
import LivePollPostComponent from '@/components/LivePollPostComponent.vue';
import Channel from '@/models/graphql/Channel';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import Topic from '@/models/graphql/Topic';

@Component({
  components: {
    Splide,
    SplideSlide,
    LivePollPostComponent,
  },
})
export default class FeedPostCard extends Vue {
  @Prop({ required: false, default: '' })
  private uid!: string;

  @Prop({ required: false, default: FeedPostType.TEXT })
  private type!: string;

  @Prop({ required: false, default: '' })
  private content!: string;

  @Prop({ required: false, default: [] })
  private medias!: FeedPostMedia[];

  @Prop({ required: false, default: [] })
  private links!: FeedPostLink[];

  @Prop({ required: false, default: [] })
  private polls!: Poll[];

  @Prop({ required: false, default: false })
  private isModalRepost!: boolean;

  @Prop({ required: true })
  private initiator!: CommunityUser | SubEdition | Exhibitor | Channel | Topic;

  private feedPostType = FeedPostType;

  private urlRegex = new RegExp(DOMAIN, 'gmi');

  private get slides(): { src: string; alt: string }[] {
    if (this.medias) {
      return this.medias.map((item) => {
        if (item.type === 'video') {
          return {
            src: FileResourceHelper.getFullPath(item.mediaFileResource as FileResource),
            alt: '',
            type: item.mediaFileResource?.fileType,
          };
        }
        return {
          src: FileResourceHelper.getFullPath(item.mediaFileResource as FileResource, 'w728'),
          alt: '',
          type: item.mediaFileResource?.fileType,
        };
      });
    }
    return [];
  }

  get parseContent(): string | null {
    if (this.content) {
      if (this.urlRegex.test(this.content)) {
        const matches = this.content.match(this.urlRegex);
        if (matches && matches.length > 0) {
          let { content } = this;
          matches.forEach((link) => {
            const href = /^(((https?|ftps?):)?\/\/)/i.test(link) ? link : `//${link}`;
            content = content
              .replaceAll(link, `<a href="${href}" class="text-blue-b-3-primary-blue" target="_blank">${link}</a>`);
          });
          return content;
        }
      }
      return this.content;
    }
    return null;
  }

  @Watch('medias', { deep: true })
  private remountSplide(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-unused-expressions
    (this.$refs[`splide-${this.uid}`] as any)?.remount();
  }
}
