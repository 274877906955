


























import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component
export default class MediaBackground extends BreakpointWrapper {
  @Prop({ required: false, default: 0 })
  private videoInnerGap!: number;

  @Prop({ required: false, default: false })
  private parallax!: boolean;

  @Prop({ required: false, default: 0 })
  private containerHeight!: number;

  @Prop({ required: false, default: null })
  private sources!: string[];

  @Prop({ required: false, default: true })
  private muted!: boolean;

  @Prop({ required: false, default: true })
  private isCurrent!: boolean;

  @Prop({ required: false, default: '' })
  private imageUrl!: string;

  private videoRatio: number | null = null;

  private get poster(): string {
    return this.imageUrl ? `https:${this.imageUrl}` : '';
  }

  mounted(): void {
    if (this.videoCanPlay()) {
      (this.$refs.video as HTMLVideoElement).oncanplay = () => {
        if (!this.$refs.video) return;
        this.videoRatio = (this.$refs.video as HTMLVideoElement).videoWidth
            / (this.$refs.video as HTMLVideoElement).videoHeight;
        this.setVideoSize();
        (this.$refs.video as HTMLVideoElement).style.visibility = 'visible';
      };
    }
    window.addEventListener('resize', this.resize);
    window.addEventListener('scroll', this.resize);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('scroll', this.resize);
  }

  setVideoSize(): void {
    if (this.parallax) {
      (this.$refs.video as HTMLElement).style.position = 'fixed';
      (this.$refs.video as HTMLElement).style.zIndex = '-9999';
      (this.$refs.video as HTMLElement).style.minHeight = '100%';
    }

    if (this.containerHeight) {
      (this.$el as HTMLElement).style.height = `${this.containerHeight}px`;
    } else {
      let theight = 0;
      Array.from((this.$refs.content as HTMLElement).children).forEach((child) => {
        theight += child.clientHeight;
      });
      (this.$refs.content as HTMLElement).style.top = 'auto';
      (this.$el as HTMLElement).style.height = `${theight + this.videoInnerGap}px`;
    }

    let width; let height;
    const containerRatio = (this.$el as HTMLElement).offsetWidth / (this.$el as HTMLElement).offsetHeight;
    if (this.videoRatio && containerRatio > this.videoRatio) {
      width = (this.$el as HTMLElement).offsetWidth;
    } else {
      height = (this.$el as HTMLElement).offsetHeight;
    }
    (this.$refs.video as HTMLVideoElement).style.width = width ? `${width}px` : 'auto';
    (this.$refs.video as HTMLVideoElement).style.height = height ? `${height}px` : 'auto';
  }

  resize(): void {
    if (this.videoCanPlay()) {
      this.setVideoSize();
    }
  }

  videoCanPlay(): boolean {
    return !!(this.$refs.video as HTMLVideoElement).canPlayType;
  }

  // eslint-disable-next-line class-methods-use-this
  getMediaType(src: string): string {
    return `video/${src.split('.').pop()}`;
  }

  @Watch('isCurrent')
  private isVideoActive(): void {
    if (this.isCurrent) {
      (this.$refs.video as HTMLVideoElement).play();
    } else {
      (this.$refs.video as HTMLVideoElement).pause();
    }
  }
}
