export const POLL_ANSWER_BASE_FRAGMENT = `
  fragment pollAnswerBaseFragment on PollAnswer {
    uid
    id
    title
    answerCount
    displaySequence
    correctAnswer
  }
`;
