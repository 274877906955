





















































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import { Getter, namespace } from 'vuex-class';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import { validationMixin } from 'vuelidate';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import MemberInviteAccountFound
  from '@/components/modals/member-invite/MemberInviteAccountFound.vue';
import MemberInviteMultipleAccount
  from '@/components/modals/member-invite/MemberInviteMultipleAccount.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import MemberInviteNoAccountFound
  from '@/components/modals/member-invite/MemberInviteNoAccountFound.vue';
import { CommunityUsersFilter } from '@/graphql/_Filters/CommunityUsersFilter';
import ActionType from '@/utils/enums/ActionType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { EMAIL } from '@/utils/constants/Regex';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import PermissionsManagerForm from '@/components/modals/PermissionsManagerForm.vue';
import { CommunityUserPermissions } from '@/utils/types/CommunityUserPermissions';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const companyUserRoleStore = namespace('CompanyUserRoleStore');
const communityUserStore = namespace('CommunityUserStore');
const emailValidation = helpers.regex('email', EMAIL);
const toastStore = namespace('ToastStore');
const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  data(): object {
    return {
      email: '',
    };
  },
  components: {
    FontAwesomeComponent,
    PermissionsManagerForm,
    MemberInviteNoAccountFound,
    MemberInviteMultipleAccount,
    MemberInviteAccountFound,
    UploadAssetComponent,
    ButtonComponent,
    InputText,
    ButtonIconComponent,
    StandardModal,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(1),
        emailValidation,
      },
    },
  },
})
export default class MemberInviteModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  authUser!: CommunityUser;

  @chatStore.Action
  toggleCompanyChatModerator!: (payload: {
    user: string;
    company: string;
    toAdd: boolean;
  }) => Promise<void>;

  @Getter
  private community!: Community;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly user!: CommunityUser;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  @companyUserRoleStore.Action
  private getOneWithParams!: (payload: {
    filter: {
      company: object;
      user: object;
    };
  }) => Promise<void | CompanyUserRole | undefined>;

  @companyUserRoleStore.Action
  private createCompanyUserRole!: (payload: {
    userId: string;
    companyId: string;
    role: string;
  }) => Promise<void>;

  @companyUserRoleStore.Getter
  private fetchCompanyUserRole!: CompanyUserRole;

  @companyUserRoleStore.Getter
  private isLoading!: boolean;

  @companyUserRoleStore.Getter
  private saveLoading!: boolean;

  @companyUserRoleStore.Getter
  private fetchCompanyUserRoleCreated!: boolean;

  @communityUserStore.Action(ActionType.FILTER)
  private filterCommunityUsers!: (payload: { filter: CommunityUsersFilter }) => void;

  @communityUserStore.Action
  private createAccount!: (
    entity: { email: string; firstName: string; lastName: string; state: string }
  ) => Promise<CommunityUser | undefined>;

  @communityUserStore.Getter
  private fetchCommunityUsers!: CommunityUser[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private isShown = false;

  private step = 0;

  private emailFieldIsDirty = false;

  private firstTimeUpdate = false;

  private accountExistError = false;

  private noAccount = false;

  private noAccountModalError = true;

  private selectedUser: CommunityUser | undefined = undefined;

  private newUserSaveLoading = false;

  private form = {
    email: '',
  };

  private newUser = {} as CommunityUser;

  private isBlocked = false;

  private FeatureKeys = FeatureKeys;

  private permissionsForm!: CommunityUserPermissions;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private get isTablet(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'md');
  }

  private get validateState(): boolean {
    if (this.firstTimeUpdate) {
      const $dirty = this.$v.form.email?.$dirty;
      const $error = this.$v.form.email?.$error;
      return $dirty
        ? !$error
        : true;
    }
    return true;
  }

  @Watch('$v.form.email.$model')
  onChangeEmail(): void {
    this.accountExistError = false;
    if (!this.$v.form.email?.$invalid) {
      const $error = this.$v.form.email?.$error;
      const $invalid = this.$v.form.email?.$invalid;
      if ($invalid) {
        this.emailFieldIsDirty = !!$error;
      } else {
        this.emailFieldIsDirty = false;
      }
    }
  }

  private onPermissionsChange(payload: CommunityUserPermissions): void {
    this.permissionsForm = payload;
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
    this.isShown = false;
  }

  private onBack(): void {
    this.isBlocked = false;
    this.step = this.step > 0 ? this.step - 1 : this.step;
  }

  private onContinue(): void {
    if (this.step === 0) {
      this.selectedUser = undefined;
    }
    if (this.step === 1) {
      this.step += 1;
    } else if (this.step === 0 && this.form.email !== '') {
      this.getOneWithParams({
        filter: {
          company: {
            uid: this.exhibitorId,
          },
          user: {
            email: this.form.email.toLowerCase(),
          },
        },
      }).then((response) => {
        if (response && response.state) {
          this.isBlocked = this.authUser.haveBlockedUser(response.user?.uid || '')
            || this.authUser.isBlockedByUser(response.user?.uid || '');
          this.accountExistError = response.state !== CompanyUserRoleStatusType.DECLINED
            && response.state !== CompanyUserRoleStatusType.CANCELLED
            && response.state !== CompanyUserRoleStatusType.INVITE_CANCELLED;
          if (!this.accountExistError) {
            this.filterCommunityUsers({
              filter: {
                email: this.form.email.toLowerCase(),
                _isNotBlocked: this.authUser.uid,
              },
            });
          }
        } else {
          this.filterCommunityUsers({
            filter: {
              email: this.form.email.toLowerCase(),
              _isNotBlocked: this.authUser.uid,
            },
          });
        }
      });
    }
  }

  private onShow(): void {
    this.isShown = true;
    this.step = 0;
    this.form.email = '';
    this.firstTimeUpdate = false;
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
  }

  private onBlur(): void {
    this.firstTimeUpdate = true;
    if (this.form.email !== '') {
      const $error = this.$v.form.email?.$error;
      const $dirty = this.$v.form.email?.$dirty;
      if ($error) {
        this.emailFieldIsDirty = !!$dirty;
      }
    } else {
      this.emailFieldIsDirty = true;
    }
  }

  @Watch('fetchCompanyUserRole')
  private onFetchCompanyUserRoleChange(): void {
    if (this.fetchCompanyUserRole) {
      this.accountExistError = this.fetchCompanyUserRole
        .state !== CompanyUserRoleStatusType.DECLINED
        && this.fetchCompanyUserRole.state !== CompanyUserRoleStatusType.CANCELLED;
    }
  }

  @Watch('fetchCommunityUsers')
  private onFetchCommunityUsersChange(): void {
    if (this.fetchCommunityUsers && this.fetchCommunityUsers.length > 0) {
      this.noAccount = false;
      this.step = 1;
    } else if (this.fetchCommunityUsers.length === 0) {
      this.noAccount = true;
      this.step = 1;
    }
  }

  private onNoAccountFieldChange(fields: { firstName: string; lastName: string }): void {
    this.noAccountModalError = (!fields.firstName || fields.firstName === '')
      || (!fields.lastName || fields.lastName === '');
    if (!this.noAccountModalError) {
      this.newUser = {
        email: this.form.email.toLowerCase(),
        firstName: fields.firstName,
        lastName: fields.lastName,
      } as CommunityUser;
    } else {
      this.newUser = {} as CommunityUser;
    }
  }

  private onSendInvite(): void {
    if (this.noAccount) {
      if (this.newUser.email && this.newUser.firstName && this.newUser.lastName) {
        this.newUserSaveLoading = true;
        this.createAccount({
          email: this.newUser.email,
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          state: 'TO_BE_INVITED',
        }).then((user: CommunityUser | undefined) => {
          if (user) {
            this.createCompanyUserRole({
              userId: user.uid,
              companyId: this.exhibitorId,
              role: CompanyRoleEnum.ADMINISTRATOR,
              ...this.permissionsForm,
            }).then(() => {
              this.toggleCompanyChatModerator({
                user: user.uid,
                company: this.exhibitorId,
                toAdd: !!this.permissionsForm.moderator,
              });
            });
            this.newUserSaveLoading = false;
          }
        });
      }
    } else {
      const user = this.selectedUser && this.selectedUser.uid
        ? String(this.selectedUser?.uid)
        : this.fetchCommunityUsers[0].uid;
      this.createCompanyUserRole({
        userId: user,
        companyId: this.exhibitorId,
        role: CompanyRoleEnum.ADMINISTRATOR,
        ...this.permissionsForm,
      }).then(() => {
        this.toggleCompanyChatModerator({
          user,
          company: this.exhibitorId,
          toAdd: !!this.permissionsForm.moderator,
        });
      });
    }
  }

  @Watch('fetchCompanyUserRoleCreated')
  private onUserInvited(): void {
    if (this.fetchCompanyUserRoleCreated) {
      this.showToast(ToastActionType.USER_INVITED);
    }
  }

  @Watch('saveLoading')
  private onCompanyUserRoleSave(): void {
    if (!this.saveLoading) {
      this.$emit('on-update-end');
    }
  }

  private onUserChoiceChange(user: CommunityUser): void {
    this.selectedUser = user;
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
