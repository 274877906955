


















































































































































































































































































import {
  Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import CommunityUser from '@/models/graphql/CommunityUser';
import VueSecureHTML from 'vue-html-secure';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Comment from '@/models/graphql/Comment';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { formatDistanceToNow } from 'date-fns';
import { Getter, namespace, State } from 'vuex-class';
import FeedPost from '@/models/graphql/FeedPost';
import FeedItem from '@/models/graphql/FeedItem';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { CommentFilter } from '@/graphql/_Filters/CommentFilter';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import GenericEvent from '@/utils/types/GenericEvent';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { DOMAIN } from '@/utils/constants/Regex';

const commentStore = namespace('CommentStore');
const notificationStore = namespace('NotificationStore');

@Component({
  name: 'FeedPostCommentComponent',
  components: {
    LoadingSpinnerComponent,
    FontAwesomeComponent,
    AvatarSoloWidget,
    DropdownMenu,
    DropdownMenuItem,
    ConfirmModal,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedPostCommentComponent extends VueBaseNotify {
  @Getter
  protected readonly authUser!: CommunityUser;

  @commentStore.Action('createFeedPostComment')
  private createComment!: (payload: {
    feedPost: FeedPost;
    authUser: CommunityUser;
    parentComment: Comment | null;
    entity: Partial<Comment>;
  }) => Promise<Comment | undefined>;

  @commentStore.Action
  private updateComment!: (entity: Partial<Comment>) => Promise<Comment | undefined>;

  @commentStore.Action
  private deleteComment!: (uid: string) => Promise<boolean | undefined>;

  @commentStore.Action
  private likeComment!: (payload: {
    commentUid: string;
    authUserId: string;
  }) => Promise<boolean | undefined>;

  @commentStore.Action
  private unlikeComment!: (payload: {
    commentUid: string;
    authUserId: string;
  }) => Promise<boolean | undefined>;

  @commentStore.Action
  private getPaginatedComments!: (payload: {
    filter: CommentFilter;
    authUser: string;
    first: number;
    offset: number;
  }) => Promise<Comment[] | null>;

  @commentStore.Action
  private getAllComments!: (payload: {
    filter: CommentFilter;
    authUser: string;
  }) => Promise<Comment[] | null>;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @notificationStore.Getter
  private genericEvents!: Record<string, { unsubscribe: () => void }>;

  @Prop({
    required: true,
    default: null,
  })
  private readonly feedItems!: FeedItem[];

  @Prop({
    required: true,
    default: null,
  })
  private readonly comment!: Comment;

  @Prop({
    required: true,
    default: 0,
  })
  private readonly level!: number;

  @Prop({
    required: false,
    default: false,
  })
  private readonly commentLoaded!: boolean;

  @Prop({
    required: false,
    default: '',
  })
  private readonly feedItemElementUid!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideActions!: boolean;

  @Inject({
    from: 'context',
    default: '',
  })
  private context!: string;

  @State
  private dateLocale!: Locale;

  private localComment: Comment = this.comment;

  private numberOfLikes = this.localComment._likesCount ? this.localComment._likesCount : 0;

  private showCommentReplies = false;

  private numberOfRows = 5;

  private onFocus = false;

  private myComment = '';

  private myEditedComment = '';

  private showReplyForm = false;

  private isEditing = false;

  private offset = 0;

  private numberOfCommentPerSection = 2;

  private renderKey = 0;

  private isLoading = false;

  private urlRegex = new RegExp(DOMAIN, 'gmi');

  private commentEditRowCount = 1;

  private isCreateFirstPost = false;

  get content(): string | null {
    if (this.localComment.textContent) {
      if (this.urlRegex.test(this.localComment.textContent)) {
        const matches = this.localComment.textContent.match(this.urlRegex);
        if (matches && matches.length > 0) {
          let content = this.localComment.textContent;
          matches.forEach((link) => {
            const href = /^(((https?|ftps?):)?\/\/)/i.test(link) ? link : `//${link}`;
            content = content
              .replaceAll(link, `<a href="${href}" class="text-blue-b-3-primary-blue" target="_blank">${link}</a>`);
          });
          return content;
        }
      }
      return this.localComment.textContent;
    }
    return null;
  }

  private get commentTime(): string {
    if (this.localComment.createdTime) {
      const createdTime = DateTimeHelper.toLocal(new Date(this.localComment.createdTime));
      return formatDistanceToNow(createdTime, {
        addSuffix: true,
        locale: this.dateLocale,
      });
    }
    return '';
  }

  private get localCommentFullName(): string {
    return `${this.localComment.author?.firstName} ${this.localComment.author?.lastName}`;
  }

  private get userJobTitle(): string {
    const { author } = this.comment;
    if (author) {
      const jobTitleEmployer: string[] = [];
      if (author.jobTitle) {
        jobTitleEmployer.push(author.jobTitle as string);
      }
      if (author.employerName) {
        jobTitleEmployer.push(`${author.employerName}`);
      }
      return jobTitleEmployer.join(` ${this.$tc('actions.at', 0, { employer: '' })} `);
    }
    return '';
  }

  private get authorPicturePath(): string {
    if (this.localComment?.author?.pictureFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.localComment.author.pictureFileResource), 'w40');
    }
    return '';
  }

  private get repliesCount(): number {
    if (this.localComment?._childrenCommentsCount) {
      return this.localComment._childrenCommentsCount;
    }
    return 0;
  }

  private get commentDeleted(): boolean {
    if (this.localComment.userDeleted) {
      return this.localComment.userDeleted;
    }
    return false;
  }

  mounted(): void {
    this.localComment = this.comment;
    this.numberOfLikes = this.comment._likesCount ? this.comment._likesCount : 0;
    this.myEditedComment = this.comment.textContent ? this.comment.textContent : '';
    if (this.commentLoaded) {
      this.loadAllReplies();
    }
    this.genericEvent({ channel: 'feed-post-comment' });
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.POST_COMMENT_CREATE_REPLY,
      NotificationEventType.POST_COMMENT_EDIT,
      NotificationEventType.POST_COMMENT_DELETE,
      NotificationEventType.POST_COMMENT_LIKE,
    ];
  }

  onBlur(): void {
    this.onFocus = false;
  }

  focusIn(): void {
    this.onFocus = true;
  }

  onChange(e: Event): void {
    if (e && e.target && this.myComment === '') {
      (e.target as HTMLElement).style.height = 'auto';
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.authUser !== this.authUser.uid) {
      switch (event.type) {
        case NotificationEventType.POST_COMMENT_CREATE_REPLY:
          if (Comment.hydrate(extraData.parentComment).uid === this.localComment.uid) {
            this.onPostCommentCreatedReplyFromEvent(Comment.hydrate(extraData.comment));
          }
          break;
        case NotificationEventType.POST_COMMENT_EDIT:
          if (extraData.comment.uid === this.localComment.uid) {
            this.onPostCommentEditFromEvent(Comment.hydrate(extraData.comment));
          }
          break;
        case NotificationEventType.POST_COMMENT_LIKE:
          if (extraData.comment.uid === this.localComment.uid) {
            this.onPostCommentLikeFromEvent(Comment.hydrate(extraData.comment));
          }
          break;
        case NotificationEventType.POST_COMMENT_DELETE:
          if (extraData.comment.uid === this.localComment.uid) {
            this.onPostCommentDeleteFromEvent();
          }
          break;
        default:
          break;
      }
    }
  }

  @Watch('myEditedComment')
  private updateEditSize(): void {
    const lines = this.myEditedComment.split(/\r\n|\r|\n/).length;
    this.commentEditRowCount = lines > 0 ? lines : 1;
  }

  @Watch('comment', { deep: true })
  private refreshComments(): void {
    this.localComment = this.comment;
    this.myEditedComment = this.comment.textContent ? this.comment.textContent : '';
    this.renderKey += 1;
  }

  private onEditComment(): void {
    this.isEditing = true;
  }

  private editComment(): void {
    this.localComment.textContent = this.myEditedComment.trim();
    this.triggerGenericEvent({
      channels: ['feed-post-comment'],
      type: NotificationEventType.POST_COMMENT_EDIT,
      entityId: this.comment.uid,
      extra: JSON.stringify({
        comment: this.localComment,
        authUser: this.authUser.uid,
      }),
    });
    this.updateComment({
      uid: this.localComment.uid,
      textContent: VueSecureHTML.escapeHTML(this.myEditedComment.trim()),
    })
      .then(() => {
        this.isEditing = false;
      });
  }

  private onLike(): void {
    if (!this.localComment._isLiked) {
      this.localComment._isLiked = !this.localComment._isLiked;
      this.numberOfLikes += 1;
      this.localComment._likesCount = this.numberOfLikes;
      this.likeComment({
        commentUid: this.localComment.uid,
        authUserId: this.authUser.uid,
      });
    } else {
      this.localComment._isLiked = !this.localComment._isLiked;
      this.numberOfLikes -= 1;
      this.localComment._likesCount = this.numberOfLikes;
      this.unlikeComment({
        commentUid: this.localComment.uid,
        authUserId: this.authUser.uid,
      });
    }
    this.triggerLikeEvent();
  }

  private triggerLikeEvent(): void {
    this.triggerGenericEvent({
      channels: ['feed-post-comment'],
      type: NotificationEventType.POST_COMMENT_LIKE,
      entityId: this.comment.uid,
      extra: JSON.stringify({
        comment: this.localComment,
        authUser: this.authUser.uid,
      }),
    });
  }

  private onReplyClick(): void {
    this.showReplyForm = true;
    this.onFocus = true;
    this.$nextTick(() => {
      this.addAutoResize();
      (this.$refs[`sendComment-${this.comment.uid}`] as HTMLTextAreaElement).focus();
    });
  }

  private sendComment(e: Event): void {
    const feedPost = this.getFeedPost();
    if (feedPost && this.myComment.length) {
      this.showReplyForm = false;
      if (this.repliesCount === 0) {
        this.isCreateFirstPost = true;
      }
      this.isLoading = true;
      this.createComment({
        feedPost,
        authUser: this.authUser,
        parentComment: this.localComment,
        entity: {
          textContent: VueSecureHTML.escapeHTML(this.myComment.trim()),
        },
      })
        .then((response) => {
          if (response) {
            if (this.localComment.childrenComments) {
              this.localComment.childrenComments.unshift(response);
            } else {
              this.localComment.childrenComments = [response];
            }
          }
          let childrenCommentsCount = this.localComment._childrenCommentsCount ?? 0;
          childrenCommentsCount += 1;
          this.localComment._childrenCommentsCount = childrenCommentsCount;
          this.offset += 1;
          this.renderKey += 1;
          this.myComment = '';
          if (!this.showCommentReplies) {
            this.onViewRepliesClick();
          }
          if (e && e.target) {
            (e.target as HTMLElement).style.height = 'auto';
          }
          this.isLoading = false;
          this.isCreateFirstPost = false;
          if (response) {
            this.triggerGenericEvent({
              channels: ['feed-post-comment'],
              type: NotificationEventType.POST_COMMENT_CREATE_REPLY,
              entityId: this.getFeedPost()?.uid || '',
              extra: JSON.stringify({
                parentComment: this.localComment,
                comment: response,
                authUser: this.authUser.uid,
              }),
            });
          }
        });
    }
  }

  private onDeleteComment(): void {
    this.$bvModal.show(`comment-delete-${this.localComment.uid}`);
  }

  private deleteCommentConfirm(): void {
    if (this.localComment.author?.uid === this.authUser.uid) {
      this.deleteComment(this.localComment.uid)
        .then((response) => {
          if (response) {
            this.localComment.userDeleted = true;
            this.triggerGenericEvent({
              channels: ['feed-post-comment'],
              type: NotificationEventType.POST_COMMENT_DELETE,
              entityId: this.comment.uid,
              extra: JSON.stringify({
                comment: this.localComment,
                authUser: this.authUser.uid,
              }),
            });
          }
        });
    }
  }

  private addAutoResize = (): void => {
    document.querySelectorAll<HTMLElement>('[data-autoresize]')
      .forEach((element) => {
        const offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', (event) => {
          if (event && event.target) {
            (event.target as HTMLElement).style.height = `${(event.target as HTMLElement).scrollHeight + offset}px`;
          }
        });
        element.removeAttribute('data-autoresize');
      });
  };

  private getFeedPost(): FeedPost | null {
    if (this.feedItems && this.feedItems.length > 0) {
      if (
        this.feedItems[0].action === 'FEEDPOST_CREATED'
          && this.feedItems[0].triggered
      ) {
        return this.feedItems[0].triggered as FeedPost;
      }
    }
    return null;
  }

  private onViewRepliesClick(): void {
    if (!this.showCommentReplies) {
      this.loadReplies();
    } else {
      this.showCommentReplies = false;
    }
  }

  private loadReplies(): void {
    if (!this.localComment.childrenComments || this.repliesCount > this.localComment.childrenComments?.length) {
      if (!this.showCommentReplies && (!this.localComment.childrenComments || !this.localComment.childrenComments.length)) {
        this.isLoading = true;
        this.getPaginatedComments({
          filter: {
            parentComment: { uid: this.localComment.uid },
          },
          authUser: this.authUser.uid,
          first: this.numberOfCommentPerSection,
          offset: this.offset,
        })
          .then((comments) => {
            if (comments) {
              if (this.localComment.childrenComments) {
                this.localComment.childrenComments.push(...comments);
              } else {
                this.localComment.childrenComments = [...comments];
              }
              this.offset += this.numberOfCommentPerSection;
            }
            this.isLoading = false;
            this.showCommentReplies = true;
          });
      } else {
        this.showCommentReplies = true;
      }
    } else {
      this.showCommentReplies = true;
    }
  }

  private loadAllReplies(): void {
    if (!this.localComment.childrenComments || this.repliesCount > this.localComment.childrenComments?.length) {
      if (!this.showCommentReplies && (!this.localComment.childrenComments || !this.localComment.childrenComments.length)) {
        this.isLoading = true;
        this.getAllComments({
          filter: {
            parentComment: { uid: this.localComment.uid },
          },
          authUser: this.authUser.uid,
        })
          .then((comments) => {
            if (comments) {
              if (this.localComment.childrenComments) {
                this.localComment.childrenComments.push(...comments);
              } else {
                this.localComment.childrenComments = [...comments];
              }
              this.offset += this.numberOfCommentPerSection;
            }
            this.isLoading = false;
            this.showCommentReplies = true;
          });
      } else {
        this.showCommentReplies = true;
      }
    } else {
      this.showCommentReplies = true;
    }
  }

  private loadMoreReplies(): void {
    this.getPaginatedComments({
      filter: {
        parentComment: { uid: this.localComment.uid },
      },
      authUser: this.authUser.uid,
      first: this.numberOfCommentPerSection,
      offset: this.offset,
    })
      .then((comments) => {
        if (comments) {
          if (this.localComment.childrenComments) {
            this.localComment.childrenComments.push(...comments);
          } else {
            this.localComment.childrenComments = [...comments];
          }
          this.offset += this.numberOfCommentPerSection;
          this.renderKey += 1;
        }
      });
  }

  private onPostCommentCreatedReplyFromEvent(comment: Comment): void {
    if (this.localComment.childrenComments) {
      this.localComment.childrenComments.unshift(comment);
    } else {
      this.localComment.childrenComments = [comment];
    }
    this.localComment._childrenCommentsCount = (this.localComment._childrenCommentsCount || 0) + 1;
    this.renderKey += 1;
  }

  private onPostCommentEditFromEvent(comment: Comment): void {
    this.localComment.textContent = comment.textContent;
  }

  private onPostCommentLikeFromEvent(comment: Comment): void {
    if (comment._isLiked) {
      this.numberOfLikes += 1;
    } else {
      this.numberOfLikes -= 1;
    }
    this.localComment._likesCount = this.numberOfLikes;
  }

  private onPostCommentDeleteFromEvent(): void {
    this.localComment.userDeleted = true;
  }
}
