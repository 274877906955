

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Meeting from '@/models/graphql/Meeting';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';

@Component
export default class MeetingSlotComponent extends Vue {
  @Prop({ default: null })
  private meeting!: Meeting;

  @Prop({ default: false })
  private active!: boolean;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private onlyCreator = false;

  private get time(): string {
    if (this.meeting && this.meeting.startTime) {
      return format(
        utcToZonedTime(`${this.meeting.startTime}Z`, this.selectedTzName),
        `${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale },
      ).replace(':00 ', '').toLowerCase()
        .replace(' ', '');
    }
    return '';
  }

  private get title(): string {
    if (this.meeting && this.meeting.subject) {
      return this.meeting.subject;
    }
    return '';
  }

  private get avatars(): Array<{src: string; name: string; abbr: string}> {
    const result = [];
    if (this.meeting && this.meeting.creatorUser) {
      result.push({
        src: CommunityUser.hydrate(this.meeting.creatorUser).profilePicture,
        name: CommunityUser.hydrate(this.meeting.creatorUser).fullName,
        abbr: CommunityUser.hydrate(this.meeting.creatorUser).nameAbbr,
      });
    }
    if (this.meeting && this.meeting.participants && this.meeting.participants.length > 0) {
      result.push(...this.meeting.participants.map((p) => ({
        src: CommunityUser.hydrate(p.user as CommunityUser).profilePicture,
        name: CommunityUser.hydrate(p.user as CommunityUser).fullName,
        abbr: CommunityUser.hydrate(p.user as CommunityUser).nameAbbr,
      })));
    }
    return result;
  }

  private mounted(): void {
    this.onlyCreator = this.$el && this.$el.getBoundingClientRect().width < 64;
    window.addEventListener('resize', () => {
      this.onlyCreator = this.$el && this.$el.getBoundingClientRect().width < 64;
    });
  }
}
