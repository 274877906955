export default {
  published: {
    status: 'published',
    variant: 'green',
    text: 'admin-panel.status.published',
  },
  scheduled: {
    status: 'scheduled',
    variant: 'yellow',
    text: 'admin-panel.status.scheduled',
  },
  unpublished: {
    status: 'unpublished',
    variant: 'white',
    text: 'admin-panel.status.unpublished',
  },
};
