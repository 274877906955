var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton',{staticClass:"rounded-pill filter",attrs:{"height":"2rem","type":"button","width":"5rem"}})]},proxy:true},{key:"default",fn:function(){return [_c('div',[_c('h4',{staticClass:"text-left mb-4"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.address.title'))+" ")]),_c('div',{staticClass:"d-flex flex-column w-100 row-gap-6"},[_c('div',{staticClass:"input-container w-100"},[_c('my-profile-input-text',{attrs:{"default-value":_vm.companyAddress.address1,"disabled":_vm.disabled,"label":("" + (_vm.$t('app.admin-panel.address.field.address-1')))},on:{"on-focus-out":function (newValue) { return _vm.onAddressChange('address1', newValue); }}})],1),_c('div',{staticClass:"input-container w-100"},[_c('my-profile-input-text',{attrs:{"default-value":_vm.companyAddress.address2,"disabled":_vm.disabled,"label":("" + (_vm.$t('app.admin-panel.address.field.address-2')))},on:{"on-focus-out":function (newValue) { return _vm.onAddressChange('address2', newValue); }}})],1),_c('div',{class:[
            'd-flex',
            {'column-gap-6': !_vm.isMobile},
            {'flex-column row-gap-6': _vm.isMobile} ]},[_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('my-profile-input-text',{attrs:{"default-value":_vm.address.city,"disabled":_vm.disabled,"label":("" + (_vm.$t('app.admin-panel.address.field.city')))},on:{"on-focus-out":function (newValue) { return _vm.onAddressChange('city', newValue); }}})],1),_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('my-profile-input-text',{attrs:{"default-value":_vm.address.state,"disabled":_vm.disabled,"label":("" + (_vm.$t('app.admin-panel.address.field.state')))},on:{"on-focus-out":function (newValue) { return _vm.onAddressChange('state', newValue); }}})],1)]),_c('div',{class:[
            'd-flex',
            {'column-gap-6': !_vm.isMobile},
            {'flex-column row-gap-6': _vm.isMobile} ]},[_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('my-profile-input-text',{attrs:{"default-value":_vm.address.postalCode,"disabled":_vm.disabled,"label":("" + (_vm.$t('app.admin-panel.address.field.zip')))},on:{"on-focus-out":function (newValue) { return _vm.onAddressChange('postalCode', newValue); }}})],1),_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('p',{staticClass:"mb-0 text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.address.field.country'))+" ")]),_c('input-select-options-component',{attrs:{"disabled":_vm.disabled,"is-icon":true,"only-select":true,"option-chosen-prop":_vm.address.country
                ? {
                  uid: _vm.address.country.uid,
                  value: _vm.address.country.iso,
                  text: _vm.address.country.printableName,
                  countryFlag: ("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.address.country.iso
                    .toLowerCase()) + ".svg")
                } : null,"options":_vm.parsedCountriesList,"boundary":"scrollParent","component-id":"address"},on:{"on-select":function (country) { return _vm.onAddressChange('country', country); }}})],1)])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }