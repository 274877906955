import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  inheritAttrs: false,
})
export default class VueBasePanelComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
    @Prop({ required: false, default: null })
    private readonly mobileOrder!: string;

    get order(): string {
      let { mobileOrder } = this;
      if (this.isDesktop || mobileOrder === null) {
        return '';
      }

      const num = Number(mobileOrder);

      if (!this.mobileOrder || Number.isNaN(num) || num > 5) {
        mobileOrder = '5';
      }

      return `order-${this.mobileOrder}`;
    }

    created(): void {
      this.setDataConfig();
    }
}
