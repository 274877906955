










































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputText from '@/components/InputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent, InputText, ButtonIconComponent },
})
export default class PaginationComponent extends VueContextLoading {
  @Prop({ required: true, default: 0 })
  private readonly totalRow!: number;

  @Prop({ required: false, default: 20 })
  private readonly perPage!: number;

  @Prop({ required: false, default: 1 })
  private currentPage!: number;

  @Prop({ required: false, default: false })
  private hideShowingOut!: boolean;

  private page = this.currentPage;

  private enterValidation = true;

  private get showingOut(): { from: number; to: number; out: number } {
    if (this.page > 0
      && this.page <= this.pages
      && Number.isInteger(this.page)
      && this.enterValidation) {
      const d = {
        from: this.page === 1 ? 1 : (this.page - 1) * this.perPage + 1,
        to: (this.page * this.perPage) > this.totalRow || this.totalRow < this.perPage
          ? this.totalRow
          : this.page * this.perPage,
        out: this.totalRow,
      };
      this.$emit('get-page-data', d);
      return d;
    }
    const d = {
      from: 1,
      to: (this.totalRow < this.perPage) ? this.totalRow : this.perPage,
      out: this.totalRow,
    };
    this.$emit('get-page-data', d);
    return {
      from: 1,
      to: (this.totalRow < this.perPage) ? this.totalRow : this.perPage,
      out: this.totalRow,
    };
  }

  private get pages(): number {
    return this.totalRow % this.perPage === 0
      ? this.totalRow / this.perPage
      : Math.trunc(this.totalRow / this.perPage) + 1;
  }

  private mounted(): void {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
      }).href);
  }

  @Watch('currentPage')
  private syncPageWithCurrent(): void {
    this.page = this.currentPage;
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
      }).href);
  }

  private onEnterPressed(): void {
    if (this.page > 0
      && this.page <= this.pages
      && Number.isInteger(this.page)) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null,
        '',
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
        }).href);
      this.$emit('on-page-change', this.page);
      this.enterValidation = true;
    }
  }

  private goToStart(): void {
    this.page = 1;
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
      }).href);
    this.$emit('on-page-change', this.page);
    this.enterValidation = true;
  }

  private goToLast(): void {
    this.page = this.pages;
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
      }).href);
    this.$emit('on-page-change', this.page);
    this.enterValidation = true;
  }

  private goToNext(): void {
    if (this.page < this.pages) {
      this.page += 1;
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null,
        '',
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
        }).href);
      this.$emit('on-page-change', this.page);
      this.enterValidation = true;
    }
  }

  private goToPrev(): void {
    if (this.page > 1) {
      this.page -= 1;
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null,
        '',
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query: Object.assign(this.$route.query, { page: Math.abs(this.page) }),
        }).href);
      this.$emit('on-page-change', this.page);
      this.enterValidation = true;
    }
  }
}
