


































































































































import Component from 'vue-class-component';
import OneTileMosaicComponent from '@/components/mosaic/tiles/OneTileMosaicComponent.vue';
import TwoTileMosaicComponent from '@/components/mosaic/tiles/TwoTileMosaicComponent.vue';
import ThreeTileMosaicComponent from '@/components/mosaic/tiles/ThreeTileMosaicComponent.vue';
import FourTileMosaicComponent from '@/components/mosaic/tiles/FourTileMosaicComponent.vue';
import { Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import TileComponent from '@/components/mosaic/tiles/TileComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    OneTileMosaicComponent,
    TwoTileMosaicComponent,
    ThreeTileMosaicComponent,
    FourTileMosaicComponent,
    TileComponent,
  },
})
export default class MosaicComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private list!: Array<Data>;

  @Prop({ required: true, default: 'mosaic' })
  private display!: string;

  @Prop({ required: true, default: 'Channel' })
  private type!: string;

  @Prop({ required: false, default: null })
  private readonly tileRoute!: string;

  private get mosaic(): Array<Array<Data>> {
    if (this.display === 'dynamic mosaic') {
      return this.organizedSplitAlgo(this.list);
    }
    return this.oldSplitAlgo(this.list);
  }

  // eslint-disable-next-line class-methods-use-this
  private organizedSplitAlgo(arr: Array<Data>): Array<Array<Data>> {
    const pattern = [1, 2, 3, 4];
    let index = 0;
    if (arr.length <= 2) return [arr];

    const res = new Array<Array<Data>>();
    let i = 0;
    let rnd;
    while (i < arr.length) {
      rnd = pattern[index % pattern.length];
      res.push(arr.slice(i, i + rnd));
      i += rnd;
      index += 1;
    }
    return res;
  }

  private oldSplitAlgo(arr: Array<Data>): Array<Array<Data>> {
    const orderedTiles: Data[][] = [];
    if (arr) {
      if (arr.length <= 4) {
        return [arr];
      }
      let groupTiles: Data[] = [];
      let groupCount = 1;
      let tilesCount = 0;
      const lastTileIndex = arr.length - 1;
      arr.forEach((tile) => {
        groupTiles.push(tile);
        if (groupCount < 4) {
          if (tilesCount === lastTileIndex) {
            orderedTiles.push(groupTiles);
          }
          groupCount += 1;
        } else if (groupCount === 4) {
          groupCount = 1;
          orderedTiles.push(groupTiles);
          groupTiles = [];
        }
        tilesCount += 1;
      }, this);
    }
    return orderedTiles;
  }

  private getGridMaxWidth = (tileLength: number): number => {
    let maxWidth = 1100;
    if (tileLength === 2) {
      maxWidth = 700;
    } else if (tileLength >= 3) {
      maxWidth = 350;
    }
    return maxWidth;
  }

  private tileDefaultHeight(tilesCount: number, index: number): string {
    let result = '';
    if (tilesCount === 4) {
      if (this.isMobile) {
        result = '16.5rem';
      } else {
        switch (this.breakpoint.value) {
          case 'lg':
          case 'xl':
            switch (index) {
              case 0:
              case 3:
                result = '33.75rem';
                break;
              case 1:
              case 2:
                result = '21.875rem';
                break;
              default:
                break;
            }
            break;
          case 'md':
            switch (index) {
              case 0:
              case 3:
                result = '20rem';
                break;
              case 1:
              case 2:
                result = '13rem';
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    } else if (tilesCount < 4) {
      switch (this.breakpoint.value) {
        case 'lg':
        case 'xl':
          switch (tilesCount) {
            case 1:
            case 2:
              result = '33.75rem';
              break;
            case 3:
              result = '21.875rem';
              break;
            default:
              break;
          }
          break;
        case 'md':
          switch (tilesCount) {
            case 1:
            case 2:
              result = '20rem';
              break;
            case 3:
              result = '13rem';
              break;
            default:
              break;
          }
          break;
        case 'sm':
        case 'xs':
          result = '16.5rem';
          break;
        default:
          break;
      }
    }
    return result;
  }
}
