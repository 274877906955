import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const DEAL_BASE_FRAGMENT = `
  fragment dealBaseFragment on Deal {
    uid
    schemaCode
    name
    title
    content
    startTime
    endTime
    displayFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
