import { SESSION_BASE_FRAGMENT } from '@/graphql/_Fragments/Session/Base';

export const SPEAKER_ROLE_WITHOUT_SPEAKER_FRAGMENT = `
  fragment speakerRoleWithoutSpeakerFragment on SpeakerRole {
    uid
    schemaCode
    role
    disclosure
    session {
      ...sessionBaseFragment
    }
  }
  ${SESSION_BASE_FRAGMENT}
`;
