import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SESSION_BASE_FRAGMENT = `
  fragment sessionBaseFragment on Session {
    uid
    schemaCode
    name
    shortName
    description
    descriptionHtml
    startTime
    startTimestamp
    endTime
    endTimestamp
    speakers
    featured
    parentSession {
      uid
    }
    showLiveChat
    showQNA
    showPolls
    imageFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _bookmarkCount
    likeCount: _likeCount
    dislikeCount: _dislikeCount
    loveCount: _loveCount
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
