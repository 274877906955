



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Category from '@/models/graphql/Category';
import Size from '@/utils/enums/Size';

@Component({
  components: { FontAwesomeComponent },
  inheritAttrs: false,
})
export default class PillCategoriesComponent extends Vue {
  @Prop({ required: true })
  readonly entityId!: string;

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: false, default: Size.NANO })
  readonly size!: Size;

  private characterLength = 35;

  private displayedCategories = [] as Category[];

  private extraCategories = [] as Category[];

  private sizeEnum = Size;

  created(): void {
    if (this.categories.length <= 2) {
      this.displayedCategories = this.categories;
    } else if (this.getCharacterLengthGroup()) {
      this.extraCategories = [...this.categories];
      this.displayedCategories = this.extraCategories.splice(0, 3);
    } else {
      this.extraCategories = [...this.categories];
      this.displayedCategories = this.extraCategories.splice(0, 2);
    }
  }

  private getCharacterLengthGroup(): boolean {
    const categoriesThreeFirstName = this.categories[0].name
      + this.categories[1].name
      + this.categories[2].name;

    return categoriesThreeFirstName.length <= this.characterLength;
  }
}
