import Vue from 'vue';
import ExtraProperty from '@/models/graphql/ExtraProperty';
import { Component } from 'vue-property-decorator';

@Component
export default class ProductCertificatePartial extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get extraProps(): ExtraProperty[] {
    return [];
  }

  get certificate(): string {
    const certificateField = this.extraProps
      .find((ext) => ext && ['NMMA_CERTIFIED', 'CERTIFICATE'].includes(ext.key));

    if (!certificateField) {
      return '';
    }

    if (certificateField
      && certificateField.value.toLowerCase().trim() === 'true'
      && certificateField.key === 'NMMA_CERTIFIED') {
      return 'nmma';
    }

    return certificateField.value.toLowerCase().trim();
  }
}
