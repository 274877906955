




































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import SelectedListParams from '@/utils/types/SelectedListParams';
import Variant from '@/utils/enums/Variant';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    StandardModal,
    PillWidget,
    FilterItemComponent,
    InputSearchComponent,
    ButtonIconComponent,
    ButtonComponent,
  },
})
export default class EntitySearchFilterModal extends VueBaseWidget {
  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly filterId!: string;

  @Prop({ required: true })
  private readonly filterList!: SelectedListParams[];

  @Prop({ required: false, default: [] })
  private readonly selectedIds!: string[];

  @Prop({ required: false, default: false })
  private isDateFilter!: boolean;

  private allFilterList = this.filterList;

  private filteredFilterList = this.filterList;

  private updatedSelectedIds = this.selectedIds;

  private selectedFilterList: SelectedListParams[] = [];

  private searchQuery = '';

  private variantEnum = Variant;

  mounted(): void {
    this.updateList();
  }

  private onConfirm(): void {
    this.$emit('on-confirm', this.updatedSelectedIds);
  }

  private onCancel(): void {
    this.$bvModal.hide(this.filterId);
    this.$emit('on-cancel');
  }

  private onSearch(payload: { query: string }): void {
    this.filteredFilterList = this.filterList;
    const search = payload.query;
    if (search.length > 0) {
      this.filteredFilterList = this.allFilterList
        .filter((filter: SelectedListParams) => filter.title.toLowerCase().includes(search.toLowerCase()));
    } else if (search.length === 0) {
      this.filteredFilterList = this.filterList;
    }
  }

  @Watch('filterList')
  private updateList(): void {
    this.filteredFilterList = this.filterList;
    this.allFilterList = [...this.filterList];
    const selectedIds = [...this.selectedIds];
    if (selectedIds.length === 0) {
      selectedIds.push('all');
    }
    this.updatedSelectedIds = selectedIds;
    this.updateSelectedFilterList();
  }

  @Watch('selectedIds')
  private updateSelectedIds(): void {
    const selectedIds = [...this.selectedIds];
    if (selectedIds.length === 0) {
      selectedIds.push('all');
    }
    this.updatedSelectedIds = selectedIds;
  }

  @Watch('updatedSelectedIds')
  private updateSelectedFilterList(): void {
    this.selectedFilterList = [];
    if (!this.updatedSelectedIds.includes('all')) {
      this.updatedSelectedIds.forEach((selectedId) => {
        const found = this.allFilterList.filter((filter) => filter.id === selectedId);
        if (found.length !== 0) {
          this.selectedFilterList.push(found[0]);
        }
      });
    }
  }

  private toggleFilterItem(id: string): void {
    let removeAll = false;
    const idIndex = this.updatedSelectedIds.findIndex((i) => i === id);
    if (id === 'all') {
      this.updatedSelectedIds = ['all'];
    } else if (idIndex < 0) {
      removeAll = this.updatedSelectedIds.includes('all');
      this.updatedSelectedIds.push(id);
    } else {
      removeAll = this.updatedSelectedIds.includes('all');
      this.updatedSelectedIds.splice(idIndex, 1);
    }

    const allIdIndex = this.updatedSelectedIds.findIndex((i) => i === 'all');
    if (removeAll) {
      this.updatedSelectedIds.splice(allIdIndex, 1);
    }

    const selectedIdsFilter = [...this.updatedSelectedIds];
    if (selectedIdsFilter.includes('all')) {
      selectedIdsFilter.splice(allIdIndex, 1);
    }

    if (this.updatedSelectedIds.length === 0) {
      this.updatedSelectedIds = ['all'];
    }
    this.updateFilterList();
  }

  private updateFilterList(): void {
    this.updatedSelectedIds.forEach((uid) => {
      this.allFilterList.forEach((f) => {
        f.selected = f.id === uid;
      });
    });
  }

  private onClearFilter(selectedFilter: SelectedListParams): void {
    this.toggleFilterItem(selectedFilter.id);
  }
}
