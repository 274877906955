var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"position-relative",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"filter"},[_c('div',{class:['filter-pill d-flex align-items-center bg-white user-select-none',
               'py-2 flex-nowrap align-self-start justify-content-center',
               {'selected': _vm.isOpen}],on:{"click":_vm.toggleRangeClick}},[_c('div',{staticClass:"pill d-flex align-items-center",class:[
          {'text-blue-b-3-primary-blue': _vm.hasSelection},
          {'text-blue-b-3-primary-blue': _vm.isOpen},
          {'text-neutral-n-6-label': !_vm.isOpen}
        ]},[_c('span',{staticClass:"button caps caps-3 bold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.filterLabel))+" ")]),(_vm.hasSelection)?_c('span',{staticClass:"button caps caps-3 bold"},[_vm._v("· 1")]):_vm._e()])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',{staticClass:"card p-3 position-absolute",class:{
        'range-card': !_vm.isMobile,
        'range-card-sm': _vm.isMobile
      }},[_c('div',{staticClass:"d-flex flex-row pb-3",staticStyle:{"gap":"0.5rem"}},[_c('div',{staticClass:"d-flex flex-column align-items-start flex-1",staticStyle:{"gap":"0.5rem"}},[_c('span',[_vm._v(_vm._s(_vm.$t('entity-search.entity-search-range-component.min')))]),_c('input-number-component',{attrs:{"default-value":_vm.rangeMin,"max":12,"min":1},on:{"update:defaultValue":function($event){_vm.rangeMin=$event},"update:default-value":function($event){_vm.rangeMin=$event}}})],1),_c('div',{staticClass:"d-flex flex-column align-items-start flex-1",staticStyle:{"gap":"0.5rem"}},[_c('span',[_vm._v(_vm._s(_vm.$t('entity-search.entity-search-range-component.max')))]),_c('input-number-component',{attrs:{"default-value":_vm.rangeMax,"max":12,"min":1},on:{"update:defaultValue":function($event){_vm.rangeMax=$event},"update:default-value":function($event){_vm.rangeMax=$event}}})],1)]),_c('div',{staticClass:"d-flex w-100 pt-3 bg-gray",staticStyle:{"gap":"0.5rem"}},[_c('button-component',{staticClass:"flex-1",attrs:{"text":_vm.$t('entity-search.entity-search-range-component.cancel'),"size":"md","variant":"gray"},on:{"on-click":_vm.onCancelFilter}}),_c('button-component',{staticClass:"flex-1",attrs:{"text":_vm.$t('entity-search.entity-search-range-component.apply'),"size":"md","variant":"primary"},on:{"on-click":_vm.onApplyFilter}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }