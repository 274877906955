


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class TopicsAboutComponent extends Vue {
  @Prop({ required: false, default: null })
  private readonly description!: string;
}
