import { POLL_ANSWER_BASE_FRAGMENT } from '@/graphql/_Fragments/PollAnswer/Base';
import { POLL_USER_ANSWER_BASE_FRAGMENT } from '@/graphql/_Fragments/PollUserAnswer/Base';

export const POLL_FULL_FRAGMENT = `
  fragment pollFullFragment on Poll {
    uid
    title
    realTimeAnswers
    startTime
    endTime
    startTimestamp
    endTimestamp
    pollAnswers(orderBy: displaySequence_asc) {
     ...pollAnswerBaseFragment
    }
    session {
      uid
      id
      schemaCode
      name
      startTime
      endTime
      startTimestamp
      endTimestamp
      parentSession {
        uid
        id
        schemaCode
        name
        startTime
        endTime
        startTimestamp
        endTimestamp
      }
    }
    answerCountByAnswerId {
      key
      value
    }
    _myAnswer(myUid: "%authUser%") {
      ...pollUserAnswerBaseFragment
    }
  }
  ${POLL_ANSWER_BASE_FRAGMENT}
  ${POLL_USER_ANSWER_BASE_FRAGMENT}
`;
