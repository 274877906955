import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const PRODUCT_IMAGE_BASE_FRAGMENT = `
  fragment productImageBaseFragment on ProductImage {
    uid
    schemaCode
    fullFileResource {
      ...fileResourceBaseFragment
    }
    thumbFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
