import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import Message from '@/models/graphql/Message';
import MessageQnaRepository from '@/repositories/MessageQnaRepository';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';

interface MessageState extends LoadableState {
  paginatedMessages: Message[];
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class QnaBaseStore extends LoadableStore<MessageState> {
  paginatedMessages: Message[] = [];

  private page = 0;

  private qnAcount = 0;

  private itemsPerPage = 10;

  private readonly messageRepository = new MessageQnaRepository();

  get getMessages(): Message[] {
    return this.paginatedMessages;
  }

  get getQnAcount(): number {
    return this.qnAcount;
  }

  protected get repository(): MessageQnaRepository {
    return this.messageRepository;
  }

  @Action
  loadPaginatedMessages(payload: {data: Record<string, BasicTypes>; offset: number; limit: number}): Promise<number | null> {
    this.context.commit('load', true);
    return this.repository.filter({
      definition: buildQueryDefinition({
        filter: {
          value: payload.data.filter as object | null | undefined,
          type: GqlEntityFilterType.MESSAGE_FILTER,
        },
        orderBy: {
          value: (payload.data.orderBy) ? payload.data.orderBy as string[] : ['senttimestamp_desc'],
          type: GqlEntityOrderingType.MESSAGE_ORDERING,
        },
        first: payload.limit,
        offset: payload.offset,
      }),
      operationName: 'PaginateMessages',
      fragmentName: 'messageFullFragment',
      authUser: payload.data.authUser as string,
    }).then((response) => {
      if (this.page === 0) {
        if (response.length !== 0) {
          this.context.commit('setPage', 1);
        }
      } else {
        this.context.commit('setPage', this.page + 1);
      }
      this.context.commit('addElements', response);
      this.context.commit('load', false);
      return response.length;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  // eslint-disable-next-line max-len
  loadPaginatedMessagesPresentation(payload: {data: Record<string, BasicTypes>; offset: number; limit: number}): Promise<number | null> {
    this.context.commit('load', true);
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    configs.push({
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: payload.data.filter as object | null | undefined,
          type: GqlEntityFilterType.MESSAGE_FILTER,
        },
        orderBy: {
          value: (payload.data.orderBy) ? payload.data.orderBy as string[] : ['senttimestamp_desc'],
          type: GqlEntityOrderingType.MESSAGE_ORDERING,
        },
        first: payload.limit,
        offset: payload.offset,
      }),
      fragmentName: 'messageFullFragment',
      operation: 'message',
    });
    configs.push({
      gqlDefinition: buildQueryDefinition({
        filter: {
          value: payload.data.filter as object | null | undefined,
          type: GqlEntityFilterType.MESSAGE_FILTER,
        },
        orderBy: {
          value: (payload.data.orderBy) ? payload.data.orderBy as string[] : ['senttimestamp_desc'],
          type: GqlEntityOrderingType.MESSAGE_ORDERING,
        },
      }),
      fragmentName: 'messageCountFragment',
      operation: 'message',
      alias: 'countMessage',
    });
    return this.repository.filterWithCount({
      params: {
        operationName: 'PaginateMessages',
        definitions: configs,
        magicArgs: { },
      },
      queries: '',
    }).then((response) => {
      let result = 0;
      Object.keys(response).forEach((k) => {
        const keys = k.split('_');
        if (keys[0] === 'message') {
          if (this.page === 0) {
            if (response[k].length !== 0) {
              this.context.commit('setPage', 1);
            }
          } else {
            this.context.commit('setPage', this.page + 1);
          }
          this.context.commit('addElements', response[k]);
          result = response[k].length;
        }
        if (keys[0] === 'countMessage') {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { _count } = (response[k] as { _count: number }[])[0];
          this.context.commit('setCount', _count);
        }
      });
      this.context.commit('load', false);
      return result;
    }).catch(() => new Promise((resolve) => resolve(null)));
  }

  @Action
  deleteMessage(uid: string): Promise<boolean> {
    return this.repository.delete({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: uid,
      }]),
    }).then((response) => !!response);
  }

  @Action
  updateMessage(payload: {uid: string; content: string}): Promise<Message | undefined> {
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.MESSAGE_INPUT,
        value: payload,
      }]),
    });
  }

  @Action
  createQnaMessage(payload: {
    userUid: string;
    groupUid: string;
    parentMessageUid: string | null;
    entity: Message;
  }): Promise<Message | undefined> {
    const filters = [
      {
        fieldName: 'user_CommunityUserUid',
        type: 'ID',
        value: payload.userUid,
      },
      {
        fieldName: 'group_MessageGroupUid',
        type: 'ID',
        value: payload.groupUid,
      },
      {
        fieldName: 'entity',
        type: GqlEntityInputType.MESSAGE_INPUT,
        value: payload.entity,
      },
    ];
    if (payload.parentMessageUid) {
      filters.push({
        fieldName: 'parentMessage_MessageUid',
        type: 'ID',
        value: payload.parentMessageUid,
      });
      return this.repository.createAnswer({
        definition: buildMutationDefinition(filters),
      });
    }
    return this.repository.createQuestion({
      definition: buildMutationDefinition(filters),
    });
  }

  @Action
  resetPage(): void {
    this.context.commit('setPage', 0);
    this.context.commit('setPaginatedMessages', []);
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }

  @Mutation
  setCount(count: number): void {
    this.qnAcount = count;
  }

  @Mutation
  addElements(messages: Message[]): void {
    this.paginatedMessages = [...this.paginatedMessages, ...messages];
  }

  @Mutation
  setPaginatedMessages(list: Message[]): void {
    this.paginatedMessages = list;
  }
}
