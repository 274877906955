export const COMMUNITY_USER_PREFERENCES_BASE_FRAGMENT = `
  fragment communityUserPreferencesBaseFragment on CommunityUserPreferences {
    uid
    schemaCode
    emailConnectionRequest
    emailNewMessage
    emailMeetingInvitation
    emailCompanyInvitation
  }
`;
