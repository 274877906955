import { render, staticRenderFns } from "./DropdownComponent.vue?vue&type=template&id=12d10ce4&scoped=true&"
import script from "./DropdownComponent.vue?vue&type=script&lang=ts&"
export * from "./DropdownComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/form/DropdownComponent.scss?vue&type=style&index=0&id=12d10ce4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d10ce4",
  null
  
)

export default component.exports