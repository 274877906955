var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"filter",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{class:['filter-pill d-flex align-items-center bg-white user-select-none',
               'py-2 flex-nowrap align-self-start justify-content-center',
               {'selected': _vm.showList}],on:{"click":_vm.toggleListClick}},[_c('div',{staticClass:"pill d-flex align-items-center",class:[
          {'text-blue-b-3-primary-blue': (_vm.selectedIds.length > 0
            && !_vm.selectedIds.includes('all') || _vm.showList)},
          {'text-neutral-n-6-label': !_vm.showList}
        ]},[_c('span',{staticClass:"button caps caps-3 bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.selectedIds.length > 0 && !_vm.selectedIds.includes('all'))?_c('span',{staticClass:"button caps caps-3 bold"},[_vm._v("·")]):_vm._e(),(_vm.selectedIds.length > 0 && !_vm.selectedIds.includes('all'))?_c('span',{staticClass:"button caps caps-3 bold"},[_vm._v(" "+_vm._s(_vm.selectedIds.length)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showList),expression:"showList"}],staticClass:"filter-list bg-white pt-4 filter-shadow position-fixed",style:(_vm.styles)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"body d-flex"},[(_vm.loading)?_c('loading-spinner-component',{staticClass:"py-4"}):_vm._e(),(!_vm.loading && _vm.list.length > 0)?_c('div',{staticClass:"list h-100 w-100 d-flex flex-wrap gap-2"},[_vm._l((_vm.showedFilterList),function(item,i){return _c('filter-item-component',{key:((item.id) + "-" + i),attrs:{"id":item.id,"no-padding":true,"selected":_vm.selectedIds.findIndex(function (u) { return u === item.id; }) > -1,"title":("" + (_vm.isDateFilter ? _vm.$t(item.title) : item.title))},on:{"on-change":_vm.toggleFilterItem}})}),(_vm.showedFilterList.length !== _vm.filterList.length)?_c('div',{staticClass:"d-flex align-items-center pr-2 pb-2 more-button"},[_c('pill-widget',{attrs:{"hover":true,"icon":{
                      'iconDef': 'fa-regular fa-ellipsis',
                      'iconClass': 'icon-16'
                    },"variant":_vm.variantEnum.WHITE,"decoration":"icon","size":"md"},on:{"on-click":_vm.toggleModal}})],1):_vm._e()],2):_vm._e(),(!_vm.loading && _vm.list.length === 0)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$t('filter-component.empty-state.empty-list')),expression:"$t('filter-component.empty-state.empty-list')"}],staticClass:"m-0 paragraph-1 text-center w-100 text-neutral-n-6-label py-4"}):_vm._e()],1)])])])],1)]),_c('entity-search-filter-modal',{attrs:{"filter-id":_vm.code,"filter-list":_vm.filterList,"is-date-filter":_vm.isDateFilter,"label":_vm.title,"selected-ids":_vm.selectedIds},on:{"on-confirm":_vm.filterModalConfirm,"on-cancel":_vm.filterModalCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }