







































































































































import Category from '@/models/graphql/Category';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { Component, Prop } from 'vue-property-decorator';
import {
  format, isAfter, isBefore, isWithinInterval, parse,
} from 'date-fns';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import Session from '@/models/graphql/Session';
import LiveSessionComponent from '@/components/video-player/LiveSessionComponent.vue';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BaseModel from '@/models/BaseModel';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import CommunityUser from '@/models/graphql/CommunityUser';

const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    FontAwesomeComponent,
    LiveSessionComponent,
    RecommendationBadgeComponent,
  },
  inheritAttrs: false,
})
export default class SessionPageHeaderComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayFeaturedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayLiveBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly displayInterestedBadge!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly _actions!: { key: string; value: string }[];

  @Prop({ required: false })
  private readonly id!: number;

  @Prop({ required: false })
  private readonly uid!: string;

  @Prop({ required: false })
  private readonly name!: string;

  @Prop({ required: false })
  private readonly parentSession!: BaseModel;

  @Prop({ required: false })
  private readonly startTime!: string;

  @Prop({ required: false })
  private readonly endTime!: string;

  @Prop({ required: false })
  private readonly startTimestamp!: string;

  @Prop({ required: false })
  private readonly endTimestamp!: string;

  @Prop({ required: false })
  private readonly videoEnabledStartTime!: string;

  @Prop({ required: false })
  private readonly videoEnabledEndTime!: string;

  @Prop({ required: false })
  private readonly videoType!: string;

  @Prop({ required: false })
  private readonly videoEnabled!: boolean;

  @Prop({ required: false })
  private readonly showLiveChat!: boolean;

  @Prop({ required: false })
  private readonly showQNA!: boolean;

  @Prop({ required: false })
  private readonly showPolls!: boolean;

  @Prop({ required: false })
  private readonly videoLiveEnabled!: boolean;

  @Prop({ required: false })
  private readonly videoArchiveEnabled!: boolean;

  @Prop({ required: false })
  private readonly videoLiveEndpoint!: string;

  @Prop({ required: false })
  private readonly videoArchiveEndpoint!: string;

  @Prop({ required: false })
  private readonly videoLiveEmbedEndpoint!: string;

  @Prop({ required: false })
  private readonly videoArchiveEmbedEndpoint!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly bannerFileResource!: FileResource;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly categoriesInContext!: Category[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly moderators!: CommunityUser[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly featured!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly _isRecommendedForMe!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly _isBookmarked!: boolean;

  @Prop({
    required: false,
    default: 0,
  })
  private readonly _bookmarkCount!: number;

  @Getter
  private readonly community!: Community;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private ActionRuleService = ActionRuleService;

  private session: Session = Session.hydrate({
    id: this.id,
    uid: this.uid,
    name: this.name,
    parentSession: this.parentSession,
    moderators: this.moderators,
    startTime: this.startTime,
    endTime: this.endTime,
    startTimestamp: this.startTimestamp,
    endTimestamp: this.endTimestamp,
    videoEnabledStartTime: this.videoEnabledStartTime,
    videoEnabledEndTime: this.videoEnabledEndTime,
    showLiveChat: this.showLiveChat,
    showQNA: this.showQNA,
    showPolls: this.showPolls,
    videoType: this.videoType,
    videoEnabled: this.videoEnabled,
    videoLiveEnabled: this.videoLiveEnabled,
    videoLiveEndpoint: this.videoLiveEndpoint,
    videoLiveEmbedEndpoint: this.videoLiveEmbedEndpoint,
    videoArchiveEnabled: this.videoArchiveEnabled,
    videoArchiveEndpoint: this.videoArchiveEndpoint,
    videoArchiveEmbedEndpoint: this.videoArchiveEmbedEndpoint,
  });

  private get showLiveSession(): boolean {
    return this.session && !!this.session.videoEnabled
        && (this.isLive
            || (this.isRecorded
                && (this.videoRecordedNoTimes || (this.videoRecordedCorrectStartTime && this.videoRecordedCorrectEndTime))));
  }

  private get isLive(): boolean {
    return !!this.session.videoLiveEnabled && this.session.videoType === 'live';
  }

  private get isRecorded(): boolean {
    return !!this.session.videoArchiveEnabled
        && this.session.videoType === 'recorded';
  }

  private get videoRecordedNoTimes(): boolean {
    return !this.session.videoEnabledStartTime
        && !this.session.videoEnabledEndTime;
  }

  private get videoRecordedCorrectStartTime(): boolean {
    return !!(this.session.videoEnabledStartTime
        && isBefore(
          DateTimeHelper.toUTC(
            parse(this.session.videoEnabledStartTime.toString(),
              DateTimeHelper.TIME_FORMAT_ISO_8601, new Date()),
          ),
          DateTimeHelper.toUTC(new Date()),
        ));
  }

  private get videoRecordedCorrectEndTime(): boolean {
    return !!(this.session.videoEnabledEndTime
        && isAfter(
          DateTimeHelper.toUTC(parse(
            this.session.videoEnabledEndTime.toString(),
            DateTimeHelper.TIME_FORMAT_ISO_8601, new Date(),
          )),
          DateTimeHelper.toUTC(new Date()),
        ));
  }

  private get canViewVideo(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this.ActionRuleService.isRuleValid(this._actions, ActionRule.CAN_VIEW_LIVE_VIDEO);
  }

  private get bannerSrc(): string {
    if (this.bannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.bannerFileResource, 'w1920')})`;
    }
    if (this.community.defaultSessionBannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.community.defaultSessionBannerFileResource, 'w1920')})`;
    }
    return 'url(/img/banner/empty.svg)';
  }

  private get sessionState(): string {
    if (this.session.videoEnabled
        && this.session.videoLiveEnabled
        && this.session.videoType === 'live'
        && this.session.startTime
        && this.session.endTime) {
      let { startTime } = this;
      let { endTime } = this;
      if (this.session.videoEnabledStartTime && this.session.videoEnabledEndTime) {
        startTime = this.session.videoEnabledStartTime;
        endTime = this.session.videoEnabledEndTime;
      }
      const today = DateTimeHelper.toUTC(new Date());
      if (isBefore(
        DateTimeHelper.toUTC(parse(startTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
        DateTimeHelper.toUTC(parse(endTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
      ) && isWithinInterval(today, {
        start: DateTimeHelper.toUTC(parse(startTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
        end: DateTimeHelper.toUTC(parse(endTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
      })) {
        return 'live';
      }
      if (isBefore(
        today,
        DateTimeHelper.toUTC(parse(startTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
      )) {
        return 'soon';
      }
      if (isAfter(
        today,
        DateTimeHelper.toUTC(parse(endTime.toString(), DateTimeHelper.TIME_FORMAT_ISO_8601, new Date())),
      )) {
        return 'archived';
      }
    }
    return '';
  }

  private get categories(): string {
    const categories = '';
    if (this
        && this.categoriesInContext) {
      if (Array.isArray(this.categoriesInContext)
          && (this.categoriesInContext as Category[]).length > 0) {
        const entity = this.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 3)
            .map((c) => c.name)
            .join(', ')}
          ${entity.length > 3 ? ` + ${entity.length - 3}` : ''}`;
        }
      }
    }
    return categories;
  }

  private get showRecordedAlert(): boolean {
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${this.session.videoEnabledStartTime}Z`,
      this.selectedTzName,
    );
    return !!(this.session.videoEnabled
        && this.session.videoType === 'recorded'
        && this.session.videoEnabledStartTime
        && isBefore(new Date(), startTime));
  }

  private get recordedAlertDateTime(): { date: string; time: string } {
    if (this.session.videoEnabledStartTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.session.videoEnabledStartTime}Z`,
        this.selectedTzName,
      );
      return {
        date: format(
          startTime,
          `${this.$t('app.date.defaultDateFormat')}`,
          { locale: this.dateLocale },
        ),
        time: format(
          startTime,
          `${this.$t('app.date.defaultTimeFormat')}`,
          { locale: this.dateLocale },
        ),
      };
    }
    return {
      date: '',
      time: '',
    };
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.SESSION_VIDEO_CHANGE,
    ];
  }

  beforeDestroy(): void {
    if (this.session) {
      this.unsubscribeGenericEventPointer(`session-settings-${this.session.uid}`);
    }
  }

  mounted(): void {
    this.session = Session.hydrate({
      id: this.id,
      uid: this.uid,
      name: this.name,
      parentSession: this.parentSession,
      moderators: this.moderators,
      startTime: this.startTime,
      endTime: this.endTime,
      startTimestamp: this.startTimestamp,
      endTimestamp: this.endTimestamp,
      videoEnabledStartTime: this.videoEnabledStartTime,
      videoEnabledEndTime: this.videoEnabledEndTime,
      showLiveChat: this.showLiveChat,
      showQNA: this.showQNA,
      showPolls: this.showPolls,
      videoType: this.videoType,
      videoEnabled: this.videoEnabled,
      videoLiveEnabled: this.videoLiveEnabled,
      videoLiveEndpoint: this.videoLiveEndpoint,
      videoLiveEmbedEndpoint: this.videoLiveEmbedEndpoint,
      videoArchiveEnabled: this.videoArchiveEnabled,
      videoArchiveEndpoint: this.videoArchiveEndpoint,
      videoArchiveEmbedEndpoint: this.videoArchiveEmbedEndpoint,
    });
  }

  protected notificationCallback(event: GenericEvent): void {
    if (event
        && event.type === NotificationEventType.SESSION_VIDEO_CHANGE
        && this.session && event.entityId === this.session.uid) {
      const extraData = JSON.parse(event.extra);
      this.session.videoEnabled = !extraData.disabled;
      if (extraData.isLive) {
        this.session.videoLiveEnabled = true;
        this.session.videoArchiveEnabled = false;
        this.session.videoLiveEmbedEndpoint = extraData.videoUrl;
        this.session.videoType = 'live';
      } else if (extraData.isRecorded) {
        this.session.videoLiveEnabled = false;
        this.session.videoArchiveEnabled = true;
        this.session.videoArchiveEmbedEndpoint = extraData.videoUrl;
        this.session.videoType = 'recorded';
      }
    }
  }
}
