import { render, staticRenderFns } from "./SessionSettingsComponent.vue?vue&type=template&id=61a1b078&scoped=true&"
import script from "./SessionSettingsComponent.vue?vue&type=script&lang=ts&"
export * from "./SessionSettingsComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/MainSettingsPage.scss?vue&type=style&index=0&id=61a1b078&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61a1b078",
  null
  
)

export default component.exports