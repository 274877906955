export const COMMENT_BASE_FRAGMENT = `
  fragment commentBaseFragment on Comment {
    uid
    textContent
    parentComment {
        uid
        textContent
        parentComment{
            uid
            textContent
            parentComment{
                uid
                textContent
            }
        }
    }
    createdTime
    updatedTime
    createdTimestamp
    updatedTimestamp
    likeUsers {
        uid
        firstName
        lastName
        name
        pictureFileResource{
            schemaCode
            path
        }
    }
    author {
        uid
        firstName
        lastName
        name
        jobTitle
        employerName
        pictureFileResource {
            schemaCode
            path
        }
        bannerFileResource {
            schemaCode
            path
        }
    }
    _isLiked(myUid: "%authUser%")
    _likesCount
    _childrenCommentsCount
    userDeleted
  }
`;
