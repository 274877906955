








































import { Component } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';

@Component({
  components: { FontAwesomeComponent, ButtonComponent },
})

export default class Forbidden extends Vue {
  @Getter
  protected readonly community!: Community;

  // eslint-disable-next-line class-methods-use-this
  private tryAgain(): void {
    window.location.reload();
  }
}
