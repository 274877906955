import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import EntityType from '@/utils/enums/EntityType';
import ActionButtonType from '@/utils/enums/ActionButtonType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Component({
  inheritAttrs: false,
})
export default class VueBaseActionButtonWidget extends mixins(BreakpointWrapper, VueBaseNotify) {
  @Getter
  protected readonly community!: Community;

  @Getter
  protected readonly authUser!: CommunityUser;

  @Prop({ required: true })
  protected readonly actionType!: string;

  @Prop({ required: true })
  protected readonly entityUid!: string;

  @Prop({ required: true })
  protected readonly entityType!: string;

  @Prop({
    required: false,
    default: 0,
  })
  protected readonly entityId!: number;

  @Prop({
    required: false,
    default: null,
  })
  protected readonly entityName!: string;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly dropdownStyle!: boolean;

  @Prop({ required: true })
  protected readonly index!: number;

  @Prop({ required: true })
  protected readonly isVisible!: boolean;

  @Prop({ required: true })
  protected readonly reRender!: boolean;

  @Prop({ required: true })
  protected readonly visibilityStoreName!: string;

  @Prop({ required: true })
  protected readonly modalPortalName!: string;

  protected EntityTypeEnum = EntityType;

  protected ActionButtonType = ActionButtonType;

  protected get communityCode(): string {
    return this.community.code as string;
  }

  protected getter<T>(name: string): T | null {
    if (this.visibilityStoreName) {
      return this.$store.getters[`${this.visibilityStoreName}/${name}`];
    }
    return null;
  }

  protected commit(name: string, payload?: BasicTypes): void {
    if (this.visibilityStoreName) {
      this.$store.commit(`${this.visibilityStoreName}/${name}`, payload);
    }
  }

  protected dispatch<T>(name: string, payload?: BasicTypes): Promise<T> | null {
    if (this.visibilityStoreName) {
      return this.$store.dispatch(`${this.visibilityStoreName}/${name}`, payload);
    }
    return null;
  }

  protected logStats(category: string, action: string, name: string): void {
    this.$logger.logMatomoStats(
      this.authUser,
      this.communityCode,
      this.entityType,
      action,
      name,
      this.entityId,
      this.entityUid,
      category,
      this.$i18n.locale,
    );
  }
}
