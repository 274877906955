

































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import ExhibitorTag from '@/models/graphql/ExhibitorTag';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { namespace } from 'vuex-class';
import { StateChanger } from 'vue-infinite-loading';
import { TAG_LIST } from '@/utils/constants/PaginationOffsets';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import TagComponent from '@/components/tag/TagComponent.vue';
import TagListItem from '@/components/TagListItem.vue';
import { debounce } from '@/utils/Debounce';

const exhibitorTagStore = namespace('ExhibitorTagStore');

@Component({
  components: {
    TagListItem,
    TagComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    StandardModal,
  },
})
export default class ExhibitorTagModal extends BreakpointWrapper {
  @exhibitorTagStore.Action('filter')
  private loadPaginatedTags!: (payload: { filter: object; offset: number; first: number }) => Promise<ExhibitorTag[]>;

  @exhibitorTagStore.Action
  private addTargetLinkOnBatch!: (payload: Array<{
    tagUid: string;
    targetUid: string;
  }>) => Promise<Record<string, ExhibitorTag>[]>;

  @exhibitorTagStore.Action
  private removeTargetLinkOnBatch!: (payload: Array<{
    tagUid: string;
    targetUid: string;
  }>) => Promise<Record<string, ExhibitorTag>[]>;

  @Prop({
    required: true,
  })
  private modalId!: string;

  @Prop({
    required: true,
  })
  private exhibitorUid!: string;

  @Prop({
    required: true,
  })
  private targetUid!: string;

  @Prop({
    required: true,
  })
  private targetType!: string;

  @Prop({
    required: true,
    default: () => [],
  })
  private selectedTags!: ExhibitorTag[];

  private localSelectedTags: ExhibitorTag[] = JSON.parse(JSON.stringify(this.selectedTags));

  private localTags: ExhibitorTag[] = [];

  private searchQuery = '';

  private offset = 0;

  private isLoading = false;

  private isSaveBtnLoading = false;

  private debouncedHandleInputChange = debounce(this.onSearch, 300);

  private get localSelectedTagsUids(): string[] {
    return this.localSelectedTags.map((t) => t.uid);
  }

  private initModal(): void {
    this.localSelectedTags = JSON.parse(JSON.stringify(this.selectedTags));
    (this.$refs.InfiniteLoading as unknown as Vue).$emit('$InfiniteLoading:reset');
  }

  private setFocus(): void {
    (this.$refs.search as HTMLInputElement).focus({ preventScroll: true });
  }

  private onSearch(): void {
    this.isLoading = true;
    this.offset = 0;
    this.localTags = [];
    (this.$refs.InfiniteLoading as unknown as Vue).$emit('$InfiniteLoading:reset');
  }

  private manageLocalSelectedTags(tag: ExhibitorTag): void {
    if (this.localSelectedTagsUids.includes(tag.uid)) {
      this.localSelectedTags.splice(
        this.localSelectedTags.findIndex((t) => t.uid === tag.uid),
        1,
      );
    } else {
      this.localSelectedTags.push(tag);
    }
  }

  private reset(): void {
    this.localTags = [];
    this.localSelectedTags = [];
    this.searchQuery = '';
    this.offset = 0;
    this.isLoading = false;
    this.isSaveBtnLoading = false;
  }

  private onSave(): void {
    this.isSaveBtnLoading = true;
    const initialTagsUids = this.selectedTags.map((t) => t.uid);
    const tagsToBeAdded = this.localSelectedTags
      .filter((tag) => !initialTagsUids.includes(tag.uid))
      .map((tag) => ({
        tagUid: tag.uid,
        targetUid: this.targetUid,
      }));
    const tagsToBeRemoved = this.selectedTags
      .filter((tag) => !this.localSelectedTagsUids.includes(tag.uid))
      .map((tag) => ({
        tagUid: tag.uid,
        targetUid: this.targetUid,
      }));
    const promises = [];
    if (tagsToBeRemoved.length > 0) {
      promises.push(this.removeTargetLinkOnBatch(tagsToBeRemoved));
    }
    if (tagsToBeAdded.length > 0) {
      promises.push(this.addTargetLinkOnBatch(tagsToBeAdded));
    }
    Promise.all(promises)
      .then(() => {
        this.$emit('on-new-selected-tags', this.localSelectedTags);
      })
      .finally(() => {
        this.isSaveBtnLoading = false;
        this.$bvModal.hide(this.modalId);
      });
  }

  private infiniteHandler(state: StateChanger): void {
    this.isLoading = true;
    // eslint-disable-next-line @typescript-eslint/camelcase
    const searchFilter = this.searchQuery.length > 0 ? { name_contains: this.searchQuery } : {};
    this.loadPaginatedTags({
      filter: {
        ...searchFilter,
        exhibitor: {
          uid: this.exhibitorUid,
        },
      },
      offset: this.offset,
      first: TAG_LIST,
    })
      .then((tags) => {
        if (this.offset === 0) {
          this.localTags = tags;
        } else {
          this.localTags.push(...tags);
        }
        if (tags.length === TAG_LIST) {
          state.loaded();
          this.offset += TAG_LIST;
        } else {
          state.complete();
          this.offset = 0;
        }
      })
      .catch(() => state.complete())
      .finally(() => {
        this.isLoading = false;
      });
  }
}
