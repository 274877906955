







































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { StateChanger } from 'vue-infinite-loading';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import { BasicTypes } from '@/utils/types/BasicTypes';
import Message from '@/models/graphql/Message';
import MessageBoxBubbleComponent from '@/components/chat/MessageBoxBubbleComponent.vue';
import MessageType from '@/utils/enums/chat/MessageType';
import MessageBoxWriteComponent from '@/components/chat/MessageBoxWriteComponent.vue';
import VueSecureHTML from 'vue-html-secure';
import CommunityUser from '@/models/graphql/CommunityUser';
import MessageBoxAnswerComponent from '@/components/chat/MessageBoxAnswerComponent.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import ActionType from '@/utils/enums/ActionType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import MessageGroupPermission from '@/utils/enums/chat/MessageGroupPermission';
import EntityType from '@/utils/enums/EntityType';
import Session from '@/models/graphql/Session';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import InputText from '@/components/InputText.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import QnaRegisterStore from '@/components/qna/QnaRegisterStore';

const messageGroupStore = namespace('MessageGroupStore');
const notificationStore = namespace('NotificationStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    InputSearchComponent,
    InputText,
    DropdownMenuItem,
    FontAwesomeComponent,
    MessageBoxWriteComponent,
    IllustrationComponent,
    LoadingSpinnerComponent,
    MessageBoxAnswerComponent,
    MessageBoxBubbleComponent,
  },
})
/* eslint-disable max-len,no-underscore-dangle,@typescript-eslint/camelcase */
export default class SessionCmsQaComponent extends mixins(BreakpointWrapper, VueBaseNotify, QnaRegisterStore) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @permissionManagerStore.Getter
  protected canModerate!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly sessionUid!: string;

  @Prop({ default: 'ChatStore' })
  private readonly storeContext!: string;

  @Prop({
    required: false,
    default: true,
  })
  private readonly canUnsubscribe!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly lonelyComponent!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly autofocus!: boolean;

  @Prop({ default: 'QnaStore' })
  private readonly storeName!: string;

  qnaStoreName = this.storeName;

  @Getter
  private authUser!: CommunityUser;

  @messageGroupStore.Action
  private addUser!: (payload: {
    userUid: string;
    groupUid: string;
  }) => Promise<boolean | undefined>;

  @messageGroupStore.Action(ActionType.CREATE)
  private createMessageGroup!: (payload: {
    targetUid: string;
    messageGroup: MessageGroup;
  }) => Promise<MessageGroup | undefined>;

  @messageGroupStore.Action
  private updateMessageGroup!: (payload: MessageGroup) => Promise<MessageGroup | undefined>;

  @messageGroupStore.Action(ActionType.GET_ONE)
  private getMessageGroup!: (payload: Record<string, BasicTypes>) => Promise<MessageGroup | undefined>;

  @messageGroupStore.Getter
  private messageGroup!: MessageGroup;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private MessageEntity = Message;

  private messageToAnswer: Message | null = null;

  private myAnswer = '';

  private localMessages: Message[] = [];

  private filtered = false;

  private localMessageGroup: MessageGroup | null = null;

  private offset = 0;

  private limit = 10;

  private IllustrationType = IllustrationType;

  private EntityType = EntityType;

  private partialAuthUser = {} as CommunityUser;

  private openedMenu = false;

  private FeatureKeys = FeatureKeys;

  private showViewAllButton = false;

  private tempAnswerText = '';

  public get canModerateOrIsSpeaker(): boolean {
    return this.authUser && (this.canModerate
        || (this.authUser.speakers.length > 0
            && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE)
            && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE).enabled));
  }

  private get getMessages(): Message[] {
    if (this.storeCreated) {
      return this.getter<Message[]>('getMessages') || [];
    }
    return [];
  }

  private get messageActionPermission(): MessageGroupPermission {
    if (this.authUser && this.localMessageGroup) {
      if (this.localMessageGroup.isSessionModerator) {
        return MessageGroupPermission.ADMINISTRATOR;
      }
      return MessageGroupPermission.STANDARD;
    }
    return MessageGroupPermission.GUEST;
  }

  private get selectedGroup(): MessageGroup {
    return this.$store.getters[`ChatDispatcherStore/${this.storeContext}/selectedGroup`];
  }

  private get targetSession(): Session | null {
    return this.selectedGroup
    && this.selectedGroup.target
    && this.selectedGroup.target.__typename === EntityType.SESSION
      ? this.selectedGroup.target as Session
      : null;
  }

  private get yourAreBanned(): boolean {
    if (this.authUser
        && !!this.targetSession
        && this.selectedGroup
        && this.selectedGroup.users
        && this.selectedGroup.users.length > 0) {
      return !!this.selectedGroup.users.find((u) => u.uid === this.authUser.uid && u.banned);
    }
    return false;
  }

  private get isQnaClosed(): boolean {
    return (!(this.localMessageGroup && this.localMessageGroup.active)
            && !(this.featureByKey(FeatureKeys.COMMUNITY_AUTO_OPEN_SESSION_QNA)))
        || !!(this.localMessageGroup && !this.localMessageGroup.active);
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.QA_NEW_QUESTION,
      NotificationEventType.QA_DELETE_QUESTION,
      NotificationEventType.QA_NEW_ANSWER,
      NotificationEventType.QA_EDIT_ANSWER,
      NotificationEventType.QA_DELETE_ANSWER,
      NotificationEventType.QA_OPEN,
      NotificationEventType.QA_CLOSE,
      NotificationEventType.QA_GROUP_CREATE,
    ];
  }

  beforeDestroy(): void {
    if (this.canUnsubscribe) {
      this.unsubscribeGenericEventPointer(`qa-channel-${this.sessionUid}`);
    }
  }

  mounted(): void {
    if (this.authUser) {
      this.partialAuthUser = {
        uid: this.authUser.uid,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        pictureFileResource: this.authUser.pictureFileResource,
      } as CommunityUser;
    }
    this.offset = 0;
    this.resetPage();
    this.genericEvent({
      channel: `qa-channel-${this.sessionUid}`,
    });
    if (this.sessionUid) {
      this.initMessageGroup();
    }

    if (this.lonelyComponent) {
      this.loadMessages();
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.user && event) {
      switch (event.type) {
        case NotificationEventType.QA_NEW_QUESTION:
          this.newQuestionFromEvent(event);
          break;
        case NotificationEventType.QA_NEW_ANSWER:
          this.newAnswerFromEvent(event);
          break;
        case NotificationEventType.QA_EDIT_ANSWER:
          this.editAnswerFromEvent(event);
          break;
        case NotificationEventType.QA_DELETE_QUESTION:
          this.deleteQuestionFromEvent(event);
          break;
        case NotificationEventType.QA_DELETE_ANSWER:
          this.deleteAnswerFromEvent(event);
          break;
        case NotificationEventType.QA_OPEN:
          this.openCloseQaFromEvent(event, true);
          break;
        case NotificationEventType.QA_CLOSE:
          this.openCloseQaFromEvent(event, false);
          break;
        case NotificationEventType.QA_GROUP_CREATE:
          this.createGroupFromEvent(event);
          break;
        default:
          break;
      }
    }
  }

  private loadPaginatedMessages(payload: {
    data: Record<string, BasicTypes>;
    offset: number;
    limit: number;
  }): Promise<number | null> {
    return this.dispatch<number | null>('loadPaginatedMessages', payload);
  }

  private deleteMessage(uid: string): Promise<boolean> {
    return this.dispatch<boolean>('deleteMessage', uid);
  }

  private updateMessage(payload: {
    uid: string;
    content: string;
  }): Promise<Message | undefined> {
    return this.dispatch<Message | undefined>('updateMessage', payload);
  }

  private createQnaMessage(payload: {
    userUid: string;
    groupUid: string;
    parentMessageUid: string | null;
    entity: Message;
  }): Promise<Message | undefined> {
    return this.dispatch<Message | undefined>('createQnaMessage', payload);
  }

  private resetPage(): Promise<void> {
    return this.dispatch<void>('resetPage');
  }

  @Watch('sessionUid')
  private onSessionUidChange(): void {
    if (this.sessionUid) {
      this.initMessageGroup();
    }
  }

  private initMessageGroup(): void {
    if (this.sessionUid) {
      this.getMessageGroup({
        filter: {
          target: {
            uid: this.sessionUid,
          },
          type: MessageType.QNA,
        },
      })
        .then((messageGroup) => {
          if (messageGroup) {
            this.localMessageGroup = messageGroup;
          }
        });
    }
  }

  @Watch('getMessages', { immediate: true })
  private onMessagesChange(): void {
    this.localMessages = this.getMessages;
  }

  // eslint-disable-next-line class-methods-use-this
  private messageHasAnswer(message: Message): boolean {
    return message.childMessages ? message.childMessages.length > 0 : false;
  }

  private loadMessages(): void {
    this.loadPaginatedMessages({
      data: {
        filter: {
          group: {
            target: {
              uid: this.sessionUid,
            },
            type: MessageType.QNA,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          userDeleted_not: true,
          parentMessage: null,
          type: 'QNA',
        },
      },
      limit: this.lonelyComponent && this.offset !== 5 ? 5 : 0,
      offset: this.offset,
    })
      .then((questionsCount) => {
        if (questionsCount && questionsCount >= 5 && this.offset === 0) {
          this.showViewAllButton = true;
          this.offset = 5;
        } else {
          this.showViewAllButton = false;
        }
      });
  }

  private infiniteHandler(state: StateChanger): void {
    const filterUnanswered = this.filtered ? { childMessages: null } : '';
    this.loadPaginatedMessages({
      data: {
        filter: {
          group: {
            target: {
              uid: this.sessionUid,
            },
            type: MessageType.QNA,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          userDeleted_not: true,
          parentMessage: null,
          type: 'QNA',
          ...filterUnanswered,
        },
      },
      limit: this.limit,
      offset: this.offset,
    })
      .then((questionsCount) => {
        if (questionsCount === this.limit) {
          state.loaded();
          this.offset += 10;
        } else {
          state.complete();
          this.offset += 10;
        }
      })
      .catch(() => state.complete());
  }

  private onDeleteQuestion(messageUid: string): void {
    const messageIndex = this.localMessages.findIndex((m) => m.uid === messageUid);
    if (messageIndex >= 0) {
      this.localMessages[messageIndex].userDeleted = true;
      const { childMessages } = this.localMessages[messageIndex];
      if (childMessages && childMessages.length) {
        this.deleteMessage(childMessages[0].uid);
        this.localMessages[messageIndex].childMessages = [];
      }
    }
    this.triggerGenericEvent({
      channels: [`qa-channel-${this.sessionUid}`],
      type: NotificationEventType.QA_DELETE_QUESTION,
      entityId: messageUid,
      extra: JSON.stringify({
        user: this.partialAuthUser,
        messageGroupUid: this.localMessageGroup ? this.localMessageGroup.uid : '',
        messageUid,
      }),
    });
  }

  private removeChildMessage(message: Message): void {
    const messageIndex = this.localMessages.findIndex((m) => m.uid === message.uid);
    if (messageIndex >= 0) {
      const childMessage = this.localMessages[messageIndex].childMessages;
      if (childMessage && childMessage.length) {
        this.localMessages[messageIndex].childMessages = [];
        this.deleteMessage(childMessage[0].uid);
        this.triggerGenericEvent({
          channels: [`qa-channel-${this.sessionUid}`],
          type: NotificationEventType.QA_DELETE_ANSWER,
          entityId: childMessage[0].uid,
          extra: JSON.stringify({
            user: this.partialAuthUser,
            messageGroupUid: this.localMessageGroup ? this.localMessageGroup.uid : '',
            parentMessageUid: this.localMessages[messageIndex].uid,
          }),
        });
        const tempLocalMessages = this.localMessages;
        this.localMessages = [];
        this.localMessages = tempLocalMessages;
      }
    }
  }

  private onTextChange(answer: string): void {
    this.myAnswer = answer;
  }

  private onEditAnswerClick(message: Message): void {
    if (message.childMessages && message.childMessages.length > 0) {
      this.messageToAnswer = message;
      this.tempAnswerText = message.childMessages[0].content || '';
    }
  }

  private onPostMessageClick(): void {
    if (this.canModerateOrIsSpeaker && !this.lonelyComponent) {
      const message = VueSecureHTML.escapeHTML(this.myAnswer);
      if (message.trim().length > 0) {
        this.publishAnswer(message);
      }
    } else if (
      (!this.canModerateOrIsSpeaker && !this.localMessageGroup)
        || (this.canModerateOrIsSpeaker && this.lonelyComponent && !this.localMessageGroup)
    ) {
      const messageGroupToCreate = {
        uid: '',
        type: 'QNA',
        active: true,
      } as unknown as MessageGroup;
      this.localMessageGroup = messageGroupToCreate;
      this.createMessageGroup({
        targetUid: this.sessionUid,
        messageGroup: messageGroupToCreate,
      })
        .then((response) => {
          if (response) {
            this.localMessageGroup = response;
            const message = VueSecureHTML.escapeHTML(this.myAnswer);
            if (message.trim().length > 0) {
              this.publishQuestion(message);
            }
          }
        });
    } else {
      const message = VueSecureHTML.escapeHTML(this.myAnswer);
      if (message.trim().length > 0) {
        this.publishQuestion(message);
      }
    }
  }

  private onAnswerQuestion(payload: { query: string }): void {
    if (this.canModerateOrIsSpeaker && this.lonelyComponent && payload.query.length) {
      const message = VueSecureHTML.escapeHTML(payload.query);
      if (message.trim().length > 0) {
        this.publishAnswer(message);
      }
      this.tempAnswerText = '';
      this.messageToAnswer = null;
    }
  }

  private onAnswerChange(payload: { query: string }): void {
    this.tempAnswerText = payload.query;
  }

  private onUpdateMessageToReply(message: Message): void {
    this.messageToAnswer = message;
    if (this.lonelyComponent && this.canModerateOrIsSpeaker) {
      if (message.childMessages && message.childMessages.length > 0) {
        this.tempAnswerText = message.childMessages[0].content || '';
      } else {
        this.tempAnswerText = '';
      }
    }
  }

  private onOpenCloseQuestionClick(): void {
    this.openedMenu = false;
    if (this.localMessageGroup) {
      const isActive = this.localMessageGroup.active;
      const localMessageGroup = {
        uid: this.localMessageGroup.uid,
        active: !isActive,
      } as MessageGroup;
      this.updateMessageGroup(localMessageGroup)
        .then((response) => {
          if (response) {
            this.triggerGenericEvent({
              channels: [`qa-channel-${this.sessionUid}`],
              type: isActive
                ? NotificationEventType.QA_CLOSE
                : NotificationEventType.QA_OPEN,
              entityId: localMessageGroup.uid,
              extra: JSON.stringify({
                user: this.partialAuthUser,
              }),
            });
          }
        });
      this.localMessageGroup.active = !this.localMessageGroup.active;
    } else {
      const messageGroupToCreate = {
        uid: '',
        type: 'QNA',
        active: true,
      } as unknown as MessageGroup;
      this.localMessageGroup = messageGroupToCreate;
      this.createMessageGroup({
        targetUid: this.sessionUid,
        messageGroup: messageGroupToCreate,
      })
        .then((response) => {
          if (response) {
            this.localMessageGroup = response;
            this.triggerGenericEvent({
              channels: [`qa-channel-${this.sessionUid}`],
              type: NotificationEventType.QA_GROUP_CREATE,
              entityId: response.uid,
              extra: JSON.stringify({
                user: this.partialAuthUser,
              }),
            });
          }
        });
    }
  }

  private onOpenPresentationQna(): void {
    if (this.sessionUid) {
      const sessionQnaUrl = this.$router.resolve({
        name: 'live-session-presentation-qna',
        params: { sessionId: this.sessionUid },
      }).href;
      window.open(sessionQnaUrl, '_blank');
    }
  }

  private publishQuestion(message: string): void {
    const newMessage = {
      uid: 'temporary',
      type: MessageType.QNA,
      content: message,
      toUserRead: false,
      toUserView: false,
    } as Message;
    const localMessage = { ...newMessage } as Message;
    localMessage.user = this.authUser;
    localMessage.group = this.localMessageGroup || undefined;
    localMessage.childMessages = [];
    this.localMessages.unshift(localMessage);
    this.offset += 1;
    if (this.localMessageGroup) {
      this.addUser({
        groupUid: this.localMessageGroup.uid,
        userUid: this.authUser.uid,
      })
        .then((response) => {
          if (response && this.localMessageGroup) {
            this.createQnaMessage({
              parentMessageUid: null,
              groupUid: this.localMessageGroup.uid,
              userUid: this.authUser.uid,
              entity: newMessage,
            })
              .then((finalMessage) => {
                if (finalMessage) {
                  const newMessageIndex = this.localMessages
                    .findIndex((m) => m.uid === 'temporary');
                  if (newMessageIndex >= 0) {
                    this.localMessages[newMessageIndex].uid = finalMessage.uid;
                  }
                  this.triggerGenericEvent({
                    channels: [`qa-channel-${this.sessionUid}`],
                    type: NotificationEventType.QA_NEW_QUESTION,
                    entityId: finalMessage.uid,
                    extra: JSON.stringify({
                      user: this.partialAuthUser,
                      newMessageContent: message,
                      newMessageUid: finalMessage.uid,
                      messageGroupUid: this.localMessageGroup ? this.localMessageGroup.uid : '',
                    }),
                  });
                }
              });
          }
        });
    }
  }

  private publishAnswer(message: string): void {
    const localMessageToAnswer = { ...this.messageToAnswer } as unknown as Message;
    let newMessage = {} as Message;
    if (localMessageToAnswer && localMessageToAnswer.group) {
      if (localMessageToAnswer.childMessages && localMessageToAnswer.childMessages.length) {
        const messageToUpdateUid = localMessageToAnswer.childMessages[0].uid;
        newMessage.uid = messageToUpdateUid;
        newMessage.content = message;
        this.addUser({
          groupUid: this.localMessageGroup?.uid || '',
          userUid: this.authUser.uid,
        })
          .then((response) => {
            if (response && localMessageToAnswer && localMessageToAnswer.group) {
              this.updateMessage({
                uid: messageToUpdateUid,
                content: message,
              })
                .then((updatedMessage) => {
                  if (updatedMessage) {
                    this.triggerGenericEvent({
                      channels: [`qa-channel-${this.sessionUid}`],
                      type: NotificationEventType.QA_EDIT_ANSWER,
                      entityId: updatedMessage.uid,
                      extra: JSON.stringify({
                        user: this.partialAuthUser,
                        newMessageUid: updatedMessage.uid,
                        newMessageContent: message,
                        messageGroupUid: this.localMessageGroup ? this.localMessageGroup.uid : '',
                        parentMessageUid: localMessageToAnswer.uid,
                      }),
                    });
                  }
                });
            }
          });
      } else {
        newMessage = {
          type: MessageType.QNA,
          content: message,
          toUserRead: false,
          toUserView: false,
        } as Message;
        this.addUser({
          groupUid: localMessageToAnswer.group.uid,
          userUid: this.authUser.uid,
        })
          .then((response) => {
            if (response && localMessageToAnswer && localMessageToAnswer.group) {
              this.createQnaMessage({
                parentMessageUid: localMessageToAnswer.uid,
                groupUid: localMessageToAnswer.group.uid,
                userUid: this.authUser.uid,
                entity: newMessage,
              })
                .then((childMessage) => {
                  if (childMessage) {
                    const currentMessageIndex = this.localMessages
                      .findIndex((mess) => mess.uid === localMessageToAnswer?.uid);
                    if (currentMessageIndex >= 0) {
                      const currentMessage = this.localMessages[currentMessageIndex];
                      if (currentMessage.childMessages && currentMessage.childMessages.length) {
                        currentMessage.childMessages[0] = childMessage;
                      }
                    }
                    this.triggerGenericEvent({
                      channels: [`qa-channel-${this.sessionUid}`],
                      type: NotificationEventType.QA_NEW_ANSWER,
                      entityId: childMessage.uid,
                      extra: JSON.stringify({
                        user: this.partialAuthUser,
                        newMessageContent: message,
                        messageGroupUid: this.localMessageGroup ? this.localMessageGroup.uid : '',
                        parentMessageUid: localMessageToAnswer.uid,
                        newMessageUid: childMessage.uid,
                      }),
                    });
                  }
                });
            }
          });
      }
      const currentMessageIndex = this.localMessages
        .findIndex((mess) => mess.uid === localMessageToAnswer?.uid);
      if (currentMessageIndex >= 0) {
        const currentMessage = this.localMessages[currentMessageIndex];
        const messageToAdd = { ...newMessage } as Message;
        messageToAdd.user = this.authUser;
        messageToAdd.group = localMessageToAnswer.group;
        messageToAdd.parentMessage = localMessageToAnswer;
        currentMessage.childMessages = [messageToAdd];
      }
      this.messageToAnswer = null;
    }
  }

  private newQuestionFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (this.localMessageGroup
        && extraData.user
        && this.authUser
        && extraData.user.uid === this.authUser.uid) {
      const message = this.localMessages.find((m) => m.uid === extraData.newMessageUid);
      if (!message) {
        const newMessage = {
          uid: extraData.newMessageUid,
          group: this.localMessageGroup,
          user: extraData.user,
          content: extraData.newMessageContent,
          type: MessageType.QNA,
          userDeleted: false,
        } as Message;
        this.localMessages.unshift(newMessage);
      }
    }
    if (extraData.user
        && ((this.authUser && extraData.user.uid !== this.authUser.uid) || !this.authUser)) {
      const message = this.localMessages.find((m) => m.uid === extraData.newMessageUid);
      if (!message) {
        if (!this.localMessageGroup && extraData.messageGroupUid) {
          if (this.sessionUid) {
            this.getMessageGroup({
              filter: {
                target: {
                  uid: this.sessionUid,
                },
                type: MessageType.QNA,
              },
            })
              .then((messageGroup) => {
                if (messageGroup) {
                  this.localMessageGroup = messageGroup;
                  const newMessage = {
                    uid: extraData.newMessageUid,
                    group: this.localMessageGroup,
                    user: extraData.user,
                    content: extraData.newMessageContent,
                    type: MessageType.QNA,
                    userDeleted: false,
                  } as Message;
                  this.localMessages.unshift(newMessage);
                  this.offset += 1;
                }
              });
          }
        } else if (extraData.messageGroupUid
            && this.localMessageGroup
            && extraData.messageGroupUid === this.localMessageGroup.uid) {
          const newMessage = {
            uid: extraData.newMessageUid,
            group: this.localMessageGroup,
            user: extraData.user,
            content: extraData.newMessageContent,
            type: MessageType.QNA,
            userDeleted: false,
          } as Message;
          this.localMessages.unshift(newMessage);
          this.offset += 1;
        }
      }
    }
  }

  private newAnswerFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
        && this.localMessageGroup
        && extraData.messageGroupUid === this.localMessageGroup.uid) {
      const parentMessageIndex = this.localMessages
        .findIndex((message) => message.uid === extraData.parentMessageUid);
      if (parentMessageIndex >= 0) {
        const newMessage = {
          uid: extraData.newMessageUid,
          group: this.localMessageGroup,
          user: extraData.user,
          content: extraData.newMessageContent,
        } as unknown as Message;
        newMessage.parentMessage = this.localMessages[parentMessageIndex];
        this.localMessages[parentMessageIndex].childMessages = [newMessage];
      }
    }
  }

  private editAnswerFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
        && this.localMessageGroup
        && extraData.messageGroupUid === this.localMessageGroup.uid) {
      const parentMessageIndex = this.localMessages
        .findIndex((message) => message.uid === extraData.parentMessageUid);
      if (parentMessageIndex >= 0) {
        const newMessage = {
          uid: extraData.newMessageUid,
          group: this.localMessageGroup,
          user: extraData.user,
          content: extraData.newMessageContent,
        } as Message;
        newMessage.parentMessage = this.localMessages[parentMessageIndex];
        if (this.localMessages[parentMessageIndex].childMessages) {
          this.localMessages[parentMessageIndex].childMessages = [newMessage];
        }
      }
    }
  }

  private deleteQuestionFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
        && this.localMessageGroup
        && extraData.messageGroupUid === this.localMessageGroup.uid) {
      const messageIndex = this.localMessages
        .findIndex((message) => message.uid === extraData.messageUid);
      if (messageIndex >= 0) {
        this.localMessages.splice(messageIndex, 1);
      }
    }
  }

  private deleteAnswerFromEvent(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.messageGroupUid
        && this.localMessageGroup
        && extraData.messageGroupUid === this.localMessageGroup.uid) {
      const messageIndex = this.localMessages.findIndex((m) => m.uid === extraData.parentMessageUid);
      if (messageIndex >= 0) {
        const { childMessages } = this.localMessages[messageIndex];
        if (childMessages && childMessages.length) {
          this.localMessages[messageIndex].childMessages = [];
        }
      }
    }
  }

  private openCloseQaFromEvent(event: GenericEvent, open: boolean): void {
    if (this.localMessageGroup && event.entityId && event.entityId === this.localMessageGroup.uid) {
      this.localMessageGroup.active = open;
    }
  }

  private createGroupFromEvent(event: GenericEvent): void {
    if (event.entityId) {
      this.localMessageGroup = {
        uid: event.entityId,
        type: 'QNA',
        active: true,
      } as unknown as MessageGroup;
    }
  }
}
