




















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays,
  endOfDay,
  format,
  getUnixTime,
  isSameDay,
  isToday,
  startOfDay,
  subDays,
} from 'date-fns';
import { namespace, State } from 'vuex-class';
import CalendarDailyViewComponent from '@/components/calendar/CalendarDailyViewComponent.vue';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CommunityUser from '@/models/graphql/CommunityUser';
import Meeting from '@/models/graphql/Meeting';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

/* eslint-disable @typescript-eslint/camelcase, no-nested-ternary */

const companyCalendarStore = namespace('CompanyCalendarStore');

@Component({
  components: {
    CheckboxSwitchComponent,
    CalendarDailyViewComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class CompanyCalendarViewComponent extends BreakpointWrapper {
  @Prop({ required: false, default: null })
  private meetingRequest!: MeetingRequest | null;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @companyCalendarStore.Action
  private loadCompanyReps!: (filter: object) => Promise<void>;

  @companyCalendarStore.Action
  private loadMeetings!: (filter: object) => Promise<Meeting[]>;

  @companyCalendarStore.Getter
  private companyReps!: CompanyUserRole[];

  @companyCalendarStore.State
  private meetings!: Meeting[];

  private selectedDate = DateTimeHelper.getCurrentDateTime();

  private selectedRepsUid: string[] = [];

  private CommunityUser = CommunityUser;

  private renderDailyCalendarView = 0;

  private get formattedDate(): string {
    return format(this.selectedDate, `${this.$t('app.date.defaultDateFormat')}`, { locale: this.dateLocale });
  }

  private get formattedDay(): string {
    return `${isToday(this.selectedDate) ? `${this.$t('calendar.filter.today')}, ` : ''}${format(
      this.selectedDate,
      `${this.$t('app.date.dayOfWeek')}`,
      { locale: this.dateLocale },
    )}`;
  }

  private get filterText(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.selectedRepsUid.length === 0
      ? `${this.$tc('calendar.filter.all')}`
      : this.selectedRepsUid.length === 1
        ? `${CommunityUser.hydrate(this.companyReps.find((cr) => cr.user?.uid === this.selectedRepsUid[0])?.user || {}).fullName}`
        : `${this.$tc('calendar.filter.nb-selected', 0, { count: this.selectedRepsUid.length })}`;
  }

  created(): void {
    const { companyId } = this.$route.params;
    this.selectedDate = DateTimeHelper.utcToZonedTimeDate(
      this.meetingRequest
    && this.meetingRequest.meeting
    && this.meetingRequest.meeting.startTime
        ? this.meetingRequest.meeting.startTime
        : this.meetingRequest && this.meetingRequest.startTime
          ? this.meetingRequest.startTime
          : DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );
    this.loadCompanyReps({
      company: {
        uid: companyId,
      },
      OR: [{
        handleMeetings: true,
        state: CompanyUserRoleStatusType.ACCEPTED,
        role: {
          code: CompanyRoleEnum.ADMINISTRATOR,
        },
      },
      {
        role: {
          code: CompanyRoleEnum.OWNER,
        },
      }],
    }).then(() => {
      this.loadMeetingsData();
    });
  }

  @Watch('meetingRequest')
  private meetingRequestSelected(): void {
    const date = DateTimeHelper.utcToZonedTimeDate(
      this.meetingRequest
    && this.meetingRequest.meeting
    && this.meetingRequest.meeting.startTime
        ? this.meetingRequest.meeting.startTime
        : this.meetingRequest
      && this.meetingRequest.startTime
          ? this.meetingRequest.startTime
          : DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );

    if (date) {
      if (!isSameDay(this.selectedDate, date)) {
        this.selectedDate = date;
        this.loadMeetingsData();
      }
    }
  }

  private loadMeetingsData(): void {
    let reps = this.selectedRepsUid;
    if (this.selectedRepsUid.length === 0) {
      reps = this.companyReps.map((rep) => rep.user?.uid || '');
    }
    this.renderDailyCalendarView += 1;
    this.loadMeetings({
      startTimestamp_gte: getUnixTime(startOfDay(this.selectedDate)),
      startTimestamp_lte: getUnixTime(endOfDay(this.selectedDate)),
      creatorUser: {
        uid_in: reps,
      },
    });
  }

  private updateSelectedReps(checked: boolean, uid: string): void {
    if (checked) {
      this.selectedRepsUid.push(uid);
    } else {
      this.selectedRepsUid = this.selectedRepsUid.filter((u) => u !== uid);
    }
    this.loadMeetingsData();
  }

  private updateSelectedDate(operation: string): void {
    if (operation === 'next') {
      this.selectedDate = addDays(this.selectedDate, 1);
    }
    if (operation === 'prev') {
      this.selectedDate = subDays(this.selectedDate, 1);
    }
    this.loadMeetingsData();
  }
}
