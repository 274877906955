export default class FormatHelper {
  static formatOrderNumber(orderId: number): string {
    return `UN${orderId.toString().padStart(6, '0')}`;
  }

  static formatCurrency(amount: number, currency = 'USD', locale = 'en-US'): string {
    return new Intl
      .NumberFormat(locale, { style: 'currency', currency })
      .format(amount);
  }
}
