
















































































import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { namespace, State } from 'vuex-class';
import { CommentFilter } from '@/graphql/_Filters/CommentFilter';
import Comment from '@/models/graphql/Comment';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { BSpinner } from 'bootstrap-vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueSecureHTML from 'vue-html-secure';
import FeedPostCommentComponent from '@/components/feed/FeedPostCommentComponent.vue';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import { mixins } from 'vue-class-component';
import GenericEvent from '@/utils/types/GenericEvent';

const commentStore = namespace('CommentStore');
const companyCalendarStore = namespace('CompanyCalendarStore');
const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    FeedPostCommentComponent,
    BSpinner,
    FontAwesomeComponent,
    AvatarSoloWidget,
  },
})
export default class CompanyCalendarDetailCommentsComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  @commentStore.Action
  getPaginatedComments!: (payload: {
    filter: CommentFilter;
    authUser: string;
    first: number;
    offset: number;
  }) => Promise<Comment[] | null>;

  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @State
  protected authUser!: CommunityUser;

  @commentStore.Action
  private createMeetingComment!: (payload: {
    meetingUid: string;
    authUser: CommunityUser;
    entity: Partial<Comment>;
  }) => Promise<Comment | undefined>;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @companyCalendarStore.Action
  private addMeetingRequestComment!: (payload: {
    uid: string;
  }) => void;

  @Prop({ required: true, default: '' })
  private readonly meetingRequestUid!: string;

  private isMainCommentLoading = false;

  private comment = '';

  private onFocus = false;

  private comments: Comment[] = [];

  private get authUserPicturePath(): string {
    if (this.authUser?.pictureFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.authUser.pictureFileResource), 'w40');
    }
    return '';
  }

  created(): void {
    if (this.meetingRequestUid) {
      this.notifyEvents = [
        NotificationEventType.MEETING_REQUEST_COMMENT,
      ];

      this.getPaginatedComments({
        filter: {
          meetingRequest: { uid: this.meetingRequestUid },
        },
        authUser: this.authUser.uid,
        first: 0,
        offset: 0,
      }).then((comments: Comment[] | null) => {
        if (comments && comments.length > 0) {
          this.comments = comments;
        }
      });
    }
  }

  mounted(): void {
    this.genericEvent({ channel: `meeting-request-comment-${this.meetingRequestUid}` });
  }

  onBlur(): void {
    this.onFocus = false;
  }

  focusIn(): void {
    this.onFocus = true;
  }

  onChange(e: Event): void {
    if (e && e.target && this.$data.comment === '') {
      (e.target as HTMLElement).style.height = 'auto';
    }
  }

  beforeDestroy(): void {
    this.unsubscribeGenericEventPointer(`meeting-request-comment-${this.meetingRequestUid}`);
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (extraData.authUser !== this.authUser.uid && event.type === NotificationEventType.MEETING_REQUEST_COMMENT) {
      const { comment } = extraData;
      if (this.comments) {
        this.comments.push(comment);
      } else {
        this.comments = [comment];
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private sendComment(e: Event): void {
    if (this.meetingRequestUid && this.comment.length > 0) {
      this.isMainCommentLoading = true;
      this.createMeetingComment({
        meetingUid: this.meetingRequestUid,
        authUser: this.authUser,
        entity: {
          textContent: VueSecureHTML.escapeHTML(this.comment.trim()),
        },
      })
        .then((comment) => {
          if (comment) {
            this.addMeetingRequestComment({ uid: this.meetingRequestUid });
            if (this.comments) {
              this.comments.push(comment);
            } else {
              this.comments = [comment];
            }
            this.isMainCommentLoading = false;
            this.triggerGenericEvent({
              channels: [`meeting-request-comment-${this.meetingRequestUid}`],
              type: NotificationEventType.MEETING_REQUEST_COMMENT,
              entityId: this.meetingRequestUid,
              extra: JSON.stringify({
                comment,
                authUser: this.authUser.uid,
              }),
            });
          }
        });
      this.$data.comment = '';
      if (e && e.target) {
        (e.target as HTMLElement).style.height = 'auto';
      }
    }
  }
}
