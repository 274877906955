










































































import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import Poll from '@/models/graphql/Poll';
import PollAnswer from '@/models/graphql/PollAnswer';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    IllustrationComponent,
  },
})
export default class LivePollComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private readonly poll!: Poll;

  @Prop({ required: false, default: true })
  private showResult!: boolean;

  private now = DateTimeHelper.getCurrentDateTime();

  private get pollIsClosed(): boolean {
    if (!this.poll.endTime && this.poll.startTime) {
      return false;
    }
    const endTime = this.poll.endTime ? DateTimeHelper.toLocal(new Date(this.poll.endTime)) : null;
    if (endTime) {
      return this.now > endTime;
    }
    return true;
  }

  private get timeLeft(): string {
    let result = '';
    if (this.pollIsClosed) {
      result = this.$t('session-polls.poll-list-item.voting-closed').toString();
    } else if (this.poll && this.poll.endTime) {
      const localEndTime = DateTimeHelper.toLocal(new Date(this.poll.endTime as string));
      const today = this.now;
      if (localEndTime >= today) {
        let daysDifference = differenceInDays(today, localEndTime);
        daysDifference = daysDifference < 0 ? -daysDifference : daysDifference;
        let hoursDifference = differenceInHours(today, localEndTime);
        hoursDifference = hoursDifference < 0 ? -hoursDifference : hoursDifference;
        let minutesDifference = differenceInMinutes(today, localEndTime);
        minutesDifference = minutesDifference < 0 ? -minutesDifference : minutesDifference;
        let secondsDifference = differenceInSeconds(today, localEndTime);
        secondsDifference = secondsDifference < 0 ? -secondsDifference : secondsDifference;
        if (daysDifference > 7) {
          result = this.$tc('session-polls.poll-list-item.voting-open');
        } else if (daysDifference >= 1) {
          result = this.$tc('session-polls.poll-list-item.left.days', 0, {
            timeLeftDiff: daysDifference,
          });
        } else if (hoursDifference <= 24 && hoursDifference >= 1) {
          result = this.$tc('session-polls.poll-list-item.left.hours', 0, {
            timeLeftDiff: hoursDifference,
          });
        } else if (minutesDifference >= 1) {
          result = this.$tc('session-polls.poll-list-item.left.minutes', 0, {
            timeLeftDiff: minutesDifference,
          });
        } else {
          result = this.$tc('session-polls.poll-list-item.left.seconds', 0, {
            timeLeftDiff: secondsDifference,
          });
        }
      }
    }
    return result;
  }

  private get getPollAnswersCount(): number {
    if (this.poll && this.poll.pollAnswers && this.poll.pollAnswers.length) {
      return this.poll.pollAnswers
        .reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return 0;
  }

  mounted(): void {
    setInterval(() => {
      this.now = DateTimeHelper.getCurrentDateTime();
    }, 950);
  }

  private getAnswerPercentage(pollAnswer: PollAnswer): string {
    const pollAnswerCount = this.getUserAnswerCount(pollAnswer);
    return `${this.$tc('session-polls.answer-percent',
      0,
      {
        count: pollAnswerCount > 0
          ? (parseInt(((100 * pollAnswerCount) / this.getPollAnswersCount).toString(), 10))
          : 0,
      })
    }`;
  }

  private getUserAnswerCount(pollAnswer: PollAnswer): number {
    if (this.poll.answerCountByAnswerId && this.poll.answerCountByAnswerId.length) {
      const answerCount = this.poll.answerCountByAnswerId
        .find((item) => parseInt(item.key, 10) === pollAnswer.id);
      if (answerCount) {
        return answerCount.value ? parseInt(answerCount.value, 10) : 0;
      }
    }
    return 0;
  }
}
