import { render, staticRenderFns } from "./ScheduleOfEventItemComponent.vue?vue&type=template&id=080a19d3&scoped=true&"
import script from "./ScheduleOfEventItemComponent.vue?vue&type=script&lang=ts&"
export * from "./ScheduleOfEventItemComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ScheduleOfEventItemComponent.vue?vue&type=style&index=0&id=080a19d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080a19d3",
  null
  
)

export default component.exports