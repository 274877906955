import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { HANDOUT_BASE_FRAGMENT } from '@/graphql/_Fragments/Handout/Base';
import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import {
  SPEAKER_ROLE_WITHOUT_SPEAKER_FRAGMENT,
} from '@/graphql/_Fragments/SpeakerRole/WithoutSpeaker';

export const SPEAKER_FULL_FRAGMENT = `
  fragment speakerFullFragment on Speaker {
    uid
    firstName
    lastName
    name
    suffix
    prefix
    bio
    prefix
    suffix
    jobTitle
    homepageUrl
    twitterUrl
    facebookUrl
    instagramUrl
    pinterestUrl
    photoFileResource {
      ...fileResourceBaseFragment
    }
    user {
      ...communityUserBaseFragment
    }
    speakerRoles {
      ...speakerRoleWithoutSpeakerFragment
    }
    handouts(orderBy: creationTime_asc) {
      ...handoutBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${SPEAKER_ROLE_WITHOUT_SPEAKER_FRAGMENT}
  ${HANDOUT_BASE_FRAGMENT}
`;
