






























































































































































/* eslint-disable no-underscore-dangle */
import {
  Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace, State } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import Channel from '@/models/graphql/Channel';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FeedContent from '@/components/feed/FeedContent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import InputSelectOptionsComponent from '@/components/InputSelectOptionsComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  fromUnixTime,
} from 'date-fns';
import { TranslateResult } from 'vue-i18n';
import FeedType from '@/utils/enums/feed/FeedType';
import EntitySelector from '@/components/common/EntitySelector.vue';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import SubEditionUserRole from '@/models/graphql/SubEditionUserRole';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import InputSelectOptionsParams from '@/utils/types/InputSelectOptionParams';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    StandardModal,
    ButtonComponent,
    AvatarSoloWidget,
    FeedContent,
    InputSelectOptionsComponent,
    EntitySelector,
  },
})
export default class FeedItemRepostModal extends VueBaseWidget {
  @permissionManagerStore.Getter
  protected canPostInCompany!: (companyUid: string) => boolean;

  @permissionManagerStore.Getter
  protected canPostInSubEdition!: (subEditionId: string) => boolean;

  @Prop({ default: '' })
  private modalId!: string;

  @Prop({ required: true })
  private feedActionType!: string;

  @Prop({ required: true })
  private feedItemElement!: FeedItemWrapper;

  @State
  private dateLocale!: Locale;

  @Inject({ from: 'feedType' })
  private feedType!: { value: string };

  @feedItemWrapperStore.Getter
  private fetchFeedInitiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  @feedItemWrapperStore.Getter
  private entityUidFeed!: string;

  @feedItemWrapperStore.Getter
  private fetchFeedInitiatorRepostList!: {
    uid: string;
    value: string;
    text: string;
  }[];

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @feedItemWrapperStore.Mutation
  private setFeedInitiator!: (initiator: CommunityUser | Exhibitor | SubEdition | Channel) => void;

  private loading = false;

  private isGlobal = true;

  private navigateTo = '';

  private topicUid = '';

  private seletedTopic = {
    value: '',
    text: '',
    uid: '',
  };

  private localTopicRepostList: {
    uid: string;
    value: string;
    text: string;
  }[] = [];

  private get seletedDefaultTopic(): InputSelectOptionsParams {
    const [{ value = '', text = '', uid = '' } = {}] = this.topicsList ?? [];
    return { value, text, uid };
  }

  private get isGlobalReal(): boolean {
    return [FeedType.GLOBAL, FeedType.TOPIC].includes(this.feedType.value as FeedType);
  }

  private get initiatorName(): string {
    if (this.fetchFeedInitiator && this.fetchFeedInitiator.uid) {
      if ((this.fetchFeedInitiator as CommunityUser).fullName) {
        return CommunityUser.hydrate(
          this.fetchFeedInitiator as CommunityUser,
        ).fullName;
      }
      return (this.fetchFeedInitiator as Exhibitor | SubEdition | CommunityUser).name || '';
    }
    if (this.authUser) {
      return this.authUser.fullName;
    }

    return '';
  }

  private get isLoading(): boolean {
    return this.loading;
  }

  private get canPostAsAuthUser(): boolean {
    const feature = this.featureByKey(FeatureKeys.USER_POST_CREATION);
    return feature ? feature.enabled : false;
  }

  private get entities(): { // Review
    uid: string;
    img: string;
    firstName?: string;
    lastName?: string;
    name: string;
  }[] {
    const localEntities: {
      uid: string;
      img: string;
      firstName?: string;
      lastName?: string;
      name: string;
    }[] = [];
    if (this.canPostAsAuthUser) {
      localEntities.push({
        uid: this.authUser.uid,
        img: this.authUser.profilePicture,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        name: this.authUser.fullName,
      });
    } else if (!this.isGlobal && this.authUser && this.fetchFeedInitiator && this.fetchFeedInitiator.uid !== this.authUser.uid) {
      localEntities.push({
        uid: this.authUser.uid,
        img: this.authUser.profilePicture,
        firstName: this.authUser.firstName,
        lastName: this.authUser.lastName,
        name: this.authUser.fullName,
      });
    }

    if (this.isGlobal) {
      // eslint-disable-next-line no-unused-expressions
      this.authUser.companyRoles?.forEach((role: CompanyUserRole) => {
        if (role.company && this.canPostInCompany(role.company.uid)) {
          const exhibitor = role.company as Exhibitor;
          const { uid } = exhibitor;
          const img = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w56');
          const name = exhibitor.name ? exhibitor.name : '';
          localEntities.push({ uid, img, name });
        }
      });

      // eslint-disable-next-line no-unused-expressions
      this.authUser.subEditionRoles?.forEach((role: SubEditionUserRole) => {
        if (role.subEdition && this.canPostInSubEdition(role.subEdition.uid)) {
          const subEdition = role.subEdition as SubEdition;
          const { uid } = subEdition;
          const img = FileResourceHelper.getFullPath(subEdition.logoFileResource, 'w56');
          const name = subEdition.name ? subEdition.name : '';
          localEntities.push({ uid, img, name });
        }
      });
    }
    if (this.isGlobal && !this.canPostAsAuthUser) {
      const companyRole = this.authUser.companyRoles?.filter((
        role: CompanyUserRole,
      ) => role.company && role.company.uid === localEntities[0].uid);
      if (companyRole?.length && companyRole[0].company) {
        this.setFeedInitiator(companyRole[0].company);
      }
      const subEditionRole = this.authUser.subEditionRoles?.filter((
        role: SubEditionUserRole,
      ) => role.subEdition && role.subEdition.uid === localEntities[0].uid);
      if (subEditionRole?.length && subEditionRole[0].subEdition) {
        this.setFeedInitiator(subEditionRole[0].subEdition);
      }
    }
    return localEntities;
  }

  private get repostPostHeader(): object {
    const attributes = {
      initiatorImage: '',
      title: '',
      subtitle: '',
      time: '',
      firstName: '',
      lastName: '',
    };
    if (!this.feedItemElement.initiator) return attributes;
    // eslint-disable-next-line no-underscore-dangle
    switch (this.feedItemElement.initiator.__typename) {
      case FeedType.COMMUNITY_USER:
      case FeedType.PROFILE: {
        const user = CommunityUser.hydrate(this.feedItemElement.initiator) as CommunityUser;
        attributes.initiatorImage = user.profilePicture;
        attributes.title = user.fullName;
        attributes.subtitle = user.jobTitle as string;
        attributes.firstName = user.firstName as string;
        attributes.lastName = user.lastName as string;
      }
        break;
      case FeedType.EXHIBITOR: {
        const exhibitor = this.feedItemElement.initiator as Exhibitor;
        attributes.initiatorImage = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w40');
        attributes.title = exhibitor.name as string;
        attributes.subtitle = this.followerSubtitle(exhibitor._followerCount as number) as string;
      }
        break;
      case FeedType.SUB_EDITION: {
        const subEdition = this.feedItemElement.initiator as SubEdition;
        attributes.initiatorImage = FileResourceHelper.getFullPath(subEdition.logoFileResource, 'w40');
        attributes.title = subEdition.name as string;
        attributes.subtitle = this.followerSubtitle(subEdition._followerCount as number) as string;
      }
        break;
      case FeedType.CHANNEL: {
        const channel = this.feedItemElement.initiator as Channel;
        attributes.initiatorImage = FileResourceHelper.getFullPath(channel.thumbnailFileResource, 'w40');
        attributes.title = channel.name as string;
        attributes.subtitle = this.followerSubtitle(channel._followerCount as number) as string;
      }
        break;
      default:
        break;
    }
    attributes.time = this.dateReceived();
    return attributes;
  }

  private get initiatorImage(): string {
    if (this.authUser) {
      return CommunityUser.hydrate(
          this.authUser as CommunityUser,
      ).profilePicture;
    }
    return FileResourceHelper.getFullPath(
      (this.authUser as Exhibitor | SubEdition).logoFileResource,
      'w40',
    );
  }

  private get firstName(): string {
    if (this.fetchFeedInitiator) {
      if (this.authUser && this.fetchFeedInitiator.uid === this.authUser.uid && this.canPostAsAuthUser) {
        return this.authUser.firstName as string;
      }
    }
    return '';
  }

  private get lastName(): string {
    if (this.fetchFeedInitiator) {
      if (this.authUser && this.fetchFeedInitiator.uid === this.authUser.uid && this.canPostAsAuthUser) {
        return this.authUser.lastName as string;
      }
    }
    return '';
  }

  private get topicsList(): InputSelectOptionsParams[] | null {
    if (this.localTopicRepostList) {
      return this.localTopicRepostList;
    }
    return null;
  }

  created(): void {
    if (this.localTopicRepostList.length <= 0) {
      if (this.feedItemElement.initiator?.uid !== this.authUser.uid
      && (this.entityUidFeed !== this.authUser.uid || this.isGlobalReal)) {
        this.localTopicRepostList.push({
          value: this.$t('app.feed-page.repost.to-my-feed').toString(),
          text: this.$t('app.feed-page.repost.to-my-feed').toString(),
          uid: '',
        });
      }
      if (this.fetchFeedInitiatorRepostList) {
        this.localTopicRepostList.push(...this.fetchFeedInitiatorRepostList);
      }
    }
  }

  @Watch('seletedDefaultTopic', { immediate: true })
  private updateSelectedDates(): void {
    this.seletedTopic = this.seletedDefaultTopic;
  }

  private dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    if (!this.feedItemElement.updatedTimestamp) return '';
    const updatedTime = fromUnixTime(this.feedItemElement.updatedTimestamp);
    if (differenceInSeconds(today, updatedTime) < 60) {
      return `${this.$t('app.feed-page.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, updatedTime);
    if (diffInMinutes < 60) {
      return `${this.$t('app.feed-page.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, updatedTime);
    if (diffInDays < 1) {
      return `${this.$t('app.feed-page.time-ago.hours', {
        number: differenceInHours(today, updatedTime),
      })}`;
    }

    if (diffInDays < 7) {
      return `${this.$t('app.feed-page.time-ago.days', { number: diffInDays })}`;
    }

    return format(updatedTime, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private followerSubtitle(count: number): TranslateResult | string {
    if (count === 1) {
      return `${count} ${this.$t('app.feed-page.follower')}`;
    }
    return `${count} ${this.$t('app.feed-page.followers')}`;
  }

  private onEntitySelected(entitySelected: { uid: string; img: string; name: string }): void {
    let initiator;
    if (this.authUser.uid === entitySelected.uid) {
      initiator = this.authUser;
      this.setFeedInitiator(initiator);
      return;
    }

    const companyRole = this.authUser.companyRoles?.filter((
      role: CompanyUserRole,
    ) => role.company && role.company.uid === entitySelected.uid);
    if (companyRole?.length && companyRole[0].company) {
      this.setFeedInitiator(companyRole[0].company);
      return;
    }

    const subEditionRole = this.authUser.subEditionRoles?.filter((
      role: SubEditionUserRole,
    ) => role.subEdition && role.subEdition.uid === entitySelected.uid);
    if (subEditionRole?.length && subEditionRole[0].subEdition) {
      this.setFeedInitiator(subEditionRole[0].subEdition);
    }
  }

  private setRepost(topic: InputSelectOptionsParams): void {
    this.seletedTopic = {
      text: topic.text,
      uid: topic.uid,
      value: topic.value,
    };
  }

  private onDiscard(): void {
    this.clear();
    this.$bvModal.hide(this.modalId);
  }

  private repostPost(): void {
    const initiatorUid = this.fetchFeedInitiator.uid;
    const topicUid = this.seletedTopic.uid;
    this.$emit('repostPost', { initiatorUid, topicUid });
    this.$bvModal.hide(this.modalId);
    this.clear();
  }

  private clear(): void {
    this.seletedTopic = {
      value: '',
      text: '',
      uid: '',
    };
  }
}
