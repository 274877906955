






























































import { Component, Prop } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    AvatarSoloWidget,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class FeedContentCard extends BreakpointWrapper {
  @Prop({ required: false })
  private firstname!: string;

  @Prop({ required: false })
  private lastname!: string;

  @Prop({ required: true })
  private imageSrc!: string;

  @Prop({
    required: false,
    default: 'circle',
  })
  private imageClass!: string;

  @Prop({ required: true })
  private title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private titleNavigateTo!: string | object;

  @Prop({ required: true })
  private subtitle!: string;

  @Prop({
    required: false,
    default: false,
  })
  private hasBorder!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private defaultIcon!: string;

  @Prop({
    required: false,
    default: false,
  })
  private recommendedForYou!: boolean;

  protected get avatarSize(): string {
    if (this.isSquare) {
      return this.isMobile ? '48' : '136';
    }

    return this.isMobile ? '40' : '96';
  }

  private get isSquare(): boolean {
    return this.imageClass === 'square';
  }
}
