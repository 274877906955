var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(!!_vm.logoSrc || !!_vm.bannerSrc)?_c('div',{staticClass:"detail-page-header d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"banner rounded-lg position-relative",class:{
              'desktop': _vm.isDesktop,
              'tablet': _vm.isTablet,
              'mobile': _vm.isMobile
            },style:({backgroundImage:
              ("" + (_vm.bannerSrc ? ("url(" + _vm.bannerSrc + ")")
                : 'var(--empty-space-bg-gradient)'))})},[_c('div',{staticClass:"banner-pills d-flex align-items-center position-absolute",class:_vm.isMobile ? 'justify-content-between' : 'justify-content-start'},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.isMobile && _vm.displayLiveBadge
                    && _vm.eventState && _vm.eventState === _vm.statesEnum.LIVE)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.live'),"size":"xlg","variant":"red"}}):_vm._e(),(_vm.isMobile && _vm.displayLiveBadge
                    && _vm.eventState
                    && _vm.eventState === _vm.statesEnum.ARCHIVED)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.archived'),"size":"xlg","variant":"dark-gray"}}):_vm._e(),(!_vm.autoFollow
                    && _vm.displayFollowerCountBadge
                    && _vm._followerCount
                    && _vm._followerCount > 0 )?_c('recommendation-badge-component',{staticClass:"mr-2",attrs:{"icon":_vm.isMobile ? {
                    'iconDef': 'fa-regular fa-user-group',
                    'iconClass': 'icon-12'
                  } : null,"label":_vm.isMobile ?
                    _vm._followerCount :
                    _vm.$tc('actions.followers', _vm._followerCount, { followersCount: _vm._followerCount}),"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"black"}}):_vm._e()],1),(!_vm.isMobile && _vm.displayLiveBadge
                  && _vm.eventState && _vm.eventState === _vm.statesEnum.LIVE)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.live'),"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"red"}}):_vm._e(),(!_vm.isMobile && _vm.displayLiveBadge
                  && _vm.eventState && _vm.eventState === _vm.statesEnum.ARCHIVED)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.archived'),"size":_vm.isMobile ? 'lg' : 'xlg',"variant":"dark-gray"}}):_vm._e()],1)]),_c('div',{class:{
              'mobile': _vm.isMobile,
              'logo': true
            }},[_c('avatar-solo-widget',{attrs:{"recommended-for-you":_vm._isRecommendedForMe && _vm.displayRecommendedBadge,"show-edit-button":false,"size":_vm.isMobile ? 96 : 136,"src":_vm.logoSrc,"with-borders":true}})],1)]):_vm._e()])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-column align-items-center mt-4 mt-md-5"},[(_vm.name)?_c('div',{class:[
          'w-fit',
          'd-inline-flex align-items-center'
        ]},[_c('h1',{staticClass:"m-0 name"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]):_vm._e(),_c('div',{staticClass:"w-fit mt-2"},[_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s([_vm.eventDates, _vm.eventLocation].join(' — '))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }