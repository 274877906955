var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('feed-content-card',{attrs:{"firstname":_vm.displayUser.firstName,"has-border":true,"image-src":_vm.profilePicture,"lastname":_vm.displayUser.lastName,"recommended-for-you":_vm.displayUser._isRecommendedForMe,"subtitle":_vm.subtitle,"title":_vm.title,"title-navigate-to":{ name: 'member-detail', params: { memberId: _vm.displayUser.uid } },"image-class":"round"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [((_vm.authUser && _vm.displayUser.uid !== _vm.authUser.uid) || !_vm.authUser)?_c('div',{staticClass:"d-flex column-gap-2"},[_c('button-component',{attrs:{"disabled":!!_vm.connected && _vm.connected.linkState === _vm.connectionStatusType.INVITED,"rounded":true,"text":("" + (_vm.connected && _vm.connected.linkState === _vm.connectionStatusType.INVITED
            ? _vm.$t('app.feed-page.card-action.connect.awaitingReply')
            : _vm.connected && _vm.connected.linkState === _vm.connectionStatusType.ACCEPTED
              ? _vm.$t('app.feed-page.card-action.connect.remove')
              : _vm.$t('app.feed-page.card-action.connect.add'))),"variant":_vm.connected && _vm.connected.linkState === _vm.connectionStatusType.INVITED
            ? 'secondary big-border'
            : _vm.connected && _vm.connected.linkState === _vm.connectionStatusType.ACCEPTED ?
              'alt-2' : 'secondary',"size":"md"},on:{"on-click":_vm.toggleModal},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.connected ? 'fa-regular fa-user-check' : 'fa-regular fa-user-plus',"icon-classes":!!_vm.connected && _vm.connected.linkState === _vm.connectionStatusType.INVITED
                ? 'text-neutral-n-5-placeholder' : '',"container-classes":"icon-24"}})]},proxy:true}],null,false,1288053345)}),_c('follow-button',{attrs:{"_is-followed":_vm.displayUser._isFollowed,"entity-type":_vm.entityType,"entity-uid":_vm.displayUser.uid}})],1):_vm._e()]},proxy:true}])}),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('component.user-card.modal.button.cancel'))),"description":("" + (_vm.$t('component.user-card.modal.remove-connection.message',
                        { name: ("<b>" + (_vm.displayUser.firstName) + " " + (_vm.displayUser.lastName) + "</b>") }))),"ok-text":("" + (_vm.$t('component.user-card.modal.button.confirm'))),"title":("" + (_vm.$t('component.user-card.modal.remove-connection.title'))),"user-id":_vm.displayUser.uid,"type":"user-card-remove-connection"},on:{"on-confirm":_vm.toggleConnection}}),_c('send-connection-request-modal',{attrs:{"name":((_vm.displayUser.firstName) + " " + (_vm.displayUser.lastName)),"user-id":_vm.displayUser.uid,"type":"user-card-issue-connection-request"},on:{"on-confirm":_vm.toggleConnection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }