








































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import LocaleModel from '@/models/LocaleModel';
import LocaleService from '@/services/LocaleService';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Community from '@/models/graphql/Community';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent, ButtonIconComponent, ButtonComponent },
})
export default class LocaleSwitcher extends Vue {
  @Getter
  private community!: Community;

  @State
  private locales!: LocaleModel[];

  @Action('switchLang')
  private changeLang!: (locale: string) => void;

  private localeService!: LocaleService;

  private showMenu = false;

  private get selectedLocale(): string {
    return this.$i18n.locale;
  }

  private set selectedLocale(value: string) {
    this.changeLang(value);
  }

  onOpenMenu(): void {
    if (this.showMenu) {
      this.$emit('on-open-menu');
    }
  }

  private created(): void {
    this.initLocaleService();
  }

  @Watch('community')
  private initLocaleService(): void {
    if (!this.localeService) {
      this.localeService = new LocaleService(this.$store.state, this.$store.getters);
    }
  }
}
