var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('admin-panel.modal.product.delete.cancel-text'))),"description":("" + (_vm.$t('admin-panel.modal.product.delete.description'))),"ok-text":("" + (_vm.$t('admin-panel.modal.product.delete.ok-text'))),"title":("" + (_vm.$t('admin-panel.modal.product.delete.title'))),"user-id":0,"type":"delete-modal"},on:{"on-confirm":function($event){return _vm.$emit('delete-item', _vm.selectedItem)}}}),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('admin-panel.modal.product.publish.cancel-text'))),"description":("" + (_vm.$t('admin-panel.modal.product.publish.description'))),"ok-text":("" + (_vm.$t('admin-panel.modal.product.publish.ok-text'))),"title":("" + (_vm.$t('admin-panel.modal.product.publish.title'))),"user-id":0,"type":"publish-modal"},on:{"on-confirm":function($event){return _vm.$emit('publish-item', _vm.selectedItem)}}}),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('admin-panel.modal.product.unpublish.cancel-text'))),"description":("" + (_vm.$t('admin-panel.modal.product.unpublish.description'))),"ok-text":("" + (_vm.$t('admin-panel.modal.product.unpublish.ok-text'))),"title":("" + (_vm.$t('admin-panel.modal.product.unpublish.title'))),"user-id":0,"type":"unpublish-modal"},on:{"on-confirm":function($event){return _vm.$emit('unpublish-item', _vm.selectedItem)}}}),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.standard.cancel-text'))),"description":_vm.$tc('admin-panel.modal.communityuser.company-role.standard.description', 0, {
      userName: _vm.selectedItem
        ? ((_vm.selectedItem.firstName) + " " + (_vm.selectedItem.lastName))
        : ''
    }),"ok-text":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.standard.ok-text'))),"title":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.standard.title'))),"user-id":0,"type":"user-role-standard-modal"},on:{"on-confirm":function($event){return _vm.$emit('update-user-company-role', 'standard')}}}),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.administrator.cancel-text'))),"description":_vm.$tc('admin-panel.modal.communityuser.company-role.administrator.description', 0, {
      userName: _vm.selectedItem
        ? ((_vm.selectedItem.firstName) + " " + (_vm.selectedItem.lastName))
        : ''
    }),"ok-text":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.administrator.ok-text'))),"title":("" + (_vm.$t('admin-panel.modal.communityuser.company-role.administrator.title'))),"user-id":0,"type":"user-role-admin-modal"},on:{"on-confirm":function($event){return _vm.$emit('update-user-company-role', 'administrator')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }