import { EXTRA_PROPERTY_SECTION_BASE_FRAGMENT } from '@/graphql/_Fragments/ExtraPropertySection/Base';

export const EXTRA_PROPERTY_CONFIGURATION_FULL_FRAGMENT = `
  fragment extraPropertyConfigurationFullFragment on ExtraPropertyConfiguration {
    uid
    schemaCode
    valueType
    targetType
    name
    displaySequence
    key
    section {
      ...extraPropertySectionBaseFragment
    }
  }
  ${EXTRA_PROPERTY_SECTION_BASE_FRAGMENT}
`;
