















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import SelectedListParams from '@/utils/types/SelectedListParams';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';
import FloorplanFilterComponent from '@/components/FloorplanFilterComponent.vue';

@Component({
  components: { FloorplanFilterComponent },
  inheritAttrs: false,
})
export default class FloorplanSearchListWidget extends Vue {
  @Prop({ required: false, default: null })
  private storeName!: string;

  @Prop({ required: true })
  private buttonLabel!: string;

  @Prop({ required: true })
  private code!: string;

  @Prop({ required: true })
  private value!: string;

  @Prop({ required: true })
  private text!: string;

  @Prop({ required: true })
  private dataQuery!: SelectedListParams[];

  @Prop({ required: true })
  private clearFilterTriggered!: boolean;

  @Prop({ required: false, default: '' })
  private filterQuery!: string;

  private loading = true;

  private selectedList: string[] = [];

  mounted(): void {
    this.selectedList = this.fetchDataFromQuery();
    this.$watch('$route', () => {
      this.selectedList = this.fetchDataFromQuery();
    });
  }

  fetchDataFromQuery(): string[] {
    const { query } = this.$route;
    if ('filters' in query && query.filters && ((query as EntitySearchQuery).filters ?? {})[this.code]) {
      return ((query as EntitySearchQuery).filters ?? {})[this.code] as string[];
    }
    return [];
  }

  onFilter(selected: string[]): void {
    this.selectedList = selected;
    const { query } = this.$route;
    if (selected.length > 0) {
      if (!('filters' in query && query.filters)) {
        Object.assign(query, { filters: {} });
      }
      const filters: Record<string, Array<string | number> | number | string | boolean> = {};
      filters[this.code] = selected;
      Object.assign(query.filters, { ...filters });
    } else {
      delete ((query as EntitySearchQuery).filters ?? {})[this.code];
      if ('filters' in query && query.filters && Object.keys(query.filters).length === 0) {
        delete query.filters;
      }
    }
    const route = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query,
    }).href;
    window.history.pushState(
      query,
      document.title,
      route,
    );
    this.$emit('on-route-query-changed', this.$route.query);
  }

  @Watch('clearFilterTriggered', { deep: true })
  private initSelectedList(): void {
    this.selectedList = [];
    this.$emit('reset-clear-filter-triggered');
  }

  private loadFilterData(): Promise<void> {
    return this.$store.dispatch(`${this.storeName}/loadFilterData`, this.code)
      .then(() => {
        this.loading = false;
      });
  }
}
