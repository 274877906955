

































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TitleComponent from '@/components/TitleComponent.vue';
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

@Component({
  components: {
    TitleComponent,
  },
})
export default class ActionListItemWidget extends mixins(
  VueBaseWidget,
  VueRegisterStoreWidget,
) {
    @Prop({ required: false, default: '' })
    protected readonly title!: string;

    @Prop({ required: false, default: '' })
    protected readonly subtitle!: string;

    @Prop({ required: false, default: '' })
    protected route!: string;

    @Prop({ required: false, default: true })
    protected useCircleBackground!: boolean;

    @Prop({ required: false, default: null })
    protected image!: FileResource;

    private FileResourceHelper = FileResourceHelper;

    private handleClick(route: string): void {
      if (route) {
        let external = route.startsWith('//');
        if (external) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.CLICK,
            route,
            -1,
            '',
            StatLoggerCategories.CAROUSEL,
            this.$i18n.locale,
          );
          window.open(route, '_blank');
        } else {
          try {
            const url = new URL(route);
            external = !!url.protocol;
            if (external) {
              this.$logger.logMatomoStats(
                this.authUser,
                this.community.code || '',
                '',
                StatLoggerActions.CLICK,
                route,
                -1,
                '',
                StatLoggerCategories.CAROUSEL,
                this.$i18n.locale,
              );
              window.open(route, '_blank');
            }
          } catch {
            let r = route;
            const matches = route.match(/(%[a-zA-Z-_]+%)/gs);
            if (matches) {
              matches.forEach((m) => {
                const prop = m.replaceAll('%', '').trim();
                if (this.$route.params[prop]) {
                  r = r.replaceAll(m, this.$route.params[prop]);
                }
              });
            }
            const { name } = this.$router.resolve(r).route;
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code || '',
              '',
              StatLoggerActions.CLICK,
              name || r,
              -1,
              '',
              StatLoggerCategories.CAROUSEL,
              this.$i18n.locale,
            );
            this.$router.push(r);
          }
        }
      }
    }
}
