import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SPONSOR_BASE_FRAGMENT = `
  fragment sponsorBaseFragment on Sponsor {
    uid
    name
    description
    url
    type
    logoFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
