import BaseModel from '@/models/BaseModel';
import CommunityUser from '@/models/graphql/CommunityUser';
import FeedPostBase from '@/models/graphql/FeedPostBase';

class Comment extends BaseModel {
  id?: number;

  textContent?: string;

  parentComment?: Comment;

  createdTime?: string;

  updatedTime?: string;

  createdTimestamp?: number;

  updatedTimestamp?: number;

  _isLiked?: boolean;

  _likesCount?: number;

  _childrenCommentsCount?: number;

  likeUsers?: CommunityUser;

  childrenComments?: Comment[];

  author?: CommunityUser;

  userDeleted?: boolean;

  post?: FeedPostBase;

  static hydrate(item: object): Comment {
    const result = new Comment();
    Object.assign(result, item);
    return result;
  }
}

export default Comment;
