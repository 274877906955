








































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import FileType from '@/utils/enums/FileType';
import UploadAssetComponent from '@/components/UploadAssetComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import { namespace, State } from 'vuex-class';
import ExtraPropertySection from '@/models/graphql/ExtraPropertySection';
import Booth from '@/models/graphql/Booth';
import InputSelectOptionsComponent from '@/components/InputSelectOptionsComponent.vue';
import InputSelectOptionParams from '@/utils/types/InputSelectOptionParams';
import InputColorComponent from '@/components/InputColorComponent.vue';
import LargeProduct from '@/models/graphql/LargeProduct';
import ToastActionType from '@/utils/enums/ToastActionType';
import Community from '@/models/graphql/Community';
import ToastActionParams from '@/utils/types/ToastActionParams';
import FileResource from '@/models/graphql/FileResource';
import ExtraPropertiesTypes from '@/utils/enums/ExtraPropertiesTypes';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import Category from '@/models/graphql/Category';
import Handout from '@/models/graphql/Handout';
import LocaleModel from '@/models/LocaleModel';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import urlParser from 'js-video-url-parser';

const largeProductStore = namespace('LargeProductStore');
const categoryStore = namespace('CategoryStore');
const toastStore = namespace('ToastStore');

/* eslint-disable
@typescript-eslint/camelcase,
@typescript-eslint/no-unused-vars,
no-underscore-dangle */
@Component({
  components: {
    CategorySelectorComponent,
    InputColorComponent,
    InputSelectOptionsComponent,
    CheckboxSwitchComponent,
    UploadAssetComponent,
    TextAreaComponent,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class BoatProductEditModal extends BreakpointWrapper {
  @State
  private community!: Community;

  @Prop({ required: true })
  private readonly modalId!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly largeProduct!: LargeProduct;

  @Prop({
    required: false,
    default: null,
  })
  private readonly extraPropertySections!: ExtraPropertySection[];

  @Prop({
    required: false,
    default: '',
  })
  private readonly exhibitorId!: string;

  @Prop({
    required: true,
    default: () => [],
  })
  private booths!: Booth[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly formFields!: string[];

  @Prop({
    required: false,
    default: '',
  })
  private readonly mandatoryFields!: string;

  @Prop({
    required: false,
    default: false,
  })
  private allowExtraProperties!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private boatCanEditBooth!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private boatCanEdit!: boolean;

  @Prop({
    required: false,
    default: 5,
  })
  private readonly imageLimit!: number;

  @Prop({
    required: false,
    default: 5,
  })
  private readonly handoutLimit!: number;

  @largeProductStore.Action
  private editLargeProduct!: (payload: {
    exhibitorId: string; largeProduct: object;
  }) => Promise<void | LargeProduct | undefined>;

  @largeProductStore.Action
  private updateLargeProductLinkedEntities!: (payload: {
    largeProduct: object; communityCode: string;
  }) => Promise<void>;

  @categoryStore.Getter
  private fetchProductCategoryForCmsTable!: Category;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @State
  private readonly locales!: LocaleModel[];

  private updatedTranslations: Record<string, string> = {};

  private boat: Data = {
    name: '',
    videoUrl: '',
  };

  private FileType = FileType;

  private EntityType = EntityType;

  private ExtraPropertiesTypes = ExtraPropertiesTypes;

  private saveLoading = false;

  private isSaveBtnDisabled = false;

  private isDeleteBtnDisabled = false;

  private renderFileUploader = 0;

  private newCategories: object[] = [];

  private priceMask = createNumberMask({
    prefix: this.priceCurrency,
    suffix: '',
    placeholder: '0.00',
    allowDecimal: true,
    decimalLimit: 2,
  });

  get translation(): EntityTranslationParams[] {
    const result: EntityTranslationParams[] = [];
    const translations = this.boat._translations as Array<{
      locale: string;
      values: Array<{ key: string; value: string | null }>;
    }>;
    if (translations.length > 0) {
      translations
        .forEach((trans) => {
          result.push(
            ...trans.values
              .map((val) => ({
                locale: trans.locale,
                value: val.value,
                field: val.key,
              })),
          );
        });
    }
    return result;
  }

  private get isEnhanced(): boolean {
    return this.allowExtraProperties || this.extraPropertySections.length > 0;
  }

  private get formMandatoryFields(): string[] {
    if (this.mandatoryFields) {
      return this.mandatoryFields.split(',');
    }
    return [];
  }

  private get selectedBooth(): InputSelectOptionParams | null {
    if (this.boat
        && this.boat.boothsOfEdition
        && (this.boat.boothsOfEdition as Booth[]).length > 0) {
      const booth = this.boat.boothsOfEdition as Booth[];
      return {
        text: booth[0].number as string,
        value: booth[0].number as string,
        uid: booth[0].uid,
      };
    }
    return null;
  }

  private get boothOptions(): InputSelectOptionParams[] {
    const options = [{
      text: this.$t('admin-panel.modal.boat.booth-not-at-the-show') as string,
      value: '-1',
      uid: 'not-at-the-show',
    }];
    if (this.booths
        && this.booths.length > 0) {
      options.push(...this.booths.map((b) => ({
        text: b.number as string,
        value: b.number as string,
        uid: b.uid,
      })));
    }
    return options;
  }

  private get priceCurrency(): string {
    return `${this.$t('app.currency')}`;
  }

  private get nameTranslations(): EntityTranslationParams[] {
    if (!!this.boat?.uid && this.boat.uid !== '' && this.locales && this.locales.length > 1) {
      return this.translationsByField('name');
    }
    return this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'name',
    }));
  }

  private get descriptionTranslations(): EntityTranslationParams[] {
    if (!!this.boat?.uid && this.boat.uid !== '' && this.locales && this.locales.length > 1) {
      return this.translationsByField('description');
    }
    return this.locales.map((l) => ({
      locale: l.locale || '',
      value: '',
      field: 'description',
    }));
  }

  private get validYoutubeUrl(): boolean {
    if (!('videoUrl' in this.boat)) {
      return true;
    }
    if ('videoUrl' in this.boat && !!this.boat.videoUrl && (this.boat.videoUrl as string).length === 0) {
      return true;
    }
    if (!!this.boat.videoUrl && (this.boat.videoUrl as string).length > 0) {
      const infos = urlParser.parse((this.boat.videoUrl as string) || '') as { provider: string; id: string };
      return infos && infos.provider === 'youtube';
    }
    return true;
  }

  translationsByField(field: string): EntityTranslationParams[] {
    return this.translation.filter((trans) => trans.field === field);
  }

  private extraPropertyMandatoryIsEmpty(): boolean {
    if (this.isEnhanced && this.boat && this.boat.extraProperties) {
      return Object.values(this.boat.extraProperties)
        .filter((ext) => ext.required && (ext.value === null || ext.value === '')).length > 0;
    }
    return false;
  }

  private setColor(key: string, value: string): void {
    if ('extraProperties' in this.boat
        && (this.boat.extraProperties as Record<string, Data>)[key]) {
      (this.boat.extraProperties as Record<string, Data>)[key].value = value;
    }
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onCategoryUpdate(payload: { ids: object[] }): void {
    this.newCategories = payload.ids;
    this.boat.categories = payload.ids;
    this.fieldUpdated();
  }

  private updateTranslations(field: string, translation: EntityTranslationParams): void {
    if (this.$i18n.locale === translation.locale) {
      this.boat[field] = translation.value as string;
    }
    if (this.locales && this.locales.length > 1) {
      Object.assign(this.updatedTranslations, { [`${translation.field}-${translation.locale}`]: translation.value });
    }
    this.fieldUpdated();
  }

  private fieldEnable(field: string): boolean {
    if (this.formFields.length > 0) {
      return this.formFields.includes(field);
    }
    return true;
  }

  private fieldIsMandatory(field: string): boolean {
    if (this.formMandatoryFields.length > 0) {
      return this.formMandatoryFields.includes(field);
    }
    return true;
  }

  private fieldUpdated(): void {
    const isFieldMandatoryAndEmpty = this.formMandatoryFields
      .reduce((currentValue, field) => currentValue || this.isFieldMandatoryAndEmpty(field.trim()), false);
    this.isSaveBtnDisabled = !this.boat.name
        || this.boat.name === ''
        || !this.validYoutubeUrl
        || isFieldMandatoryAndEmpty
        || this.extraPropertyMandatoryIsEmpty();
  }

  private initDefaultSectionsFields(): void {
    this.extraPropertySections.forEach((section) => {
      section.configsInSection.forEach((config) => {
        if (!('extraProperties' in this.boat)) {
          Object.assign(this.boat, { extraProperties: {} });
        }
        Object.assign(this.boat.extraProperties, {
          [config.key]: {
            configUid: config.uid,
            propertyUid: null,
            valueType: config.valueType,
            value: null,
            key: config.key,
            required: config.isMandatory || false,
          },
        });
      });
    });
  }

  private mapExtraProperties(): void {
    if (this.largeProduct
        && this.isEnhanced
        && this.largeProduct.extraProperties
        && this.largeProduct.extraProperties.length > 0) {
      this.largeProduct.extraProperties.forEach((ext) => {
        const property = (this.boat.extraProperties as Record<string, Record<string, string | string[] | boolean>>)[ext.key];
        if (property && !['NMMA_CERTIFIED', 'CERTIFICATE'].includes(ext.key)) {
          if ([ExtraPropertiesTypes.COLOR, ExtraPropertiesTypes.COLOR_LIST]
            .includes(ext.config?.valueType as ExtraPropertiesTypes)) {
            property.value = ext.value.split(/(?:\s*,\s*(?=#)|\s*,\s*(?=r)|\s*,\s*(?=h))+/);
          } else if (ext.config?.valueType === ExtraPropertiesTypes.BOOLEAN) {
            property.value = ext.value === 'true';
          } else {
            property.value = ext.value;
          }
          property.propertyUid = ext.uid;
        }
      });
    }
  }

  private mapImages(): void {
    if (this.largeProduct && this.largeProduct.images) {
      if (!this.boat.images) {
        Object.assign(this.boat, { images: {} });
      }
      this.largeProduct.images.forEach((img, index) => {
        Object.assign(this.boat.images, { [index]: img });
      });
    }
  }

  private mapHandouts(): void {
    if (this.largeProduct && this.largeProduct.handouts) {
      const handouts: Record<string, Handout> = {};
      this.largeProduct.handouts.forEach((handout, index) => {
        Object.assign(handouts, { [index]: handout });
      });
      this.boat.handouts = handouts;
    }
  }

  private mapTranslations(): void {
    if (this.largeProduct && this.largeProduct._translations) {
      this.boat.translations = this.largeProduct._translations;
    }
  }

  @Watch('extraPropertySections', { deep: true })
  @Watch('largeProduct', { deep: true })
  private initData(): void {
    this.isDeleteBtnDisabled = false;
    this.isSaveBtnDisabled = false;
    this.saveLoading = false;
    this.renderFileUploader += 1;
    this.boat = {
      name: '',
      videoUrl: '',
    };
    this.initDefaultSectionsFields();
    if (this.largeProduct) {
      this.onCategoryUpdate({ ids: this.largeProduct.categories || [] });
      const omitExtraProperties = (({
        images,
        extraProperties,
        price,
        ...o
      }) => o)(this.largeProduct);
      Object.assign(this.boat, omitExtraProperties);
      if (this.largeProduct.price) this.$set(this.boat, 'price', (this.largeProduct.price).toFixed(2));
      this.mapExtraProperties();
      this.mapImages();
      this.mapHandouts();
      this.mapTranslations();
    }
    this.fieldUpdated();
    this.isDeleteBtnDisabled = !(this.boat.uid && (this.boat.uid as string).length > 0);
  }

  private isFieldMandatoryAndEmpty(fieldType: string): boolean {
    switch (fieldType) {
      case 'categories':
        return this.fieldIsMandatory('categories')
            && ((!('categories' in this.boat)
                    || ('categories' in this.boat
                        && (this.boat.categories as Array<object>).length === 0))
                || this.newCategories.length === 0);
      case 'images':
        return this.fieldIsMandatory('images')
            && (!('images' in this.boat)
                || ('images' in this.boat
                    && Object.keys(this.boat.images).length === 0));
      case 'location':
        return this.fieldIsMandatory('location')
            && (!('boothsOfEdition' in this.boat)
                || ('boothsOfEdition' in this.boat
                    && (this.boat.boothsOfEdition as Array<object>).length === 0));
      default:
        return false;
    }
  }

  private setImages(data: Data, index: number): void {
    if (!this.boat.images) {
      Object.assign(this.boat, { images: {} });
    }
    if (data.readerResult) {
      if (`${index}` in (this.boat as unknown as Record<string, object>).images) {
        Object.assign(this.boat.images, { images: {} });
      }
      (this.boat.images as unknown as Record<string, object>)[index] = data;
    } else {
      delete (this.boat.images as unknown as Record<string, object>)[index];
    }
    this.fieldUpdated();
  }

  private setHandouts(data: Data, index: number): void {
    if (!this.boat.handouts) {
      Object.assign(this.boat, { handouts: {} });
    }
    if (data.readerResult) {
      if (`${index}` in (this.boat as unknown as Record<string, object>).handouts) {
        Object.assign(this.boat.handouts, { handouts: {} });
      }
      (this.boat.handouts as unknown as Record<string, object>)[index] = data;
    } else {
      delete (this.boat.handouts as unknown as Record<string, object>)[index];
    }
    this.fieldUpdated();
  }

  private setBooth(opt: InputSelectOptionParams | null): void {
    this.boat.boothsOfEdition = [opt];
    this.fieldUpdated();
  }

  private onSave(): void {
    this.saveLoading = true;
    let uid = '';
    const boothToSet: Booth[] = [];
    const boothToDelete: Booth[] = [];
    let newCategories: object[] = [];
    let oldCategories: Category[] = [];
    const propertiesToDelete: object[] = [];
    const propertiesToAdd: object[] = [];
    const propertiesToUpdate: object[] = [];
    const imagesToDelete: FileResource[] = [];
    const imagesToAdd: object[] = [];
    const handoutsToDelete: Handout[] = [];
    const handoutsToAdd: object[] = [];
    if (this.largeProduct) {
      if (this.largeProduct.categories) {
        oldCategories = this.largeProduct.categories;
      }
      if (this.boat.boothsOfEdition
          && (this.boat.boothsOfEdition as InputSelectOptionParams[]).length > 0
          && (this.boat.boothsOfEdition as InputSelectOptionParams[]).filter((b) => b.uid === 'not-at-the-show').length === 0
      ) {
        boothToSet.push({ uid: (this.boat.boothsOfEdition as InputSelectOptionParams[])[0].uid });
      }

      if (this.largeProduct.boothsOfEdition
          && (this.largeProduct.boothsOfEdition as InputSelectOptionParams[]).length > 0
          && this.boat.boothsOfEdition
          && (this.boat.boothsOfEdition as InputSelectOptionParams[]).length > 0
          && (this.boat.boothsOfEdition as InputSelectOptionParams[]).filter((b) => b.uid === 'not-at-the-show').length > 0
      ) {
        boothToDelete.push({ uid: (this.largeProduct.boothsOfEdition as InputSelectOptionParams[])[0].uid });
      }

      const imagesOnSave = this.manageImagesOnSave();
      imagesToAdd.push(...imagesOnSave.imagesToAdd);
      imagesToDelete.push(...imagesOnSave.imagesToDelete);

      const handoutsOnSave = this.manageHandoutsOnSave();
      handoutsToAdd.push(...handoutsOnSave.handoutsToAdd);
      handoutsToDelete.push(...handoutsOnSave.handoutToDelete);

      const extraPropertiesOnSave = this.manageExtraPropertiesOnSave();
      propertiesToAdd.push(...extraPropertiesOnSave.propertiesToAdd);
      propertiesToDelete.push(...extraPropertiesOnSave.propertiesToDelete);
      propertiesToUpdate.push(...extraPropertiesOnSave.propertiesToUpdate);
    } else {
      if (this.boat.boothsOfEdition && (this.boat.boothsOfEdition as InputSelectOptionParams[]).length > 0) {
        const boothUid = (this.boat.boothsOfEdition as InputSelectOptionParams[])[0].uid;
        if (boothUid !== 'not-at-the-show') {
          boothToSet.push({ uid: boothUid });
        }
      }
      if (this.boat.images && Object.keys(this.boat.images).length > 0) {
        Object.values(this.boat.images)
          .forEach((img) => {
            imagesToAdd.push({
              base64Path: img.readerResult.replace(`data:${img.fileType};base64,`, ''),
              fileName: img.fileName,
              fileType: img.fileType,
            });
          });
      }
      if (this.boat.handouts && Object.keys(this.boat.handouts).length > 0) {
        Object.values(this.boat.handouts)
          .forEach((handout) => {
            handoutsToAdd.push({
              base64Path: handout.readerResult.replace(`data:${handout.fileType};base64,`, ''),
              fileName: handout.fileName,
              fileType: handout.fileType,
              name: handout.name,
            });
          });
      }
      const extraPropertiesOnSave = this.manageExtraPropertiesOnSave();
      propertiesToAdd.push(...extraPropertiesOnSave.propertiesToAdd);
    }

    if (this.newCategories) {
      newCategories = this.newCategories;
    }

    if (this.fetchProductCategoryForCmsTable.domain) {
      newCategories.push(this.fetchProductCategoryForCmsTable);
    }

    const translations: Record<string, string> = {};
    Object.keys(this.updatedTranslations)
      .forEach((key) => {
        if (!key.includes(this.$i18n.locale)) {
          Object.assign(translations, { [key]: this.updatedTranslations[key] || '' });
        }
      });
    const product = {
      uid: this.largeProduct ? this.largeProduct.uid : null,
      name: this.boat.name,
      manufacturer: this.boat.manufacturer,
      model: this.boat.model,
      serie: this.boat.serie,
      serialNumber: this.boat.serialNumber,
      price: 'price' in this.boat
      && this.boat.price
      && (this.boat.price as string).length > 0
        ? parseFloat((this.boat.price as string)
          .replace('$', '')
          .replaceAll(',', ''))
        : null,
      description: this.boat.description,
      videoUrl: this.boat.videoUrl,
      url: this.boat.url,
    };
    this.editLargeProduct({
      exhibitorId: this.exhibitorId,
      largeProduct: product,
    })
      .then((response) => {
        if (response) {
          if (response.uid) {
            uid = response.uid;
          }
          if (this.community.code) {
            return this.updateLargeProductLinkedEntities({
              largeProduct: {
                uid,
                boothToSet,
                boothToDelete,
                imagesToDelete,
                imagesToAdd,
                propertiesToDelete,
                propertiesToAdd,
                propertiesToUpdate,
                oldCategories,
                newCategories,
                handoutsToDelete,
                handoutsToAdd,
                translations,
              },
              communityCode: this.community.code,
            })
              .then(() => {
                if (this.largeProduct) {
                  this.showToast(ToastActionType.UPDATE_PRODUCT);
                } else {
                  this.showToast(ToastActionType.CREATE_PRODUCT);
                }
              })
              .finally(() => {
                this.saveLoading = false;
                this.onSaveEnd();
              })
              .catch(() => {
                this.saveLoading = false;
                this.onSaveEnd();
                this.onCancel();
              });
          }
          return Promise.resolve();
        }
        return Promise.resolve();
      })
      .finally(() => {
        this.saveLoading = false;
        this.onSaveEnd();
      })
      .catch(() => {
        this.saveLoading = false;
        this.onSaveEnd();
        this.onCancel();
      });
  }

  private onSaveEnd(): void {
    this.$emit('on-update-end');
  }

  private onDelete(): void {
    this.$emit('on-delete', {
      productId: this.largeProduct.uid,
    });
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private manageImagesOnSave(): { imagesToAdd: object[]; imagesToDelete: FileResource[] } {
    const imagesToAdd: object[] = [];
    const imagesToDelete: FileResource[] = [];
    if (this.largeProduct) {
      const fileResources = Object.values(this.boat.images || {})
        .filter((img) => img.uid);
      (this.largeProduct.images || []).forEach((img) => {
        const found = fileResources
          .find((f) => img.uid === f.uid);
        if (!found) {
          imagesToDelete.push(img);
        }
      });
      imagesToAdd.push(...Object.values(this.boat.images || {})
        .filter((img) => !img.uid)
        .map((img) => ({
          base64Path: img.readerResult.replace(`data:${img.fileType};base64,`, ''),
          fileName: img.fileName,
          fileType: img.fileType,
        })));
    }
    return {
      imagesToAdd,
      imagesToDelete,
    };
  }

  private manageHandoutsOnSave(): { handoutsToAdd: object[]; handoutToDelete: Handout[] } {
    const handoutsToAdd: object[] = [];
    const handoutToDelete: Handout[] = [];
    if (this.largeProduct) {
      const handouts = Object.values(this.boat.handouts || {})
        .filter((handout) => handout && handout.uid);
      (this.largeProduct.handouts || []).forEach((handout) => {
        const found = handouts.find((h) => handout.uid === h.uid);
        if (!found) {
          handoutToDelete.push(handout);
        }
      });
      handoutsToAdd.push(...Object.values(this.boat.handouts || {})
        .filter((handout) => !handout.uid)
        .map((handout) => ({
          base64Path: handout.readerResult.replace(`data:${handout.fileType};base64,`, ''),
          fileName: handout.fileName,
          fileType: handout.fileType,
          name: handout.name,
        })));
    }
    return {
      handoutsToAdd,
      handoutToDelete,
    };
  }

  private manageExtraPropertiesOnSave(): {
    propertiesToDelete: object[];
    propertiesToAdd: object[];
    propertiesToUpdate: object[];
    } {
    const propertiesToDelete: object[] = [];
    const propertiesToAdd: object[] = [];
    const propertiesToUpdate: object[] = [];
    if (this.boat.extraProperties) {
      const largeProductExtraPropertyKeys: string[] = [];
      const allExtraPropertyWithValue = Object
        .values(this.boat.extraProperties as Record<string, Record<string, string | [] | boolean | null>>)
        .filter((ext) => ext.value !== null
              && ((typeof (ext.value) === 'boolean' && (ext.value || !ext.value))
                  || (typeof ext.value === 'string' && ext.value.length > 0)
                  || (Array.isArray(ext.value) && ext.value.length > 0)));
      if (this.largeProduct) {
        const largeProductExtraProperties = (this.largeProduct.extraProperties || [])
          .filter((ext) => !['NMMA_CERTIFIED', 'CERTIFICATE'].includes(ext.key))
          .map((ext) => ({
            key: ext.key,
            configUid: ext.config.uid,
            propertyUid: ext.uid,
            valueType: ext.config.valueType,
            value: ext.value,
          }));
        largeProductExtraPropertyKeys.push(...(this.largeProduct.extraProperties || [])
          .filter((ext) => !['NMMA_CERTIFIED', 'CERTIFICATE'].includes(ext.key))
          .map((ext) => ext.key));
        propertiesToDelete.push(...largeProductExtraProperties
          .filter((ext) => !allExtraPropertyWithValue.find((prop) => ext.key === prop.key)));
        const deletedKeys = propertiesToDelete.map((ext) => (ext as { key: string }).key);
        largeProductExtraProperties
          .filter((ext) => !deletedKeys.includes(ext.key))
          .forEach((ext) => {
            const updatedExtraProperty = allExtraPropertyWithValue
              .find((prop) => prop.configUid === ext.configUid && prop.value !== ext.value);
            if (updatedExtraProperty) {
              propertiesToUpdate.push({
                configUid: updatedExtraProperty.configUid,
                propertyUid: updatedExtraProperty.propertyUid,
                key: updatedExtraProperty.key,
                valueType: updatedExtraProperty.valueType,
                // eslint-disable-next-line no-nested-ternary
                value: [ExtraPropertiesTypes.BOOLEAN, ExtraPropertiesTypes.NUMBER]
                  .includes(updatedExtraProperty.valueType as ExtraPropertiesTypes)
                  ? updatedExtraProperty.value?.toString()
                  : [ExtraPropertiesTypes.COLOR, ExtraPropertiesTypes.COLOR_LIST]
                    .includes(updatedExtraProperty.valueType as ExtraPropertiesTypes)
                    ? `${(updatedExtraProperty.value as []).join(', ')}`
                    : updatedExtraProperty.value,
              });
            }
          });
      }
      propertiesToAdd.push(...allExtraPropertyWithValue
        .filter((ext) => !largeProductExtraPropertyKeys.includes(ext.key as string))
        .map((ext) => ({
          configUid: ext.configUid,
          propertyUid: ext.propertyUid,
          key: ext.key,
          valueType: ext.valueType,
          // eslint-disable-next-line no-nested-ternary
          value: [ExtraPropertiesTypes.BOOLEAN, ExtraPropertiesTypes.NUMBER]
            .includes(ext.valueType as ExtraPropertiesTypes)
            ? ext.value?.toString()
            : [ExtraPropertiesTypes.COLOR, ExtraPropertiesTypes.COLOR_LIST]
              .includes(ext.valueType as ExtraPropertiesTypes)
              ? `${(ext.value as []).join(', ')}`
              : ext.value,
        })));
    }
    return {
      propertiesToDelete,
      propertiesToAdd,
      propertiesToUpdate,
    };
  }
}
