var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.bannerSrc)?_c('div',{staticClass:"detail-page-header deal d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"banner rounded-lg position-relative",class:{
              'desktop': _vm.isDesktop,
              'tablet': _vm.isTablet,
              'mobile': _vm.isMobile
            },style:({backgroundImage:
              ("" + (_vm.bannerSrc ? ("url(" + _vm.bannerSrc + ")")
                : 'var(--empty-space-bg-gradient)'))})})]):_vm._e()])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-column align-items-center mt-4 mt-md-5"},[((_vm._isRecommendedForMe && _vm.displayRecommendedBadge) ||
          (_vm.dealState && _vm.displayExpirationBadge
            && (_vm.dealState === 'expired' || _vm.dealState === 'expire-soon')))?_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-2 mb-4"},[(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.recommended-for-you'),"size":"xlg","variant":"orange-gradient"}}):_vm._e(),(_vm.dealState && _vm.dealState === 'expired' && _vm.displayExpirationBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('deal-card-component.expired'),"size":"xlg","variant":"dark-gray"}}):_vm._e(),(_vm.dealState && _vm.dealState === 'expire-soon' && _vm.displayExpirationBadge)?_c('recommendation-badge-component',{attrs:{"label":_vm.$t('deal-card-component.expires-soon'),"size":"xlg","variant":"red"}}):_vm._e()],1):_vm._e(),(_vm.name)?_c('div',{class:[
          'w-fit',
          'd-inline-flex align-items-center'
        ]},[_c('h1',{staticClass:"m-0 name"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]):_vm._e(),(_vm.categories)?_c('div',{staticClass:"w-fit mt-2"},[_c('p',{staticClass:"caps caps-2 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.categories)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }