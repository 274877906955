









































































































import {
  Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import FeedItemCreatorModal from '@/components/feed/creator/FeedItemCreatorModal.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace } from 'vuex-class';
import SubEdition from '@/models/graphql/SubEdition';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FeedPostType from '@/utils/enums/feed/FeedPostType';
import Channel from '@/models/graphql/Channel';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FeedType from '@/utils/enums/feed/FeedType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import SubEditionUserRole from '@/models/graphql/SubEditionUserRole';
import EntitySelector from '@/components/common/EntitySelector.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    AvatarSoloWidget,
    InputSearchComponent,
    ButtonComponent,
    FontAwesomeComponent,
    PillComponent,
    FeedItemCreatorModal,
    EntitySelector,
  },
})
export default class FeedItemCreator extends BreakpointWrapper {
  @Inject({ from: 'windowWidth' })
  protected readonly windowWidth!: { value: number }

  @permissionManagerStore.Getter
  protected canPostInCompany!: (companyUid: string) => boolean;

  @permissionManagerStore.Getter
  protected canPostInSubEdition!: (subEditionId: string) => boolean;

  @Prop({ required: true })
  private type!: string;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature | undefined;

  @feedItemWrapperStore.Mutation
  private setFeedInitiator!: (initiator: CommunityUser | Exhibitor | SubEdition | Channel) => void;

  @feedItemWrapperStore.Getter
  private fetchFeedInitiator!: CommunityUser | Exhibitor | SubEdition | Channel;

  @feedItemWrapperStore.Getter
  private isTopicPublicPosting!: boolean;

  private feedPostType = FeedPostType;

  private openWith: FeedPostType = FeedPostType.TEXT;

  @Prop({ required: false, default: null })
  private container!: string | null;

  private containerId: string | null = null;

  private iconOnly = false;

  private entities: {
    uid: string;
    img: string;
    firstName?: string;
    lastName?: string;
    name: string;
  }[] = [];

  private get isFeatureEnableAuthUserPosting(): boolean {
    const feature = this.featureByKey(FeatureKeys.USER_POST_CREATION);
    return feature ? feature.enabled : false;
  }

  private get isAllRolePosting(): boolean {
    return [FeedType.GLOBAL, FeedType.TOPIC, FeedType.CHANNEL].includes(this.type as unknown as FeedType);
  }

  private get isTopic(): boolean {
    return this.type as unknown as FeedType === FeedType.TOPIC;
  }

  private get initiatorImage(): string {
    if (this.fetchFeedInitiator) {
      if (this.authUser && this.fetchFeedInitiator.uid === this.authUser.uid && this.isFeatureEnableAuthUserPosting) {
        return FileResourceHelper.getFullPath(
          (this.fetchFeedInitiator as CommunityUser).pictureFileResource,
          'w56',
        );
      }
      return FileResourceHelper.getFullPath(
        (this.fetchFeedInitiator as Exhibitor | SubEdition).logoFileResource,
        'w56',
      );
    }
    return '';
  }

  private get firstName(): string {
    if (this.fetchFeedInitiator) {
      if (this.authUser && this.fetchFeedInitiator.uid === this.authUser.uid && this.isFeatureEnableAuthUserPosting) {
        return this.authUser.firstName as string;
      }
    }
    return '';
  }

  private get lastName(): string {
    if (this.fetchFeedInitiator) {
      if (this.authUser && this.fetchFeedInitiator.uid === this.authUser.uid && this.isFeatureEnableAuthUserPosting) {
        return this.authUser.lastName as string;
      }
    }
    return '';
  }

  mounted(): void {
    this.containerId = this.container;
    this.$nextTick(() => {
      this.updateButtonTextDisplay();
    });
    this.$watch('windowWidth', this.updateButtonTextDisplay, { deep: true });
  }

  created(): void{
    this.setEntities();
  }

  @Watch('isTopicPublicPosting', { deep: true })
  private setEntities(): void {
    this.entities = [];
    if (!this.isTopicPublicPosting) {
      this.setEntitiesSubEdition();
    } else {
      if (this.isFeatureEnableAuthUserPosting) {
        this.setEntitiesAuthUser();
      } else if (!this.isAllRolePosting
      && this.authUser
      && this.fetchFeedInitiator
      && this.fetchFeedInitiator.uid !== this.authUser.uid) {
        this.setEntitiesAuthUser();
      }
      if (this.isAllRolePosting) {
        this.setEntitiesCompanies();
        this.setEntitiesSubEdition();
      }
      if (this.isAllRolePosting && !this.isFeatureEnableAuthUserPosting) {
        const companyRole = this.authUser.companyRoles?.filter((
          role: CompanyUserRole,
        ) => role.company && role.company.uid === this.entities[0].uid);
        if (companyRole?.length && companyRole[0].company) {
          this.setFeedInitiator(companyRole[0].company);
        }
        const subEditionRole = this.authUser.subEditionRoles?.filter((
          role: SubEditionUserRole,
        ) => role.subEdition && role.subEdition.uid === this.entities[0].uid);
        if (subEditionRole?.length && subEditionRole[0].subEdition) {
          this.setFeedInitiator(subEditionRole[0].subEdition);
        }
      }
    }
  }

  private updateButtonTextDisplay(): void {
    const container = this.$el;
    if (container) {
      this.iconOnly = container.getBoundingClientRect().width < 465;
    }
  }

  private setEntitiesAuthUser(): void{
    this.entities.push({
      uid: this.authUser.uid,
      img: this.authUser.profilePicture,
      firstName: this.authUser.firstName,
      lastName: this.authUser.lastName,
      name: this.authUser.fullName,
    });
  }

  private setEntitiesCompanies(): void{
    if (this.authUser.companyRoles) {
      this.authUser.companyRoles.forEach((role: CompanyUserRole) => {
        if (role.company && this.canPostInCompany(role.company.uid)) {
          const exhibitor = role.company as Exhibitor;
          const { uid } = exhibitor;
          const img = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w56');
          const name = exhibitor.name ? exhibitor.name : '';
          this.entities.push({ uid, img, name });
        }
      });
    }
  }

  private setEntitiesSubEdition(): void{
    let setFirstIntiator = true;
    if (this.authUser.subEditionRoles) {
      this.authUser.subEditionRoles.forEach((role: SubEditionUserRole) => {
        if (role.subEdition && this.canPostInSubEdition(role.subEdition.uid)) {
          const subEdition = role.subEdition as SubEdition;
          const { uid } = subEdition;
          const img = FileResourceHelper.getFullPath(subEdition.logoFileResource, 'w56');
          const name = subEdition.name ? subEdition.name : '';
          this.entities.push({ uid, img, name });
          if (setFirstIntiator && !this.isTopicPublicPosting) {
            setFirstIntiator = false;
            this.setFeedInitiator(role.subEdition);
          }
        }
      });
    }
  }

  private onEntitySelected(entitySelected: { uid: string; img: string; name: string }): void {
    let initiator;
    if (this.authUser.uid === entitySelected.uid) {
      initiator = this.authUser;
      this.setFeedInitiator(initiator);
      return;
    }

    const companyRole = this.authUser.companyRoles?.filter((
      role: CompanyUserRole,
    ) => role.company && role.company.uid === entitySelected.uid);
    if (companyRole?.length && companyRole[0].company) {
      this.setFeedInitiator(companyRole[0].company);
      return;
    }

    const subEditionRole = this.authUser.subEditionRoles?.filter((
      role: SubEditionUserRole,
    ) => role.subEdition && role.subEdition.uid === entitySelected.uid);
    if (subEditionRole?.length && subEditionRole[0].subEdition) {
      this.setFeedInitiator(subEditionRole[0].subEdition);
    }
  }

  private show(): void {
    this.openWith = FeedPostType.TEXT;
    this.$bvModal.show('create-feed-item-modal');
  }
}
