










import { Component, Prop, Watch } from 'vue-property-decorator';
import urlParser from 'js-video-url-parser';
import UploadFileHandler from '@/utils/UploadFileHandler';
import 'plyr/dist/plyr.css';
import Plyr from 'plyr';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

type SourceType = { provider: 'youtube' | 'vimeo' | undefined; src: string; type?: string} | null;

@Component
export default class VideoComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private readonly url!: string;

  @Prop({ required: false, default: 'companyVideoPresentation' })
  private readonly reference!: string;

  private player: Plyr | null = null;

  get source(): SourceType {
    const info = urlParser.parse(this.url || '') as { provider: string; id: string };
    if (info && info.provider && ['youtube', 'vimeo'].includes(info.provider)) {
      return { provider: info.provider, src: info.id } as SourceType;
    }
    return {
      src: `${this.url}?t=${Date.now()}`,
      type: `video/${UploadFileHandler.getMime(this.url)}`,
    } as SourceType;
  }

  mounted(): void {
    this.$nextTick(() => {
      this.player = new Plyr(this.$refs[this.reference] as HTMLElement, {
        ratio: '16:9',
        hideControls: true,
        resetOnEnd: true,
      });
      this.displayVideo();
    });
  }

  beforeDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }

  @Watch('url')
  private displayVideo(): void {
    if (this.player) {
      if (this.source) {
        this.player.source = {
          type: 'video',
          sources: [this.source],
        };
      }

      this.player.on('ready', () => {
        const videoElement = this.$el.querySelector('.plyr__controls');
        if (videoElement) {
          videoElement.classList.add('d-none');
        }
        if (this.player) {
          this.player.muted = true;
        }
      });
      this.player.on('play', () => {
        this.onVideoPlaying();
      });
    }
  }

  private onVideoPlaying(): void {
    if (this.player) {
      const videoElement = this.$el.querySelector('.plyr__controls');
      if (videoElement) {
        videoElement.classList.remove('d-none');
      }
    }
  }
}
