import BaseModel from '@/models/BaseModel';
// eslint-disable-next-line import/no-cycle
import Exhibitor from '@/models/graphql/Exhibitor';
import OrderPackage from '@/models/graphql/OrderPackage';
import OrderStatus from '@/utils/enums/OrderStatus';

class Order extends BaseModel {
  id?: number;

  totalPrice?: number;

  status?: OrderStatus;

  currency?: string;

  creationTime?: Date;

  exhibitor?: Exhibitor;

  paymentIntentId?: string;

  orderPackages?: OrderPackage[];

  static hydrate(item: object): Order {
    const result = new Order();
    Object.assign(result, item);

    return result;
  }
}

export default Order;
