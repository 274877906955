import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SUB_EDITION_AUTH_USER_FRAGMENT = `
  fragment subEditionAuthUserFragment on SubEdition {
    uid
    schemaCode
    name
    code
    autoFollow
    logoFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
