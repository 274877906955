enum UrlType {
    PHONE = 'phone',
    MOBILE_PHONE= 'mobile-phone',
    WEBSITE = 'website',
    SECONDARY_WEBSITE = 'secondary-website',
    SOCIAL = 'social',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    LINKEDIN = 'linkedin',
    TWITTER = 'twitter',
    PINTEREST = 'pinterest',
    WEB = 'web',
    EMAIL = 'email'
}

export default UrlType;
