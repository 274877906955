



































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FollowButton from '@/components/buttons/FollowButton.vue';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    FollowButton,
  },
})
export default class TopicsDetailsComponent extends VueBaseWidget {
  @Prop({ required: false, default: null })
  private readonly name!: string;

  @Prop({ required: false, default: 0 })
  private readonly _followerCount!: number;

  @Prop({ required: false, default: null })
  private readonly _isFollowed!: string;

  @Prop({ required: false, default: null })
  private readonly uid!: string;

  @Prop({ required: false })
  private readonly autoFollow!: boolean;

  private entityType = EntityType;
}
