





































import {
  Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Meeting from '@/models/graphql/Meeting';
import CommunityUser from '@/models/graphql/CommunityUser';
import { format } from 'date-fns';
import { Getter, namespace, State } from 'vuex-class';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Event from '@/utils/types/Event';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    AvatarSoloWidget,
  },
})
export default class CalendarViewSlotComponent extends Vue {
  @Inject({ from: 'windowWidth' })
  protected readonly windowWidth!: { value: string };

  @State
  private selectedTzName!: string;

  @Prop({ required: true, default: null })
  private data!: Meeting;

  @Getter
  private authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  private styleConfig: 'sm' | 'lg' = 'lg';

  private maxContentLines = 1;

  get clickable(): boolean {
    return !!(this.data.creatorUser?.uid === this.authUser.uid
      || (this.data.participants
        && this.data.participants.findIndex((p) => p.user?.uid === this.authUser.uid) > -1));
  }

  get time(): string {
    return this.data.startTimestamp
      ? format(
        DateTimeHelper.utcToZonedTimeDate(
          this.data.startTimestamp * 1000,
          this.selectedTzName,
        ),
        `${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale },
      ) : '';
  }

  private get creator(): CommunityUser {
    return CommunityUser.hydrate(this.data.creatorUser || {});
  }

  private openDetail(): void {
    if (this.clickable) {
      this.setViewMode(ViewMode.DETAIL);
      this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(this.data, this.selectedTzName));
      this.$eventsBus.emit('ontoolbox', {
        view: ToolbarMenuActions.TOOLBAR_AGENDA,
      });
    }
  }

  @Watch('windowWidth.value', { immediate: true })
  private updateStyle(): void {
    this.$nextTick(() => {
      const el = this.$el;
      if (el) {
        this.styleConfig = 'lg';
        const w = el.getBoundingClientRect().width;
        if (w < 90) {
          this.styleConfig = 'sm';
        }
      }
      const content = this.$el.querySelector('.slot-content');
      if (content) {
        const h = content.getBoundingClientRect().height;
        this.maxContentLines = Math.floor(h / (this.styleConfig === 'lg' ? 8 : 6));
      }
    });
  }
}
