




















































































































import { Component, Prop } from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VideoSelectorComponent from '@/components/VideoSelectorComponent.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import VideoSelectorModal from '@/components/modals/VideoSelectorModal.vue';
import GUUID from '@/utils/GUUID';
import VideoComponent from '@/components/VideoComponent.vue';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    VideoComponent,
    VideoSelectorModal,
    ButtonIconComponent,
    VideoSelectorComponent,
    FontAwesomeComponent,
  },
})
export default class MyProfileInputVideo extends VueContextLoading {
  @Prop({ default: '' })
  readonly label!: string;

  @Prop({ default: '' })
  readonly subLabel!: string;

  @Prop({ default: '' })
  readonly title!: string;

  @Prop({ default: 'url' })
  private defaultSelection!: string;

  @Prop({ default: '' })
  private defaultUrl!: string;

  @Prop({ default: EntityType.COMPANY })
  private entityType!: string;

  private file: File | undefined | null = null;

  private selected = 'url';

  private modalId = `company-video-selector-modal-${GUUID.uuidv4()}`;

  private sherpaBaseVideoUrl: string = process.env.VUE_APP_S3_VIDEO_AMAZONAWS ?? '';

  private isFileLoaded = false;

  created(): void {
    if (this.defaultUrl && this.defaultUrl.startsWith(this.sherpaBaseVideoUrl)) {
      const request = new XMLHttpRequest();
      request.open('GET', this.defaultUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        const reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = async (e) => {
          if (e && e.target) {
            const arr = ((e.target.result as string) || '').split(',');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const mime = arr[0].match(/:(.*?);/)![1];

            const response = await fetch(e.target.result as string);
            const blob = await response.blob();
            this.file = new File([blob], 'video-presentation', { type: mime });
            this.isFileLoaded = true;
          }
        };
      };
      request.send();
    } else {
      this.isFileLoaded = true;
    }
  }

  private onVideoChange(payload: {selected: string; url: string; file: File}): void {
    if (payload.selected === 'upload' && payload.file) {
      this.file = payload.file;
    }
    if (payload.selected === 'url' && (this.isUrlValid(payload.url) || payload.url === '')) {
      this.file = null;
    }
    this.$emit('on-change', payload.url);
  }

  // eslint-disable-next-line class-methods-use-this
  private isUrlValid(url: string): boolean {
    return RouteHelper.isUrlValid(url);
  }

  private onDeleteVideoClick(): void {
    this.file = null;
    this.$emit('on-delete-video');
  }
}
