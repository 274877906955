import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SPEAKER_AUTH_USER_FRAGMENT = `
  fragment speakerAuthUserFragment on Speaker {
    uid
    firstName
    lastName
    suffix
    prefix
    photoFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
