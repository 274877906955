































import { Component, Prop } from 'vue-property-decorator';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import { deepGet } from '@/utils/ObjectHelpers';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

@Component({
  components: { FontAwesomeComponent },
  inheritAttrs: false,
})
export default class CompanyLogoWidget extends BaseCardWidget {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  protected readonly community!: Community;

  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private route!: string;

  @Prop({ required: true })
  private logoFileResource!: FileResource;

  @Prop({ required: true })
  private name!: string;

  private noImage = !(this.logoFileResource && this.logoFileResource.path);

  private FileResourceHelper = FileResourceHelper;

  private get handleBaseUrl(): string | null {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private logStats(): void {
    this.$logger.logMatomoStats(
      this.authUser,
      this.community.code || '',
      EntityType.EXHIBITOR,
      StatLoggerActions.CLICK,
      '',
      -1,
      this.uid,
      StatLoggerCategories.LOGO_LIST,
      this.$i18n.locale,
    );
  }
}
