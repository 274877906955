













































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import Session from '@/models/graphql/Session';
import Article from '@/models/graphql/Article';
import Deal from '@/models/graphql/Deal';
import Product from '@/models/graphql/Product';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import { Getter, namespace } from 'vuex-class';
import Speaker from '@/models/graphql/Speaker';
import SubEdition from '@/models/graphql/SubEdition';
import BookmarkButton from '@/components/buttons/BookmarkButton.vue';
import FollowButton from '@/components/buttons/FollowButton.vue';
import LargeProduct from '@/models/graphql/LargeProduct';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    BookmarkButton,
    FollowButton,
  },
})
/* eslint-disable no-underscore-dangle */
export default class FeedBookmarkCard extends Vue {
  @communityUserAgendaEntryStore.Action
  addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Prop({ required: true })
  private triggered!: CommunityUserBookmark;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  private isInAgenda = this.triggered.link?.target?._isInAgenda;

  private get type(): string | undefined {
    return this.triggered.link?.target?.__typename;
  }

  private get title(): string {
    switch (this.type) {
      case EntityType.USER:
      case EntityType.SPEAKER:
        return CommunityUser.hydrate(this.triggered.link?.target as CommunityUser).fullName;
      case EntityType.EXHIBITOR:
      case EntityType.SESSION:
      case EntityType.ARTICLE:
      case EntityType.SUB_EDITION:
        return (this.triggered.link?.target as Exhibitor | Session | Article).name as string;
      case EntityType.DEAL:
        return (this.triggered.link?.target as Deal).title as string;
      case EntityType.LARGE_PRODUCT:
      case EntityType.PRODUCT:
        return (this.triggered.link?.target as Product | LargeProduct).name as string;
      default:
        return '';
    }
  }

  private get subtitle(): string {
    switch (this.type) {
      case EntityType.USER:
      case EntityType.SPEAKER:
        return CommunityUser.hydrate(this.triggered.link?.target as CommunityUser).jobTitle as string;
      case EntityType.EXHIBITOR:
      case EntityType.PRODUCT:
      case EntityType.SESSION:
      case EntityType.SUB_EDITION:
        return (this.triggered.link?.target as Exhibitor | Session | Product).description?.replace(/<[^>]*>?/gm, '') as string;
      case EntityType.ARTICLE:
        return (this.triggered.link?.target as Article).subtitle as string;
      case EntityType.DEAL:
        return (this.triggered.link?.target as Deal).name as string;
      default:
        return '';
    }
  }

  private get imageSrc(): string {
    switch (this.type) {
      case EntityType.USER:
        return CommunityUser.hydrate(this.triggered.link?.target as CommunityUser).profilePicture;
      case EntityType.EXHIBITOR:
      case EntityType.SUB_EDITION:
        return FileResourceHelper.getFullPath((this.triggered.link?.target as Exhibitor).logoFileResource, 'w250');
      case EntityType.ARTICLE:
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT: {
        const { images } = this.triggered.link?.target as Product;
        const image = images
        && images.length > 0
        && 'fullFileResource' in images[0]
        && images[0].fullFileResource
          ? images[0].fullFileResource
          : undefined;
        return FileResourceHelper.getFullPath(image, 'w250');
      }
      case EntityType.SESSION:
        return FileResourceHelper.getFullPath((this.triggered.link?.target as Session).bannerFileResource, 'w250');
      case EntityType.DEAL:
        return FileResourceHelper.getFullPath((this.triggered.link?.target as Deal).displayFileResource, 'w250');
      case EntityType.SPEAKER:
        return FileResourceHelper.getFullPath((this.triggered.link?.target as Speaker).photoFileResource, 'w250');
      default:
        return '';
    }
  }

  private get navigateTo(): object | string {
    const uid = this.triggered.link?.target?.uid;
    if (uid) {
      switch (this.type) {
        case EntityType.SPEAKER:
          return { name: 'speaker-detail', params: { speakerId: uid } };
        case EntityType.USER:
          return { name: 'member-detail', params: { memberId: uid } };
        case EntityType.EXHIBITOR:
          return { name: 'company-detail', params: { companyId: uid } };
        case EntityType.PRODUCT:
          return { name: 'product-detail', params: { productId: uid } };
        case EntityType.SESSION:
          return { name: 'session-detail', params: { sessionId: uid } };
        case EntityType.ARTICLE:
          return { name: 'article-detail', params: { articleId: uid } };
        case EntityType.DEAL:
          return { name: 'deal-detail', params: { dealId: uid } };
        case EntityType.SUB_EDITION:
          return {
            name: 'event-detail',
            params: { editionCode: (this.triggered.link?.target as SubEdition).code || '' },
          };
        default:
          return { path: '/' };
      }
    }
    return { path: '/' };
  }

  private get isAutoFollow(): boolean | undefined {
    if (this.type === EntityType.SUB_EDITION) {
      const subEditionTarget = this.triggered.link?.target as SubEdition;
      return (subEditionTarget.autoFollow === null) ? true : subEditionTarget.autoFollow;
    }
    return false;
  }

  @Watch('triggered', { deep: true })
  private updateTriggered(): void {
    this.isInAgenda = this.triggered.link?.target?._isInAgenda;
  }

  private toggleAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isInAgenda) {
      this.removeFromAgenda({ uid: this.isInAgenda });
      this.isInAgenda = null;
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.triggered.link?.target?.__typename as string,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.triggered.link?.target?.uid as string,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    } else {
      this.addToAgenda({
        userId: this.authUser.uid,
        linkedUserId: this.triggered.link?.target?.uid,
        entityType: this.triggered.link?.target?.__typename,
      }).then((response) => {
        this.isInAgenda = response?.uid || '';
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          this.triggered.link?.target?.__typename as string,
          StatLoggerActions.ADD_TO_AGENDA,
          'addAppointment',
          -1,
          this.triggered.link?.target?.uid as string,
          StatLoggerCategories.ADD,
          this.$i18n.locale,
        );
      });
    }
  }
}
