import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const HANDOUT_BASE_FRAGMENT = `
  fragment handoutBaseFragment on Handout {
    uid
    schemaCode
    name
    type
    fileUrl
    content
    subEdition
    creationTime
    handoutFileResource {
      ...fileResourceBaseFragment
    }
    thumbnailFileResource {
      ...fileResourceBaseFragment
    }
    relatedEntity: _relatedEntity {
      entityType: __typename
      uid
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
