export const EXHIBITOR_URL_BASE_FRAGMENT = `
  fragment exhibitorUrlBaseFragment on ExhibitorUrl {
    uid
    schemaCode
    type
    name
    url
    icon
  }
`;
