






















































































































import { Component, Prop } from 'vue-property-decorator';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import Meeting from '@/models/graphql/Meeting';
import { utcToZonedTime } from 'date-fns-tz';
import MeetingSlotComponent from '@/components/full-calendar/MeetingSlotComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

type MonthlyCalendarType = {
  day: Date;
  date: number;
  isCurrentMonth: boolean;
  isToday: boolean;
  meetings: Meeting[];
};

@Component({
  components: { FontAwesomeComponent, MeetingSlotComponent },
})
export default class MonthlyCalendarComponent extends BreakpointWrapper {
  @Prop({ default: () => new Date() })
  private date!: Date;

  @Prop({ default: () => [] })
  private meetings!: Meeting[];

  @Prop({ default: null })
  private activeMeeting!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private selectedDate: MonthlyCalendarType | null = null;

  private cellSize = {
    width: 'calc(100% / 7)',
    height: '176px',
  };

  private meetingListPopupStyle = '';

  get calendar(): Array<Array<MonthlyCalendarType>> {
    const start = startOfWeek(startOfMonth(this.date), { weekStartsOn: 0 });
    const end = endOfWeek(endOfMonth(this.date));
    const calendar = [];
    let currentDate = start;

    while (currentDate <= end) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push({
          day: currentDate,
          date: currentDate.getDate(),
          isCurrentMonth: isSameMonth(currentDate, this.date),
          isToday: isToday(currentDate),
          // eslint-disable-next-line no-loop-func
          meetings: this.meetings.filter((m) => m.startTime
            && isSameDay(utcToZonedTime(`${m.startTime}Z`, this.selectedTzName), currentDate)),
        });
        currentDate = addDays(currentDate, 1);
      }
      calendar.push(week);
    }

    return calendar;
  }

  private get maxMeetingToDisplayPerDay(): number {
    return Math.floor((parseFloat(this.cellSize.height) - 68) / 44);
  }

  private get daysOfWeek(): string[] {
    const firstDayOfWeek = startOfWeek(new Date(), { locale: this.dateLocale, weekStartsOn: 0 });
    return eachDayOfInterval({ start: firstDayOfWeek, end: addDays(firstDayOfWeek, 6) })
      .map((day) => format(day, this.isMobile ? 'EEEEE' : 'EEEE', { locale: this.dateLocale }));
  }

  private get selectedDay(): string {
    if (this.selectedDate) {
      return format(
        this.selectedDate.day,
        `${this.$t('app.date.dayOfWeekShort')}, ${this.$t('app.date.monthDayShort')}`,
        { locale: this.dateLocale },
      );
    }
    return '';
  }

  mounted(): void {
    this.calcCellSize();
    window.addEventListener('resize', this.calcCellSize);
  }

  private calcCellSize(): void {
    const el = document.querySelector('.monthly-calendar');
    if (el) {
      const widthEl = el.getBoundingClientRect().width / 7;
      if (this.isMobile) {
        this.cellSize = {
          width: `${widthEl}px`,
          height: `${widthEl}px`,
        };
      } else {
        this.cellSize = {
          width: `${widthEl}px`,
          height: '176px',
        };
      }
    }
  }

  private onDayClick(day: MonthlyCalendarType): void {
    if (!this.isMobile) {
      const height = (day.meetings.length < 5 ? (day.meetings.length * 42) : 168) + 84;
      const width = (parseFloat(this.cellSize.width) + 64) > 192 ? parseFloat(this.cellSize.width) + 64 : 192;
      const x = (width - parseFloat(this.cellSize.width)) / 2;
      const y = (height - parseFloat(this.cellSize.height)) / 2;
      this.meetingListPopupStyle = `width: ${width}px; height: ${height}px; transform: translate(-${x}px, -${y}px);`;
    } else {
      this.meetingListPopupStyle = '';
    }
    if (day.day !== this.selectedDate?.day) {
      this.selectedDate = day;
    } else {
      this.selectedDate = null;
    }
  }
}
