






































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Country from '@/models/graphql/Country';
import { namespace } from 'vuex-class';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import CommunityUser from '@/models/graphql/CommunityUser';
import SubEdition from '@/models/graphql/SubEdition';
import Exhibitor from '@/models/graphql/Exhibitor';
import ActionType from '@/utils/enums/ActionType';
import { ExhibitorUrlFilter } from '@/graphql/_Filters/ExhibitorUrlFilter';
import Channel from '@/models/graphql/Channel';
import Topic from '@/models/graphql/Topic';

const exhibitorUrlStore = namespace('ExhibitorUrlStore');

@Component({
  components: {
    SocialLinksComponent,
  },
})
export default class FeedAddressCard extends Vue {
  @Prop({ required: true })
  private initiator!: CommunityUser | SubEdition | Exhibitor | Channel | Topic;

  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false })
  private address1!: string;

  @Prop({ required: false })
  private address2!: string;

  @Prop({ required: false })
  private address3!: string;

  @Prop({ required: false })
  private city!: string;

  @Prop({ required: false })
  private state!: string;

  @Prop({ required: false })
  private postalCode!: string;

  @Prop({ required: false })
  private phone!: string;

  @Prop({ required: false })
  private fax!: string;

  @Prop({ required: false })
  private country!: Country;

  @exhibitorUrlStore.Action(ActionType.FILTER)
  private getExhibitorUrls!: (payload: { filter: ExhibitorUrlFilter }) => void;

  @exhibitorUrlStore.Getter
  private fetchExhibitorUrls!: ExhibitorUrl[];

  private exhibitorUrls: Array<{icon: string; url: string; type: string}> = [];

  private exhibitorWebsite = '';

  private exhibitorPhone = '';

  private exhibitorEmail = '';

  private get address(): string {
    const addr: string[] = [];
    let address = '';
    if (this.address1) {
      address = `${this.address1},\n`;
    }
    if (this.city) {
      addr.push(this.city);
    }
    if (this.state) {
      addr.push(this.state);
    }
    if (this.country
        && this.country.name) {
      addr.push(this.country.name);
    }
    if (this.postalCode) {
      addr.push(this.postalCode);
    }
    return `${address}${addr.join(', ')}`;
  }

  created(): void {
    if (this.initiator.uid) {
      this.getExhibitorUrls({
        filter: {
          exhibitor: {
            uid: this.initiator.uid,
          },
        },
      });
    }
  }

  @Watch('fetchExhibitorUrls')
  getAllExhibitorUrls(): void {
    if (this.fetchExhibitorUrls && this.fetchExhibitorUrls.length > 0) {
      this.exhibitorUrls = this.fetchExhibitorUrls.filter((url) => url && url.url && !['email', 'phone'].includes(url.type || ''))
        .map((url) => ({
          url: url && url.url && !url.url.match(/^[a-zA-Z]+:\/\//) ? `http://${url.url}` : url.url || '',
          type: url.type,
          // eslint-disable-next-line max-len
          icon: (AppSocialMediaIcons as Record<string, string>)[url.type ? url.type.toLowerCase() : 'website'] || AppSocialMediaIcons.website,
        }));
      const phone = this.fetchExhibitorUrls.find((url) => url && url.url && url.type && url.type === 'phone');
      if (phone) {
        this.exhibitorPhone = phone.url;
      }
      const email = this.fetchExhibitorUrls.find((url) => url && url.url && url.type && url.type === 'email');
      if (email) {
        this.exhibitorEmail = email.url;
      }
      const site = this.fetchExhibitorUrls.find((url) => url && url.url && url.type && url.type === 'website');
      if (site) {
        if (!site.url.match(/^[a-zA-Z]+:\/\//)) {
          this.exhibitorWebsite = `http://${site.url}`;
        }
        this.exhibitorWebsite = site.url;
      }
    }
  }
}
