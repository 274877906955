












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DynamicTextUpdateComponent extends Vue {
  @Prop({ required: true })
  private readonly text!: string;

  private get isHtml(): boolean {
    return /<?[a-z][\s\S]*>.*<\/[a-z][\s\S]*>/i.test(this.text);
  }
}
