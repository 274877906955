




















import { Component, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class UpToDateFeedComponent extends Vue {
}
