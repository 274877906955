import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const EXHIBITOR_BASE_FRAGMENT = `
  fragment exhibitorBaseFragment on Exhibitor {
    uid
    schemaCode
    name
    displayName
    description
    featured
    mainExhibitor
    videoPresentationS3Url
    logoFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _followerCount
    _bookmarkCount
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
