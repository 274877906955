




























































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import { namespace } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import TagComponent from '@/components/tag/TagComponent.vue';
import { CommunityUserTagFilter } from '@/graphql/_Filters/CommunityUserTagFilter';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import TagListItem from '@/components/TagListItem.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CreateRenameTagModal from '@/components/toolbox/tags/CreateRenameTagModal.vue';
import { StateChanger } from 'vue-infinite-loading';
import { TAG_LIST } from '@/utils/constants/PaginationOffsets';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';

const communityUserTagStore = namespace('CommunityUserTagStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    LoadingSpinnerComponent,
    CreateRenameTagModal,
    FontAwesomeComponent,
    TagListItem,
    TagComponent,
    ButtonComponent,
    StandardModal,
  },
})
export default class TagAddCreateModal extends VueBaseWidget {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({
    required: false,
    default: '',
  })
  private entityType!: string;

  @Prop({ required: true })
  private readonly entityCode!: string;

  @communityUserTagStore.Action
  private paginated!: (payload: { filter: CommunityUserTagFilter; offset: number }) => Promise<CommunityUserTag[]>;

  @communityUserTagStore.Action
  private filterUserEntityTags!: (payload: { filter: CommunityUserTagFilter },
  ) => Promise<CommunityUserTag[]>;

  @communityUserTagStore.Action
  private createForUser!: (payload: {
    userUid: string;
    entity: Partial<CommunityUserTag>;
  }) => Promise<void | CommunityUserTag>;

  @communityUserTagStore.Action
  private setTagsForEntity!: (payload: {
    tagsToRemove: string[];
    tagsToAdd: string[];
    entityUid: string;
    myUid: string;
  }) => Promise<void | boolean>;

  @communityUserTagStore.Getter
  private fetchUserEntityTags!: CommunityUserTag[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  private localTagList: CommunityUserTag[] = [];

  private selectedIds: string[] = [];

  private selectedList: CommunityUserTag[] = [];

  private localSelectedList: CommunityUserTag[] = [];

  private searchQuery = '';

  private isSaveBtnDisable = true;

  private saveLoading = false;

  private saveNewTagLoading = false;

  private hasShowMoreButton = false;

  private showMore = false;

  private isLoading = false;

  private canCreateNewTag = false;

  private offset = 0;

  private renderTagList = 0;

  @Watch('saveLoading')
  onLoadingChange(): void {
    if (!this.saveLoading) {
      this.$bvModal.hide(this.modalId);
      this.$emit('on-save');
    }
  }

  private onConfirm(): void {
    this.saveLoading = true;
    const toAddUids: string[] = [];
    const toDeleteUids: string[] = [];
    this.localSelectedList.forEach((tag: CommunityUserTag) => {
      if (this.selectedIds.find((id) => id === tag.uid) === undefined) {
        toAddUids.push(tag.uid);
      }
    });
    this.selectedIds.forEach((id) => {
      if (this.localSelectedList.find((tag: CommunityUserTag) => id === tag.uid) === undefined) {
        toDeleteUids.push(id);
      }
    });
    this.setTagsForEntity({
      tagsToRemove: toDeleteUids,
      tagsToAdd: toAddUids,
      entityUid: this.entityCode,
      myUid: this.authUser.uid,
    })
      .then((response) => {
        if (response) {
          this.saveLoading = false;
        }
      });
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onSearch(): void {
    this.isLoading = true;
    this.localTagList = [];
    this.offset = 0;
    this.renderTagList += 1;
  }

  @Watch('localSelectedList', { deep: true })
  private updateSelectedFilterList(): void {
    if (this.localSelectedList.length > 0) {
      this.addRemoveMoreButton();
    }
    if (this.selectedIds.length !== this.localSelectedList.length) {
      this.isSaveBtnDisable = false;
    } else if (this.localSelectedList.length === 0) {
      this.isSaveBtnDisable = true;
    } else {
      this.localSelectedList.some((tag, index) => {
        if (this.selectedIds.find((uid) => uid === tag.uid) === undefined) {
          this.isSaveBtnDisable = false;
          return true;
        }
        if (index === (this.localSelectedList.length - 1)) {
          this.isSaveBtnDisable = true;
          return true;
        }
        return false;
      });
    }
  }

  private createNewTag(tag: CommunityUserTag): void {
    this.localSelectedList.push(tag);
    this.onSearch();
  }

  private toggleTagItem(tag: CommunityUserTag): void {
    const tagIndex = this.localSelectedList.findIndex((e) => e.uid === tag.uid);
    if (tagIndex < 0) {
      this.localSelectedList.push(tag);
    } else {
      this.localSelectedList.splice(tagIndex, 1);
    }
  }

  private onClearFilter(selectedFilter: CommunityUserTag): void {
    this.toggleTagItem(selectedFilter);
  }

  private onShow(): void {
    this.searchQuery = '';
    this.onSearch();
    if (this.authUser) {
      this.filterUserEntityTags({
        filter: {
          user: { uid: this.authUser.uid },
          links: { target: { uid: this.entityCode } },
        },
      })
        .then((response) => {
          if (response && response.length > 0) {
            this.localSelectedList = response;
            this.selectedIds = response.map((tag: CommunityUserTag) => tag.uid) || [];
          } else {
            this.selectedIds = [];
          }
        });
    }
  }

  @Watch('hasShowMoreButton')
  private showMoreButtonChange(): void {
    if (!this.hasShowMoreButton) {
      this.showMore = false;
    }
  }

  private addRemoveMoreButton(): void {
    this.$nextTick(() => {
      if (this.$el) {
        const element = this.$el.querySelector('.my-selected-list');
        if (element) {
          const limit = 88;
          if (element.hasChildNodes() && !this.showMore) {
            const firstChildTop = element.children[0].getBoundingClientRect().top;
            element.childNodes.forEach((child) => {
              if ((child as HTMLElement).getBoundingClientRect().top >= (limit + firstChildTop)
                  || (child as HTMLElement).classList.contains('d-none')) {
                (child as HTMLElement).classList.add('d-none');
                this.hasShowMoreButton = true;
              } else {
                (child as HTMLElement).classList.remove('d-none');
                this.hasShowMoreButton = false;
              }
            });
          }
        }
      }
    });
  }

  private onShowMoreButtonClick(): void {
    if (!this.showMore) {
      if (this.$el) {
        const element = this.$el.querySelector('.my-selected-list');
        if (element) {
          if (element.hasChildNodes()) {
            element.childNodes.forEach((child) => {
              (child as HTMLElement).classList.remove('d-none');
            });
          }
        }
      }
    } else {
      this.addRemoveMoreButton();
    }
    this.showMore = !this.showMore;
  }

  private isTagSelected(tag: CommunityUserTag): boolean {
    const isSelected = this.localSelectedList.find((element: CommunityUserTag) => tag.uid === element.uid);
    return !!isSelected;
  }

  private infiniteHandler(state: StateChanger): void {
    this.isLoading = true;
    // eslint-disable-next-line @typescript-eslint/camelcase
    const searchFilter = this.searchQuery.length > 0 ? { name_contains: this.searchQuery } : {};
    this.paginated({
      filter: {
        user: {
          uid: this.authUser.uid,
        },
        ...searchFilter,
      },
      offset: this.offset,
    })
      .then((tags) => {
        this.localTagList.push(...tags);
        if (this.searchQuery) {
          this.canCreateNewTag = tags.length === 0
                || (tags && tags.length > 0 && tags[0].name !== this.searchQuery.trim());
        }
        if (tags.length === TAG_LIST) {
          state.loaded();
          this.offset += TAG_LIST;
        } else {
          state.complete();
          this.offset = 0;
        }
      })
      .catch(() => state.complete())
      .finally(() => {
        this.isLoading = false;
      });
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
