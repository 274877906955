var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('standard-modal',{attrs:{"modal-id":_vm.modalId,"size":"lg"},on:{"on-show-modal":_vm.onShow},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-start justify-content-between w-100 column-gap-2"},[_c('div',{staticClass:"d-flex flex-column align-items-start w-100 row-gap-2"},[(_vm.isMobile)?_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.product.add.large-product-title'))+" ")]):_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('admin-panel.modal.product.add.large-product-title'))+" ")]),_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$t('admin-panel.modal.product.add.large-product-info')),expression:"$t('admin-panel.modal.product.add.large-product-info')"}],staticClass:"m-0"})]),_c('div',{staticClass:"d-flex"},[_c('button-icon-component',{staticClass:"border-0 ml-2",attrs:{"size":"md","variant":"tertiary"},on:{"on-click":_vm.onCancel},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-xmark","icon-classes":"icon-16"}})]},proxy:true}])})],1)])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"w-100 p-4 shadows-line-down"},[_c('input-search-component',{attrs:{"default-value":_vm.searchQuery,"placeholder":("" + (_vm.$t('admin-panel.search-placeholder'))),"color":"gray","size":"md"},on:{"search-query":_vm.onSearch}})],1),_c('div',{class:[
        'd-flex flex-column w-100 hide-scrollbar',
        {'large-product-list': !_vm.isMobile},
        {'large-product-list-sm': _vm.isMobile} ]},[_vm._l((_vm.getPaginatedLargeProducts),function(largeProduct){return _c('div',{key:largeProduct.uid,class:['large-product-list-item py-2 cursor-pointer ' +
                   'd-flex align-items-center justify-content-between shadows-line-down',
                 {'selected': _vm.isSelected(largeProduct)}],on:{"click":function($event){return _vm.onListItemClick(largeProduct)}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"list-item-checkbox-container"},[(_vm.isSelected(largeProduct))?_c('font-awesome-component',{attrs:{"icon":"fa-solid fa-square-check","icon-classes":"icon-20 text-blue-b-3-primary-blue"}}):_c('font-awesome-component',{attrs:{"container-classes":"icon-20","icon":"fa-regular fa-square","icon-classes":"icon-20 text-neutral-n-5-placeholder bg-white"}})],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"h-100 px-2"},[_c('div',{staticClass:"list-item-img-container"},[(_vm.getItemImage(largeProduct))?_c('img',{staticClass:"image",attrs:{"src":_vm.getItemImage(largeProduct),"alt":""}}):_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 w-100"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-16","icon":"fa-regular fa-file","icon-classes":"text-neutral-n-6-label icon-16"}})],1)])]),_c('div',{staticClass:"d-flex align-items-center pl-3"},[_c('p',{staticClass:"mb-0 text-left text-neutral-n-8-dark-ink name-section-text"},[_vm._v(" "+_vm._s(largeProduct.name)+" ")])])])]),_c('div',{class:[
            {'manufacturer': !_vm.isMobile},
            {'manufacturer-sm': _vm.isMobile} ]},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(largeProduct.manufacturer)+" ")])])])])}),_c('infinite-loading',{ref:"infiniteLoading",on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"spinner",fn:function(){return [_c('loading-spinner-component')]},proxy:true}])},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),(_vm.getPaginatedLargeProducts.length > 0)?_c('div',{attrs:{"slot":"no-results"},slot:"no-results"}):_c('div',{staticClass:"h-100 w-100 d-flex align-items-center",attrs:{"slot":"no-results"},slot:"no-results"},[_c('illustration-component',{staticClass:"w-100 h-100",attrs:{"illustration-type":_vm.IllustrationType.SEARCH_EMPTY,"message":("" + (_vm.$t('toolbox.agenda.view.filter-no-result-found-text'))),"title":("" + (_vm.$t('toolbox.agenda.view.filter-no-result-found-title')))}})],1)])],2)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('div',{class:[ 'd-flex column-gap-2',
                  {'w-100 flex-row': _vm.isMobile}]},[_c('div',{class:[{'w-50': _vm.isMobile}]},[_c('button-component',{attrs:{"text":("" + (_vm.$t('admin-panel.modal.product.button.cancel'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onCancel}})],1),_c('div',{class:[{'w-50': _vm.isMobile}]},[_c('button-component',{attrs:{"disabled":_vm.isSaveBtnDisabled,"loading":_vm.getAllPromisesLoading,"text":("" + (_vm.$t('admin-panel.modal.product.button.save'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onConfirm}})],1)])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }