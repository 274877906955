









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class MediaBackground extends Vue {
  @Prop({ required: false, default: false })
  private parallax!: boolean;

  @Prop({ required: false, default: null })
  private img!: string;

  @Prop({ required: false, default: 0 })
  private containerHeight!: number;

  @Prop({ required: false })
  private backgroundVariant!: string;

  @Watch('img', { immediate: true })
  setBgImage(): void {
    this.$nextTick(() => {
      if (this.img) {
        (this.$el as HTMLElement).style.backgroundImage = `url(${this.img})`;

        if (this.containerHeight) {
          (this.$el as HTMLElement).style.height = `${this.containerHeight}px`;
        }

        if (this.parallax) {
          (this.$el as HTMLElement).style.backgroundAttachment = 'fixed';
          (this.$el as HTMLElement).style.backgroundPosition = 'center';
          (this.$el as HTMLElement).style.backgroundRepeat = 'no-repeat';
        }
      }
    });
  }
}
