var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column row-gap-6"},[_c('h4',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.profile-tab.company-information'))+" ")]),_c('div',{staticClass:"d-flex flex-column row-gap-4"},[_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_c('div',{class:[
          'd-flex align-items-center justify-content-start',
          {'column-gap-6': !_vm.isMobile},
          {'flex-column row-gap-6': _vm.isMobile} ]},[_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('p',{staticClass:"m-0 paragraph-1 regular text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.profile-tab.name'))+" ")]),_c('my-profile-input-text',{attrs:{"default-value":_vm.company.name,"disabled":""},on:{"update:defaultValue":function($event){return _vm.$set(_vm.company, "name", $event)},"update:default-value":function($event){return _vm.$set(_vm.company, "name", $event)}}})],1),_c('div',{staticClass:"d-flex flex-column row-gap-2 w-100"},[_c('p',{staticClass:"m-0 paragraph-1 regular text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.profile-tab.display-name'))+" ")]),_c('my-profile-input-text',{attrs:{"default-value":_vm.company.displayName,"disabled":_vm.disabled},on:{"update:defaultValue":function($event){return _vm.$set(_vm.company, "displayName", $event)},"update:default-value":function($event){return _vm.$set(_vm.company, "displayName", $event)},"on-focus-out":function (newValue) { return _vm.onDisplayNameChange(newValue); }}})],1)]),_c('label',{staticClass:"label-3 regular text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.profile-tab.no-edit'))+" ")])]),(_vm.descriptionLimit !== 0)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_c('text-editor-component',{class:[!_vm.isDesktop &&'hide-toolbar'],attrs:{"configs":_vm.editorConfigs,"disabled":_vm.disabled,"initial-content":_vm.formatCompanyDescription,"label":("" + (_vm.$t('app.admin-panel.profile-tab.description'))),"limit":_vm.descriptionLimit,"translatable":_vm.locales && _vm.locales.length > 1,"translations":_vm.descriptionTranslations},on:{"on-blur":_vm.onDescriptionChange}})],1):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE)
        && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_COMPANY_PROFILE_COUNTRY_FEATURE).enabled)?_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_c('p',{staticClass:"m-0 paragraph-1 regular text-left text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('app.admin-panel.address.field.company.country'))+" ")]),_c('div',{class:[
          {'country-input': !_vm.isMobile},
          {'w-100': _vm.isMobile}
        ]},[_c('input-select-options-component',{attrs:{"disabled":_vm.disabled,"is-icon":true,"label":_vm.$t('app.admin-panel.address.field.choose-country'),"only-select":true,"option-chosen-prop":_vm.company
            ? _vm.company.country
              ? {
                uid: _vm.company.country.uid,
                value: _vm.company.country.iso,
                text: _vm.company.country.printableName,
                countryFlag: ("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.company.country.iso
                  .toLowerCase()) + ".svg")
              } : null : null,"options":_vm.parsedCountriesList,"boundary":"scrollParent","component-id":"company-country"},on:{"on-select":_vm.onLocationChange}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }