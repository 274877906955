














import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import VideoSelectorComponent from '@/components/VideoSelectorComponent.vue';
import VideoSelectorModal from '@/components/modals/VideoSelectorModal.vue';
import FileResource from '@/models/graphql/FileResource';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    VideoSelectorModal,
    VideoSelectorComponent,
    ButtonComponent,
    InputText,
    ButtonIconComponent,
    StandardModal,
  },
})
export default class ExhibitorVideoEditModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  private community!: Community;

  @Prop({ required: false, default: null })
  private readonly exhibitorVideo!: ExhibitorVideo;

  @Prop({ required: false, default: '' })
  private readonly exhibitorId!: string;

  private exhibitorVideoToEdit: ExhibitorVideo | null = null;

  private name: string | undefined = '';

  private url: string | undefined = '';

  private file: File | undefined | null = null;

  private thumbnail: FileResource | undefined | null = null;

  private EntityType = EntityType;

  mounted(): void {
    this.exhibitorVideoToEdit = JSON.parse(JSON.stringify(this.exhibitorVideo));
    this.setData();
  }

  @Watch('exhibitorVideo')
  private updateExhibitorVideo(): void {
    this.exhibitorVideoToEdit = JSON.parse(JSON.stringify(this.exhibitorVideo));
    this.setData();
  }

  private setData(): void {
    if (this.exhibitorVideoToEdit) {
      this.name = this.exhibitorVideoToEdit.name;
      this.url = this.exhibitorVideoToEdit.url;
      this.thumbnail = this.exhibitorVideoToEdit.thumbnailFileResource;
    } else {
      this.name = '';
      this.url = '';
      this.file = null;
      this.thumbnail = null;
    }
  }

  private onConfirm(): void {
    this.$emit('on-update-end');
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }
}
