export const UI_ONBOARDING_WIDGET_BASE_FRAGMENT = `
  fragment uiOnboardingWidgetBaseFragment on UiOnboardingWidget {
    uid
    schemaCode
    community {
      uid
    }
    type
    code
    payload
    displaySequence
    createdTime
    updatedTime
  }
`;
