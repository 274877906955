



















import Channel from '@/models/graphql/Channel';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ChannelTileComponent from '@/components/mosaic/tiles/ChannelTileComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import CategoryTileComponent from '@/components/mosaic/tiles/CategoryTileComponent.vue';

@Component({
  components: {
    CategoryTileComponent,
    ChannelTileComponent,
  },
})
export default class OneTileMosaicComponent extends Vue {
  @Prop({ required: true })
  private tile!: Channel;

  @Prop({ required: true, default: 'Channel' })
  private type!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  private entityType = EntityType;
}
