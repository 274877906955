import { Component, Vue } from 'vue-property-decorator';
import { BasicTypes } from '@/utils/types/BasicTypes';
import QnaBaseStore from '@/store/QnaBaseStore';

@Component({
  inheritAttrs: false,
})
export default class QnaRegisterStore extends Vue {
  protected qnaStoreName!: string;

  protected storeCreated = false;

  created(): void {
    if (this.qnaStoreName && !this.$store.hasModule(this.qnaStoreName)) {
      this.$store.registerModule(this.qnaStoreName, QnaBaseStore);
      this.storeCreated = true;
    }
  }

  destroyed(): void {
    if (this.qnaStoreName && !this.$store.hasModule(this.qnaStoreName)) {
      this.$store.unregisterModule(this.qnaStoreName);
    }
  }

  protected dispatch<T>(name: string, payload?: BasicTypes): Promise<T> {
    return this.$store.dispatch(`${this.qnaStoreName}/${name}`, payload);
  }

  protected commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`${this.qnaStoreName}/${name}`, payload);
  }

  protected getter<T>(name: string): T {
    return this.$store.getters[`${this.qnaStoreName}/${name}`];
  }

  protected state<T>(name: string): T {
    return this.$store.state[this.qnaStoreName][name];
  }
}
