











































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import {
  differenceInMinutes,
  eachHourOfInterval,
  endOfDay,
  format,
  getHours,
  getMinutes,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { State } from 'vuex-class';
import Meeting from '@/models/graphql/Meeting';
import CalendarViewSlotComponent from '@/components/calendar/CalendarViewSlotComponent.vue';
import CalendarEventParams from '@/utils/types/CalendarEventParams';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  components: { CalendarViewSlotComponent },
})
export default class CalendarDailyViewComponent extends Vue {
  @State
  private selectedTzName!: string;

  @Prop({ required: true, default: () => [] })
  private meetings!: Meeting[];

  @Prop({ required: true, default: null })
  private selectedDate!: Date;

  @Prop({ required: false, default: null })
  private meetingRequest!: MeetingRequest | null;

  @State
  private dateLocale!: Locale;

  private STARTTIME = 0;

  private ENDTIME = 24;

  private HEIGHTOFHOUR = 60;

  private eventsById: Record<string, CalendarEventParams> = {};

  private agendaEvents: CalendarEventParams[] = [];

  private timeSlot: { enable: boolean; style: {top: string; height: string}} | null = null;

  private get dayHours(): string[] {
    const now = new Date();
    const dayHours: string[] = [];
    const start = startOfDay(now);
    const end = endOfDay(now);
    eachHourOfInterval({ start, end }).forEach((hour) => {
      dayHours.push(format(hour, `${this.$t('app.date.defaultTimeFormat')}`, { locale: this.dateLocale }));
    });
    return dayHours;
  }

  @Watch('meetingRequest', { immediate: true, deep: true })
  private setTimeSlot(): void {
    if (this.meetingRequest && !this.meetingRequest.meeting) {
      const tzStartTime = DateTimeHelper.utcToZonedTimeDate(
        this.meetingRequest.startTimestamp * 1000,
        this.selectedTzName,
      );
      const tzEndTime = DateTimeHelper.utcToZonedTimeDate(
        this.meetingRequest.endTimestamp * 1000,
        this.selectedTzName,
      );
      if (isSameDay(tzStartTime, this.selectedDate)) {
        const top = `${(getHours(tzStartTime) * this.HEIGHTOFHOUR) + getMinutes(tzStartTime)}px`;
        const height = `${differenceInMinutes(tzEndTime, tzStartTime)}px`;
        this.timeSlot = {
          enable: true,
          style: {
            top,
            height,
          },
        };
        this.scrollToMeeting();
      }
    } else {
      this.timeSlot = null;
    }
  }

  @Watch('meetings')
  private renderDailyView(): void {
    this.updateAgendaEvents();
    let max: number;
    let m;
    let e;
    let ts;
    let event;
    let leftIndex;

    const MINUTESINDAY = (this.ENDTIME - this.STARTTIME) * this.HEIGHTOFHOUR;

    const timeslots: Record<number, string[]> = [];
    for (m = 0; m < MINUTESINDAY; m++) {
      Object.assign(timeslots, { [m]: [] });
    }

    this.setUpEvents();
    const numEvents = this.agendaEvents.length;
    for (e = 0; e < numEvents; e++) {
      event = this.agendaEvents[e];
      for (m = event.start; m < event.stop; m++) {
        timeslots[m].push(event.id);
      }
    }

    for (m = 0; m < MINUTESINDAY; m++) {
      ts = timeslots[m];
      for (e = 0; e < ts.length; e++) {
        event = this.eventsById[ts[e]];
        max = ts.length;
        // eslint-disable-next-line no-loop-func
        ts.forEach((id) => {
          const evt = this.eventsById[id];
          max = (evt.numColumns > max) ? evt.numColumns : max;
        });

        if (event.numColumns <= max) {
          event.numColumns = max;
        }

        if (event.leftIndex === -1) {
          leftIndex = 0;
          while (!this.isFreeSpace(ts, leftIndex, event.id)) {
            leftIndex += 1;
          }
          event.leftIndex = leftIndex;
        }
      }
    }

    for (m = 0; m < MINUTESINDAY; m++) {
      ts = timeslots[m];
      for (e = 0; e < ts.length; e++) {
        event = this.eventsById[ts[e]];
        max = ts.length;
        // eslint-disable-next-line no-loop-func
        ts.forEach((id) => {
          const evt = this.eventsById[id];
          max = (evt.numColumns > max) ? evt.numColumns : max;
        });

        if (event.numColumns <= max) {
          event.numColumns = max;
        }
      }
    }
    this.layoutEvents();
  }

  @Watch('meetings')
  private scrollToMeeting(): void {
    this.$nextTick(() => {
      const contentEl = this.$el;
      if (contentEl) {
        let top = this.HEIGHTOFHOUR * 7;
        if (this.meetings && this.meetings.length > 0 && this.meetings[0].startTimestamp) {
          top = getHours(
            DateTimeHelper.utcToZonedTimeDate(
              this.meetings[0].startTimestamp * 1000,
              this.selectedTzName,
            ),
          ) * this.HEIGHTOFHOUR;
        }
        if (this.timeSlot
        && this.timeSlot.style
        && this.timeSlot.style.top) {
          top = parseInt(this.timeSlot.style.top.substr(0, this.timeSlot.style.top.length - 2), 10);
        }
        contentEl.scrollTop = top;
      }
    });
  }

  private updateAgendaEvents(): void {
    this.agendaEvents = [];
    this.meetings.forEach((item) => {
      this.agendaEvents.push({
        data: item,
        id: item.uid,
        starttime: item.startTimestamp
          ? format(
            DateTimeHelper.utcToZonedTimeDate(
              item.startTimestamp * 1000,
              this.selectedTzName,
            ), 'HH:mm',
          )
          : '',
        endtime: item.endTimestamp
          ? format(DateTimeHelper.utcToZonedTimeDate(
            item.endTimestamp * 1000,
            this.selectedTzName,
          ), 'HH:mm')
          : '',
        start: 0,
        height: 0,
        stop: 0,
        numColumns: 1,
        topPos: 0,
        leftIndex: 0,
        dayOfWeek: -1,
        fullPosition: {},
      });
    });
  }

  private layoutEvents(): void {
    let numX;
    let xFactor;
    let left;
    this.agendaEvents.forEach((event) => {
      numX = event.numColumns;
      xFactor = 1 / numX;
      left = (event.leftIndex * xFactor * 100);
      event.fullPosition = {
        top: `${Math.round(event.topPos)}px`,
        left: `calc(${left}% + ${event.leftIndex > 0 ? '4px' : 0})`,
        width: event.leftIndex > 0
          ? `calc(${Math.floor(100 * xFactor)}% - 4px)`
          : `${Math.floor(100 * xFactor)}%`,
        height: `${Math.round(event.height)}px`,
      };
    });
  }

  private setUpEvents(): void {
    const numEvents = this.agendaEvents.length;
    let event;
    let e;
    let pos;
    let stH;
    let stM;
    let etH;
    let etM;
    let height;

    for (e = 0; e < numEvents; e++) {
      event = this.agendaEvents[e];
      event.leftIndex = -1;
      event.numColumns = 0;
      pos = event.starttime.indexOf(':');
      stH = parseInt(event.starttime.substr(0, pos), 10);
      stM = parseInt(event.starttime.substr(pos + 1), 10) / this.HEIGHTOFHOUR;

      event.start = ((stH - this.STARTTIME) * this.HEIGHTOFHOUR) + (stM * this.HEIGHTOFHOUR);
      event.topPos = ((stH - this.STARTTIME) * this.HEIGHTOFHOUR) + (stM * this.HEIGHTOFHOUR);

      pos = event.endtime.indexOf(':');
      etH = parseInt(event.endtime.substr(0, pos), 10);
      etM = parseInt(event.endtime.substr(pos + 1), 10) / this.HEIGHTOFHOUR;
      event.stop = ((etH - this.STARTTIME) * this.HEIGHTOFHOUR) + (etM * this.HEIGHTOFHOUR);

      height = (etH - stH) * this.HEIGHTOFHOUR;
      height -= stM * this.HEIGHTOFHOUR;
      height += etM * this.HEIGHTOFHOUR;
      event.height = height;
      this.eventsById[event.id] = event;
    }
  }

  private isFreeSpace(ts: string[], leftIndex: number, eventId: string): boolean {
    const tsLength = ts.length;
    let event;
    for (let i = 0; i < tsLength; ++i) {
      event = this.eventsById[ts[i]];
      if (event.leftIndex === leftIndex) {
        return event.id === eventId;
      }
    }
    return true;
  }
}
