
























































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import VueContextLoading from '@/components/VueContextLoading';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import InputSelectOptionParams from '@/utils/types/InputSelectOptionParams';

@Component({
  components: {
    IllustrationComponent,
    LoadingSpinnerComponent,
    InputSearchComponent,
  },
})
export default class InputSelectOptionsComponent extends VueContextLoading {
  @Prop({ default: '' })
  readonly defaultValue!: string;

  @Prop({ default: '' })
  readonly placeholder!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: null })
  readonly optionChosenProp!: InputSelectOptionParams | null;

  @Prop({ default: false })
  readonly onlySelect!: boolean;

  @Prop({ default: false })
  readonly showEvenEmpty!: boolean;

  @Prop({ default: false })
  readonly isMobile!: boolean;

  @Prop({ default: 'viewport' })
  readonly boundary!: string;

  @Prop({
    required: true,
    default: false,
  })
  readonly componentId!: string;

  @Prop({ default: '' })
  private label!: string;

  @Prop({ default: false })
  private isIcon!: boolean;

  @Prop({ default: false })
  private lazyLoading!: boolean;

  @Prop({ required: true })
  private options!: InputSelectOptionParams[];

  @Prop({ default: '' })
  private name!: string;

  @Prop({
    required: false,
    default: 'text',
  })
  private type!: string;

  @Prop({
    required: false,
    default: true,
  })
  private enableSearch!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isMandatory!: boolean;

  private inputText = '';

  private searchResults = [] as InputSelectOptionParams[];

  private optionChosen:
      InputSelectOptionParams | null = this.optionChosenProp || null;

  private IllustrationType = IllustrationType;

  private optionsParam: InputSelectOptionParams[] = [{
    uid: '',
    value: 'default',
    text: this.label,
    // eslint-disable-next-line no-nested-ternary
    iconClass: this.options && this.options[0]
      ? this.options[0].iconClass ? this.options[0].iconClass : undefined : undefined,
    // eslint-disable-next-line no-nested-ternary
    countryFlag: this.options && this.options[0]
      ? this.options[0].countryFlag ? this.options[0].countryFlag : undefined : undefined,
  }];

  @Watch('optionChosenProp', { deep: true })
  watchOptionChosenProp(): void {
    this.optionChosen = this.optionChosenProp;
  }

  mounted(): void {
    this.optionsParam = this.options;
    this.inputText = this.defaultValue;
  }

  @Watch('defaultValue')
  updateInput(): void {
    this.inputText = this.defaultValue;
  }

  @Watch('options')
  watchOptions(): void {
    this.optionsParam = this.options;
  }

  private onSelect(payload: InputSelectOptionParams): void {
    this.optionChosen = payload;
    this.inputText = payload.text;
    this.$emit('on-select', this.optionChosen);
  }

  private onSearch(payload: { query: string }): void {
    if (this.lazyLoading) {
      (this.$refs.infiniteLoading as unknown as Vue).$emit('$InfiniteLoading:reset');
      this.$emit('on-search', payload.query);
    } else {
      this.searchResults = this.optionsParam
        .filter((item) => item.text.toLowerCase()
          .includes(payload.query.toLowerCase()));
    }
  }
}
