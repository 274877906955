enum ValidationType{
    EMAIL = 'email',
    PASSWORD = 'password',
    PHONE = 'phone',
    WEBSITE = 'website',
    REQUIRED = 'required',
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
}

export default ValidationType;
