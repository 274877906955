
































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: { ButtonComponent },
})
export default class CompanyResourceItem extends BreakpointWrapper {
  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: false })
  private type!: string;

  @Prop({ required: false })
  private fax!: string;

  @Prop({ required: false })
  private phone!: string;

  @Prop({ required: false })
  private email!: string;

 @Prop({ default: false })
  private disabled!: string;

 private get hasPrimaryInfo(): boolean {
   return this.name !== '' || this.type !== '';
 }

 private get hasSecondaryInfo(): boolean {
   return this.fax !== '' || this.phone !== '' || this.email !== '';
 }
}
