var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-box w-100\n    d-flex",class:[
    {'justify-content-between align-items-center column-gap-6': !_vm.isMobile},
    {'flex-column row-gap-6': _vm.isMobile && !_vm.isSwitcher && !_vm.hideEditBtn},
    {'justify-content-between align-items-start column-gap-4': _vm.isMobile && _vm.isSwitcher},
    {'p-4': !_vm.isFullWidth},
    {'py-4 pr-4': _vm.isFullWidth}
  ]},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.titleSmall)?_c('h6',{staticClass:"mb-0 text-left"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('h4',{staticClass:"mb-0 text-left"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex"},[(!_vm.isMobile)?_vm._t("icon"):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"m-0 text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],2)]),_c('div',{class:[
      'd-flex',
      !!_vm.$slots.icon && _vm.isMobile ? 'justify-content-between' : 'justify-content-end'
    ]},[(!!_vm.$slots.icon && _vm.isMobile)?_vm._t("icon"):_vm._e(),(_vm.isSwitcher)?_c('checkbox-switch-component',_vm._g({attrs:{"is-loading":_vm.isLoading,"value":_vm.isSelected}},_vm.$listeners)):(!_vm.hideEditBtn)?_c('button-component',{attrs:{"text":("" + (_vm.$t('settings-page.edit-button'))),"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.$emit('on-edit')}}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }