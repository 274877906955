







































































import { Component, Vue } from 'vue-property-decorator';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import AlertType from '@/utils/enums/AlertType';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import Community from '@/models/graphql/Community';
import { EMAIL } from '@/utils/constants/Regex';

const emailValidation = helpers.regex('email', EMAIL);
const signInStore = namespace('SignInStore');
const authenticationStore = namespace('AuthenticationStore');

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(3),
        emailValidation,
      },
    },
  },
})
export default class SignInPasswordReset extends Vue {
  @Getter
  protected readonly community!: Community;

  @Getter
  private readonly authEmail!: string | null;

  @signInStore.Action
  private triggerRecovery!: (payload: {
    email: string;
  }) => Promise<{ success: boolean }>;

  @authenticationStore.Action
  private setAuthEmail!: (email: string | null) => void;

  private alertType = AlertType;

  private isLoading = false;

  private emailError = false;

  private showError = false;

  private emailServiceError = false;

  private form = {
    email: '',
  };

  created(): void {
    if (this.authEmail) {
      this.form.email = this.authEmail;
    }
  }

  private emailValidation(): void {
    if (this.$v && this.form.email.length > 0) {
      this.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
    }
  }

  private goBack(): void {
    this.$router.back();
  }

  private onSubmit(): void {
    this.emailValidation();
    this.showError = true;
    this.emailServiceError = false;
    this.isLoading = true;
    if (this.form.email
      && this.form.email !== ''
      && !this.$data.emailError
    ) {
      this.triggerRecovery({
        email: this.form.email,
      }).then((response) => {
        if (response && response.success) {
          this.setAuthEmail(this.form.email);
          this.$router.push({ name: 'email-sent' });
        } else {
          this.emailServiceError = true;
        }
      }).catch(() => {
        this.emailServiceError = true;
      });
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }
}
