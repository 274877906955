import { CATEGORY_BASE_FRAGMENT } from '@/graphql/_Fragments/Category/Base';

export const COMMUNITY_USER_INTEREST_BASE_FRAGMENT = `
  fragment communityUserInterestBaseFragment on CommunityUserInterest {
    uid
    schemaCode
    hold
    interest {
      ...categoryBaseFragment
    }
  }
  ${CATEGORY_BASE_FRAGMENT}
`;
