

























import { Component, Prop } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

@Component({
  components: {
    AvatarSoloWidget,
  },
})
export default class FeedFileResourceUpdateComponent extends BreakpointWrapper {
  @Prop({ required: false, default: '' })
  private logo!: string;

  @Prop({ required: false, default: '' })
  private banner!: string;

  @Prop({ required: false, default: '' })
  private defaultIcon!: string;
}
