











































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import HandoutEditModal from '@/components/modals/HandoutEditModal.vue';
import ViewCartModal from '@/components/modals/ViewCartModal.vue';
import { namespace } from 'vuex-class';
import { BasicTypes } from '@/utils/types/BasicTypes';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import PillComponent from '@/components/pill/PillComponent.vue';
import Variant from '@/utils/enums/Variant';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FormatHelper from '@/utils/helpers/FormatHelper';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');

@Component({
  components: {
    ViewCartModal,
    HandoutEditModal,
    FontAwesomeComponent,
    ButtonIconComponent,
    PillComponent,
  },
})
export default class PackageListComponent extends BreakpointWrapper {
    @Prop({ required: false, default: null })
    private salesPackages!: SalesPackage[];

    @Prop({ required: false, default: false })
    private edit!: boolean;

    @widgetDispatcherStore.Getter
    private fetchMagicArgs!: Record<string, BasicTypes> ;

    private Variant = Variant;

    private get isCompanySet(): boolean {
      return !!(this.fetchMagicArgs.companyId);
    }

    private get companyUid(): string {
      return this.fetchMagicArgs.companyId as string;
    }

    private deleteSalePackage(item: SalesPackage): void {
      this.$emit('delete-sale-package', item);
    }

    // eslint-disable-next-line class-methods-use-this
    private getItemImage(item: SalesPackage): string | null{
      let result = null;
      if (item.fileResource) {
        result = FileResourceHelper.getFullPath(item.fileResource, 'w56');
      }
      return result;
    }

    private quantity(item: SalesPackage, num: number): void{
      this.$emit('quantity', { item, num });
    }

    // eslint-disable-next-line class-methods-use-this
    private getPrice(item: SalesPackage): string {
      if (!item.price) {
        return '';
      }

      return FormatHelper.formatCurrency(item.price / 100);
    }

    // eslint-disable-next-line class-methods-use-this
    private validateStockQuantity(item: SalesPackage): boolean {
      if (item.localyOrderQuantity) {
        // eslint-disable-next-line no-underscore-dangle
        const isUnderStock = item._stock.quantity === -1 || item.localyOrderQuantity <= item._stock.quantity;
        item.isOverStock = !isUnderStock;
        return isUnderStock;
      }

      item.isOverStock = false;
      return false;
    }
}
