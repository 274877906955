
































































































































import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import DynamicTextComponent from '@/components/DynamicTextComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import Meeting from '@/models/graphql/Meeting';
import { format } from 'date-fns';
import CommunityUser from '@/models/graphql/CommunityUser';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import Event from '@/utils/types/Event';
import { Getter, namespace, State } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    PillWidget,
    ButtonIconComponent,
    DynamicTextComponent,
    AvatarSoloWidget,
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class CalendarDetailComponent extends BreakpointWrapper {
  @Prop({ default: null })
  private meeting!: Meeting;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private dateLocale!: Locale;

  private MeetingParticipantState = MeetingParticipantState;

  private FileResourceHelper = FileResourceHelper;

  private get meetingDetailDate(): { d: string; t: string } | null {
    if (this.meeting) {
      const event = AgendaStoreHelper.convertMeetingToEvent(this.meeting, this.selectedTzName);
      const std = event.tzStartTime;
      const edd = event.tzEndTime;
      if (std && edd) {
        const zone = this.selectedTzAbbreviation;
        return {
          d: `${format(std, this.$t('app.date.defaultDateFormat')
            .toString(), { locale: this.dateLocale })}`,
          t: `${format(
            std,
            this.$t('app.date.defaultTimeFormat')
              .toString(),
            { locale: this.dateLocale },
          )} — ${format(
            edd,
            this.$t('app.date.defaultTimeFormat')
              .toString(),
            { locale: this.dateLocale },
          )}${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
          || !this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE).enabled ? ` (${zone})` : ''}`,
        };
      }
    }
    return null;
  }

  private get meetingDetailUserEntityList(): Array<MeetingParticipant> {
    if (this.meeting
      && this.meeting.participants
      && this.meeting.participants
      && this.meeting.participants.length > 0) {
      return [{
        uid: this.meeting.creatorUser?.uid,
        state: MeetingParticipantState.ACCEPTED,
        user: this.meeting.creatorUser,
      } as MeetingParticipant,
      ...this.meeting.participants];
    }
    return [];
  }

  private jobDescriptionText(item: CommunityUser): string {
    if (item.employerName) {
      return this.$tc('toolbox.agenda.detail.content-at', 0, {
        job: item.jobTitle,
        employer: item.employerName,
      }).toString();
    }
    return (item.jobTitle) ? item.jobTitle?.toString() : '';
  }

  private openAgendaDetail(): void {
    if (this.meeting) {
      this.setViewMode(ViewMode.DETAIL);
      this.setEventViewed(AgendaStoreHelper.convertMeetingToEvent(this.meeting, this.selectedTzName));
      this.$eventsBus.emit('ontoolbox', {
        view: ToolbarMenuActions.TOOLBAR_AGENDA,
      });
    }
  }
}
