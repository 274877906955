// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends(...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;

  // eslint-disable-next-line func-names
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}
