



























































import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class FeedUserSelector extends BreakpointWrapper {
    @Prop({ required: true, default: null })
    private entities!: { uid: string; img: string; name: string }[];

    private selectedEntity: { uid: string; img: string; name: string } = this.entities[0];

    private isActive = false;

    @Watch('entities')
    updateDefaultSelected(): void{
      // eslint-disable-next-line prefer-destructuring
      this.selectedEntity = this.entities[0];
    }
}
