

















































import { Component, Prop, Watch } from 'vue-property-decorator';
import Meeting from '@/models/graphql/Meeting';
import { State } from 'vuex-class';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import {
  differenceInMinutes, format, getHours, setHours, startOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import MeetingSlotComponent from '@/components/full-calendar/MeetingSlotComponent.vue';

type DailyCalendarType = {
  top: number;
  left: number;
  width: number;
  height: number;
  meeting: Meeting;
}

@Component({
  components: { MeetingSlotComponent },
})
export default class DailyCalendarComponent extends BreakpointWrapper {
  @Prop({ default: () => new Date() })
  private date!: Date;

  @Prop({ default: () => [] })
  private meetings!: Meeting[];

  @Prop({ default: null })
  private activeMeeting!: string;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private calendarHeight = 'calc(100dvh - 8rem)';

  private cellHeight = 142;

  private cellWidth = 142;

  private calendarRecord: DailyCalendarType[] = [];

  private get hours(): string[] {
    const firstDayOfDay = startOfDay(new Date());
    return [...Array(24).keys()]
      .map((hour) => format(
        setHours(firstDayOfDay, hour),
        `${this.$t('app.date.defaultTimeFormat')}`,
        { locale: this.dateLocale },
      ).replace(':00 ', ''));
  }

  mounted(): void {
    this.calcCalendarHeight();
    window.addEventListener('resize', this.calcCalendarHeight);
  }

  private initCalendarRecord(): void {
    this.calendarRecord = [];
    this.meetings.forEach((m) => {
      const startTime = utcToZonedTime(`${m.startTime}Z`, this.selectedTzName);
      const endTime = utcToZonedTime(`${m.endTime}Z`, this.selectedTzName);
      const start = differenceInMinutes(startTime, startOfDay(startTime));
      const end = differenceInMinutes(endTime, startTime);
      this.calendarRecord.push({
        meeting: m,
        top: this.cellHeight * (start / 60) + getHours(startTime) + 2,
        left: this.isMobile ? 53 : 83,
        width: this.cellWidth - 4,
        height: (this.cellHeight * (end / 60)) - 4,
      });
    });
    this.arrangeCalSideBySide();
    const calendarMeetingEl = document.querySelector('.daily-calendar');
    if (calendarMeetingEl) {
      let initScrollPosition = this.cellHeight * 6;
      if (this.calendarRecord.length > 0) {
        initScrollPosition = this.calendarRecord[0].top - this.cellHeight;
      }
      calendarMeetingEl.scrollTo({ top: initScrollPosition });
    }
  }

  private arrangeCalSideBySide(): void {
    this.calendarRecord.sort((a, b) => a.top - b.top);
    let currentRow = 0;
    const rows: DailyCalendarType[][] = [];

    this.calendarRecord.forEach((currentCal, index) => {
      if (index === 0) {
        rows.push([currentCal]);
      } else if (
        this.isColliding(currentCal, this.calendarRecord[index - 1])
      ) {
        rows[currentRow].push(currentCal);
      } else {
        currentRow += 1;
        rows.push([currentCal]);
      }
    });
    this.adjustCalWidths(rows, this.cellWidth);
  }

  private isColliding = (object1: DailyCalendarType, object2: DailyCalendarType): boolean => {
    if (object1.left >= object2.left + object2.width) {
      return false;
    }
    if (object1.left + object1.width <= object2.left) {
      return false;
    }
    if (object1.top >= object2.top + object2.height) {
      return false;
    }
    return object1.top + object1.height > object2.top;
  }

  private adjustCalWidths(rows: DailyCalendarType[][], availableWidth: number): void {
    this.calendarRecord = rows.map((row) => {
      const space = 4;
      const rowWidth = row.reduce((sum, div) => sum + div.width, 0);
      const scaleFactor = availableWidth / rowWidth;
      return row.map((cal, index) => {
        let { left } = cal;
        let { width } = cal;
        if (scaleFactor < 1) {
          left = cal.left + (cal.width * scaleFactor * index);
          width = cal.width * scaleFactor;
          if (index > 0) {
            left += space;
            width -= space;
          }
        }
        return {
          ...cal,
          left,
          width,
        };
      });
    }).flat();
  }

  @Watch('meetings')
  private calcCalendarHeight(): void {
    const el = document.querySelector('.daily-calendar');
    if (el) {
      this.calendarHeight = `calc(100dvh - ${el.getBoundingClientRect().top}px)`;
      this.cellWidth = el.getBoundingClientRect().width - (this.isMobile ? 51 : 81);
    }
    this.initCalendarRecord();
  }
}
