
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import Channel from '@/models/graphql/Channel';
import CommunityUser from '@/models/graphql/CommunityUser';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import EntityType from '@/utils/enums/EntityType';
import Topic from '@/models/graphql/Topic';

@Component({
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
  },
})

/* eslint-disable no-underscore-dangle */
export default class JoinedFeedItemCardComponent extends Vue {
  @Prop({ required: true })
  private initiator!: Exhibitor | SubEdition | Channel | CommunityUser | Topic;

  @Prop({ required: true })
  private createdFormattedDate!: string;

  @Prop({ required: true })
  private elapsedTime!: string;

  private get resource(): string {
    switch (this.initiator.__typename) {
      case EntityType.TOPIC: {
        const topic = Topic.hydrate(this.initiator);
        return topic.mainPicture;
      }
      case EntityType.USER: {
        const user = CommunityUser.hydrate(this.initiator);
        return user.profilePicture;
      }
      case EntityType.EXHIBITOR: {
        const exhibitor = Exhibitor.hydrate(this.initiator);
        return exhibitor.mainPicture;
      }
      case EntityType.SUB_EDITION: {
        const subEdition = SubEdition.hydrate(this.initiator);
        return subEdition.mainPicture;
      }
      case EntityType.CHANNEL: {
        const channel = this.initiator as Channel;
        return FileResourceHelper.getFullPath(channel.thumbnailFileResource, 'w40');
      }
      default:
        return '';
    }
  }

  private get nbFollowers(): number {
    if (this.initiator && '_followerCount' in this.initiator && this.initiator._followerCount) {
      return this.initiator._followerCount;
    }
    return 0;
  }
}
