import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const COMMUNITY_USER_BASE_FRAGMENT = `
  fragment communityUserBaseFragment on CommunityUser {
    uid
    schemaCode
    firstName
    lastName
    name
    email
    jobTitle
    employerName
    banned
    profilePrivacyMode
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    videoPresentationS3Url
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
