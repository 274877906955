var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(function (value) {_vm.isHovered = value;}),expression:"(value) => {isHovered = value;}"}],class:['meeting-request w-100 d-flex justify-content-between text-left p-3 cursor-pointer border-bottom',
           {'bg-neutral-n-2-background': _vm.isHovered}],on:{"click":_vm.onMeetingRequestClick}},[_c('div',{staticClass:"d-flex column-gap-4"},[_c('avatar-solo-widget',{staticClass:"user-avatar border-0 bg-transparent align-self-start",attrs:{"firstname":_vm.firstName,"lastname":_vm.lastName,"src":_vm.image,"with-borders":!_vm.image,"default-icon":"fa-regular fa-circle-user","icon-classes":"icon-16 text-neutral-n-6-label","size":"40"}}),_c('div',{staticClass:"d-flex flex-column row-gap-2"},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.requestTime)?_c('label',{staticClass:"m-0 label label-4 regular text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.requestTime)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex column-gap-4"},[_c('p',{class:['m-0 paragraph large',
                     _vm.isUnread
                       ? 'text-blue-b-3-primary-blue font-weight-bold'
                       : 'text-neutral-n-8-dark-ink']},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('label',{class:['m-0 label label-3 regular text-neutral-n-6-label', {'py-2': !_vm.subtitle}]},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])])],1),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[(_vm.isHovered)?_c('div',{staticClass:"actions d-flex justify-content-center gap-2 py-12",on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.menuList),function(menu){return _c('button-icon-component',{key:menu.uid,attrs:{"id":'menu-item-'+menu.uid,"rounded":true,"size":"md","variant":"tertiary"},on:{"on-click":function($event){return _vm.doAction(menu.action)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":("far " + (menu.icon)),"container-classes":"icon-24","icon-classes":"text-neutral-n-8-dark-ink"}}),_c('b-tooltip',{attrs:{"target":('menu-item-'+menu.uid),"title":_vm.$t(menu.name),"boundary":"viewport","placement":"bottom"}})]},proxy:true}],null,true)})}),1):_vm._e(),(!_vm.isHovered && (_vm.isPending || _vm.isScheduled || _vm.isCompleted))?_c('div',{class:['d-flex flex-column align-items-center p-2 rounded',
               {'bg-neutral-n-3-disable text-neutral-n-6-label': _vm.isCompleted},
               {'bg-yellow-y-1-light-yellow text-neutral-n-8-dark-ink': _vm.isPending},
               {'bg-green-g-1-light-green text-neutral-n-8-dark-ink': _vm.isScheduled}]},[_c('label',{staticClass:"m-0 label-5 medium"},[_vm._v(" "+_vm._s(_vm.badgeStatus)+" ")]),_c('span',{staticClass:"m-0 caps caps-4 bold"},[_vm._v(" "+_vm._s(_vm.badgeDateString)+" ")]),_c('label',{staticClass:"m-0 label label-5"},[_vm._v(" "+_vm._s(_vm.badgeTimeSlot)+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }