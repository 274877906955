





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedContentCard from '@/components/feed/card/FeedContentCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import { Getter, namespace } from 'vuex-class';
import EntityType from '@/utils/enums/EntityType';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import GroupType from '@/utils/enums/chat/GroupType';
import Community from '@/models/graphql/Community';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import FeedAction from '@/utils/enums/feed/FeedAction';
import SendConnectionRequestModal from '@/components/modals/SendConnectionRequestModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import FollowButton from '@/components/buttons/FollowButton.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

const communityUserConnectionStore = namespace('CommunityUserConnectionStore');

@Component({
  components: {
    AvatarSoloWidget,
    FeedContentCard,
    ButtonComponent,
    FontAwesomeComponent,
    SendConnectionRequestModal,
    ConfirmModal,
    FollowButton,
  },
})
export default class FeedConnectionCard extends Vue {
  @Prop({ required: false })
  private uid!: string;

  @Prop({ required: false, default: null })
  private action!: string;

  @Prop({ required: false })
  private user!: CommunityUser;

  @Prop({ required: false })
  private connectedUser!: CommunityUser;

  private connected = this.displayUser?._ourConnection;

  private connectionStatusType = ConnectionStatusType;

  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @communityUserConnectionStore.Action
  private fullyConnect!: (payload: {
    connectionArgs: object;
    newConversationArgs: object;
  }) => Promise<CommunityUserConnection | undefined>;

  @communityUserConnectionStore.Action
  private fullyDisconnect!: (payload: {
    uid: string;
    messageLookupArgs: object;
    toastUserFullName: string;
  }) => Promise<CommunityUserConnection | undefined>;

  private entityType = EntityType.USER;

  private get title(): string {
    if (this.displayUser) {
      return CommunityUser.hydrate(this.displayUser).fullName;
    }
    return '';
  }

  private get subtitle(): string {
    if (this.displayUser
    && this.displayUser.jobTitle) {
      return this.displayUser.jobTitle;
    }
    return '';
  }

  private get profilePicture(): string {
    if (this.displayUser) {
      return FileResourceHelper.getFullPath(this.displayUser.pictureFileResource, 'w250');
    }
    return '';
  }

  private get displayUser(): CommunityUser {
    if (this.action === FeedAction.USER_CONNECTIONS_UPDATED_FROM) {
      this.connected = this.connectedUser?._ourConnection;
      return this.connectedUser;
    }
    this.connected = this.user?._ourConnection;
    return this.user;
  }

  private toggleModal(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.connected
      && this.connected.linkState === this.connectionStatusType.INVITED) return;
    if (this.connected) {
      this.$bvModal.show(`user-card-remove-connection-${this.displayUser.uid}`);
    } else {
      this.$bvModal.show(`user-card-issue-connection-request-${this.displayUser.uid}`);
    }
  }

  private toggleConnection(description: string | null = null): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    const auth = (({
      uid, firstName, lastName, pictureFileResource,
    }) => ({
      uid, firstName, lastName, pictureFileResource,
    }))(this.authUser) as CommunityUser;
    if (this.connected) {
      const temp = this.connected;
      this.connected = null;
      this.fullyDisconnect({
        uid: temp.uid,
        messageLookupArgs: {
          myUid: this.authUser.uid,
          users: [auth, {
            uid: this.displayUser.uid,
            firstName: this.displayUser.firstName,
            lastName: this.displayUser.lastName,
            pictureFileResource: this.displayUser.pictureFileResource,
          } as CommunityUser],
          groupType: GroupType.DIRECT,
        },
        toastUserFullName: `${this.displayUser.firstName} ${this.displayUser.lastName}`,
      }).then(() => {
        this.connected = null;
      }).catch(() => {
        this.connected = temp;
      });
    } else {
      this.fullyConnect({
        connectionArgs: {
          userId: this.authUser.uid,
          linkedUserId: this.displayUser.uid,
          message: description,
        },
        newConversationArgs: {
          message: description,
          users: [CommunityUser.hydrate({ uid: this.displayUser.uid }),
            CommunityUser.hydrate({ uid: this.authUser.uid })],
          tempId: '',
          messageTempId: '',
        },
      }).then((connection) => {
        this.connected = connection;
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          EntityType.USER,
          StatLoggerActions.ADD,
          '',
          -1,
          this.displayUser.uid,
          StatLoggerCategories.CONNECT,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.connected = null;
      });
    }
  }
}
