import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const CATEGORY_BASE_FRAGMENT = `
  fragment categoryBaseFragment on Category {
    uid
    schemaCode
    domain
    name
    displaySequence
    bannerFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
