export default class ClipboardHelper {
  static copyTextToClipboard(text: string): Promise<void> {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return Promise.resolve();
    }
    return navigator.clipboard.writeText(text);
  }

  // eslint-disable-next-line class-methods-use-this
  static fallbackCopyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      // Catch error
    }

    document.body.removeChild(textArea);
  }
}
