






































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Message from '@/models/graphql/Message';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class MessageBoxAnswerComponent extends Vue {
  @Prop({
    required: true,
    default: null,
  })
  private readonly message!: Message;

  @Prop({ default: true })
  private readonly showAction!: boolean;

  private isHovered = false;

  private localMessageContent = '';

  private get getUserName(): string {
    return this.message.user ? CommunityUser.hydrate(this.message.user).fullName : '';
  }

  @Watch('message')
  updateMessageStyle(): void {
    if (this.message && this.message.content) {
      this.localMessageContent = this.message.content;
      this.localMessageContent = this.localMessageContent.replaceAll('\n', '<br/>');
    }
  }

  created(): void {
    this.updateMessageStyle();
  }

  private handleHover(hovered: boolean): void {
    this.isHovered = hovered;
  }
}
