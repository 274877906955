







































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import Poll from '@/models/graphql/Poll';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import PollAnswer from '@/models/graphql/PollAnswer';
import ActionType from '@/utils/enums/ActionType';
import PollDurationModal from '@/components/modals/PollDurationModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import ChoiceParams from '@/utils/types/ChoiceParams';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays, addHours, addMinutes, addSeconds, format,
} from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import ClipboardHelper from '@/utils/helpers/ClipboardHelper';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';

const pollStore = namespace('PollStore');
const toastStore = namespace('ToastStore');
const pollAnswerStore = namespace('PollAnswerStore');

@Component({
  components: {
    CheckboxSwitchComponent,
    FontAwesomeComponent,
    StandardModal,
    ButtonIconComponent,
    ButtonComponent,
    PollDurationModal,
    InputText,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(500),
      },
      choice1: {
        minLength: minLength(1),
      },
      choice2: {
        minLength: minLength(1),
      },
    },
  },
})
export default class PollCreateEditModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({
    required: true,
    default: null,
  })
  private readonly poll!: Poll;

  @Prop({
    required: true,
    default: '',
  })
  private readonly sessionId!: string;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @pollStore.Action(ActionType.CREATE)
  private createPollForSession!: (payload: { sessionUid: string; entity: Partial<Poll> })
      => Promise<Poll | undefined>;

  @pollStore.Action(ActionType.UPDATE)
  private pollUpdate!: (payload: Partial<Poll>)
      => Promise<Poll | undefined>;

  @pollAnswerStore.Action(ActionType.CREATE)
  private createPollAnswer!: (payload: { pollId: string; entity: PollAnswer })
      => Promise<PollAnswer | undefined>;

  @pollAnswerStore.Action(ActionType.UPDATE)
  private updatePollAnswer!: (payload: PollAnswer)
      => Promise<PollAnswer | undefined>;

  @pollAnswerStore.Action(ActionType.DELETE)
  private deletePollAnswer!: (uid: string)
      => Promise<PollAnswer | undefined>;

  private form = {
    title: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',
    choice5: '',
  };

  private correctAnswers: string[] = [];

  private canValidateChoice1 = false;

  private canValidateChoice2 = false;

  private saveWithTimeLoading = false;

  private saveWithoutTimeLoading = false;

  private pollDuration: {
    days: ChoiceParams;
    hours: ChoiceParams;
    minutes: ChoiceParams;
    seconds: ChoiceParams;
    chooseTime: boolean;
    showLiveAnswer: boolean;
  } | null = null;

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private async copyToClipBoard(): Promise<void> {
    try {
      await ClipboardHelper.copyTextToClipboard(this.sessionId);
      this.showToast(ToastActionType.COPY_SESSION_ID_TO_CLIPBOARD);
    } catch (err) {
      this.showToast(ToastActionType.FAILED_COPY_TO_CLIPBOARD);
    }
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  @Watch('poll')
  private onShow(): void {
    this.pollDuration = null;
    this.form.title = `${this.$t('session-polls.modal.placeholder.title')}`;
    this.correctAnswers = [];
    this.form.choice1 = '';
    this.form.choice2 = '';
    this.form.choice3 = '';
    this.form.choice4 = '';
    this.form.choice5 = '';
    if (this.poll) {
      this.form.title = this.poll.title || '';
      if (this.poll.pollAnswers && this.poll.pollAnswers.length) {
        this.form.choice1 = this.poll.pollAnswers.length >= 1
          ? this.poll.pollAnswers[0].title || ''
          : '';
        this.form.choice2 = this.poll.pollAnswers.length >= 2
          ? this.poll.pollAnswers[1].title || ''
          : '';
        this.form.choice3 = this.poll.pollAnswers.length >= 3
          ? this.poll.pollAnswers[2].title || ''
          : '';
        this.form.choice4 = this.poll.pollAnswers.length >= 4
          ? this.poll.pollAnswers[3].title || ''
          : '';
        this.form.choice5 = this.poll.pollAnswers.length >= 5
          ? this.poll.pollAnswers[4].title || ''
          : '';
        this.poll.pollAnswers.forEach((ans, index) => {
          if (ans.correctAnswer) {
            this.correctAnswers.push(`choice${index + 1}`);
          }
        });
      }
    }
    this.canValidateChoice1 = false;
    this.canValidateChoice2 = false;
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
  }

  private onBlur(fieldType: string): void {
    if (this.$v) {
      switch (fieldType) {
        case 'choice1':
          this.canValidateChoice1 = true;
          break;
        case 'choice2':
          this.canValidateChoice2 = true;
          break;
        case 'choice3':
        case 'choice4':
        case 'choice5':
          if (this.form[fieldType].length === 0) {
            this.correctAnswers = this.correctAnswers.filter((ca) => ca !== fieldType);
          }
          break;
        default:
          break;
      }
    }
  }

  private onCorrectAnswerSelected(choice: string, isSelected: boolean): void {
    if (isSelected) {
      this.correctAnswers = Array.from(new Set([...this.correctAnswers, choice]));
    } else {
      this.correctAnswers = this.correctAnswers.filter((ca) => ca !== choice);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private validateField(fieldValue: string, canBeValidated: boolean): boolean {
    return fieldValue === '' && canBeValidated;
  }

  private onPublishNow(payload: {
    days: ChoiceParams;
    hours: ChoiceParams;
    minutes: ChoiceParams;
    seconds: ChoiceParams;
    chooseTime: boolean;
    showLiveAnswer: boolean;
  }): void {
    this.$bvModal.hide('poll-duration-modal-create-edit');
    this.pollDuration = payload;

    if (!this.poll) {
      this.createNewPoll();
    } else {
      this.updatePoll();
    }
  }

  private onPublishClick(later: boolean): void {
    if (!later) {
      this.$bvModal.show('poll-duration-modal-create-edit');
    } else if (!this.poll) {
      this.createNewPoll();
    } else {
      this.updatePoll();
    }
  }

  private createNewPoll(): void {
    const poll = {
      title: this.form.title,
    } as Poll;
    if (this.pollDuration) {
      this.saveWithTimeLoading = true;
      const startTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
      const endTime = this.getPollEndTime(startTime);
      poll.startTime = format(startTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
      poll.endTime = endTime && this.pollDuration.chooseTime ? format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601) : undefined;
      poll.realTimeAnswers = this.pollDuration.showLiveAnswer;
    } else {
      this.saveWithoutTimeLoading = true;
    }
    const pollAnswers: PollAnswer[] = [];
    Object.keys(this.form)
      .forEach((key: string, index: number) => {
        const { form } = this;
        const value = this.form[key as keyof typeof form];
        if (value.length && key !== 'title') {
          pollAnswers.push({
            title: value,
            displaySequence: index,
            correctAnswer: this.correctAnswers.includes(key),
          } as PollAnswer);
        }
      });
    this.createPollForSession({
      sessionUid: this.sessionId,
      entity: poll,
    })
      .then((newPoll: Poll | undefined) => {
        const answersPromises: Promise<PollAnswer | undefined>[] = [];
        if (newPoll) {
          pollAnswers.forEach((pollAnswer: PollAnswer) => {
            answersPromises.push(
              this.createPollAnswer({
                pollId: newPoll.uid,
                entity: pollAnswer,
              }),
            );
          });
          Promise.all(answersPromises)
            .then(() => {
              this.saveWithTimeLoading = false;
              this.saveWithoutTimeLoading = false;
              const sessionId = newPoll.session?.uid;
              const parentSessionId = newPoll.session?.parentSession?.uid;

              this.$emit('on-publish', newPoll.uid, sessionId, parentSessionId);
              this.onCancel();
            });
        }
      });
  }

  private updatePoll(): void {
    if (this.poll) {
      const poll = {
        uid: this.poll.uid,
        title: this.form.title,
      } as Poll;
      if (this.pollDuration) {
        this.saveWithTimeLoading = true;
        const startTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
        const endTime = this.getPollEndTime(startTime);
        poll.startTime = format(startTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
        poll.endTime = endTime && this.pollDuration.chooseTime ? format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601) : null;
        poll.realTimeAnswers = this.pollDuration.showLiveAnswer;
      } else {
        this.saveWithoutTimeLoading = true;
      }
      this.pollUpdate(poll)
        .then(() => {
          const pollAnswersToCreate: Promise<PollAnswer | undefined>[] = [];
          const pollAnswersToUpdate: Promise<PollAnswer | undefined>[] = [];
          Object.keys(this.form)
            .forEach((key: string, index: number) => {
              const { form } = this;
              const value = this.form[key as keyof typeof form];
              if (key !== 'title') {
                const currentAnswer = this.poll.pollAnswers && this.poll.pollAnswers.length >= index
                  ? this.poll.pollAnswers[index - 1] : null;
                if (value.length === 0 && currentAnswer) {
                  pollAnswersToUpdate.push(this.deletePollAnswer(currentAnswer.uid));
                }
                if (
                  currentAnswer
                        && (currentAnswer.title !== value || (currentAnswer.correctAnswer !== this.correctAnswers.includes(key)))
                ) {
                  pollAnswersToUpdate.push(this.updatePollAnswer({
                    uid: currentAnswer.uid,
                    title: value,
                    correctAnswer: this.correctAnswers.includes(key),
                  }));
                } else if (!currentAnswer && value) {
                  pollAnswersToCreate.push(this.createPollAnswer({
                    pollId: this.poll.uid,
                    entity: {
                      title: value,
                      displaySequence: index,
                      correctAnswer: this.correctAnswers.includes(key),
                    } as PollAnswer,
                  }));
                }
              }
            });
          if (pollAnswersToCreate.length || pollAnswersToUpdate.length) {
            Promise.all([...pollAnswersToCreate, ...pollAnswersToUpdate])
              .then(() => {
                this.saveWithTimeLoading = false;
                this.saveWithoutTimeLoading = false;
                this.$emit('on-publish', poll.uid);
                this.onCancel();
              });
          } else {
            this.saveWithTimeLoading = false;
            this.saveWithoutTimeLoading = false;
            this.$emit('on-publish', poll.uid);
            this.onCancel();
          }
        });
    }
  }

  private getPollEndTime(startTime: Date): Date | undefined {
    if (this.pollDuration) {
      return addDays(
        addHours(
          addMinutes(
            addSeconds(
              startTime,
              parseInt(this.pollDuration.seconds.value, 10),
            ),
            parseInt(this.pollDuration.minutes.value, 10),
          ),
          parseInt(this.pollDuration.hours.value, 10),
        ),
        parseInt(this.pollDuration.days.value, 10),
      );
    }
    return undefined;
  }
}
