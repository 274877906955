





































import { Component, Prop } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import SessionCalendarTab from '@/utils/types/session/calendar/SessionCalendarTab';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const sessionBrowserCalendarStore = namespace('SessionBrowserCalendarStore');

@Component
export default class SessionsCalendarDateTabsComponent extends BreakpointWrapper {
  @Prop({ required: true, default: () => ({}) })
  private readonly filter!: object;

  @sessionBrowserCalendarStore.Getter
  private dateTabs!: SessionCalendarTab[];

  @sessionBrowserCalendarStore.Mutation
  private setSelectedDate!: (selected: string) => void;

  @sessionBrowserCalendarStore.Mutation
  private setSelectedKey!: (key: SessionCalendarTab) => void;

  @State
  private dateLocale!: Locale;

  private createdComponent = false;

  private onClick(date: string): void {
    this.setSelectedDate(date);
    // eslint-disable-next-line no-restricted-globals
    history.pushState(history.state,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: Object.assign(this.$route.query, { calendar: date }),
      }).href);
    const foundKey = this.dateTabs.find((t) => t.selected);
    if (foundKey) {
      this.setSelectedKey(foundKey);
    }
    this.$emit('on-calendar-view-selected-date');
  }
}
