




































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Address from '@/models/graphql/Address';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import { Getter, namespace } from 'vuex-class';
import InputSelectOptionsComponent from '@/components/InputSelectOptionsComponent.vue';
import Country from '@/models/graphql/Country';
import Community from '@/models/graphql/Community';
import ToastActionType from '@/utils/enums/ToastActionType';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import EntityType from '@/utils/enums/EntityType';

const addressStore = namespace('AddressStore');
const countryStore = namespace('CountryStore');

@Component({
  components: {
    InputSelectOptionsComponent,
    MyProfileInputText,
  },
})
export default class AddressFormComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private companyId!: string;

  @Prop({ default: false })
  private disabled!: boolean;

  @Getter
  private community!: Community;

  @addressStore.Action
  private loadCompanyAddresses!: (filter?: object) => Promise<Address | undefined>;

  @countryStore.Action
  private loadCountries!: (filter?: object) => Promise<Country[]>;

  @addressStore.Action
  private updateAddress!: ({
    address,
    addressFields,
  }: {
    address: Address;
    addressFields: object;
  }) => Promise<Address | undefined>;

  @addressStore.Action
  private createAddress!: ({
    address,
    entityId,
    type,
  }: {
    address: Partial<Address>;
    entityId: string;
    type: EntityType;
  }) => Promise<Address | undefined>;

  @countryStore.Action
  private countryUpdateExhibitor!:
      ({
        newCountryId,
        addressId,
      }: {
        newCountryId: string;
        addressId: string;
      }) => Promise<Country | undefined>;

  @addressStore.Getter
  private fetchAddresses!: Address[];

  @countryStore.Getter
  private fetchCountries!: Country[];

  private loading = false;

  private address: Address = {
    uid: '',
    tag: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: undefined,
  } as Address;

  private get companyAddress(): Address | null {
    return this.address;
  }

  private get isLoading(): boolean {
    return this.loading;
  }

  private get parsedCountriesList(): ({
    uid: string; value: string; text: string; iconClass?: string; countryFlag?: string;
  } | null)[] {
    return this.fetchCountries.map((country) => (country
      ? {
        uid: country.uid,
        value: country.iso ? country.iso : '',
        text: country.printableName ? country.printableName : '',
        countryFlag: country.iso ? `https://cdn.ipregistry.co/flags/emojitwo/${country.iso
          .toLowerCase()}.svg` : '',
        iconClass: undefined,
      }
      : null));
  }

  created(): void {
    this.loadAddress();
  }

  @Watch('companyId')
  private loadAddress(): void {
    this.loading = true;
    const promises: Array<Promise<Country[] | Address | undefined>> = [this.loadCountries({ schemaCode: this.community?.code })];
    if (this.companyId && this.companyId.length > 0) {
      promises.push(this.loadCompanyAddresses({
        companyId: this.companyId,
        communityCode: this.community.code,
        tag: 'public',
      }));
    }
    Promise.all(promises)
      .then(() => {
        if (this.fetchAddresses && this.fetchAddresses.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.address = this.fetchAddresses.reverse()[0];
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private onAddressChange(field: string, newValue: string | Country): void {
    const address = this.address as unknown as Record<string, string | Country>;
    if (address
        && ((typeof newValue === 'string'
                && address[field] !== newValue)
            || (typeof newValue === 'object'
                && !(address[field]
                    && (address[field] as Country).uid === (newValue as Country).uid)))
    ) {
      if (!(address && address.uid)) {
        const addressFields = field === 'country' ? { tag: 'public' } : {
          [field]: newValue,
          tag: 'public',
        };
        this.createAddress({
          address: addressFields,
          entityId: this.companyId,
          type: EntityType.EXHIBITOR,
        })
          .then((response) => {
            if (response && response.uid) {
              this.address = response;
              if (field === 'country') {
                this.updateAddressField(this.address, newValue, field);
              }
            }
          });
      } else {
        this.updateAddressField(this.address, newValue, field);
      }
    }
  }

  private updateAddressField(address: Address, newValue: string | Country, field: string): void {
    if (field === 'country') {
      this.countryUpdateExhibitor({
        newCountryId: (newValue as Country).uid,
        addressId: this.address.uid,
      })
        .then((result) => {
          this.address.country = result;
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
        });
    } else {
      const addressFields = { [field]: newValue };
      const countryObj = address.country;
      this.updateAddress({
        address,
        addressFields,
      })
        .then((response) => {
          if (response && response.uid) {
            this.address = response;
            if (countryObj) {
              this.address.country = countryObj;
            }
          }
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
        });
    }
  }

  private showToast(type: ToastActionType): void {
    this.$emit('on-show-toast', type);
  }
}
