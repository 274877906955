
















































































import {
  Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import SelectedListParams from '@/utils/types/SelectedListParams';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FloorplanFilterItemComponent from '@/components/FloorplanFilterItemComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';

@Component({
  components: {
    LoadingSpinnerComponent,
    IllustrationComponent,
    FloorplanFilterItemComponent,
    InputSearchComponent,
  },
})
export default class FloorplanFilterComponent extends Vue {
  @Inject({ from: 'windowWidth' })
  private readonly windowWidth!: { value: number };

  @Prop({ required: false })
  private readonly loading!: boolean;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ required: true })
  private readonly list!: SelectedListParams[];

  @Prop({ required: false, default: () => [] })
  private readonly selectedList!: string[];

  private filterList = this.list;

  private showList = false;

  private selectedIds: string[] = this.selectedList;

  private query = '';

  private IllustrationType = IllustrationType;

  private styles: { top: string; left: string; width: string } = {
    top: '0px',
    left: '0px',
    width: '100%',
  };

  mounted(): void {
    this.updateStyles();
    this.updateList();
    this.updateSelectedList();
    document.addEventListener('scroll', this.updateStyles);
    this.$watch('showList', () => (this.showList ? this.updateStyles() : ''));
  }

  clearSelected(): void {
    this.selectedIds = [];
    this.filterList = this.filterList.map((f) => ({ ...f, selected: false }));
  }

  @Watch('selectedList')
  private updateSelectedList(): void {
    this.selectedIds = this.selectedList;
  }

  @Watch('list')
  private updateList(): void {
    this.filterList = this.list;
  }

  @Watch('showList')
  private close(): void {
    this.$emit('toggle');
  }

  @Watch('windowWidth.value')
  private updateStyles(): void {
    const element = this.$el.querySelector('div.filter-pill');
    const filter = this.$el.querySelector('div.filter-list');
    if (element && filter) {
      const container = (element.parentElement)?.parentElement;
      const pillLeft = element.getBoundingClientRect().left;
      const top = element.getBoundingClientRect().top + element.getBoundingClientRect().height + 8;
      const { width } = filter.getBoundingClientRect();
      const safeZone = 16;
      if (this.windowWidth.value > width + pillLeft + safeZone) {
        this.styles = {
          top: `${top}px`,
          left: `${pillLeft}px`,
          width: 'auto',
        };
      } else if (this.windowWidth.value > width + safeZone) {
        this.styles = {
          top: `${top}px`,
          left: `${container?.getBoundingClientRect().left}px`,
          width: 'auto',
        };
      } else {
        this.styles = {
          top: `${top}px`,
          left: '0px',
          width: 'auto',
        };
      }
    }
  }

  private toggleFilterItem(id: string): void {
    const idIndex = this.selectedIds.findIndex((i) => i === id);
    if (idIndex < 0) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(idIndex, 1);
    }
    this.updateFilterList();
    this.$emit('selected-filters', this.selectedIds);
  }

  private updateFilterList(): void {
    this.selectedList.forEach((uid) => {
      this.filterList.forEach((f) => {
        f.selected = f.id === uid;
      });
    });
  }

  private onSearch(payload: { query: string }): void {
    if (payload.query.length > 2) {
      this.filterList = this.filterList
        .filter((f) => f.title.toLowerCase().includes(payload.query.toLowerCase()));
    } else {
      this.filterList = this.list;
    }
    this.query = payload.query;
  }
}
