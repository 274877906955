var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 crm-user-card-widget",class:[
    {'is-mobile': _vm.isMobile},
    {'is-tablet': _vm.isTablet}
  ]},[_c('b-link',{class:[
      'crm-user-card-widget-link w-100 h-100 d-flex', _vm.linkClasses,
      {'border-top-1 border-neutral-n-4-line': _vm.withBorderTop},
      {'is-mobile flex-column': _vm.isMobile},
      {'is-tablet flex-column': _vm.isTablet} ],attrs:{"to":!_vm.urlOpenNewWindow && _vm.cardContext === 'user' ? _vm.handleBaseUrl : null},on:{"click":_vm.onMainCardClick}},[_c('div',{class:[
        'w-100 d-flex align-items-start',
        {'column-gap-6': !_vm.isMobile},
        {'column-gap-2 p-3': _vm.isMobile || _vm.isTablet}
      ]},[_c('div',{staticClass:"avatar"},[_c('avatar-solo-widget',{attrs:{"default-icon":'far fa-circle-user',"firstname":_vm.firstName,"lastname":_vm.lastName,"recommended-for-you":_vm._isRecommendedForMe && _vm.displayRecommendedBadge,"size":_vm.isMobile ? '40' : '96',"src":_vm.FileResourceHelper.getFullPath(_vm.pictureFileResource, 'w96'),"with-borders":true}})],1),_c('div',{class:[
          'd-flex w-100 h-100 flex-column justify-content-between text-neutral-n-8-dark-ink',
          {'row-gap-6': !_vm.isMobile},
          {'row-gap-2': _vm.isMobile}
        ]},[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('div',{class:[
              'd-flex flex-column align-items-start',
              {'row-gap-1': !_vm.isMobile}
            ]},[_c('div',{staticClass:"d-flex align-items-center justify-content-center column-gap-2 mr-3"},[(!_vm.isMobile)?_c('h3',{staticClass:"mb-0 text-left ellipsis-1"},[_vm._v(" "+_vm._s(_vm.getFullPrefixedName)+" ")]):_c('h5',{staticClass:"mb-0 text-left ellipsis-1"},[_vm._v(" "+_vm._s(_vm.getFullPrefixedName)+" ")]),(_vm.countryFlag)?_c('div',{staticClass:"country-container d-inline-flex align-items-center ml-1",attrs:{"id":'tool-item-' + _vm.uid}},[_c('img',{attrs:{"alt":(_vm.countryFlag + " flag"),"height":_vm.isMobile ? '12' : '16',"src":("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.countryFlag.toLowerCase()) + ".svg")}}),(_vm.country && _vm.country.name)?_c('b-tooltip',{attrs:{"target":'tool-item-'+_vm.uid,"title":_vm.country.name,"custom-class":"tooltip-style","placement":"top"}}):_vm._e()],1):_vm._e()]),_c('div',{class:[
                {'job-title-container-lg': _vm.isDesktop || _vm.isTablet},
                {'job-title-container-sm': _vm.isMobile} ]},[(_vm.jobTitleEmployer)?_c('span',{class:[
                  'm-0 text-neutral-n-6-label caps ellipsis-1',
                  {'caps-2': !_vm.isMobile},
                  {'caps-5': _vm.isMobile}
                ]},[_vm._v(" "+_vm._s(_vm.jobTitleEmployer)+" ")]):_vm._e()])]),(((_vm.hasConnectionFeature && _vm.cardContext === 'user') || _vm.cardContext === 'meetingRequest')
              && _vm.buttonsEnabled
              && _vm.isDesktop)?_c('crm-user-card-buttons',{attrs:{"actions":_vm._actions,"card-context":_vm.cardContext,"is-archived":_vm.isArchived,"is-profile-visible":_vm.isProfileVisible,"is-scheduled":_vm.isScheduled,"our-connection":_vm.ourConnection,"uid":_vm.uid,"name":_vm.getFullPrefixedName},on:{"toggle-modal":_vm.toggleModal,"on-accept-connection":_vm.onAccept,"on-decline-connection":_vm.onDecline,"on-open-conversation":function($event){return _vm.openConversation()},"on-create-meeting":function($event){return _vm.createMeeting()},"on-view-profile":function($event){return _vm.onCardClick(_vm.handleBaseUrl)},"on-archive-click":function($event){return _vm.$emit('on-archive-click')},"on-schedule-click":function($event){return _vm.$emit('on-schedule-click')}}}):_vm._e()],1),_c('div',{class:[
            'd-flex',
            {'flex-column align-items-start row-gap-2 justify-content-start': _vm.isMobile},
            {'align-items-center justify-content-between': !_vm.isMobile}
          ]},[_c('div',{class:[
              'd-flex column-gap-2',
              {'pills-sm': _vm.isMobile}
            ]},[(_vm._ourSharedInterestsCompanyCount > 0)?_c('pill-component',{staticClass:"pointer-event-none text-nowrap",attrs:{"clickable":false,"size":_vm.isMobile ? 'sm' : 'md',"text":("" + (_vm.$tc('component.crm-user-card.interests',
                            0, { nbInterests: _vm._ourSharedInterestsCompanyCount | 0 }))),"variant":"yellow"}}):_vm._e(),(_vm._commentCount && _vm._commentCount > 0)?_c('pill-component',{class:['pointer-event-none text-nowrap',
                       {'pl-2 pr-3 py-2': !_vm.isMobile}
              ],attrs:{"clickable":false,"size":_vm.isMobile ? 'sm' : 'md',"text":!_vm.isMobile
                ? ("" + (_vm.$tc('component.crm-user-card.comments', 0, { nbComments: _vm._commentCount })))
                : (_vm._commentCount || '0'),"decoration":"icon","icon-class":"far fa-message-lines","variant":"white"}}):_vm._e()],1),(_vm.isDesktop || _vm.isMobile)?_c('div',{class:[
              'd-flex align-items-center justify-content-end column-gap-2',
              {'avatars-sm': _vm.isMobile}
            ]},[_c('div',{staticClass:"d-flex"},_vm._l((_vm._connections),function(connection,index){return _c('avatar-solo-widget',{key:index,class:[
                  {'avatar-list-item': index < 2},
                  ("avatar-list-item-" + index)
                ],attrs:{"default-icon":'far fa-circle-user',"firstname":connection.firstName,"lastname":connection.lastName,"size":_vm.isDesktop ? '40' : '24',"src":_vm.FileResourceHelper.getFullPath(connection.pictureFileResource, 'w40'),"with-borders":true}})}),1),(_vm._connectionCount > 3)?_c('label',{staticClass:"m-0 text-neutral-n-6-label label-2 medium"},[_vm._v(" "+_vm._s(((_vm._connectionCount - 3) + "+"))+" ")]):_vm._e()]):_vm._e()])])]),(_vm.isMobile || _vm.isTablet)?_c('div',{class:[
        'd-flex align-items-center justify-content-between text-neutral-n-8-dark-ink p-3',
        {'shadows-line-up': _vm.isMobile}
      ]},[(_vm.isTablet)?_c('div',{class:[
          'd-flex align-items-center justify-content-end',
          {'column-gap-2': !_vm.isMobile},
          {'column-gap-1': _vm.isMobile} ]},[_c('div',{staticClass:"d-flex"},_vm._l((_vm._connections),function(connection,index){return _c('avatar-solo-widget',{key:index,class:[
              {'avatar-list-item': index < 2},
              {'avatar-list-item-sm': index < 2 && _vm.isMobile},
              ("avatar-list-item-" + index)
            ],attrs:{"default-icon":'far fa-circle-user',"firstname":connection.firstName,"lastname":connection.lastName,"src":_vm.FileResourceHelper.getFullPath(connection.pictureFileResource, 'w40'),"with-borders":true,"size":"40"}})}),1),(_vm._connectionCount > 3)?_c('label',{staticClass:"m-0 text-neutral-n-6-label label-2 medium"},[_vm._v(" "+_vm._s(((_vm._connectionCount - 3) + "+"))+" ")]):_vm._e()]):_vm._e(),(((_vm.hasConnectionFeature && _vm.cardContext === 'user') || _vm.cardContext === 'meetingRequest')
          && _vm.buttonsEnabled)?_c('crm-user-card-buttons',{attrs:{"actions":_vm._actions,"card-context":_vm.cardContext,"is-archived":_vm.isArchived,"is-profile-visible":_vm.isProfileVisible,"is-scheduled":_vm.isScheduled,"our-connection":_vm.ourConnection,"uid":_vm.uid,"name":_vm.getFullPrefixedName},on:{"toggle-modal":_vm.toggleModal,"on-accept-connection":_vm.onAccept,"on-decline-connection":_vm.onDecline,"on-open-conversation":function($event){return _vm.openConversation()},"on-create-meeting":function($event){return _vm.createMeeting()},"on-view-profile":function($event){return _vm.onCardClick(_vm.handleBaseUrl)},"on-archive-click":function($event){return _vm.$emit('on-archive-click')},"on-schedule-click":function($event){return _vm.$emit('on-schedule-click')}}}):_vm._e()],1):_vm._e()]),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('component.user-card.modal.button.cancel'))),"description":("" + (_vm.$t('component.user-card.modal.remove-connection.message',
                        { name: ("<b>" + _vm.firstName + " " + _vm.lastName + "</b>") }))),"ok-text":("" + (_vm.$t('component.user-card.modal.button.confirm'))),"title":("" + (_vm.$t('component.user-card.modal.remove-connection.title'))),"user-id":_vm.uid,"type":"user-card-remove-connection"},on:{"on-confirm":_vm.toggleConnection}}),_c('send-connection-request-modal',{attrs:{"name":(_vm.firstName + " " + _vm.lastName),"user-id":_vm.uid,"type":"user-card-issue-connection-request"},on:{"on-confirm":_vm.toggleConnection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }