import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { EXHIBITOR_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitorUrl/Base';

export const EXHIBITOR_WITH_URLS_FRAGMENT = `
  fragment exhibitorWithUrlsFragment on Exhibitor {
    uid
    schemaCode
    name
    logoFileResource {
      ...fileResourceBaseFragment
    }
    urls {
      ...exhibitorUrlBaseFragment
    }
  }
  ${EXHIBITOR_URL_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
