var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('standard-modal',{attrs:{"modal-id":_vm.modalId,"size":"md"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('h6',{staticClass:"m-0 font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.site-preferences-section.modals.timezone.title'))+" ")]),_c('button-icon-component',{staticClass:"border-0 ml-auto",attrs:{"size":"md","variant":"tertiary"},on:{"on-click":_vm.onCancel},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-xmark","icon-classes":"icon-16"}})]},proxy:true}])})],1)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center flex-column flex-md-row gap-4"},[_c('div',{class:['flex-1 d-flex flex-column align-items-center row-gap-2 p-3 rounded cursor-pointer',
                 _vm.newSelectedTimeZone === _vm.communityTzName
                   ? 'bg-blue-b-1-light-blue'
                   : 'border-1 border-neutral-n-4-line'
        ],on:{"click":function($event){return _vm.toggleTimeZone(_vm.communityTzName)}}},[_c('radio-input',{attrs:{"value":_vm.newSelectedTimeZone === _vm.communityTzName}}),_c('p',{staticClass:"paragraph-1 m-0 font-weight-bold text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.site-preferences-section.modals.timezone.options.event.title'))+" ")]),_c('p',{staticClass:"paragraph-1 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.site-preferences-section.modals.timezone.options.event.subtitle'))+" ")])],1),_c('div',{class:['flex-1 d-flex flex-column align-items-center row-gap-2 p-3 rounded cursor-pointer',
                 _vm.newSelectedTimeZone === _vm.localTzName
                   ? 'bg-blue-b-1-light-blue'
                   : 'border-1 border-neutral-n-4-line'
        ],on:{"click":function($event){return _vm.toggleTimeZone(_vm.localTzName)}}},[_c('radio-input',{attrs:{"value":_vm.newSelectedTimeZone === _vm.localTzName}}),_c('p',{staticClass:"paragraph-1 m-0 font-weight-bold text-neutral-n-8-dark-ink"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.site-preferences-section.modals.timezone.options.local.title'))+" ")]),_c('p',{staticClass:"paragraph-1 m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('settings-page.site-preferences-section.modals.timezone.options.local.subtitle'))+" ")])],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"m-0 d-flex align-items-center justify-content-end w-100"},[_c('div',{staticClass:"d-flex column-gap-6"},[_c('button-component',{attrs:{"text":("" + (_vm.$t('settings-page.site-preferences-section.modals.timezone.btn-cancel'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onCancel}}),_c('button-component',{attrs:{"text":("" + (_vm.$t('settings-page.site-preferences-section.modals.timezone.btn-save'))),"size":"md","variant":"primary"},on:{"on-click":_vm.saveTimeZone}})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }