















import { Component, Prop, Vue } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class MobileMenuModalItemComponent extends Vue {
  @Prop({ required: true })
  private readonly icon!: string;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop({ required: true })
  private readonly action!: string;

  @Prop({ required: false, default: '' })
  private readonly iconClass!: string;

  private onClick(): void {
    this.$emit('on-click', this.action);
  }
}
