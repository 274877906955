





























import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component
export default class DealInfoComponent extends Vue {
  @Prop({ required: false })
  private startTimestamp!: number;

  @Prop({ required: false })
  private endTimestamp!: number;

  @State
  private dateLocale!: Locale;

  get deal(): {
    from: string | null; until: string | null;
  } | null {
    let from: string | null = null;
    let until: string | null = null;
    if (this.startTimestamp) {
      from = DateTimeHelper.formatFromUnixTime(
        this.startTimestamp,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
    }
    if (this.endTimestamp) {
      until = DateTimeHelper.formatFromUnixTime(
        this.endTimestamp,
        `${this.$t('app.date.defaultDateFormat')}`,
        this.dateLocale,
      );
    }
    return {
      from,
      until,
    };
  }
}
