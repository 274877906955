



















































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ButtonIconComponent,
    StandardModal,
    InputText,
    ButtonComponent,
    FontAwesomeComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      currentEmail: {
        required,
      },
      email: {
        required,
        valid(value: string): boolean {
          // eslint-disable-next-line no-useless-escape, max-len
          return /^[_a-zA-Z0-9-]+((\.[_a-zA-Z0-9-]+)*|(\+[_a-zA-Z0-9-]+)*)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
        },
      },
      repeatEmail: {
        required,
        valid(value: string): boolean {
          // eslint-disable-next-line no-useless-escape, max-len
          return /^[_a-zA-Z0-9-]+((\.[_a-zA-Z0-9-]+)*|(\+[_a-zA-Z0-9-]+)*)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(value);
        },
        sameAsEmail: sameAs('email'),
      },
      password: {
        required,
      },
    },
  },
})
export default class ChangeEmailModalComponent extends VueBaseWidget {
  @Prop({ default: '' })
  private modalId!: string;

  @communityUserStore.Action
  private changeEmail!: (payload: {newEmail: string; password: string}) => Promise<Response>;

  private loading = false;

  private hasError = false;

  private form = {
    currentEmail: '',
    email: '',
    repeatEmail: '',
    password: '',
  };

  private emailFieldFocused = false;

  private emailFieldIsDirty = false;

  private repeatEmailFieldFocused = false;

  private repeatEmailFieldIsDirty = false;

  private get isLoading(): boolean {
    return this.loading;
  }

  private onShowModal(): void {
    this.form.currentEmail = this.authUser.email as string;
  }

  private onDiscard(): void {
    this.$bvModal.hide(this.modalId);
  }

  private validateState(name: string): boolean {
    const $dirty = this.$v.form[name]?.$dirty;
    const $error = this.$v.form[name]?.$error;
    return $dirty ? !$error : true;
  }

  private onBlur(fieldType: string): void {
    const $error = this.$v.form[fieldType]?.$error;
    const $dirty = this.$v.form[fieldType]?.$dirty;
    if ($dirty) {
      switch (fieldType) {
        case 'email':
          this.emailFieldFocused = false;
          this.emailFieldIsDirty = !!$error;
          break;
        case 'repeat-email':
          this.repeatEmailFieldFocused = false;
          this.repeatEmailFieldIsDirty = !!$error;
          break;
        default:
          break;
      }
    }
  }

  private checkEmptyForm(fieldType: string): void {
    const $invalid = this.$v.form[fieldType]?.$invalid;
    const $required = this.$v.form[fieldType]?.required;
    if ($invalid) {
      switch (fieldType) {
        case 'email':
          this.emailFieldIsDirty = !$required;
          break;
        case 'repeat-email':
          this.repeatEmailFieldIsDirty = !$required;
          break;
        default:
          break;
      }
    }
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onSubmit(): void {
    this.loading = true;
    if (this.$v.$invalid) {
      this.checkEmptyForm('email');
      this.checkEmptyForm('repeat-email');
      this.loading = false;
    } else {
      this.hasError = false;
      this.changeEmail({
        newEmail: this.form.email,
        password: this.form.password,
      }).then((responseData) => {
        if (responseData) {
          this.$emit('reset-email-success', this.form.email);
          this.$bvModal.hide(this.modalId);
        } else {
          this.hasError = true;
        }
        this.loading = false;
      });
    }
  }

  private disabledSubmit(): boolean {
    return this.$v.$invalid;
  }
}
