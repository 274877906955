


















































import { Component, Prop } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Data } from '@/utils/types/WidgetData';

@Component({
  components: {
    FontAwesomeComponent,
    SocialLinksComponent,
    ButtonIconComponent,
  },
})
export default class CommunityUserInfoComponent extends BreakpointWrapper {
  @Prop({ required: true })
  private data!: Data[];

  get contacts(): {
    website: string[];
    socials: { url: string; icon: string; type: string }[];
  } | null {
    const website: string[] = [];
    const socials: { url: string; icon: string; type: string }[] = [];
    (this.data as unknown as CommunityUserUrl[])
      .filter((url) => !['mobile', 'phone'].includes(url.type)).forEach((url) => {
        if (url.type === 'website' && url.url) {
          if (!url.url.match(/^[a-zA-Z]+:\/\//)) {
            website.push(`http://${url.url}`);
          } else {
            website.push(url.url);
          }
        } else if (url.type && url.url) {
          socials.push({
            url: url.url,
            icon: (AppSocialMediaIcons as Record<string, string>)[url.type.toLowerCase()]
            || (AppSocialMediaIcons as Record<string, string>).website,
            type: '',
          });
        }
      });
    return {
      website, socials,
    };
  }

  get isEmpty(): boolean {
    return !!(this.contacts
      && this.contacts.socials && this.contacts.socials.length === 0
      && this.contacts.website && this.contacts.website.length === 0);
  }
}
