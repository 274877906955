























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: { FontAwesomeComponent },
})
export default class FeedContactCard extends Vue {
  @Prop({ required: false })
  private exhibitorContactName!: string;

  @Prop({ required: false })
  private exhibitorContactType!: string;

  @Prop({ required: false })
  private phone!: string;

  @Prop({ required: false })
  private fax!: string;

  @Prop({ required: false })
  private email!: string;
}
