




























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: { ButtonComponent },
})
export default class SignInNoSignUp extends Vue {
  @Getter
  protected readonly community!: Community;

  @Getter
  protected readonly authEmail!: string;

  get registrationUrl(): string {
    return this.community
    && this.community.organizer
    && this.community.organizer.registrationWebsite
      ? this.community.organizer.registrationWebsite
      : '';
  }

  private get email(): string {
    if (this.authEmail) {
      return this.authEmail;
    }
    return `${this.$t('app.sign-in.no-sign-up.your-email')}`;
  }

  private onClickRegistration(): void {
    window.open(this.registrationUrl, '_blank');
  }

  private onClickGoBack(): void {
    this.$router.push({ name: 'sign-in' });
  }
}
