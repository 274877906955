
































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { BLink } from 'bootstrap-vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import EntityType from '@/utils/enums/EntityType';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import LargeProduct from '@/models/graphql/LargeProduct';
import ItemSelectorModal from '@/components/modals/ItemSelectorModal.vue';
import Variant from '@/utils/enums/Variant';

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    ItemSelectorModal,
    ButtonIconComponent,
    PillComponent,
    BLink,
  },
})
export default class DealProductLinkSelectorComponent extends Vue {
  readonly code = 'company-selector-deal-product-link-modal'

  @Prop({ required: true })
  private entity!: Data;

  @Prop({ required: true })
  private entityType!: EntityType;

  @Prop({ required: true })
  private listItems!: Data[];

  @Prop({ required: false })
  private publicationTime!: string;

  private selectedId = '';

  private variantEnum = Variant;

  private castedEntity: Product | LargeProduct | Deal | null = null;

  private localSelectedItem: Product | LargeProduct | Deal | null = null;

  @Watch('entity', { immediate: true, deep: true })
  private updateEntity(): void {
    this.setCastedEntity();
  }

  private setCastedEntity(): void {
    switch (this.entityType) {
      case EntityType.PRODUCT:
        if (this.entity?.uid) {
          this.castedEntity = Product.hydrate(this.entity);
        } else {
          this.castedEntity = { deals: [] } as unknown as Product;
        }
        break;

      case EntityType.LARGE_PRODUCT:
        if (this.entity?.uid) {
          this.castedEntity = LargeProduct.hydrate(this.entity);
        } else {
          this.castedEntity = { deals: [] } as unknown as LargeProduct;
        }
        break;

      case EntityType.DEAL:
        if (this.entity?.uid) {
          this.castedEntity = Deal.hydrate(this.entity);
        } else {
          this.castedEntity = { product: null } as unknown as Deal;
        }
        break;

      default:
        break;
    }
  }

  @Watch('castedEntity.product', { deep: true })
  @Watch('castedEntity.largeProduct', { deep: true })
  @Watch('castedEntity.deals', { deep: true })
  @Watch('castedEntity', { deep: true, immediate: true })
  private setSelectedCategories(): void {
    if (!this.castedEntity) return;
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
        // eslint-disable-next-line no-case-declarations
        const allProductCaseEnity = this.castedEntity as Product;
        if (allProductCaseEnity && allProductCaseEnity.deals && allProductCaseEnity.deals.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.localSelectedItem = allProductCaseEnity.deals[0];
        } else {
          this.localSelectedItem = null;
        }
        break;
      case EntityType.DEAL:
        // eslint-disable-next-line no-case-declarations
        const dealCaseEnity = this.castedEntity as Deal;
        if (dealCaseEnity) {
          if (dealCaseEnity.product) {
            this.localSelectedItem = dealCaseEnity.product;
          } else if (dealCaseEnity.largeProduct) {
            this.localSelectedItem = dealCaseEnity.largeProduct;
          } else {
            this.localSelectedItem = null;
          }
        } else {
          this.localSelectedItem = null;
        }
        break;

      default:
        break;
    }
  }

  private modalConfirm(linkItem: LargeProduct | Product | Deal): void {
    this.selectedId = linkItem.uid;
    if (this.entity) {
      switch (this.entityType) {
        case EntityType.PRODUCT:
        case EntityType.LARGE_PRODUCT:
          if (this.entity) {
            (this.castedEntity as Product).deals = [];
          }
          // eslint-disable-next-line no-unused-expressions
          (this.castedEntity as Product).deals?.push(linkItem);
          break;
        case EntityType.DEAL:
          (this.castedEntity as Deal).product = linkItem as Product;
          break;
        default:
          break;
      }
    } else {
      this.localSelectedItem = linkItem;
    }
    this.$emit('update-link',
      {
        id: linkItem.uid,
      });
    this.$bvModal.hide(this.code);
  }

  private onDeleteLink(): void {
    this.localSelectedItem = null;
    this.$emit('update-link',
      {
        id: null,
      });
  }
}
