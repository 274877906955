import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/Role/Full';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const COMPANY_USER_ROLE_FULL_FRAGMENT = `
  fragment companyUserRoleFullFragment on CompanyUserRole {
    uid
    schemaCode
    moderator
    canPost
    manageSales
    handleMeetings
    manageContent
    manageCompanyUserRoles
    defaultCompany
    state
    user {
     ...communityUserBaseFragment
    }
    company {
      ...exhibitorBaseFragment
    }
    role {
      ...roleFullFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
  ${ROLE_FULL_FRAGMENT}
`;
