import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const ARTICLE_BASE_FRAGMENT = `
  fragment articleBaseFragment on Article {
    id
    uid
    schemaCode
    name
    subtitle
    authorName
    summary
    content
    publicationTime
    images {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
