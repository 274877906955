import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const EXHIBITOR_COMPANY_ROLE_FRAGMENT = `
  fragment exhibitorCompanyRoleFragment on Exhibitor {
    uid
    schemaCode
    name
    displayName
    featured
    mainExhibitor
    logoFileResource {
      ...fileResourceBaseFragment
    }
    repsAmount: _repsAmount
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
