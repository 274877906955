


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import Handout from '@/models/graphql/Handout';
import { Data } from '@/utils/types/WidgetData';
import Exhibitor from '@/models/graphql/Exhibitor';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';

@Component({
  components: { ConfirmModal, ButtonIconComponent },
})
export default class CmsTableConfirmModals extends Vue {
  @Prop({ default: null })
  selectedItem!: Product | Deal | Handout | Data | CommunityUser | ExhibitorVideo | null;

  @Prop({ default: null })
  exhibitor!: Exhibitor;
}
