





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BLink } from 'bootstrap-vue';
import AlertType from '@/utils/enums/AlertType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    BLink,
  },
})
export default class AlertComponent extends Vue {
  @Prop({ required: false, default: '' })
  private readonly content!: string;

  @Prop({ required: false, default: AlertType.PRIMARY })
  private readonly type!: AlertType;

  @Prop({ required: false, default: false })
  private readonly dismissible!: boolean;

  @Prop({ required: false, default: '' })
  private readonly actionText!: string;

  private readonly alertType = AlertType;
}
