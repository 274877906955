



































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUser from '@/models/graphql/CommunityUser';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    DropdownMenuItem,
    DropdownMenu,
    ButtonIconComponent,
    FontAwesomeComponent,
    ButtonComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable no-underscore-dangle */
export default class CrmUserCardButtons extends BreakpointWrapper {
  @Prop({ required: true, default: 'user' })
  private readonly cardContext!: string;

  @Prop({ required: false, default: null })
  private readonly ourConnection!: CommunityUserConnection;

  @Prop({ required: true, default: false })
  private readonly isProfileVisible!: boolean;

  @Prop({ required: false, default: null })
  private readonly actions!: { key: string; value: string }[];

  @Prop({ required: false, default: false })
  private readonly isScheduled!: boolean;

  @Prop({ required: false, default: false })
  private readonly isArchived!: boolean;

  @Prop({ required: false, default: '' })
  private readonly uid!: string;

  @Prop({ required: false, default: '' })
  private readonly name!: string;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserStore.Getter
  private fetchBlockedCommunityUserUids!: string[];

  private ActionRule = ActionRule;

  private ActionRuleService = ActionRuleService;

  private connectionStatusType = ConnectionStatusType;

  private FeatureKeys = FeatureKeys;

  private isBlocked = false;

  private get text(): string {
    return `${this.isBlocked ? this.$t('actions.unblock') : this.$t('actions.block')}`;
  }

  created(): void {
    if (this.authUser) {
      this.isBlocked = this.authUser.haveBlockedUser(this.uid);
    }
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  @Watch('fetchBlockedCommunityUserUids', { deep: true })
  private setIsBlocked(): void {
    this.isBlocked = this.fetchBlockedCommunityUserUids
      .filter((userUid: string) => userUid === this.uid).length > 0;
  }

  private toggleBlockModal(): void {
    if (this.authUser) {
      this.$eventsBus.emit('open-conversation-option-modal', {
        users: [{
          uid: this.uid,
          fullName: this.name,
        }],
        groupId: this.uid,
        actionType: this.isBlocked ? MessageMenuItem.UNBLOCK : MessageMenuItem.BLOCK,
      });
    } else {
      this.$bvModal.show('sign-in-action-modal');
    }
  }
}
