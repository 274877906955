export default {
  product: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    publish: {
      name: 'publish',
      translation: 'admin-panel.menus.item.publish',
      icon: 'fa-regular fa-cloud-arrow-up',
    },
    unpublish: {
      name: 'unpublish',
      translation: 'admin-panel.menus.item.unpublish',
      icon: 'fa-regular fa-cloud-xmark',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  largeproduct: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    publish: {
      name: 'publish',
      translation: 'admin-panel.menus.item.publish',
      icon: 'fa-regular fa-cloud-arrow-up',
    },
    unpublish: {
      name: 'unpublish',
      translation: 'admin-panel.menus.item.unpublish',
      icon: 'fa-regular fa-cloud-xmark',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  boatproduct: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  deal: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    publish: {
      name: 'publish',
      translation: 'admin-panel.menus.item.publish',
      icon: 'fa-regular fa-cloud-arrow-up',
    },
    unpublish: {
      name: 'unpublish',
      translation: 'admin-panel.menus.item.unpublish',
      icon: 'fa-regular fa-cloud-xmark',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  handout: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  communityuser: {
    permissions: {
      name: 'permissions',
      translation: 'admin-panel.menus.item.permissions',
    },
    copyEmail: {
      name: 'copy-email',
      translation: 'admin-panel.menus.item.copy-email',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.remove',
    },
  },
  speaker: {
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  exhibitorvideo: {
    play: {
      name: 'play',
      translation: 'admin-panel.menus.item.play',
      icon: 'fa-regular fa-play',
    },
    edit: {
      name: 'edit',
      translation: 'admin-panel.menus.item.edit',
      icon: 'fa-regular fa-pen',
    },
    delete: {
      name: 'delete',
      translation: 'admin-panel.menus.item.delete',
      icon: 'fa-regular fa-trash-can',
    },
  },
  order: {
    print: {
      name: 'print',
      translation: 'admin-panel.menus.item.print',
      icon: 'fa-regular fa-pen',
    },
    preview: {
      name: 'preview',
      translation: 'admin-panel.menus.item.preview',
      icon: 'fa-regular fa-eye',
    },
  },
};
