










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import FeedAction from '@/utils/enums/feed/FeedAction';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import FeedItem from '@/models/graphql/FeedItem';
import UrlType from '@/utils/enums/UrlType';
import SocialNetworkDetector from 'social-network-detector';

@Component({
  components: { SocialLinksComponent },
})
export default class FeedUrlCard extends Vue {
  @Prop({ required: false })
  private feedItems!: FeedItem[];

  @Prop({ required: false })
  private action!: string;

  private get urls(): { url: string; icon: string; type: string }[] {
    const exhibitorUrls: ExhibitorUrl[] = [];
    const userUrls: CommunityUserUrl[] = [];
    switch (this.action) {
      case FeedAction.EXHIBITOR_URLS_UPDATED:
        this.feedItems.forEach((feedItem) => {
          if (feedItem.triggered) {
            exhibitorUrls.push(feedItem.triggered as ExhibitorUrl);
          }
        });
        return exhibitorUrls.map((url: ExhibitorUrl) => ({
          url: this.getUrl(url),
          icon: url.icon ?? this.getIcon(url),
          type: url.type,
        }));
      case FeedAction.USER_URLS_UPDATED:
        this.feedItems.forEach((feedItem) => {
          if (feedItem.triggered) {
            userUrls.push(feedItem.triggered as CommunityUserUrl);
          }
        });
        return userUrls.map((url) => ({
          url: this.getUrl(url),
          icon: this.getIcon(url),
          type: url.type,
        }));
      default:
        return [];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getUrl(url: CommunityUserUrl | ExhibitorUrl): string {
    if (url.type === UrlType.EMAIL || url.type === UrlType.PHONE) {
      return url.url;
    }
    return url && url.url
    && !url.url.match(/^[a-zA-Z]+:\/\//) ? `https://${url.url}` : url.url || '';
  }

  // eslint-disable-next-line class-methods-use-this
  private getIcon(url: CommunityUserUrl | ExhibitorUrl): string {
    const type = SocialNetworkDetector.detect(url.url);
    return (AppSocialMediaIcons as Record<string, string>)[type ? type.toLowerCase() : 'website']
      || AppSocialMediaIcons.website;
  }
}
