var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paginate-container d-flex flex-column-reverse align-items-start\n        flex-md-row align-items-md-center justify-content-md-between"},[_c('b-skeleton-wrapper',{staticClass:"w-100",attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"row d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-3"},[_c('b-skeleton',{attrs:{"type":"text"}})],1),_c('div',{staticClass:"d-flex align-items-center page-buttons"},[_c('b-skeleton',{attrs:{"height":"40px","type":"button","width":"40px"}}),_c('b-skeleton',{attrs:{"height":"40px","type":"button","width":"40px"}}),_c('b-skeleton',{attrs:{"type":"text","width":"40px"}}),_c('b-skeleton',{attrs:{"height":"40px","type":"button","width":"60px"}}),_c('b-skeleton',{attrs:{"type":"text","width":"40px"}}),_c('b-skeleton',{attrs:{"height":"40px","type":"button","width":"40px"}}),_c('b-skeleton',{attrs:{"height":"40px","type":"button","width":"40px"}})],1)])]},proxy:true},{key:"default",fn:function(){return [(!_vm.hideShowingOut)?_c('p',{staticClass:"current-range d-flex w-100\n         justify-content-center justify-content-md-start text-neutral-n-6-label text-nowrap m-0"},[_vm._v(" "+_vm._s(_vm.$tc('component.pagination.range-text', 0, _vm.showingOut))+" ")]):_vm._e(),_c('div',{staticClass:"page-buttons d-flex align-items-center\n      w-100 justify-content-between justify-content-md-end"},[_c('button-icon-component',{staticClass:"d-none d-md-flex ",attrs:{"disabled":_vm.page === 1,"size":"md","variant":"tertiary"},on:{"on-click":_vm.goToStart},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-chevrons-left","container-classes":"icon-24","icon-classes":[
                'fa-1x',
                _vm.page === 1
                  ? 'text-neutral-n-5-placeholder'
                  : 'text-neutral-n-8-dark-ink'
              ]}})]},proxy:true}])}),_c('button-icon-component',{staticClass:"mr-2 mr-md-0",attrs:{"disabled":_vm.page === 1,"size":"md","variant":"tertiary"},on:{"on-click":_vm.goToPrev},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-chevron-left","container-classes":"icon-24","icon-classes":[
                'fa-1x',
                _vm.page === 1
                  ? 'text-neutral-n-5-placeholder'
                  : 'text-neutral-n-8-dark-ink'
              ]}})]},proxy:true}])}),_c('p',{staticClass:"d-flex text-left text-neutral-n-8-dark-ink text-nowrap m-0 px-2"},[_vm._v(" "+_vm._s(_vm.$tc('component.pagination.page-text'))+" ")]),_c('div',{staticClass:"input-container"},[_c('b-form-input',{staticClass:"input-number rounded-sm",attrs:{"max":_vm.pages,"min":1,"state":(_vm.page < 1 || _vm.page > _vm.pages) ? false : null,"number":"","trim":"","type":"number"},on:{"input":function($event){_vm.enterValidation = false},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnterPressed.apply(null, arguments)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('p',{staticClass:"current-range text-left text-neutral-n-8-dark-ink text-nowrap m-0 px-2"},[_vm._v(" "+_vm._s(_vm.$tc('component.pagination.of-text'))+" "+_vm._s(_vm.pages)+" ")]),_c('button-icon-component',{attrs:{"disabled":_vm.page === _vm.pages,"size":"md","variant":"tertiary"},on:{"on-click":_vm.goToNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-chevron-right","container-classes":"icon-24","icon-classes":[
                'fa-1x',
                _vm.page === _vm.pages
                  ? 'text-neutral-n-5-placeholder'
                  : 'text-neutral-n-8-dark-ink'
              ]}})]},proxy:true}])}),_c('button-icon-component',{staticClass:"d-none d-md-flex",attrs:{"disabled":_vm.page === _vm.pages,"size":"md","variant":"tertiary"},on:{"on-click":_vm.goToLast},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-chevrons-right","container-classes":"icon-24","icon-classes":[
                'fa-1x',
                _vm.page === _vm.pages
                  ? 'text-neutral-n-5-placeholder'
                  : 'text-neutral-n-8-dark-ink'
              ]}})]},proxy:true}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }