
































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { validationMixin } from 'vuelidate';
import { helpers, required } from 'vuelidate/lib/validators';
import {
  BForm, BFormInput, BIconEnvelope, BIconInfoCircle, BPopover,
} from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import { namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import AlertType from '@/utils/enums/AlertType';
import MessagePage from '@/views/guest/MessagePage.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ActionType from '@/utils/enums/ActionType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { EMAIL } from '@/utils/constants/Regex';
import TranslateResult = VueI18n.TranslateResult;

const emailValidation = helpers.regex('email', EMAIL);
const accountStore = namespace('AccountStore');
const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    MessagePage,
    AlertComponent,
    InputText,
    BForm,
    BFormInput,
    BIconEnvelope,
    BIconInfoCircle,
    BPopover,
    ButtonComponent,
  },
  data(): object {
    return {
      emailSend: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        emailValidation,
      },
    },
  },
})
export default class SendToEmailPage extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  private readonly errorMessage!: string;

  @accountStore.Action
  private triggerRecovery!: (payload: {
    communityCode: string;
    email: string;
  }) => void;

  @communityUserStore.Action(ActionType.GET_ONE)
  private getCommunityUser!: (payload: { filter: { email: string } }) => void;

  @communityUserStore.Getter
  private communityUser!: CommunityUser;

  private pageType = '';

  private form = {
    email: '',
  };

  private emailFieldIsDirty = false;

  private emailExist = false;

  private alertType = AlertType;

  private messagePageType = '';

  private lowerCaseEmail = '';

  private userByEmail: CommunityUser | null = null;

  private get validateState(): boolean {
    const $dirty = this.$v.form.email?.$dirty;
    const $error = this.$v.form.email?.$error;
    if (this.pageType === 'change-email' && this.communityUser
      && this.communityUser.uid
      && this.communityUser.uid.length !== 0) {
      return false;
    }
    return $dirty
      ? !$error
      : true;
  }

  private get pageTitle(): TranslateResult | string {
    switch (this.pageType) {
      case 'find-account':
        return this.$t('app.global.find-account-email-form.title');
      case 'reset-password':
        return this.$t('app.global.reset-password-email-form.title');
      case 'change-email':
        return this.$t('app.global.change-email-form.title');
      default:
        return '';
    }
  }

  private get pageSubtitle(): TranslateResult | string {
    switch (this.pageType) {
      case 'find-account':
        return this.$t('app.global.find-account-email-form.message');
      case 'reset-password':
        return this.$t('app.global.reset-password-email-form.message');
      case 'change-email':
        return this.$t('app.global.change-email-form.message');
      default:
        return '';
    }
  }

  private get inputLabel(): TranslateResult | string {
    switch (this.pageType) {
      case 'find-account':
        return this.$t('app.global.find-account-email-form.input-label');
      case 'reset-password':
        return this.$t('app.global.reset-password-email-form.input-label');
      case 'change-email':
        return this.$t('app.global.change-email-form.input-label');
      default:
        return '';
    }
  }

  private get btnText(): TranslateResult | string {
    switch (this.pageType) {
      case 'find-account':
        return this.$t('app.global.find-account-email-form.button.continue');
      case 'reset-password':
        return this.$t('app.global.reset-password-email-form.button.continue');
      case 'change-email':
        return this.$t('app.global.change-email-form.button.continue');
      default:
        return '';
    }
  }

  created(): void {
    this.setDataConfig();
    const payload = JSON.parse(this.widget?.payload || '{}');
    if (payload.code) {
      switch (payload.code) {
        case 'FindAccountWidget':
          this.pageType = 'find-account';
          break;
        case 'ResetPasswordWidget':
          this.pageType = 'reset-password';
          break;
        default:
          this.pageType = '';
      }
    }
  }

  @Watch('communityUser')
  onCheckUserChange(): void {
    if (!this.emailFieldIsDirty) {
      this.emailExist = !!this.communityUser;
    }
  }

  onCommunityUserChange(): void {
    if (!this.emailFieldIsDirty) {
      this.emailExist = !!this.userByEmail;
    }
  }

  @Watch('$v.form.email.$model')
  onChangeEmail(): void {
    this.lowerCaseEmail = this.form.email.toLowerCase();
    if (!this.$v.form.email?.$invalid) {
      const $error = this.$v.form.email?.$error;
      const $invalid = this.$v.form.email?.$invalid;
      if ($invalid) {
        this.emailFieldIsDirty = !!$error;
      } else {
        this.emailFieldIsDirty = false;
      }
    }

    if (this.pageType === 'change-email'
      && (!this.$v.form.email?.$invalid || this.form.email === '')) {
      this.getCommunityUser({
        filter: { email: this.lowerCaseEmail },
      });
    }
  }

  private onBlur(): void {
    if (this.form.email !== '') {
      const $error = this.$v.form.email?.$error;
      const $dirty = this.$v.form.email?.$dirty;
      if ($error) {
        this.emailFieldIsDirty = !!$dirty;
      }
    } else {
      this.emailFieldIsDirty = true;
      if (this.pageType === 'change-email') {
        this.userByEmail = null;
        this.onCommunityUserChange();
      }
    }
  }

  private onSubmit(): void {
    if (this.$v.$invalid) {
      const $invalid = this.$v.form.email?.$invalid;
      const $required = this.$v.form.email?.required;
      if ($invalid) {
        this.emailFieldIsDirty = !$required;
      }
    } else {
      switch (this.pageType) {
        case 'find-account':
          this.findAccountProcess();
          break;
        case 'reset-password':
          this.resetPasswordProcess();
          break;
        case 'change-email':
          this.changeEmailProcess();
          break;
        default:
      }
      this.$data.emailSend = true;
    }
  }

  private disabledSubmit(): boolean {
    if (this.pageType === 'change-email') {
      return this.$v.$invalid || (this.communityUser
        && this.communityUser.uid !== '');
    }
    return this.$v.$invalid;
  }

  private findAccountProcess(): void {
    this.triggerRecovery({
      communityCode: this.community.code ? this.community.code : '',
      email: this.lowerCaseEmail,
    });
    this.messagePageType = 'find-account-email-success';
  }

  private resetPasswordProcess(): void {
    this.triggerRecovery({
      communityCode: this.community.code ? this.community.code : '',
      email: this.lowerCaseEmail,
    });
    this.messagePageType = 'password-email-success';
  }

  private changeEmailProcess(): void {
    this.$emit('email-changed', this.lowerCaseEmail);
  }
}
