import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment exhibitorActionButtonMessageFragment on Exhibitor {
    uid
    name
    logoFileResource {
      ...fileResourceBaseFragment
    }
    _chatReps {
      uid
      state
      moderator
      user {
        uid
        name
        firstName
        lastName
        pictureFileResource {
          ...fileResourceBaseFragment
        }
      }
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
