





























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Exhibitor from '@/models/graphql/Exhibitor';
import InputSelectOptionsParams from '@/utils/types/InputSelectOptionParams';
import InputSelectOptionsComponent from '@/components/InputSelectOptionsComponent.vue';
import Country from '@/models/graphql/Country';
import TextEditorComponent from '@/components/TextEditorComponent.vue';
import { FetchResult } from '@apollo/client';
import ToastActionType from '@/utils/enums/ToastActionType';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import ToastActionParams from '@/utils/types/ToastActionParams';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import MyProfileInputVideo from '@/components/MyProfileInputVideo.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import LocaleModel from '@/models/LocaleModel';
import EntityTranslationParams from '@/utils/types/EntityTranslationParams';
import UiPage from '@/models/graphql/UiPage';

const countryStore = namespace('CountryStore');
const exhibitorStore = namespace('ExhibitorStore');
const toastStore = namespace('ToastStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');

@Component({
  components: {
    MyProfileInputVideo,
    MyProfileInputText,
    InputSelectOptionsComponent,
    TextAreaComponent,
    TextEditorComponent,
  },
})
export default class CompanyInformation extends VueBaseWidget {
  @Getter
  protected currentPage!: UiPage;

  @Prop({ required: true })
  private company!: Exhibitor;

  @Prop({ default: false })
  private disabled!: boolean;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private FeatureKeys = FeatureKeys;

  @countryStore.Getter
  private fetchCountries!: Country[];

  @countryStore.Action
  private loadCountries!: (filter?: object) => Promise<Country[]>;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string }) => Promise<{ type: string; limit: number }[]>;

  @exhibitorStore.Action
  private updateExhibitor!: (payload: {
    uid: string;
    attributes: Partial<Exhibitor>;
  }) => Promise<Exhibitor | undefined>;

  @exhibitorStore.Action
  private updateExhibitorByLocale!: (payload: {
    uid: string;
    attributes: Partial<Exhibitor>;
    locale: string;
  }) => Promise<Exhibitor | undefined>;

  @exhibitorStore.Mutation
  private setAdminPanelExhibitorName!: (exhibitor: Exhibitor) => void;

  @countryStore.Action
  private addCountryToExhibitor!: (payload: {
    countryUid: string; exhibitorUid: string;
  }) => Promise<FetchResult<Country, object, object> | undefined>;

  @countryStore.Action
  private removeFromExhibitor!: (payload: {
    countryUid: string; exhibitorUid: string;
  }) => Promise<FetchResult<Country, object, object> | undefined>;

  @exhibitorStore.Action
  private setExhibitorCountry!: (payload: {
    countryUid: string; exhibitorUid: string;
  }) => Promise<Exhibitor | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @exhibitorStore.Mutation
  private setAdminPanelExhibitorDescription!: (exhibitor: Exhibitor) => void;

  @State
  private readonly locales!: LocaleModel[];

  private previousDescription = '';

  private descriptionLimit = -1;

  private editorConfigs = {
    readOnly: false,
    theme: 'snow',
    placeholder: '',
    formats: [
      'bold',
      'italic',
      'underline',
      'list',
    ],
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    return salesServiceFeature && salesServiceFeature.enabled;
  }

  private get parsedCountriesList(): ({
    uid: string; value: string; text: string; iconClass?: string; countryFlag?: string;
  } | null)[] {
    return this.fetchCountries.map((country) => (country
      ? {
        uid: country.uid,
        value: country.iso ? country.iso : '',
        text: country.printableName ? country.printableName : '',
        countryFlag: country.iso ? `https://cdn.ipregistry.co/flags/emojitwo/${country.iso
          .toLowerCase()}.svg` : '',
        iconClass: undefined,
      }
      : null));
  }

  private get formatCompanyDescription(): string {
    if (!this.company.description) {
      return '';
    }

    const htmlRegex = /<?[a-z][\s\S]*>.*<\/[a-z][\s\S]*>/i;

    if (htmlRegex.test(this.company.description)) {
      return this.company.description;
    }
    let formattedText = '';
    const lines = this.company.description.split('\n');
    lines.forEach((line) => {
      if (line === '') {
        formattedText += '<p><br></p>';
      } else {
        formattedText += `<p>${line}</p>`;
      }
    });
    return formattedText;
  }

  private get descriptionTranslations(): EntityTranslationParams[] {
    if (this.locales && this.locales.length > 1) {
      return Exhibitor.hydrate(this.company)
        .translationsByField('description');
    }
    return [];
  }

  created(): void {
    this.loadCountries({ schemaCode: this.community?.code });
    this.setDescription();
    if (this.isNewLimitCalculationFeatureEnabled) {
      this.filterSlots({
        exhibitorUid: this.$route.params[this.currentPage.paramName || 'companyId'],
        code: SlotTypeEnum.DESCRIPTION_LENGTH,
      })
        .then((response) => {
          if (response && response.length) {
            this.descriptionLimit = response[0].limit;
          }
        });
    }
  }

  mounted(): void {
    if (!this.isNewLimitCalculationFeatureEnabled) {
      // eslint-disable-next-line no-underscore-dangle
      this.descriptionLimit = this.company._effectiveLimitNumDescription ?? -1;
    }
  }

  @Watch('company.description')
  private setDescription(): void {
    this.previousDescription = this.company.description?.toString() || '';
  }

  private onLocationChange(option: InputSelectOptionsParams): void {
    this.setExhibitorCountry({
      countryUid: option.uid,
      exhibitorUid: this.company.uid,
    })
      .then(() => {
        this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
      });
  }

  private onDescriptionChange(data: { root: { innerHTML: string }; locale?: string }): void {
    this.updateExhibitorByLocale({
      uid: this.company.uid,
      attributes: {
        uid: this.company.uid,
        description: data.root.innerHTML,
      },
      locale: data.locale ?? this.$i18n.locale,
    })
      .then((response) => {
        if (response) {
          this.setAdminPanelExhibitorDescription(response);
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
        }
      });

    this.previousDescription = data.root.innerHTML;
  }

  private onDisplayNameChange(newValue: string): void {
    this.updateExhibitor({
      uid: this.company.uid,
      attributes: {
        uid: this.company.uid,
        displayName: newValue !== '' ? newValue : null,
      },
    })
      .then((response) => {
        if (response) {
          this.setAdminPanelExhibitorName(response);
          this.showToast(ToastActionType.UPDATE_COMPANY_INFORMATION);
        }
      });
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
