import BaseModel from '@/models/BaseModel';
/* eslint-disable import/no-cycle */
import FileResource from '@/models/graphql/FileResource';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';

class Topic extends BaseModel {
  id?: number;

  name?: string;

  description?: string;

  organizerOnlyPosting?: boolean;

  foreignId?: string;

  deleted?: boolean;

  publicationTime?: Date;

  startTimestamp?: number;

  endTimestamp?: number;

  autoFollow?: boolean;

  _isFollowed?: string | null;

  _followerCount?: number;

  __typename?: string;

  _myTags?: CommunityUserTag[];

  status?: string;

  imageFileResource?: FileResource;

  thumbnailFileResource?: FileResource;

  _actions?: { key: string; value: string }[];

  feed: FeedItemWrapper[] = [];

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.imageFileResource, 'w72');
  }

  static hydrate(item: object): Topic {
    const result = new Topic();
    Object.assign(result, item);

    return result;
  }
}

export default Topic;
